<template>
  <DataTable
    :items.sync="productSummary"
    :headers="headers"
    :propDense="true"
    :customCells="customCells"
    :hideHeader="true"
    :actions="[]"
  >
    <template #weight="{item}">
      {{ tonStringFromPounds(item.weight) }}
    </template>
    <template #amount="{item}">
      {{ formatMoney(item.amount) }}
    </template>
    <template #rate="{item}">
      {{ formatMoney(item.rate) }}
    </template>
    <template #profit-loss="{item}">
      <span :style="`color: ${item.pl < 0 ? 'red' : 'black'}`">
        {{getPLString(item.pl)}}
      </span>
    </template>
    <template #body.append>
      <tr class="font-weight-bold">
        <td class="text-lg-left">Totals</td>
        <td class="text-lg-right">-</td>
        <td class="text-lg-right">{{ totals.totalLoads }}</td>
        <td class="text-lg-right">{{ tonStringFromPounds(totals.totalWeight) }}</td>
        <td class="text-lg-right">{{ formatMoney(totals.totalAmount) }}</td>
      </tr>
    </template>
  </DataTable>
</template>

<script>
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations'
import { tractProductionHeaders } from '@/headers/Cycles.js'
export default {
  name: 'ProductSummaryTable',

  props: {
    productSummary: {
      type: Array,
      required: true
    }
  },

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue')
  },

  data: () => ({
    customCells: [
      {
        slotName: 'weight',
        value: 'weight'
      },
      {
        slotName: 'amount',
        value: 'amount'
      },
      {
        slotName: 'profit-loss',
        value: 'pl'
      },
      {
        slotName: 'rate',
        value: 'rate'
      }
    ]
  }),

  computed: {
    headers () {
      return tractProductionHeaders(this.$i18n.locale)
    },

    totals () {
      const totals = {
        totalWeight: 0,
        totalAmount: 0,
        totalLoads: 0
      }

      this.productSummary.forEach(ps => {
        totals.totalWeight += ps.weight
        totals.totalAmount += ps.amount
        totals.totalLoads += ps.loads
      })

      return totals
    }
  },

  methods: {
    tonStringFromPounds,
    formatMoney
  }
}
</script>
