<template>
  <v-menu
    bottom
    offset-y
    left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon class="mx-n1" style="margin-top:14px;">
        <v-icon
        medium
        v-bind="attrs"
        color="secondary"
        data-testid="tract-table-actions"
        v-on="on">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(option, i) in options" :key="`t-ctx-menu-${i}`" @click="option.action">
        <v-avatar left>
          <v-icon color="black">{{ option.icon }}</v-icon>
        </v-avatar>
        <v-list-item-title class="subtitle-1">{{ option.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'TractContextMenu',

  computed: {
    options () {
      return [
        {
          label: this.$t('downloadCSV'),
          icon: 'mdi-file-delimited-outline',
          action: () => this.$emit('download-csv')
        },
        {
          label: this.$t('downloadPTForm'),
          icon: 'mdi-file-pdf-box',
          action: () => this.$emit('download-pt-form')
        }
      ]
    }
  }
}
</script>
