export default {
  tracts: [],
  tractsLoading: false,
  chosenTract: null,
  transitionMode: null,
  tractTimberVolume: [],
  standingTimberFilter: {
    status: [],
    tractType: [],
    tractForester: []
  },
  committedTimberFilter: {
    status: [],
    tractType: [],
    tractForester: []
  }
}
