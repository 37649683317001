<template>
  <v-layout wrap>
    <slot name="prepend-search"/>
    <v-text-field
      v-if="showSearch"
      v-model="search"
      append-icon="mdi-magnify"
      :label="$t('search')"
      single-line
      clearable
      class="mr-2"
      color="secondary"
    ></v-text-field>
    <slot name="search-replacement"/>
    <v-tooltip bottom color="secondary">
      <template v-slot:activator="{ on }">
        <v-switch
          color="secondary"
          small
          v-if="showDense"
          class="ml-5"
          v-model="dense"
          v-on="on"
        ></v-switch>
      </template>
      <span class="white--text subtitle-1">{{ $t("denseView") }}</span>
    </v-tooltip>
    <slot name="custom-controls"></slot>
    <Icon
      v-for="action in actions"
      :dataTestId="`table-${action.text}`"
      :key="action.actionName"
      margin="mr-2"
      :small="false"
      :icon="action.icon"
      :tooltipText="action.text"
      :disabled="action.disabled"
      @icon-clicked="$emit(`table-action`, action.actionName)"
    />
  </v-layout>
</template>

<script>
export default {
  name: 'TableActions',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    actions: {
      type: Array
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    showDense: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    dense (val) {
      this.$emit('dense-toggled', val)
    },
    search (val) {
      this.$emit('search', val)
    }
  },

  data: () => ({
    dense: true,
    search: ''
  })
}
</script>
