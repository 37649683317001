<template>
  <v-card>
    <v-card-title class="secondary">
      <span class="headline white--text">{{ titleText }}</span>
      <v-spacer/>
      <v-icon large color="white" @click="$emit('close-form')">mdi-close</v-icon>
    </v-card-title>
    <v-card-text class="mt-6">
      <v-row align="center">
        <v-col :cols="isSingleTract ? 12 : 6">
          <v-select
            v-model="selectedYear"
            data-testid="tract-fiscal-year"
            :items="fiscalYears"
            color="black"
            item-color="secondary"
            :label="$t('fiscalYear')">
          </v-select>
        </v-col>
        <v-col :cols="isSingleTract ? 12 : 6">
          <v-select
            v-model="selectedQuarter"
            data-testid="tract-quarter"
            :items="quarters"
            color="black"
            item-text="name"
            item-color="secondary"
            item-value="value"
            :label="$t('quarter')">
          </v-select>
        </v-col>
      </v-row>
      <v-row dense v-if="!isSingleTract">
        <v-col cols="12">
          <v-data-table
          :headers="headers"
          :items="activeTracts"
          :hide-default-footer="activeTracts.length < 10"
          dense>
            <template #item.actions="{item}">
              <Icon
                icon="mdi-download"
                iconColor="black"
                :tooltipText="$t('ptFormDownloadFile', { tractName: item.name, year: selectedYear, quarter: selectedQuarter })"
                @icon-clicked="downloadOneTract(item)"
              />
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12">
          <v-list>
            <v-list-item v-for="(error, i) in errors" :key="`pt-error-${i}`">
              <v-list-item-avatar>
                <v-icon left color="red">
                  mdi-alert-circle-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="subtitle-1">{{ error }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
      :disabled="!yearAndQuarterSelection"
      color="secondary"
      @click="downloadAsZip(tract)">
        {{downloadText}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { localToUTC } from '@/utils/DateFormatter.js'
import moment from 'moment'
import { getPtFormBlobForTract } from '@/utils/pdf-formatters/PTFormFill.js'
import { tractPtDownloadHeaders } from '@/headers/Tract.js'
import { fileNameForString } from '@/utils/pdf-formatters/GenericSettlementsFunctions.js'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
export default {
  name: 'TractPtFormDownload',

  props: {
    tract: {
      type: Object,
      required: false
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    activeFiscalYear: undefined,
    activePayPeriod: undefined,
    selectedYear: undefined,
    selectedQuarter: undefined,
    activeTracts: [],
    errors: []
  }),

  async created () {
    await this.fetchFiscalYears()
  },

  computed: {
    ...mapGetters('fiscal-year', ['allFiscalYears']),
    isSingleTract () {
      return this.tract?.tractId !== undefined
    },

    headers () {
      return tractPtDownloadHeaders()
    },

    downloadText () {
      if (!this.yearAndQuarterSelection) return this.$t('selectPeriod')
      return this.isSingleTract || this.activeTracts.length === 1 ? this.$t('getReport') : this.$t('downloadAsZip')
    },

    titleText () {
      return this.isSingleTract
        ? this.$t('ptFormDownload', { tractName: this.tract.name })
        : this.$t('ptFormSelectPeriod')
    },

    fiscalYears () {
      const minYear = Math.min(...this.allFiscalYears.map(fy => moment(fy.startDate).year()))

      const yearsArray = []
      for (let year = minYear; year <= moment().year(); year++) {
        yearsArray.push(year)
      }

      return yearsArray
    },

    quarters () {
      return [{ name: 'Q1', value: 1 }, { name: 'Q2', value: 2 }, { name: 'Q3', value: 3 }, { name: 'Q4', value: 4 }]
    },

    yearAndQuarterSelection () {
      if (!this.selectedYear || !this.selectedQuarter) return null

      switch (this.selectedQuarter) {
        case 1:
          return {
            startDate: localToUTC(new Date(this.selectedYear, 0, 1).toISOString()),
            endDate: localToUTC(new Date(this.selectedYear, 3, 1).toISOString())
          }
        case 2:
          return {
            startDate: localToUTC(new Date(this.selectedYear, 3, 1).toISOString()),
            endDate: localToUTC(new Date(this.selectedYear, 6, 1).toISOString())
          }
        case 3:
          return {
            startDate: localToUTC(new Date(this.selectedYear, 6, 1).toISOString()),
            endDate: localToUTC(new Date(this.selectedYear, 9, 1).toISOString())
          }
        case 4:
          return {
            startDate: localToUTC(new Date(this.selectedYear, 9, 1).toISOString()),
            endDate: localToUTC(new Date(this.selectedYear, 12, 1).toISOString())
          }
        default: return null
      }
    }
  },

  watch: {
    yearAndQuarterSelection (val) {
      if (!this.isSingleTract) {
        this.refreshActiveTracts(val)
      }
    }
  },

  methods: {
    ...mapActions('fiscal-year', ['fetchFiscalYears']),
    ...mapActions('tract', ['fetchActiveTractsForPeriod', 'fetchTractTimberVolume']),

    async downloadOneTract (tract) {
      const blob = await this.getPDF(tract)
      const fileName = fileNameForString(`PT-283T_${this.selectedYear}_Q${this.selectedQuarter}_${tract.name}`, 'pdf')
      saveAs(blob, fileName)
    },

    async downloadAsZip () {
      this.errors = []
      if (this.isSingleTract || this.activeTracts.length === 1) {
        this.downloadOneTract(this.tract || this.activeTracts[0])
      } else {
        const zip = new JSZip()

        for (const tract of this.activeTracts) {
          try {
            const blob = await this.getPDF(tract)
            const fileName = fileNameForString(`PT-283T_${this.selectedYear}_Q${this.selectedQuarter}_${tract.name}`, 'pdf')
            zip.file(fileName, blob)
          } catch (error) {
            console.error(error)
            const message = this.$t('errorDownloadingPtForm', { tractName: tract.name, error: error?.response?.data?.message ?? error.message })
            this.errors.push(message)
          }
        }

        if (this.errors.length !== 0) return

        const downloadableZip = await zip.generateAsync({ type: 'blob' })
        saveAs(downloadableZip, `PT-283T_${this.selectedYear}_Q${this.selectedQuarter}`)
      }
    },

    getTimberVolumeRequest (tract) {
      if (this.yearAndQuarterSelection === null) return null

      return {
        tractId: tract.tractId,
        ...this.yearAndQuarterSelection
      }
    },

    async refreshActiveTracts (period) {
      const georgiaAbbreviation = 'GA'
      this.activeTracts = (await this.fetchActiveTractsForPeriod(period)).filter(t => t.countrySubdivision === georgiaAbbreviation && t.landownerCount > 0)
    },

    async getPDF (tract) {
      const request = this.getTimberVolumeRequest(tract)
      const data = await this.fetchTractTimberVolume(request)

      const payload = {
        tract: this.tract,
        timberTypeVolume: data,
        year: this.selectedYear,
        quarter: this.selectedQuarter
      }

      return await getPtFormBlobForTract(payload)
    }
  }
}
</script>
