<template>
  <v-container fill-height fluid grid-list-xl>
    <v-row dense>
      <v-col>
        <material-card
          color="secondary"
          :title="$t('devices')"
          :text="$t('activeDevices')"
          full-width
        >
          <DataTable
          :items.sync="deviceRegistrationsWithStatus"
          :headers="headers"
          :actions="tableActions"
          :customCells="customCells"
          @refresh="getRegistrations"
          :loading.sync="loading">
          <template #selection-controls>
            <v-chip-group>
              <v-chip class="mx-2" color="black" text-color="white" close @click:close="removeFilterItem(chip.key)" v-for="chip in filterChips" :key="chip.key">
                <span>
                  <img
                  contain
                  class="mr-1 image-white"
                  height="18"
                  :src="chip.src"
                  v-if="chip.src"/>
                </span>
                {{(chip.text)}}
              </v-chip>
            </v-chip-group>
          </template>
          <template #status="{ item }">
            <Icon
            v-if="item.expiryStatus === 'expired'"
            icon="mdi-close"
            iconColor="error"
            tooltipColor="error"
            :tooltipText="$t('deviceExpired')"/>
            <Icon
            v-else-if="item.expiryStatus === 'warning'"
            dataTestId="device-expirywarning"
            icon="mdi-alert-outline"
            iconColor="warning"
            tooltipColor="warning"
            :tooltipText="$t('deviceExpiryWarning1mo')"/>
            <Icon
            v-else
            dataTestId="device-no-expirywarning"
            icon="mdi-checkbox-marked-circle"
            iconColor="success"
            tooltipColor="success"
            :tooltipText="$t('deviceNoExpiry')"/>
          </template>
          <template #custom-controls>
            <DeviceRegistrationFilter />
          </template>
          <template #actions="{ item }">
            <Icon
            v-if="item.expiryStatus !== 'expired' && getMaxExtensionMonths(item) > 0"
            iconColor="'normal"
            tooltipColor="grey"
            icon="mdi-clock-plus-outline"
            :tooltipText="$t('extend')"
            @icon-clicked="extendRegistration(item)"
            />
            <Icon
            icon="mdi-delete-forever"
            iconColor="error"
            tooltipColor="error"
            :tooltipText="$t('delete')"
            @icon-clicked="removeRegistration(item)"/>
          </template>
          <template #application="{ item }">
            <v-tooltip
            v-if="applicationImageFor(item).value !== -1"
            bottom
            color="black">
              <template v-slot:activator="{ on, attrs }">
                <img
                  contain
                  class="mr-1"
                  height="18"
                  v-bind="attrs"
                  v-on="on"
                  :src="applicationImageFor(item).src"/>
              </template>
              <span class="subtitle-1 white--text" style="white-space: pre-line">{{$t(applicationImageFor(item).label)}}</span>
            </v-tooltip>
            <Icon v-else
            icon="mdi-help"
            iconColor="black"
            :tooltipText="$t(applicationImageFor(item).label)"
            tooltipColor="black"
            />
          </template>
          <template #version="{item}">
            <v-tooltip
            v-if="item.lastReportedVersion != null"
            bottom
            color="secondary">
              <template v-slot:activator="{on}">
                <span v-on="on">
                  {{ item.lastReportedVersion }}
                </span>
              </template>
              <span class="subtitle-1 white--text" style="white-space: pre-line">{{$t('lastUpdatedX', { x: utcToLocalDate(item.versionRecordedOn, 'L - LT')})}}</span>
            </v-tooltip>
          </template>
          </DataTable>
        </material-card>
        <Dialog :stateId="dialogId" maxWidth="400px" @dialog-closing="close">
          <NumberPickerDialog
            v-if="extendTill"
            :title="$t('extendRegistrationTitle', { object: 'device' })"
            :body="$t('extendRegistrationSubtitle', { object: 'device' })"
            :min="0"
            :max="getMaxExtensionMonths(this.focusedDevice)"
            :count="getMaxExtensionMonths(this.focusedDevice)"
            :selectText="$t('extend')"
            @confirm="extendItem"
            @cancel="closeExtend"
          />
          <ConfirmDelete
            v-if="deleting"
            :title="$t('device')"
            @delete="deleteItem"
            @cancel-delete="closeDelete"
          />
        </Dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions, mapState } from 'vuex'
import DeviceRegistrations from '@/headers/DeviceRegistration'
import { TicketOriginImage } from '@/model/Ticket'
import { utcToLocalDate } from '@/utils/DateFormatter.js'

const LOADER_APPLICATION = 0
const TRANSPORTER_APPLICATION = 1

export default {
  name: 'DeviceRegistrations',

  data: () => ({
    dialogId: 'device-registrations',
    search: '',
    deleting: false,
    focusedDevice: {},
    extendTill: undefined,
    dense: true,
    loading: false
  }),

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue'),
    NumberPickerDialog: () => import('@/components/helper/NumberPickerDialog.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Icon: () => import('../helper/Icon.vue'),
    DeviceRegistrationFilter: () => import('@/components/settings-components/device-registration/DeviceRegistrationFilter.vue')
  },

  computed: {
    ...mapGetters('device-registration', ['allDeviceRegistrations', 'filter']),

    ...mapState({
      deviceRegistrationsWithStatus (state) {
        return state['device-registration'].deviceRegistrations.map(device => {
          const expired = moment(device.expiresOn).isBefore(moment.now())
          const warning = moment(device.expiresOn).diff(moment.now(), 'weeks') <= 4
          return {
            ...device,
            expiryStatus: expired ? 'expired' : warning ? 'warning' : 'valid'
          }
        }).filter(r => this.filterRegistrations(this.filter, r))
      }
    }),

    tableActions () {
      return [
        {
          actionName: 'refresh',
          icon: 'mdi-refresh',
          text: this.$t('refresh')
        }
      ]
    },

    customCells () {
      return [
        {
          slotName: 'status',
          value: 'status'
        },
        {
          slotName: 'application',
          value: 'application'
        },
        {
          slotName: 'expires-on',
          value: 'expiresOn',
          dateFormat: 'YYYY-MM-DD @ HH:mm'
        },
        {
          slotName: 'registered-on',
          value: 'registeredOn',
          dateFormat: 'YYYY-MM-DD @ HH:mm'
        },
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'version',
          value: 'lastReportedVersion'
        }
      ]
    },

    headers () { return DeviceRegistrations.deviceRegistrationHeaders() },

    filterChips () {
      const chips = []

      if (this.filter.includeLoader) {
        chips.push({
          text: this.$t('loader'),
          key: 'includeLoader',
          src: TicketOriginImage.LOADER_SVG
        })
      }

      if (this.filter.includeTransporter) {
        chips.push({
          text: this.$t('transporter'),
          key: 'includeTransporter',
          src: TicketOriginImage.TRANSPORTER_SVG
        })
      }
      return chips
    }
  },

  async created () {
    this.getRegistrations()
    await this.initializeFilter()
  },

  methods: {
    utcToLocalDate,
    ...mapActions('device-registration', ['fetchDeviceRegistrations', 'updateRegistration', 'deleteRegistration', 'setFilter', 'removeFilter', 'initializeFilter']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),

    getMaxExtensionMonths (registration) {
      return (registration && registration.expiresOn !== undefined)
        ? moment.utc().add(1, 'year').diff(moment(registration.expiresOn), 'months')
        : 0
    },

    async getRegistrations () {
      this.loading = true
      try {
        await this.fetchDeviceRegistrations()
      } finally {
        this.loading = false
      }
    },

    removeFilterItem (filterKey) {
      this.removeFilter({ key: filterKey, defaultValue: false })
    },

    filterRegistrations (filter, element) {
      if (filter.includeLoader === filter.includeTransporter) {
        return true
      } else if (filter.includeLoader && !filter.includeTransporter) {
        return element.application === LOADER_APPLICATION
      } else if (filter.includeTransporter && !filter.includeLoader) {
        return element.application === TRANSPORTER_APPLICATION
      }
      return true
    },

    applicationImageFor (item) {
      const icons = [
        {
          value: 0,
          label: 'createdOnLoader',
          src: TicketOriginImage.LOADER
        },
        {
          value: 1,
          label: 'createdOnTransporter',
          src: TicketOriginImage.TRANSPORTER
        },
        {
          value: 3,
          label: 'createdOnYardOperator',
          src: TicketOriginImage.YARD_OPERATOR
        }
      ]
      return icons.find(i => i.value === item.application) ?? {
        value: -1,
        label: 'unknown',
        src: ''
      }
    },

    closeExtend () {
      this.focusedDevice = Object.assign({}, {})
      this.extendTill = undefined
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    extendRegistration (registration) {
      this.focusedDevice = registration
      this.extendTill = 1
      this.openOrUpdateDialog({ id: this.dialogId, width: '400px' })
    },

    async extendItem (i) {
      const newExpiry = moment(this.focusedDevice.expiresOn).add(i, 'months').utcOffset(0, false).toISOString()
      this.focusedDevice.updatedExpiresOn = newExpiry
      await this.updateRegistration(this.focusedDevice)
      this.closeExtend()
      this.getRegistrations()
    },

    removeRegistration (registration) {
      this.focusedDevice = registration
      this.deleting = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '400px', allowFullscreen: false })
    },

    closeDelete () {
      this.focusedDevice = Object.assign({}, {})
      this.deleting = false
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    close () {
      this.closeDelete()
      this.closeExtend()
    },

    async deleteItem () {
      await this.deleteRegistration(this.focusedDevice)
      this.closeDelete()
      this.getRegistrations()
    }
  }
}
</script>

<style scoped>

.image-white {
  filter: invert(100%) sepia(0%) saturate(630%) hue-rotate(227deg) brightness(108%) contrast(108%);
}

</style>
