<template>
  <v-card>
    <v-card-title :class="contractColor">
      <span class="headline white--text">
        {{titleText}}
      </span>
      <v-spacer></v-spacer>
      <BaseDialogActions hideRefresh />
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row class="mt-6">
          <v-col
          v-if="!isUpdate"
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="6">
            <ContractAutocomplete
            :includePaused="false"
            :contractMode="contractMode"
            @contract-chosen="contractSelected"/>
            <span v-if="defaultLoggerText !== null" class="font-italic">
              {{defaultLoggerText}}
            </span>
          </v-col>
          <v-col
          cols="12"
          xs="12"
          sm="12"
          md="12"
          :lg="isUpdate ? 12 : 6">
            <v-file-input
              v-model="ticketCSV"
              accept=".csv"
              outlined
              :color="contractColor"
              :label="$t('uploadTicketCSV')"
            >
              <template #prepend-inner>
                <Icon
                icon="mdi-information-outline"
                :tooltipText="tooltipText"
                :small="false"
                color="black"
                @icon-clicked="downloadCSVTemplate"></Icon>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row dense align="center" justify="center" v-if="progress !== null">
          <v-progress-circular
          :value="progress"
          :size="100"
          :color="contractColor">
            <span>{{progressText}}</span>
          </v-progress-circular>
        </v-row>
        <v-row dense v-if="uploadedTickets.length > 0">
          <v-col cols="12">
            <v-data-table
            :items="uploadedTickets"
            :headers="headers"
            :hide-default-footer="uploadedTickets.length < 10"
            dense>
              <template #item.driver="{item}">
                <span>{{item.driver ?? $t('notAvailable')}}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row dense>
          <ErrorList
          :errorStrings="validationErrors"
          errorType="validation"/>
        </v-row>
        <v-row dense>
          <ErrorList
          :errorStrings="serverErrors"
          errorType="server"/>
        </v-row>
        <v-row dense>
          <ErrorList
          :errorStrings="unupdatedTicketErrors"
          errorType="unupdated-ticket"/>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
      v-if="isResetButtonVisible"
      class="mr-2"
      :color="contractColor"
      @click="resetForm(true)">
        {{$t('reset')}}
      </v-btn>
      <v-btn
      :disabled="importButtonDisabled"
      :class="contractColor"
      @click="importTickets">
        {{$t('import')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { saveAs } from 'file-saver'
import { mapActions } from 'vuex'
import {
  getNewTicketsFromCSV,
  getUpdatedTicketsFromCSV,
  getTicketNumbersFromCSV,
  getExampleCsvData,
  getCsvWithTicketData,
  validateHeaders
} from '@/components/contract/import-tickets/importContractTickets.js'
import { getSnackMessageForError } from '@/utils/api/ErrorHandler.js'
import ContractHeaders from '@/headers/Contract'
import TicketQuery from '@/model/TicketParams.js'
import { colorClassForContractMode } from '@/utils/componentHelpers'
import moment from 'moment'

export default {
  name: 'ImportContractTickets',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    ContractAutocomplete: () => import('@/components/autocomplete/ContractAutocomplete.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ErrorList: () => import('@/components/core/ErrorList.vue')
  },

  props: {
    isUpdate: {
      type: Boolean,
      default: true
    },
    contractMode: {
      type: Object,
      default: () => ({ value: 0 })
    }
  },

  data: () => ({
    ticketCSV: null,
    contract: null,
    totalToUpload: 0,
    uploadedTickets: [],
    validationErrors: [],
    unupdatedTicketErrors: [],
    serverErrors: [],
    isLoading: false,
    inTransitTickets: []
  }),

  computed: {
    titleText () {
      switch (this.contractMode.value) {
        case 0: return this.isUpdate ? this.$t('updateLogsTickets') : this.$t('importLogsTickets')
        case 1: return this.isUpdate ? this.$t('updateByproductTickets') : this.$t('importByproductTickets')
        case 2: return this.isUpdate ? this.$t('updateTransferTickets') : this.$t('importTransferTickets')
        default: return this.isUpdate ? this.$t('updateLogsTickets') : this.$t('importLogsTickets')
      }
    },

    isByproducts () {
      return this.contractMode.value === 1
    },

    uploadButtonText () {
      return this.isUpdate ? this.$t('update') : this.$t('upload')
    },

    progress () {
      if (this.totalToUpload === 0) return null

      return Math.round((this.uploadedTickets.length / this.totalToUpload) * 100)
    },

    progressText () {
      return `${this.uploadedTickets.length}/${this.totalToUpload}`
    },

    serverErrorStrings () {
      return this.serverErrors
        .map(error => this.$t('serverErrorTicketImport', { rowNumber: error.rowNumber, errorMessage: error.message, errorCode: error.code }))
    },

    defaultLoggerText () {
      let loggerText = null

      if (this.contract?.requiresLogger === true) {
        loggerText = this.$t('defaultLoggerWillBeUsed')
      }

      return loggerText
    },

    headers () {
      return ContractHeaders.ticketImportHeaders(this.contractMode.value === 1, this.isUpdate)
    },

    importButtonDisabled () {
      if (this.isResetButtonVisible) return true
      if (this.isUpdate) return this.ticketCSV === null
      return this.ticketCSV === null || this.contract === null
    },

    isResetButtonVisible () {
      return this.totalToUpload > 0
    },

    uploadedTicketString () {
      return this.uploadedTickets.map(t => t.ticketNumber).join(', ')
    },

    contractColor () {
      return colorClassForContractMode(this.contractMode.value)
    },

    tooltipText () {
      return (this.isUpdate && this.inTransitTickets.length > 0)
        ? this.$t('downloadInTransitTicketsTemplate')
        : this.$t('downloadExampleTemplate')
    }
  },

  async created () {
    if (!this.isUpdate) return
    const filter = new TicketQuery({
      inTransit: true,
      contractMode: this.contractMode.value
    })
    this.inTransitTickets = await this.fetchTickets(filter)
  },

  methods: {
    ...mapActions('product', ['fetchProducts']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('tract', ['getLoggersForTract']),
    ...mapActions('ticket', ['createTicket', 'updateTicket', 'getTicketsWithNumbers', 'fetchTickets']),
    async importTickets () {
      this.resetForm()
      const reader = new FileReader()
      reader.readAsText(this.ticketCSV)
      reader.onload = async () => {
        try {
          const csvLines = reader.result.split('\n').filter(l => l.trim() !== '')

          if (csvLines.length === 0) {
            this.validationErrors = [this.$t('couldNotReadCsv')]
            return
          }

          const headers = csvLines.shift()
          const headerValidationResult = validateHeaders(headers.split(','), this.isUpdate, this.contractMode)
          if (headerValidationResult !== undefined) {
            this.validationErrors = [headerValidationResult]
            return
          }

          if (csvLines.length > 250) {
            this.setSnackError(this.$t('tooManyCsvRows', { linesProvided: csvLines.length, maxNumOfLines: 250 }))
            this.resetForm()
            return
          }

          const blankLineErrors = []
          csvLines.forEach((csvLine, index) => {
            if (csvLine.replaceAll(',', '').trim() === '') {
              blankLineErrors.push(index + 2)
            }
          })

          if (blankLineErrors.length > 0) {
            this.validationErrors = blankLineErrors.map(lineNumber => this.$t('blankLineInCSV', { rowNumber: lineNumber }))
            return
          }

          const { tickets, validationErrors, unupdatedTickets = [] } = this.isUpdate
            ? await this.getUpdatedTicketObjectsFromCSV(csvLines)
            : await this.getNewTicketObjectFromCSV(csvLines)

          this.unupdatedTicketErrors = unupdatedTickets.map(t => {
            return this.$t('ticketNotUpdatedNoChanges', { ticketNumber: t.ticketNumber })
          })

          if (validationErrors.length > 0) {
            this.validationErrors = validationErrors.map(({ textKey, args }) => {
              return this.$t(textKey, args)
            })
            return
          }

          this.totalToUpload = tickets.length

          for (let [i, ticket] of tickets.entries()) {
            try {
              if (this.isUpdate) {
                await this.updateTicket(ticket)
              } else {
                ticket = await this.createTicket(ticket)
              }
              this.uploadedTickets.push(ticket)
            } catch (error) {
              const { message, code } = getSnackMessageForError(error)
              this.serverErrors.push(this.$t('serverErrorTicketImport', { rowNumber: i + 1, errorMessage: message, errorCode: code }))
            }
          }

          if (this.serverErrors.length === 0) {
            this.ticketCSV = null
          }
        } catch (error) {
          if (error.message === 'ERROR_PARSING') {
            this.setSnackError(this.$t('invalidCSVFormat'))
          } else {
            this.setSnackError(error)
          }
        }
      }
    },

    contractSelected (contract) {
      this.contract = contract
    },

    resetForm (clearCsv = false) {
      this.uploadedTickets = []
      this.validationErrors = []
      this.serverErrors = []
      this.totalToUpload = 0

      if (clearCsv) {
        this.ticketCSV = null
      }
    },

    async downloadCSVTemplate () {
      const ticketData = this.inTransitTickets.length > 0 ? getCsvWithTicketData(this.inTransitTickets, this.contractMode) : getExampleCsvData(this.isUpdate, this.isByproducts)
      const fileName = `${this.titleText.replaceAll(' ', '')}Example-${moment().format('YYYY-MM-DDTHH_mm_ss')}.csv`
      saveAs(ticketData, fileName)
    },

    async getProductMap (contract) {
      const products = await this.fetchProducts({
        includeByProducts: this.isByproducts,
        includeProduction: !this.isByproducts,
        contractId: (contract?.acceptsAnyLoad || contract === undefined) ? undefined : this.contract.contractId
      })

      return products.reduce((map, product) => {
        map[product.name.toLowerCase()] = product
        return map
      }, {})
    },

    async getNewTicketObjectFromCSV (csvLines) {
      const productNameIdMap = await this.getProductMap(this.contract)

      const accounts = await this.fetchAccounts()

      const accountNameIdMap = accounts.reduce((map, account) => {
        map[account.name.toLowerCase()] = account
        return map
      }, {})

      let loggerAccountId = null

      if (this.contract.requiresLogger === true && this.isByproducts === false) {
        const loggers = await this.getLoggersForTract(this.contract.tractId)
        if (loggers.length === 0) {
          throw new Error(this.$t('noLoggersFoundDefinedOnTract', { tractName: this.contract.tract }))
        }

        const defaultLogger = loggers.find(l => l.isDefault === true)

        if (defaultLogger !== undefined) {
          loggerAccountId = defaultLogger.accountId
        } else {
          throw new Error(this.$t('noDefaultLoggerFoundOnTract', { tractName: this.contract.tract }))
        }
      }

      return getNewTicketsFromCSV(csvLines, productNameIdMap, accountNameIdMap, this.contract, this.contractMode, loggerAccountId)
    },

    async getUpdatedTicketObjectsFromCSV (csvLines) {
      const productNameIdMap = await this.getProductMap()
      const ticketNumbers = getTicketNumbersFromCSV(csvLines)
      const ticketsFromServer = await this.getTicketsWithNumbers({
        ticketNumbers: ticketNumbers,
        contractMode: this.contractMode
      })

      return getUpdatedTicketsFromCSV(csvLines, ticketsFromServer, this.contractMode, productNameIdMap)
    }
  }
}
</script>
