import messages from '@/lang'

export default {
  compositionHeaders (locale) {
    return [
      {
        sortable: false,
        text: messages[locale].name,
        value: 'product'
      },
      {
        sortable: false,
        text: messages[locale].harvestedTons,
        value: 'harvestedTons',
        align: 'right'
      },
      {
        sortable: false,
        text: messages[locale].adjustmentTons,
        value: 'adjustmentTons',
        align: 'right'
      },
      {
        sortable: false,
        text: messages[locale].remainingTons,
        value: 'remainingTons',
        align: 'right'
      },
      {
        sortable: false,
        text: messages[locale].expectedTons,
        value: 'expectedTons',
        align: 'right'
      },
      {
        sortable: false,
        text: messages[locale].pricePerTon,
        value: 'pricePerTon',
        align: 'right'
      },
      {
        sortable: false,
        text: messages[locale].cruisedValue,
        value: 'cruisedValue',
        align: 'right'
      },
      {
        sortable: false,
        text: messages[locale].actions,
        value: 'actions',
        align: 'center'
      }
    ]
  }
}
