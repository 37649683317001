<template>
  <v-card :height="showIntegrationLineItems ? '80vh' : undefined">
    <v-card-title class="secondary headline white--text">
      <span>
        {{ titleText }}
      </span>
      <v-spacer/>
      <BaseDialogActions @refresh="loader.load()" hideFullscreen/>
    </v-card-title>
    <v-card-text class="mt-3">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-card outlined hover>
              <v-card-text>
                <v-container fluid>
                  <v-row v-for="c in integrationColumns" :key="c.label" dense no-gutters>
                    <v-col cols="2">
                      {{c.label}}:
                    </v-col>
                    <v-col>
                      <Icon v-if="c.icon"
                        :icon="c.icon.icon"
                        :iconColor="c.icon.color"
                        :tooltipText="c.icon.tooltipText"
                        :tooltipColor="c.icon.color"
                        @icon-clicked="c.onClick?.call()"
                        margin="mb-1 mr-1"
                      />
                      <span :class="{ 'clickable-text': c.onClick }" @click="c.onClick?.call()">{{c.value.trim()}}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense no-gutters v-if="showIntegrationLineItems">
          <v-col cols="12">
            <v-tabs v-model="openTab">
              <v-tab v-for="(category, i) in categories" :key="`integrations-tab-title-${i}`">
                {{ category.name }}
              </v-tab>
              <v-tab-item v-for="(items, i) in itemsArray" :key="`integrations-tab-${i}`" disabled>
                <DataTable
                  :items="items"
                  :headers="headers"
                  :loading="loader.loading"
                  :customCells="customCells"
                  >
                  <template #amount="{item}">
                    <span>{{ getFormattedAmount(item) }}</span>
                  </template>
                  <template #status="{item}">
                    <Icon
                      :icon="item.statusIcon"
                      :iconColor="item.statusColor"
                      :tooltipColor="item.statusColor"
                      :tooltipText="item.statusText"
                      @icon-clicked="viewIntegrationDetails(item)"
                    />
                  </template>
                </DataTable>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <Dialog :stateId="dialogId" @close="closeDialogs">
      <IntegrationLineDetailDialog
        v-if="integrationDetailDialog && focusedIntegrationItem"
        :integrationItem="focusedIntegrationItem"
        :accountingCategory="accountingCategory"
      />
      <BatchIntegrationDetailDialog
        v-if="batchIntegrationDetailDialog"
        :exportBatch="exportBatch"
        @click:retry="retryFinancialIntegration"
      />
      <BIDataReplicationDetailDialog
        v-if="biDataReplicationDetailDialog"
        :exportBatch="exportBatch"
        @click:retry="retrySecondaryBiPump"
      />
    </Dialog>
  </v-card>
</template>

<script>
import { ResourceLoader } from '@/utils/ResourceLoader'
import { formatMoney } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { businessIntegrationHeaders } from '@/headers/ExportBatch.js'
import { mapActions, mapGetters } from 'vuex'
import { uniqueDialogId } from '@/utils/componentHelpers'
import {
  AccountingCategory,
  IntegrationPolarity,
  IntegrationStatus,
  ExportBatchIntegrationStatus,
  ExportBatchBiPumpStatus
} from '@/utils/Enumerations'
import { FinancialIntegrationType } from '../../../utils/Enumerations'
export default {
  name: 'IntegrationsDialog',

  props: {
    dateConfiguration: {
      type: Object,
      required: true
    }
  },

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    BatchIntegrationDetailDialog: () => import('@/components/settlements/business-integrations/BatchIntegrationDetailDialog.vue'),
    BIDataReplicationDetailDialog: () => import('@/components/settlements/business-integrations/BIDataReplicationDetailDialog.vue'),
    IntegrationLineDetailDialog: () => import('@/components/settlements/business-integrations/IntegrationLineDetailDialog.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    openTab: 0,
    loader: ResourceLoader.empty,
    dialogId: uniqueDialogId('integrations-detail-dialog'),
    apIntegrations: [],
    arIntegrations: [],
    acIntegrations: [],
    focusedIntegrationItem: undefined,
    integrationDetailDialog: false,
    batchIntegrationDetailDialog: false,
    biDataReplicationDetailDialog: false,
    exportBatch: undefined,
    delayedRefreshTimeout: undefined
  }),

  computed: {
    ...mapGetters('user', ['companyInfo']),

    showIntegrationLineItems () {
      return this.companyInfo.financialIntegrationType === FinancialIntegrationType.BusinessCentralWithBinaryStream.value
    },

    integrationColumns () {
      const exportBatch = this.exportBatch
      const showIntegrationLine = this.companyInfo.financialIntegrationType === FinancialIntegrationType.CsvDrop.value || this.companyInfo.financialIntegrationType === FinancialIntegrationType.BusinessCentralWithBinaryStream.value
      const showIntegrationDetailDialog = (this.exportBatch.integrationStatus !== ExportBatchIntegrationStatus.Success.value || this.companyInfo.financialIntegrationType === FinancialIntegrationType.CsvDrop.value)
      const showBiReplicationDialog = (this.exportBatch.secondaryBiPumpStatus !== ExportBatchBiPumpStatus.Success.value)

      const ifn = (cond, v) => cond ? v : undefined

      return [
        {
          label: this.$t('exportedBy'),
          value: exportBatch.exportedBy
        },
        {
          label: this.$t('exportedOn'),
          value: utcToLocalDate(exportBatch.exportDate, 'L - LT')
        },
        {
          label: this.$t('integrationType'),
          value: FinancialIntegrationType.fromInt(this.companyInfo.financialIntegrationType)
        },
        ifn(showIntegrationLine, {
          label: this.$t('integrationStatus'),
          onClick: (showIntegrationDetailDialog) ? this.viewExportBatchIntegrationDetail : undefined,
          ...this.iconForExportBatchIntegrationStatus(exportBatch)
        }),

        ifn(exportBatch.secondaryBiPumpStatus !== null, {
          label: this.$t('biDataReplication'),
          onClick: (showBiReplicationDialog) ? this.viewBiDataReplicationDetail : undefined,
          ...this.iconForExportBatchBiPumpStatus(exportBatch)
        })
      ].filter(f => f)
    },

    customCells () {
      return [
        {
          slotName: 'amount',
          value: 'absAmount'
        },
        {
          slotName: 'status',
          value: 'integrationStatus'
        }
      ]
    },

    categories () {
      return AccountingCategory.enums
    },

    titleText () {
      const batchLabel = this.dateConfiguration?.exportBatch?.label === '' || this.dateConfiguration?.exportBatch?.label === undefined
        ? utcToLocalDate(this.dateConfiguration?.exportBatch?.exportDate)
        : this.dateConfiguration?.exportBatch?.label
      return this.$t('integrationDialogTitle', { batchLabel })
    },

    headers () {
      return businessIntegrationHeaders(this.accountingCategory)
    },

    itemsArray () {
      return [this.apIntegrations, this.arIntegrations, this.acIntegrations]
    },

    accountingCategory () {
      switch (this.openTab) {
        case 0:
          return AccountingCategory.Payable
        case 1:
          return AccountingCategory.Receivable
        case 2:
          return AccountingCategory.Accrual
      }

      return null
    }
  },

  watch: {
    accountingCategory () {
      this.loader.load(true)
    }
  },

  async created () {
    this.exportBatch = this.dateConfiguration.exportBatch
    this.loader = new ResourceLoader(async (isTabSwitch) => {
      await this.refreshData(isTabSwitch)
    })

    await this.loader.load(false)
  },

  unmounted () {
    if (this.delayedRefreshTimeout !== undefined) {
      clearTimeout(this.delayedRefreshTimeout)
    }
  },

  methods: {
    ...mapActions('export-batch', ['fetchExportBatchById', 'fetchFinancialIntegrations', 'patchExportBatch']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    utcToLocalDate,
    async refreshData (isTabSwitch = false) {
      const { payPeriodId, exportBatchId } = this.dateConfiguration.exportBatch
      this.exportBatch = await this.fetchExportBatchById({ payPeriodId, exportBatchId })

      if (this.exportBatch.integrationStatus === ExportBatchIntegrationStatus.Pending.value ||
        (this.exportBatch.secondaryBiPumpStatus === ExportBatchBiPumpStatus.Pending.value)) {
        this.triggerDelayedRefresh()
      }

      let arrayToSet

      switch (this.accountingCategory) {
        case AccountingCategory.Payable:
          arrayToSet = 'apIntegrations'
          break
        case AccountingCategory.Receivable:
          arrayToSet = 'arIntegrations'
          break
        case AccountingCategory.Accrual:
          arrayToSet = 'acIntegrations'
          break
        default: break
      }

      if ((isTabSwitch && this[arrayToSet].length === 0) || !isTabSwitch) {
        const response = await this.fetchFinancialIntegrations({
          exportBatchId: this.dateConfiguration?.exportBatch?.exportBatchId,
          accountingCategory: this.accountingCategory
        })

        this[arrayToSet] = response.map(item => ({
          ...item,
          ...this.iconForIntegrationStatus(item.integrationStatus)
        }))
      }
    },

    viewExportBatchIntegrationDetail () {
      if (this.exportBatch.integrationStatus === ExportBatchIntegrationStatus.Success.value && this.companyInfo.financialIntegrationType !== FinancialIntegrationType.CsvDrop.value) return
      this.batchIntegrationDetailDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '40%' })
    },

    viewBiDataReplicationDetail () {
      if (this.exportBatch.secondaryBiPumpStatus === ExportBatchBiPumpStatus.Success.value) return
      this.biDataReplicationDetailDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '40%' })
    },

    iconForIntegrationStatus (status) {
      switch (status) {
        case IntegrationStatus.NotStarted.value:
          return {
            statusIcon: 'mdi-weather-cloudy-clock',
            statusText: this.$t('notStarted'),
            statusColor: 'black'
          }
        case IntegrationStatus.Complete.value:
          return {
            statusIcon: 'mdi-check-circle',
            statusText: this.$t('complete'),
            statusColor: 'success'
          }
        case IntegrationStatus.Error.value:
          return {
            statusIcon: 'mdi-alert-circle',
            statusText: this.$t('error'),
            statusColor: 'error'
          }
        default: {
          return {
            statusIcon: 'mdi-alert-circle',
            statusText: this.$t('exception'),
            statusColor: 'error'
          }
        }
      }
    },

    iconForExportBatchIntegrationStatus ({ integrationStatus, integrationCompletedOn }) {
      switch (integrationStatus) {
        case ExportBatchIntegrationStatus.Success.value:
          return {
            value: `Completed at ${utcToLocalDate(integrationCompletedOn, 'L - LT')}`,
            icon: {
              icon: 'mdi-check-circle',
              color: 'success',
              tooltipText: this.$t('success')
            }
          }
        case ExportBatchIntegrationStatus.Pending.value:
          return {
            value: ExportBatchIntegrationStatus.Pending.name,
            icon: {
              icon: 'mdi-clock-outline',
              color: 'grey',
              tooltipText: this.$t('pending')
            }
          }
        case ExportBatchIntegrationStatus.Error.value:
          return {
            value: ExportBatchIntegrationStatus.Error.name,
            icon: {
              icon: 'mdi-close',
              color: 'error',
              tooltipText: `${this.$t('error')} - ${this.$t('clickToViewDetails')}`
            }
          }
      }
    },

    iconForExportBatchBiPumpStatus (exportBatch) {
      const { secondaryBiPumpStatus, secondaryBiDataPumpedOn } = exportBatch
      switch (secondaryBiPumpStatus) {
        case ExportBatchBiPumpStatus.Success.value:
          return {
            value: `Completed at ${utcToLocalDate(secondaryBiDataPumpedOn, 'L - LT')}`,
            icon: {
              icon: 'mdi-check-circle',
              color: 'success',
              tooltipText: this.$t('success'),
              onClick: this.viewBiDataReplicationDetail
            }
          }
        case ExportBatchBiPumpStatus.Pending.value:
          return {
            value: ExportBatchBiPumpStatus.Pending.name,
            icon: {
              icon: 'mdi-clock-outline',
              color: 'grey',
              tooltipText: this.$t('pending'),
              onClick: this.viewBiDataReplicationDetail
            }
          }
        case ExportBatchBiPumpStatus.Error.value:
          return {
            value: ExportBatchBiPumpStatus.Error.name,
            icon: {
              icon: 'mdi-close',
              color: 'error',
              tooltipText: `${this.$t('error')} - ${this.$t('clickToViewDetails')}`,
              onClick: this.viewBiDataReplicationDetail
            }
          }
      }
    },

    triggerDelayedRefresh () {
      this.delayedRefreshTimeout = setTimeout(() => {
        this.delayedRefreshTimeout = undefined
        const { payPeriodId, exportBatchId } = this.dateConfiguration.exportBatch
        this.fetchExportBatchById({ payPeriodId, exportBatchId })
          .then(exportBatch => { this.exportBatch = exportBatch })
      }, 5 * 1000)
    },

    async retrySecondaryBiPump () {
      const { exportBatchId } = this.exportBatch
      const body = [
        {
          op: 'add',
          path: '/biPumpStatus',
          value: ExportBatchBiPumpStatus.Retry.value
        }
      ]
      await this.patchExportBatch({
        exportBatchId,
        body
      })
      this.closeDialogs()
      this.triggerDelayedRefresh()
    },

    async retryFinancialIntegration () {
      const { exportBatchId } = this.exportBatch
      const body = [
        {
          op: 'add',
          path: '/integrationStatus',
          value: ExportBatchIntegrationStatus.Retry.value
        }
      ]
      await this.patchExportBatch({
        exportBatchId,
        body
      })
      this.closeDialogs()
      this.triggerDelayedRefresh()
    },

    closeDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.focusedIntegrationItem = undefined
      this.integrationDetailDialog = false
      this.batchIntegrationDetailDialog = false
      this.biDataReplicationDetailDialog = false
    },

    viewIntegrationDetails (integrationItem) {
      this.focusedIntegrationItem = integrationItem
      this.integrationDetailDialog = true
      this.openOrUpdateDialog({
        id: this.dialogId,
        width: '60vw'
      })
    },

    getFormattedAmount ({ absAmount, polarity }) {
      return formatMoney(polarity === IntegrationPolarity.Negative.value ? absAmount * -1 : absAmount)
    }
  }
}
</script>

<style scoped>
.clickable-text:hover {
  text-decoration: underline;
}
</style>
