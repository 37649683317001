<template>
  <v-card flat>
    <v-container fluid>
      <!-- Activity receivableRegisters -->
      <v-row v-if="receivableRegister.activities.length > 0">
        <v-col cols="12">
          <span class="title">
            {{$t('receivables')}}
          </span>
        </v-col>
        <v-col cols="12" class="mt-n6">
          <DataTable
          :items="receivableRegister.activities"
          :headers="headers"
          :propDense="true"
          :hideHeader="true"
          :customCells="customReceivableCells"
          :actions="[]"
          >
            <template #weight="slotProps">
              {{ tonStringFromPounds(slotProps.item.weight) }}
            </template>
            <template #amount="{item}">
              <span>
                {{ formatMoney(item.amount) }}
              </span>
            </template>
            <template #footer>
              <tr></tr>
            </template>
          </DataTable>
        </v-col>
      </v-row>
      <!-- Corrections -->
      <v-row v-if="receivableRegister.correctionFinancials.length > 0">
        <v-col cols="12">
          <span class="title">
            {{$t('corrections')}}
          </span>
        </v-col>
        <v-col cols="12" class="mt-n6">
          <DataTable
            :items.sync="receivableRegister.correctionFinancials"
            :headers="correctionHeaders"
            :propDense="true"
            :hideHeader="true"
            :customCells="customCorrectionCells"
            :actions="[]"
          >
            <template #activity="{item}">
              <Icon
              margin="mt-n1 mr-1"
              icon="mdi-auto-fix"
              dataTestId="rec-reg-corrections"
              :small="true"
              :iconColor="item.correctionSide === 0 ? 'red' : 'green'"
              :tooltipColor="item.correctionSide === 0 ? 'red' : 'green'"
              :tooltipText="getCorrectionTooltipText(item)"
              />
              <span>
                {{item.activity}}
              </span>
            </template>
            <template #net-weight="{item}">
              {{tonStringFromPounds(item.netWeight)}}
            </template>
            <template #amount="{item}">
              {{getAmountText(item) }}
            </template>
            <template #body.append>
              <tr class="font-weight-bold">
                <td v-for="(_, i) in Array.from({length: correctionHeaders.length - 1})" :key="`${receivableRegister.accountName}-${i}`"/>
                <td class="text-right">{{totalCorrected}}</td>
              </tr>
            </template>
          </DataTable>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { tonStringFromPounds, formatMoney } from '@/utils/NumericMutations'
import { receivableRegisterHeaders, prCorrectionHeaders } from '@/headers/Cycles.js'
import { CorrectionTypes, CorrectionSide } from '@/utils/Enumerations.js'
import { customReceivableCells, customCorrectionCells } from './CustomReceivableRegisterCells.js'
export default {
  name: 'ReceivableRegisterCard',

  props: {
    receivableRegister: {
      type: Object,
      required: true
    },
    isByproduct: {
      type: Boolean,
      required: true
    }
  },

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    customReceivableCells,
    customCorrectionCells
  }),

  computed: {
    headers () {
      return receivableRegisterHeaders(this.isByproduct)
    },

    correctionHeaders () {
      return prCorrectionHeaders({ isByproduct: this.isByproduct })
    },

    totalCorrected () {
      return formatMoney(this.receivableRegister.correctionFinancials.reduce((total, curr) => total + curr.amount, 0))
    }
  },

  methods: {
    tonStringFromPounds,
    formatMoney,

    getPayoutString (item) {
      const { payout, grossPayout } = item
      if (payout === grossPayout) {
        return formatMoney(payout)
      } else {
        return `${formatMoney(payout)} (${formatMoney(grossPayout)})`
      }
    },

    getCorrectionTooltipText ({ correctionType, correctionSide }) {
      return `${CorrectionTypes.find(ct => ct.value === correctionType).name}
        ======
        ${CorrectionSide.find(cs => cs.value === correctionSide).name}`
    },

    getAmountText ({ amount }) {
      return formatMoney(amount)
    }
  }
}
</script>
