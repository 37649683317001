<template>
  <v-card max-width="800px" data-testid="period-ticket-print-dialog">
    <v-card-title class="white--text headline secondary">
      {{ $t('printPeriodTickets') }}
    </v-card-title>
    <v-card-text class="mt-6">
      <div>
        <span>{{$t('tracts')}}</span>
        <v-autocomplete
          v-model="selectedTracts"
          data-testid="tract-complete"
          item-text="name"
          value="name"
          dense
          chips
          color="black"
          item-color="secondary"
          return-object
          multiple
          deletable-chips
          clearable
          :loading="loading"
          :items="tracts"
        >
          <template v-slot:item="{ item }">
            <span>
              {{ getTractString(item) }}
            </span>
          </template>
          <template #append-outer>
            <v-icon @click="refreshTracts" color="secondary">mdi-refresh</v-icon>
          </template>
        </v-autocomplete>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn class="ml-n4" text color="black" @click="$emit('close')" data-testid="cancel">{{$t('cancel')}}</v-btn>
      <v-spacer/>
      <v-btn class="secondary mr-n4" @click="printPeriodTicketsPDF" data-testid="confirm">{{ selectedTracts.length > 0 ? 'Print' : 'Print All'}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getTicketPDF } from '@/utils/pdf-formatters/Ticket.js'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SettlementTicketsPDFDialog',

  data: () => ({
    selectedTracts: [],
    tracts: undefined,
    loading: false
  }),

  computed: {
    ...mapGetters('user', ['companyInfo'])
  },

  async created () {
    await this.getTracts()
  },

  methods: {
    ...mapActions('settlements', ['getTractsForPeriod', 'getTicketsForPeriod']),
    getTicketPDF,
    async getTracts () {
      this.loading = true
      try {
        this.tracts = await this.getTractsForPeriod()
      } finally {
        this.loading = false
      }
    },

    async printPeriodTicketsPDF () {
      const tickets = await this.getTicketsForPeriod(this.selectedTracts.map(tract => tract.tractId))
      getTicketPDF(tickets, this.companyInfo, this.selectedTracts.length === 0 ? this.tracts : this.selectedTracts)
      this.$emit('close')
    },

    refreshTracts () {
      this.getTracts()
    },

    getTractString ({ name, code, type }) {
      return `${name} || ${code} || ${type}`
    }
  }
}
</script>
