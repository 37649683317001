<template>
<div>
  <v-toolbar
    id="core-toolbar"
    flat
    color="tertiary"
  >
    <div class="v-toolbar-title" >
      <v-toolbar-title class="tertiary--text font-weight-light">
        <v-btn
          class="default v-btn--simple"
          data-testid="toggle-app-drawer"
          icon
          @click.stop="toggleDrawer"
        >
          <v-icon color="tertiary">mdi-view-list</v-icon>
        </v-btn>
      </v-toolbar-title>
    </div>
    <v-spacer />
    <v-toolbar-items>
      <v-flex
        align-center
        layout
        py-2
      >
        <Notifications
          @contract-notification="handleContractNotification"
          @insurance-notification="handleInsuranceNotification"
          @account-certification-notification="handleAccountCertificationNotification"
          @device-notification="handleDeviceNotification"
          @pending-approval-notification="handlePendingApprovalNotification"
          @returned-approval-notification="handleReturnedApprovalNotification"
        />
        <v-btn class="toolbar-items" color="grey" icon @click="jumpToSettings">
          <v-icon>mdi-cog</v-icon>
        </v-btn>

        <v-menu offset-y rounded right>
          <template v-slot:activator="{ on }">
            <v-btn icon :color="'grey'" class="toolbar-items mr-2" v-on="on"><v-icon>mdi-account-circle</v-icon></v-btn>
          </template>
          <v-card class="account-menu">
            <v-card-title class="pb-0 pt-2">
              <span class="mx-auto text-center">
                {{ username }}
              </span>
            </v-card-title>
            <v-card-text justify="center" class="pb-2 text-center">
              {{ this.userInfo.preferredUsername }}
              <v-row dense>
                <v-btn
                  block
                  color="rgba(0,0,0,0.1)"
                  elevation="0"
                  @click="onSignOutPressed()"
                >
                  <v-row dense justify="center">
                    <v-icon class="mr-2" color="black">mdi-logout</v-icon>
                    <span style="color: black"> {{$t('signOut')}} </span>
                  </v-row>
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-col class="mt-6">
          <v-row>
            <p>{{username}} <br/>{{companyName}}</p>
          </v-row>
          <v-row>
            <p></p>
          </v-row>
        </v-col>

      </v-flex>
    </v-toolbar-items>
  </v-toolbar>
  <v-dialog v-model="contractExpirationDateDialog" width="500px">
    <UpdateExpirationDate
    :contractId="focusedContractId"
    @cancel="close"
    @contract-updated="close(true)"
    @jump="close(false)"
    v-if="contractExpirationDateDialog && focusedContractId"
    />
  </v-dialog>
  <v-dialog v-model="insuranceExpiryDateDialog" width="500px">
    <UpdateAccountInsurancePolicies
    v-if="insuranceExpiryDateDialog && focusedAccountId"
    @close="close"
    :accountId="focusedAccountId"
    />
  </v-dialog>
  <v-dialog v-model="deviceExpirationDateDialog" width="500px">
    <UpdateDeviceRegistration
    :registrationId="focusedDeviceId"
    @cancel="close"
    @registration-updated="close(true)"
    v-if="deviceExpirationDateDialog && focusedDeviceId"
    />
  </v-dialog>
  <v-dialog v-model="certificationExpirationDialog" width="500px">
    <UpdateAccountCertification
      v-if="certificationExpirationDialog && focusedCertification"
      :accountId="focusedCertification.AccountId"
      :certificationId="focusedCertification.CertificationId"
      :accountName="focusedCertification.AccountName"
      @account-certification-changed="close(true)"
      @close="close"
    />
  </v-dialog>
</div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import RouterJump from '@/model/RouterJump'
import { SasTokenLocations } from '../../utils/constants'
export default {
  name: 'Toolbar',

  data: () => ({
    responsive: false,
    contractExpirationDateDialog: false,
    insuranceExpiryDateDialog: false,
    certificationExpirationDialog: false,
    deviceExpirationDateDialog: false,
    focusedContractId: undefined,
    focusedAccountId: undefined,
    focusedCertification: undefined,
    focusedDeviceId: undefined
  }),

  components: {
    UpdateExpirationDate: () => import('@/components/contract/UpdateExpirationDate.vue'),
    UpdateDeviceRegistration: () => import('@/components/core/UpdateDeviceRegistration.vue'),
    Notifications: () => import('./Notifications.vue'),
    UpdateAccountInsurancePolicies: () => import('@/components/account/Insurance/UpdateAccountInsurancePolicies.vue'),
    UpdateAccountCertification: () => import('@/components/account/certifications/UpdateAccountCertification.vue')
  },

  computed: {
    ...mapGetters('user', ['companyInfo', 'userInfo']),
    ...mapGetters('notifications', ['allNotifications']),
    ...mapGetters('contract', ['filter']),
    ...mapState('app', ['mini']),

    username () {
      if (this.userInfo !== undefined && this.companyInfo !== undefined) {
        return `${this.userInfo.name}`
      }

      return ''
    },

    companyName () {
      if (this.companyInfo !== undefined) {
        return `${this.companyInfo.name}`
      }

      return ''
    }
  },

  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapActions('contract', ['fetchContracts']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('device-registration', ['fetchDeviceRegistrations']),
    ...mapActions('notifications', ['fetchNotifications']),
    ...mapActions('user', ['logout']),
    ...mapMutations('app', ['setMini']),
    ...mapMutations('global', ['setRouterJump']),

    toggleDrawer () {
      if (!this.responsive) {
        this.setMini(!this.mini)
      } else {
        this.$emit('toggleDrawer')
      }
    },

    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    },

    handleContractNotification (contractId) {
      this.focusedContractId = contractId
      this.contractExpirationDateDialog = true
    },

    handleInsuranceNotification (accountId) {
      this.focusedAccountId = accountId
      this.insuranceExpiryDateDialog = true
    },

    handleAccountCertificationNotification (certification) {
      this.focusedCertification = certification
      this.certificationExpirationDialog = true
    },

    handleDeviceNotification (deviceId) {
      this.focusedDeviceId = deviceId
      this.deviceExpirationDateDialog = true
    },

    handlePendingApprovalNotification (contractId) {
      this.jumpToContractDetails(contractId)
    },

    handleReturnedApprovalNotification (contractId) {
      this.jumpToContractDetails(contractId)
    },

    close (refresh) {
      if (this.focusedContractId && refresh && this.$route.path === '/contracts') {
        this.fetchContracts(this.filter)
      }

      if (this.focusedAccountId && refresh && this.$route.path === '/accounts') {
        this.fetchAccounts()
      }

      if (this.focusedCertification && refresh && this.$route.path === '/accounts') {
        this.fetchAccounts()
      }

      if (this.focusedDeviceId && refresh && this.$route.path === '/deviceRegistrations') {
        this.fetchDeviceRegistrations()
      }

      this.focusedContractId = undefined
      this.focusedAccountId = undefined
      this.focusedCertification = undefined
      this.focusedDeviceId = undefined
      this.contractExpirationDateDialog = false
      this.insuranceExpiryDateDialog = false
      this.certificationExpirationDialog = false
      this.deviceExpirationDateDialog = false

      !refresh || this.fetchNotifications()
    },

    jumpToContractDetails (contractId) {
      this.setRouterJump(new RouterJump('Toolbar', 'Contracts', {
        contractId: contractId
      }))
      if (this.$router.currentRoute.path !== '/contracts') this.$router.push('contracts')
      this.close(false)
    },

    jumpToSettings () {
      if (this.$router.currentRoute.path !== '/settings') this.$router.push('settings')
    },

    deleteTokensFromLocalStorage () {
      for (const label in SasTokenLocations) {
        localStorage.removeItem(label)
      }
    },

    onSignOutPressed () {
      this.deleteTokensFromLocalStorage()
      this.logout(true)
    }
  }
}
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}

.v-toolbar__content {
  margin: 0px !important;
}
</style>

<style scoped>
.account-menu {
  max-width: 350px;
}
</style>
