export const SettlementKeys = {
  AccountsPayable: 'accountsPayable',
  AccountsReceivable: 'accountsReceivable',
  Accruals: 'accruals'
}

export const SettlementSubKeys = {
  AP: {
    SettlementsByContract: 'settlementsByContract',
    PaymentRegister: 'paymentRegister',
    BPPaymentRegister: 'bpPaymentRegister',
    ProductionSummary: 'productionSummary',
    TicketRegister: 'ticketRegister',
    SettlementsByPayee: 'settlementsByPayee',
    Corrections: 'corrections',
    ByproductCorrections: 'byproductCorrections',
    ByproductSettlementsByPayee: 'byproductSettlementsByPayee',
    MiscPayments: 'miscPayments'
  },
  AR: {
    ReceivableRegister: 'receivableRegister',
    BPReceivableRegister: 'bpReceivableRegister',
    BPReceivablesByPayer: 'bpReceivablesByPayer',
    ProductionSummary: 'productionSummary',
    SalesAnalysis: 'salesAnalysis'
  },
  AC: {
    Register: 'register',
    BPRegister: 'bpRegister'
  }
}
