<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <v-text-field
        v-model="search"
        :label="$t('searchTicketNumber')"
        :error-messages="searchFieldError ? $t('ticketNumberMustBeAnInteger') : null"
        color="black"
        clearable
        @click:clear="searchTicketNumber(true)"
        @keyup.enter="searchTicketNumber(false)"
        />
      </v-col>
      <v-col cols="auto">
        <v-btn
        :disabled="searchButtonDisabled"
        class="secondary"
        @click="searchTicketNumber(false)">
          {{ $t('search') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" v-if="searching">
        <v-progress-linear indeterminate color="#fff" background-color="#ddd" rounded></v-progress-linear>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-expansion-panels v-model="openPanel">
          <v-expansion-panel v-for="(postBatch, index) in postBatches" :key="postBatch.postBatchId">
            <v-expansion-panel-header hide-actions active-class="secondary white--text">
              <v-row>
                <v-col>
                  <span class="font-weight-bold">{{getPostedOnString(postBatch)}}</span>
                </v-col>
                <v-spacer/>
                <v-col cols="auto">
                  <span>{{ $t('postBatchTicketCount', { ticketCount: postBatch.ticketCount }) }}</span>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                      v-model="searches[index]"
                      :label="$t('search')"
                      color="black"
                      clearable
                      />
                    </v-col>
                  </v-row>
                  <v-col cols="12">
                    <v-data-table
                    v-if="selectedPostBatch !== undefined"
                    v-model="selected"
                    :items="tickets"
                    :search="searches[index]"
                    show-select
                    dense
                    :items-per-page="25"
                    item-key="ticketId"
                    class="mt-6"
                    :headers="headers"
                    :footer-props="{ 'items-per-page-options': [5, 25, 50, 100, -1] }"
                    >
                      <template #item.netWeight="{item}">
                        <span class="mr-2">{{ `${tonStringFromPounds(item.inWeight - item.outWeight - item.defectWeight)}` }}</span>
                      </template>
                      <template #item.weighedOutAt="{ item }">{{
                        utcToLocalDate(item.weighedOutAt, 'L - LT')
                      }}</template>
                      <template #item.pieces="{item}">
                        <span class="mr-2">{{item.pieces === 0 ? $t('notAvailable') : item.pieces}}</span>
                      </template>
                      <template #item.product="{ item }">{{
                        item.product
                      }}</template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <Dialog :stateId="dialogId">
      <TicketConfirmation
      v-if="focusedTicket !== undefined"
      @close="close(true)"
      :propTicket="focusedTicket"/>
    </Dialog>
  </v-container>
</template>

<script>
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { tonStringFromPounds } from '@/utils/NumericMutations.js'
import { mapActions, mapGetters } from 'vuex'
import TicketHeaders from '@/headers/Ticket'
import { TicketStatus } from '@/utils/Enumerations.js'
export default {
  name: 'UnpostTickets',

  props: {
    contractMode: {
      type: Object
    }
  },

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    TicketConfirmation: () => import('@/components/ticket/ticket-posting/TicketConfirmation.vue')
  },

  data: () => ({
    selectedPostBatch: undefined,
    focusedTicket: undefined,
    dialogId: 'unpost-ticket-by-number',
    search: '',
    searches: [],
    openPanel: 0,
    cachedBatches: {},
    selected: [],
    searching: false,
    tickets: []
  }),

  computed: {
    ...mapGetters('posting', ['postBatches']),

    headers () {
      return this.isLogsMode
        ? TicketHeaders.ticketUnpostingHeaders()
        : TicketHeaders.ticketByproductUnpostingHeaders()
    },

    searchFieldError () {
      if (this.search === '' || this.search === undefined || this.search === null) { return false }
      return isNaN(Number(this.search))
    },

    searchButtonDisabled () {
      return this.searchFieldError || this.postBatches.length === 0 || this.search?.trim() === '' || this.search === undefined || this.search === null
    },

    isLogsMode () {
      return this.contractMode.value === 0
    }
  },

  watch: {
    postBatches: {
      handler (_) {
        this.selected = []
        this.searches = this.postBatches.map(pb => '')
      },
      deep: true
    },

    selected: {
      handler (val) {
        this.$emit('tickets-selected', val)
      },
      deep: true
    },

    openPanel: {
      immediate: true,
      handler (val) {
        this.selected = []
        this.tickets = []
        if (val === null || val === undefined || this.postBatches.length === 0) { return }
        const selectedBatch = this.postBatches[val]
        this.selectedPostBatch = selectedBatch
        this.$emit('post-batch-selected', selectedBatch)
        if (this.cachedBatches[selectedBatch.postBatchId] === undefined) {
          this.getPostBatch(selectedBatch.postBatchId)
        } else {
          this.tickets = this.cachedBatches[selectedBatch.postBatchId]
        }
      }
    }
  },

  methods: {
    ...mapActions('posting', ['fetchTicketsForPostBatch']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('ticket', ['getTicketsWithNumbers']),
    utcToLocalDate,
    tonStringFromPounds,

    postBatchChosen (postBatch) {
      this.selected = []
      this.selectedPostBatch = postBatch
      this.getPostBatch(postBatch.postBatchId)
      this.$emit('post-batch-selected', postBatch)
    },

    async searchTicketNumber (clearSearch) {
      if (clearSearch) {
        this.search = ''
      }

      if (this.search?.trim() === '' || this.search === null || this.search === undefined) {
        return
      }

      const castedSearch = Number(this.search)

      if (isNaN(castedSearch)) {
        this.setSnackError(this.$t('ticketNumberMustBeAnInteger'))
        return
      }

      this.searching = true
      try {
        const tickets = await this.getTicketsWithNumbers({ ticketNumbers: [castedSearch], contractMode: this.contractMode })
        if (tickets.length === 0) {
          this.setSnackError(this.$t('ticketNotFound'))
          return
        }

        const ticket = tickets.find(t => t.ticketNumber === castedSearch) ?? tickets[0]

        if (ticket.consumed === true) {
          this.setSnackError(this.$t('cannotUnpostConsumedTicket'))
          return
        }

        if (ticket.status !== TicketStatus.Posted.value) {
          this.setSnackError(this.$t('ticketNotPosted', { ticketNumber: ticket.ticketNumber, status: TicketStatus.fromInt(ticket.status) }))
          return
        }

        this.focusedTicket = ticket
        this.openOrUpdateDialog({ id: this.dialogId, width: '80vw', allowFullscreen: false })
      } finally {
        this.searching = false
      }
    },

    async getPostBatch (postBatchId) {
      const tickets = await this.fetchTicketsForPostBatch(postBatchId)
      this.cachedBatches[postBatchId] = tickets
      this.tickets = tickets
    },

    getPostedOnString ({ postDate }) {
      return this.$t('postedAt', { date: utcToLocalDate(postDate, 'L - LT') })
    },

    close (shouldRefresh = false) {
      this.closeDialogsAtOrAbove({ id: this.dialogId })
      this.focusedTicket = undefined
      if (shouldRefresh) {
        this.$emit('refresh')
      }
    }
  }
}
</script>
