<template>
  <table style="border-spacing: 5px;">
    <tr>
      <td>
        <v-icon :color="isActive ? 'white' : 'black'" class="mr-2">mdi-weight</v-icon>
      </td>
      <td>
        <span>{{aggregateTotal.weightTons}}</span>
      </td>
      <td>
        <span>{{$t('tons')}}</span>
      </td>
    </tr>
    <tr v-if="!isByproducts">
      <td>
        <v-icon :color="isActive ? 'white' : 'black'" class="mr-2">mdi-ruler</v-icon>
      </td>
      <td>
        <span>{{aggregateTotal.mbf}}</span>
      </td>
      <td>
        <span>{{$t('mbf')}}</span>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'AggregateTotalTable',

  props: {
    aggregateTotal: {
      type: Object,
      required: true
    },

    isActive: {
      type: Boolean,
      default: false
    },

    isByproducts: {
      type: Boolean,
      default: false
    }
  }
}
</script>
