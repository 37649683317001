<template>
  <v-container fluid>
      <v-row>
        <v-col cols="auto" class="pa-0">
          <Selector
          :label="$t('selectEntity')"
          class="mt-2"
          :items="entities"
          @item-selected="entitySelected"
          variant="outlined"/>
        </v-col>
      </v-row>
    <v-row>
      <v-expansion-panels v-model="openPanel" flat accordion tile>
        <v-expansion-panel v-for="(journalEntry, index) in journalEntriesByEntity" :key="`journalEntries-${index}`">
          <v-expansion-panel-header>
            <v-row dense>
              <v-col cols="auto">
                <v-icon
                  small
                  :color="openPanel === index ? 'white' : 'black'"
                >
                  mdi-account
                </v-icon>
                {{addNameWithCode(journalEntry.accountName, journalEntry.accountCode)}}
              </v-col>
            </v-row>
            <template #actions>
              <v-container>
                <v-row justify="end">
                {{getTotalAmountForRegisterPanel(journalEntry)}}
                </v-row>
                <v-row justify="end">
                  {{getTotalWeightForRegisterPanel(journalEntry)}}
                </v-row>
              </v-container>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="lg_expansion-panel_wrapper">
              <RegisterCard
              :register="journalEntry"
              :isByproduct="isByproduct"/>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { getEntities, getFinancialsByEntity, AccrualsHelpers } from '@/utils/RegisterHelpers'

export default {
  name: 'Register',

  props: {
    registerObject: {
      type: Object,
      required: true
    },
    isByproduct: {
      type: Boolean,
      required: true
    }
  },

  components: {
    Selector: () => import('@/components/core/Selector.vue'),
    RegisterCard: () => import('@/components/settlements/accruals-module/register/RegisterCard.vue')
  },

  data: () => ({
    openPanel: -1,
    entities: [],
    selectedEntity: undefined
  }),

  mounted () {
    this.getEntities()
  },

  watch: {
    entities: {
      handler (val) {
        if (val.length > 0) {
          this.selectedEntity = val[0]
        }
      },
      deep: true
    }
  },

  computed: {
    journalEntries () {
      const { journalEntries } = this.registerObject
      if (journalEntries === undefined) { return [] }

      return journalEntries
    },

    journalEntriesByEntity () {
      if (this.selectedEntity === undefined) { return this.journalEntries }
      return getFinancialsByEntity(this.journalEntries, this.selectedEntity, 2)
    }
  },

  methods: {
    getEntities () {
      this.entities = getEntities(this.journalEntries, 2)
    },

    entitySelected (entity) {
      this.selectedEntity = entity
    },

    getTotalAmountForRegisterPanel (journalEntry) {
      return AccrualsHelpers.totalStringForAccount(journalEntry)
    },

    getTotalWeightForRegisterPanel (journalEntry) {
      return AccrualsHelpers.totalWeightStringForAccount(journalEntry)
    }
  }
}
</script>
