<template>
  <v-card>
    <v-card-title class="secondary white--text">
      <span class="headline">{{ titleText }}</span>
      <v-spacer/>
      <Icon
        :small="false"
        :large="true"
        icon="mdi-close"
        icon-color="tertiary"
        dataTestId="cruise-close"
        :tooltipText="$t('close')"
        @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text class="mt-6">
      <FormWrapper
        ref="form"
        formRef="cruiseDataForm"
        :lazyValidation="true"
        :buttonText="editing ? $t('updateCruiseData') : $t('createCruiseData')"
        @submit="saveChangesPressed"
      >
        <v-container fluid`>
          <v-row>
            <v-col cols="12" md="6">
              <ProductAutocomplete
              :woodType="woodType"
              data-testid="cruise-product"
              :productId="editing ? propCruiseData.productId : undefined"
              :excludedProducts="excludedProducts"
              ref="firstField"
              @product-chosen="productChosen"
              />
            </v-col>
            <v-col cols="12" md="6">
              <MoneyTextField
              data-testid="cruise-price"
              :initialValue="cruiseData.pricePerTon"
              :label="$t('pricePerTon')"
              @val-changed="pricePerTonEntered"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="cruiseData.adjustmentTons"
                data-testid="cruise-harvested"
                @blur="setFieldValue(cruiseData.adjustmentTons, 'harvested')"
                :label="$t('manualHarvestAdjustment')"
                :rules="[rules.positiveDecimal, rules.validHarvestedTons]"
                color="black"
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                data-testid="cruise-expected"
                v-model="cruiseData.expectedTons"
                :label="$t('expectedTons')"
                @blur="setFieldValue(cruiseData.expectedTons, 'expected')"
                :rules="[rules.positiveDecimal, rules.validExpectedTons]"
                color="black"
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import FieldRules from '@/utils/rules.js'
import { mapActions, mapGetters } from 'vuex'
import { TractContent } from '@/model/Tract.js'
import { EnumValue } from '../../../utils/Enumerations'

export default {
  name: 'CruiseDataForm',

  props: {
    propCruiseData: {
      type: TractContent,
      default: null
    },
    tract: Object,
    editing: { Boolean, default: false },
    hideTitle: { Boolean, default: false },
    woodType: {
      type: EnumValue,
      required: false,
      default: undefined
    }
  },

  components: {
    MoneyTextField: () => import('@/components/helper/MoneyTextField.vue'),
    ProductAutocomplete: () => import('@/components/autocomplete/ProductAutocomplete.vue'),
    Icon: () => import('../../helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.firstField.$children[0].focus()
    }, 0)
  },

  data: () => ({
    cruiseData: {
      expectedTons: 0,
      adjustmentTons: 0
    },
    excludedProducts: [],
    rules: FieldRules.rules
  }),

  computed: {
    ...mapGetters('tract-contents', ['allTractContents']),
    ...mapGetters('product', ['allProducts']),
    titleText () {
      return this.editing ? this.$t('editCruiseData') : this.$t('createCruiseData')
    }
  },

  created () {
    if (this.editing) {
      this.cruiseData = this.propCruiseData.clone()
    }

    this.excludedProducts = this.allTractContents.map(tc => tc.product)
  },

  methods: {
    ...mapActions('tract-contents', [
      'createTractContent',
      'updateTractContent'
    ]),
    productChosen (product) { this.cruiseData.productId = product.productId },
    pricePerTonEntered (amount) { this.cruiseData.pricePerTon = amount },

    saveChangesPressed () {
      if (!this.$refs.form.$refs.cruiseDataForm.validate()) {
        return
      }

      const cruiseDataRequestObject = this.createRequestObject(this.cruiseData)

      if (this.editing) {
        cruiseDataRequestObject.tractContentId = this.propCruiseData.tractContentId
        this.editCruiseData(cruiseDataRequestObject)
      } else {
        this.createCruiseData(cruiseDataRequestObject)
      }
    },

    setFieldValue (value, type) {
      if (value === undefined) {
        if (type === 'expected') {
          this.cruiseData.expectedTons = 0
        } else {
          this.cruiseData.adjustmentTons = 0
        }
        return
      }
      if (value.length === 0) {
        if (type === 'expected') {
          this.cruiseData.expectedTons = 0
        } else {
          this.cruiseData.adjustmentTons = 0
        }
      }
    },

    createRequestObject (cruiseData) {
      return {
        productId: cruiseData.productId,
        tractId: this.tract.tractId,
        expected: Math.round(cruiseData.expectedTons * 2000),
        adjustment: Math.round(cruiseData.adjustmentTons * 2000),
        pricePerTon: cruiseData.pricePerTon
      }
    },

    async editCruiseData (cruiseDataRequestObject) {
      await this.updateTractContent(cruiseDataRequestObject)
      this.$emit('cruise-data-changed')
    },

    async createCruiseData (cruiseDataRequestObject) {
      await this.createTractContent(cruiseDataRequestObject)
      this.$emit('cruise-data-changed')
    }
  }
}
</script>
