import PDFMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { formatMoney, formatPercentage } from '@/utils/NumericMutations.js'
import { getFooter, nestedTableStyling, formatAddress } from './GenericSettlementsFunctions.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import store from '@/store/index.js'
import { scatterToObject, sumsFor } from '../base.js'
import messages from '../../lang'
import i18n from '../../i18n'
import { TractTypeCategory, WoodType } from '../Enumerations.js'

PDFMake.vfs = pdfFonts.pdfMake.vfs

const SIDE_MARGIN = 30
const noBorder = [false, false, false, false]
const bottomBorder = [false, false, false, true]
const topBorder = [false, true, false, false]

export async function generateCruiseAnalysisReport ({ tract, tractAnalysis, tractNotes, headers, sumHeaders, includeTotalsTable, landowners }) {
  const docDefinition = {
    header: getHeader(tract),
    footer: getFooter,
    pageMargins: [SIDE_MARGIN, 100, SIDE_MARGIN, 30],
    pageOrientation: 'landscape',
    pageSize: headers.length > 20 ? 'LEGAL' : undefined,
    headerRows: 1,
    styles: {
      header: {
        fontSize: 100
      },
      rightAlign: {
        alignment: 'right'
      },
      nestedTable: {
        fontSize: 8
      }
    },
    content: [
      getMainBody(tract, landowners),
      getTractNotesSection(tractNotes),
      { text: '', pageBreak: 'after' },
      getCruiseAnalysisTable(tractAnalysis.tractHarvestAnalysis, headers, sumHeaders),
      getTractTotalsTable(tract, tractAnalysis, includeTotalsTable)
    ],
    style: 'nestedTable'
  }

  let today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()
  today = mm + dd + yyyy

  await PDFMake.createPdf(docDefinition).download(`${tract.name}_Tract_Information_${today}.pdf`.replace(/\s/g, ''))
}

const labelFor = m => messages[i18n.locale][m] ?? m

function getMainBody (tract, landowners) {
  return {
    marginBottom: 20,
    table: {
      widths: ['*', '*', '*', '*'],
      body: [
        [getGeneralInformationTable(tract), getTractMetrics(tract), getCertificationList(tract), getLandownersTable(landowners)]
      ]
    },
    layout: 'noBorders'
  }
}

function getCertificationList ({ tractCertifications }) {
  const certificationRows = ((tractCertifications.length > 0) ? tractCertifications.map(certification => `${certification.name} - ${certification.code}`) : ['None specified'])
    .map(v => [{ text: v, border: noBorder, fontSize: 8, alignment: 'left' }, { text: '', border: noBorder }])

  const body = [
    [{ text: labelFor('certifications'), decoration: 'underline', bold: true, border: noBorder, fontSize: 10, alignment: 'left' }, { text: '', border: noBorder }],
    ...certificationRows
  ]

  return {
    table: {
      body
    },
    style: nestedTableStyling
  }
}

function infoTable (title, fields) {
  const titleRow = [{ text: title, decoration: 'underline', bold: true, border: noBorder, fontSize: 10, alignment: 'left' }, { text: '', border: noBorder }]
  const rows = fields.filter(gi => gi !== undefined).map(gi => [
    { text: `${labelFor(gi.label)}:`, border: noBorder, fontSize: 8, alignment: 'left' },
    { text: gi.value, border: noBorder, fontSize: 8, alignment: 'left' }
  ])
  return [titleRow, ...rows]
}

function getTractMetrics ({ acres, cost, bondBalance, advanceBalance }) {
  const tractMetricTable = infoTable('Metrics', [
    { label: 'cost', value: cost > 0 ? formatMoney(cost) : labelFor('notSpecified') },
    { label: 'advanceBalance', value: formatMoney(advanceBalance) },
    { label: 'acres', value: acres > 0 ? acres : labelFor('notSpecified') },
    { label: 'bondBalance', value: formatMoney(bondBalance) }
  ])

  return {
    table: {
      body: tractMetricTable
    },
    style: nestedTableStyling
  }
}

function getGeneralInformationTable (tract) {
  const nonDefaultLoggers = tract.loggers
    .filter(l => l.isDefault === false)
    .map(l => l.name)
  const loggerString = nonDefaultLoggers.length > 0
    ? nonDefaultLoggers.join(', ')
    : labelFor('notSpecified')

  const generalInformationTable = infoTable('Tract Information', [
    { label: 'tractCode', value: tract.code ? tract.code : labelFor('notSpecified') },
    { label: 'tractType', value: tract.type.name },
    { label: 'purchaser', value: getBusinessEntity(tract) },
    { label: 'purchaseDate', value: tract.purchaseDate ? utcToLocalDate(tract.purchaseDate) : labelFor('notSpecified') },
    { label: 'defaultLogger', value: getLogger(tract) },
    { label: 'otherLoggers', value: loggerString },
    { label: 'forester', value: getForesterUser(tract) },
    { label: 'location', value: getTractLocationText(tract) },
    { label: 'coordinates', value: `${tract.spot.latitude.toFixed(6)}, ${tract.spot.longitude.toFixed(6)}` },
    (tract.spot.countrySubdivision === 'GA')
      ? { label: 'mapAndParcelNumber', value: getMapAndParcelNumber(tract) }
      : undefined
  ])

  return {
    table: {
      body: generalInformationTable
    },
    style: nestedTableStyling
  }
}

function getLandownersTable (landowners) {
  const landownersRows = landowners.map(lo => ({ label: lo.accountName, value: formatPercentage(lo.ownership) }))
  const landownersTable = landowners.length > 0
    ? infoTable('Tract Landowners', landownersRows)
    : [
      [{ text: labelFor('landowners'), decoration: 'underline', bold: true, border: noBorder, fontSize: 10, alignment: 'left' }, { text: '', border: noBorder }],
      [{ text: 'None specified', border: noBorder, fontSize: 8, alignment: 'left' }, { text: '', border: noBorder }]
    ]
  return {
    table: {
      body: landownersTable
    },
    style: nestedTableStyling
  }
}

function getCruiseAnalysisTable (cruiseAnalysis, columns, sumHeaders) {
  const { softwoodItems, hardwoodItems } = scatterToObject(cruiseAnalysis, {
    softwoodItems: ca => ca.woodType === WoodType.Softwood.value,
    hardwoodItems: ca => ca.woodType === WoodType.Hardwood.value
  }, { multi: false })

  const breakRow = columns.map((_, idx) => idx === 0 ? { text: '', colSpan: columns.length, fontSize: 8, border: noBorder, bold: true, margin: [0, 2, 0, 2] } : {})
  const headerRow = columns.map(c => ({ text: c.text, bold: true, border: bottomBorder, fontSize: 7, alignment: c.align }))

  const bodyRows = items => items.map((item, idx) => columns.map(c => ({ value: c.format ? c.format(item[c.value]) : item[c.value], align: c.align, rowIndex: idx }))
    .map(cv => ({ text: cv.value, border: noBorder, fontSize: 6, alignment: cv.align, fillColor: cv.rowIndex % 2 === 0 ? '#EEEEEE' : null })))

  const totalRow = (items, label, totalsValues, props = {}) => columns
    .map(c => ({ value: c.value === 'product' ? `${label ? labelFor(label) : ''} Totals` : c.total ? c.total({ items, total: totalsValues[c.value], sums: totalsValues, count: items.length }) : ' ', format: c.format, align: c.align }))
    .map(cv => ({ ...props, text: cv.format && cv.value !== ' ' ? cv.format(cv.value) : cv.value, bold: true, fontSize: 6, alignment: cv.align }))

  const woodSection = (items, label) => (items.length > 0) ? [
    ...bodyRows(items),
    totalRow(items, label, sumsFor(items, sumHeaders), { border: topBorder }),
    breakRow
  ] : []

  const body = [
    headerRow,

    ...woodSection(softwoodItems, WoodType.Softwood.name),
    ...woodSection(hardwoodItems, WoodType.Hardwood.name),

    (softwoodItems.length > 0 && hardwoodItems.length > 0)
      ? totalRow(cruiseAnalysis, '', sumsFor(cruiseAnalysis, sumHeaders), { border: noBorder })
      : undefined
  ].filter(r => r !== undefined)

  return {
    marginBottom: 20,
    table: {
      widths: 'auto',
      body,
      dontBreakRows: true,
      headerRows: 1,
      keepWithHeaderRows: true
    },
    layout: {
      hLineWidth: function (i, node) {
        return (i === 0 || i === node.table.body.length) ? 2 : 1
      },
      vLineWidth: function (i, node) {
        return (i === 0 || i === node.table.widths.length) ? 2 : 1
      },
      hLineColor: function (i) {
        return i < 2 || i > 4 ? 'black' : 'white'
      },
      vLineColor: function () {
        return 'black'
      }
    },
    style: nestedTableStyling
  }
}

function getTractTotalsTable (tract, tractAnalysis, includeTotalsTable) {
  if (includeTotalsTable) {
    const rows = tract.type.category === TractTypeCategory.Stumpage.value ? [
      { label: 'revenue', value: tractAnalysis.totalRevenue, bold: true },
      { label: 'stumpageCost', value: tractAnalysis.totalStumpageCost },
      { label: 'productionCost', value: tractAnalysis.totalProductionCost },
      { label: 'totalCost', value: tractAnalysis.totalCost, bold: true },
      { label: 'grossProfit', value: tractAnalysis.totalGrossProfit, bold: true },
      { label: 'tractPayments', value: tractAnalysis.totalTractPayments, bold: true },
      { label: 'tractPL', value: tractAnalysis.tractProfitLoss, border: topBorder, bold: true }
    ] : [
      { label: 'tractPayments', value: tractAnalysis.totalTractPayments, bold: true }
    ]

    const body = rows.map(r => r !== undefined ? [
      { text: labelFor(r.label), fontSize: 8, border: r.border ?? noBorder, bold: r.bold ?? false, alignment: 'left' },
      { text: formatMoney(r.value), fontSize: 8, border: r.border ?? noBorder, alignment: 'right' }
    ] : new Array(2).fill({ text: '', margin: 10, border: noBorder }))

    const table = {
      table: {
        body
      },
      style: nestedTableStyling,
      alignment: 'right',
      unbreakable: true
    }

    return {
      columns: [
        { text: '', width: '*' },
        { width: 'auto', ...table }
      ]
    }
  }
}

function getTractNotesSection (tractNotes) {
  const tractNoteRows = (tractNotes.length > 0)
    ? tractNotes.map(note => ({ key: `${utcToLocalDate(note.createdAt)} - ${note.createdBy}:`, value: note.content }))
    : [{ key: labelFor('noneSpecified'), value: '' }]

  const body = [
    [{ text: labelFor('tractNotes'), decoration: 'underline', bold: true, border: noBorder, fontSize: 10, alignment: 'left' }, { text: '', border: noBorder }],
    ...tractNoteRows.map(row => [{ text: row.key, border: noBorder, fontSize: 8, alignment: 'left' }, { text: row.value, fontSize: 8, border: noBorder }])
  ]

  return {
    table: {
      body
    },
    style: nestedTableStyling
  }
}

export function getHeader (tract) {
  const companyInfo = store.getters['user/companyInfo']
  const companyName = companyInfo.name ? companyInfo.name : ''
  const companyAddress = formatAddress(companyInfo)
  const companyPhoneNumber = companyInfo.mainContact && companyInfo.mainContact.phoneNumber ? companyInfo.mainContact.phoneNumber : ''

  return {
    fontSize: 15,
    bold: true,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    table: {
      widths: '*',
      body: [
        [
          {
            table: {
              body: [
                [''],
                [{ text: companyName, alignment: 'left' }],
                [{ text: companyAddress, alignment: 'left', fontSize: 8 }],
                [{ text: companyPhoneNumber, alignment: 'left', fontSize: 8 }]
              ]
            },
            layout: 'noBorders'
          },
          {
            alignment: 'center',
            table: {
              widths: '*',
              body: [
                [''],
                [''],
                [{ text: `${tract.name} Tract Information`, fontSize: 15, alignment: 'center' }]
              ]
            },
            layout: 'noBorders'
          },
          { text: '' }
        ]
      ]
    },
    layout: 'noBorders'
  }
}

function getForesterUser (tract) {
  return tract.foresterUser?.name || labelFor('notSpecified')
}

function getLogger (tract) {
  return tract.loggerAccountName || labelFor('notSpecified')
}

function getMapAndParcelNumber (tract) {
  return tract.mapAndParcelNumber || labelFor('notSpecified')
}

function getBusinessEntity (tract) {
  return tract.businessEntity || labelFor('notSpecified')
}

function getTractLocationText (tract) {
  const { countrySubdivision: state, countrySecondarySubdivision: county } = tract.spot
  return (state && county) ? `${county}, ${state}` : labelFor('notSpecified')
}
