<template>
  <v-card :flat="contractCreator" data-testid="account-form">
    <v-card-title class="secondary" v-if="!contractCreator">
      <span class="tertiary--text headline">{{titleText}}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh />
    </v-card-title>
    <v-card-text>
      <FormWrapper
        testId="save-account"
        :buttonText="saveBtnText"
        @submit="saveButtonClicked"
      >
        <v-container fluid grid-list-lg>
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="6" lg="4">
              <v-text-field
              v-model="account.name"
              data-testid="account-name"
              autocomplete="chrome-off"
              color="black"
              ref="accountName"
              :label="$t('name')"
              counter="64"
              maxlength="64"/>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="4">
              <v-text-field
              v-model="account.code"
              data-testid="account-code"
              :label="$t('code')"
              color="black"
              counter
              maxlength="10"/>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="4">
              <v-text-field
              v-model="account.exportCode"
              data-testid="account-export-code"
              :label="$t('exportCode')"
              color="black"
              counter
              maxlength="32"/>
            </v-col>
            <v-col cols="6" v-if="!loading">
              <TagSelector
              :propTags="account.accountTags"
              @tags-chosen="tagsChosen"/>
            </v-col>
            <v-col cols="12" v-if="!loading" class="mb-6">
              <AccountCertificationCardCollection
                :certifications="account.certifications"
                :accountId="accountId"
                @account-certification-added="addCertification"
                @account-certification-edited="editCertification"
                @account-certification-removed="removeCertification"
                :files="getFilesByCategory(AccountFileCategory.Certification)"
                @file-edited="getFiles"
              />
            </v-col>
            <v-col cols="12" v-if="!loading" class="mb-6">
              <InsurancePolicies
              :insurancePolicies="account.insurancePolicies"
              @policy-created="insurancePolicyCreated"
              @policy-deleted="insurancePolicyDeleted"
              @policy-edited="insurancePolicyUpdated"
              :files="getFilesByCategory(AccountFileCategory.Insurance)"
              @file-edited="getFiles"/>
            </v-col>
            <v-col>
              <AccountTabs
              :account="account"
              @address-created="addressCreated"
              @address-updated="addressUpdated"
              @address-deleted="addressDeleted"
              @contact-created="contactCreated"
              @contact-updated="contactUpdated"
              @contact-deleted="contactDeleted"/>
            </v-col>
            <v-col cols="12">
              <AccountLocation
              :propSpot="account.receiverSpot"
              @selected-location="spotUpdated"
              @removed-location="spotRemoved"/>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { accountFilesClient } from '@/utils/AccountFiles'
import { AccountFileCategory } from '@/utils/Enumerations'

export default {
  name: 'AccountForm',

  components: {
    TagSelector: () => import('@/components/account/account-tags/TagSelector.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    InsurancePolicies: () => import('@/components/account/Insurance/InsurancePolicies.vue'),
    AccountLocation: () => import('@/components/account/AccountLocation.vue'),
    AccountTabs: () => import('@/components/account/AccountTabs.vue'),
    AccountCertificationCardCollection: () => import('@/components/account/certifications/AccountCertificationCardCollection.vue')
  },

  props: {
    accountId: {
      type: Number,
      default: undefined
    },
    contractCreator: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    saveBtnText () {
      return this.accountId ? this.$t('saveChanges') : this.$t('createAccount')
    },

    titleText () {
      return this.accountId ? this.$t('editingAccount', { account: this.account.name }) : this.$t('createAccount')
    }
  },

  data: () => ({
    account: {
      name: '',
      code: '',
      loading: false,
      exportCode: '',
      addresses: [],
      contacts: [],
      tagsIds: [],
      certifications: [],
      insurancePolicies: [],
      receiverSpot: undefined
    },
    loading: false,
    files: undefined,
    AccountFileCategory
  }),

  async created () {
    await this.getAccount()
    await this.getFiles()
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.accountName.focus()
    }, 0)
  },

  methods: {
    ...mapActions('account', ['fetchAccountById', 'createAccount', 'updateAccount', 'updateAccountAddress', 'deleteAccount', 'deleteAccountAddress', 'addAddressToAccount']),
    ...mapActions('account-contacts', ['updateContact', 'deleteContact', 'createContact']),
    ...mapActions('certification', ['addAccountCertification', 'updateAccountCertification', 'removeAccountCertification']),
    ...mapActions('account-insurance-policies', ['fetchAccountInsurancePolicies', 'deleteAccountInsurancePolicy', 'createInsurancePolicy', 'updateInsurancePolicy']),
    tagsChosen (tags) {
      this.account.tagIds = tags
    },
    async getAccount () {
      if (this.accountId) {
        this.loading = true
        try {
          const response = await this.fetchAccountById(this.accountId)
          this.account = JSON.parse(JSON.stringify(response))
          this.account.tags = response.accountTags
          this.account.receiverSpot = {
            latitude: response.receiverSpot?.latitude,
            longitude: response.receiverSpot?.longitude,
            spotId: response.receiverSpot?.spotId
          }
        } finally {
          this.loading = false
        }
      }
    },

    async addCertification (certification) {
      if (this.accountId) {
        await this.addAccountCertification(certification)
        await this.getAccount()
        this.$emit('account-policies-changed')
      } else {
        this.account.certifications.push(certification)
      }
    },
    async editCertification (certification) {
      if (this.accountId) {
        await this.updateAccountCertification(certification)
        await this.getAccount()
        this.$emit('account-policies-changed')
      } else {
        const oldCertification = this.account.certifications.find(c => c.certificationId === certification.certificationId)
        if (oldCertification !== undefined) {
          oldCertification.effectiveDate = certification.effectiveDate
          oldCertification.expirationDate = certification.expirationDate
          oldCertification.certificateNumber = certification.certificateNumber
        }
      }
    },
    async removeCertification (certification) {
      if (this.accountId) {
        await this.removeAccountCertification(certification).then(async (response) => {
          if (response) {
            const file = this.getFilesByCategory(this.AccountFileCategory.Certification)?.find((file) => {
              return Number(file.policyId) === certification.certificationId
            })
            await this.deleteFile(file)
          }
        })
        await this.getAccount()
        this.$emit('account-policies-changed')
      } else {
        this.account.certifications = this.account.certifications.filter(c => c.certificationId !== certification.certificationId)
      }
    },

    async getFiles () {
      if (this.accountId) {
        this.files = await accountFilesClient.getFilesForAccount(this.accountId)
      }
    },

    async insurancePolicyCreated (policy) {
      if (this.accountId) {
        policy.accountId = this.accountId
        await this.createInsurancePolicy({
          identifiers: { accountId: this.accountId },
          accountInsurancePolicy: policy
        })
        this.getAccount()
      } else {
        this.account.insurancePolicies.push(policy)
      }
    },
    async insurancePolicyUpdated (payload) {
      const policy = payload.policy
      if (policy.accountInsurancePolicyId !== undefined && policy.accountId !== undefined) {
        await this.updateInsurancePolicy({
          identifiers: { accountId: this.accountId, accountInsurancePolicyId: policy.accountInsurancePolicyId },
          accountInsurancePolicy: policy
        })
        this.getAccount()
      } else {
        const newPolicies = JSON.parse(JSON.stringify(this.account.insurancePolicies))
        newPolicies[payload.index] = policy
        this.account.insurancePolicies = []
        this.$nextTick(_ => {
          this.account.insurancePolicies = newPolicies
        })
      }
    },
    async insurancePolicyDeleted (policy, deleteBlobFilesCallback = undefined) {
      if (policy.accountInsurancePolicyId && this.accountId) {
        await this.deleteAccountInsurancePolicy({
          accountId: this.accountId,
          accountInsurancePolicyId: policy.accountInsurancePolicyId
        }).then((response) => {
          if (response && deleteBlobFilesCallback) {
            deleteBlobFilesCallback()
          }
        })
        this.getAccount()
      } else {
        this.account.insurancePolicies.splice(this.account.insurancePolicies.indexOf(policy))
      }
    },
    async addressCreated (addresses) {
      if (this.accountId) {
        for (const address of addresses) {
          await this.addAddressToAccount({
            address: address,
            accountId: this.accountId
          })
        }
        this.getAccount()
      } else {
        this.account.addresses.push(...addresses)
      }
    },
    async addressUpdated (payload) {
      if (payload.address.accountAddressId && this.accountId) {
        await this.updateAccountAddress({
          address: payload.address,
          accountId: this.accountId
        })
        this.getAccount()
      } else {
        const newAddresses = JSON.parse(JSON.stringify(this.account.addresses))
        newAddresses[payload.index] = payload.address
        this.account.addresses = []
        this.$nextTick(_ => {
          this.account.addresses = newAddresses
        })
      }
    },
    async addressDeleted (payload) {
      if (payload.address.accountAddressId && this.accountId) {
        await this.deleteAccountAddress({
          accountAddressId: payload.address.address.addressId,
          accountId: this.accountId
        })
        this.getAccount()
      } else {
        this.account.addresses.splice(payload.index)
      }
    },
    async contactCreated (contact) {
      if (this.accountId) {
        await this.createContact({
          identifiers: { accountId: this.accountId },
          contact: contact
        })
        this.getAccount()
      } else {
        const newContacts = JSON.parse(JSON.stringify(this.account.contacts))
        if (contact.isDefault) {
          newContacts.forEach(contact => {
            contact.isDefault = false
          })
        }
        if (contact.receivesDailyTicketReport) {
          newContacts.forEach(contact => {
            contact.receivesDailyTicketReport = false
          })
        }
        newContacts.push(contact)
        this.account.contacts = []
        this.$nextTick(_ => {
          this.account.contacts = newContacts
        })
      }
    },
    async contactUpdated (payload) {
      if (payload.contact.accountContactId && this.accountId) {
        await this.updateContact({
          contact: payload.contact,
          identifiers: { accountId: this.accountId, contactId: payload.contact.accountContactId }
        })
        this.getAccount()
      } else {
        const newContacts = JSON.parse(JSON.stringify(this.account.contacts))
        const defaultIndex = newContacts.findIndex(c => c.isDefault)
        if (defaultIndex !== payload.index && defaultIndex !== -1) {
          newContacts[defaultIndex].isDefault = false
        }
        newContacts[payload.index] = payload.contact
        this.account.contacts = []
        this.$nextTick(_ => {
          this.account.contacts = newContacts
        })
      }
    },

    async contactDeleted (payload) {
      if (payload.contact.accountContactId && this.accountId) {
        await this.deleteContact({
          accountId: this.accountId,
          contactId: payload.contact.accountContactId
        })
        this.getAccount()
      } else {
        this.account.contacts.splice(payload.index)
      }
    },

    async saveButtonClicked () {
      if (this.accountId) {
        await this.updateAccount(this.account)
      } else {
        this.account.certifications.forEach(c => {
          delete c.name
        })
        const response = await this.createAccount(this.account)
        this.$emit('account-id', response)
      }
      this.$emit('account-changed')
    },

    spotUpdated (spot) {
      this.account.receiverSpot = {
        latitude: spot.latitude,
        longitude: spot.longitude
      }
    },

    spotRemoved () {
      this.account.receiverSpot = undefined
    },

    getFilesByCategory (fileCategory) {
      return this.files?.filter(f => f.fileCategory === fileCategory.value)
    },

    async deleteFile (file) {
      if (file?.ref) {
        await accountFilesClient.deleteFile(file?.ref)
        await this.getFiles()
      }
    }
  }
}
</script>
