import { authorizeUser } from '@/msal-browser/Authentication.js'
import store from '@/store/index.js'
import Constants, { LocalStorageKeys } from '@/utils/constants'
import { ErrorSource } from '@/utils/Enumerations'
import { CancelToken } from 'axios'

export const outboundRequests = {}

export const interceptor = async (request) => {
  if (request.url instanceof URL) {
    request.baseURL = request.url.origin
    request.url = `${request.url.pathname}${request.url.search}`
  } else {
    if (!request.url.startsWith('v')) { request.url = `v1/${request.url}` }
  }

  const pathname = new URL(request.baseURL + request.url).pathname
  if (pathname.includes('undefined')) {
    console.error(`Request cancelled due to undefined value in path name: "${pathname}"`)
    request.cancelToken = new CancelToken((cancel) => cancel('UNDEFINED_IN_PATH_NAME'))
  }

  let token = false

  try {
    token = await authorizeUser()
  } catch (err) {
    store.dispatch('user/logout', false)
    store.dispatch('snackbar/setSnackError', {
      message: 'Session expired.',
      code: 'AUTH_FAILED',
      source: ErrorSource.WebClient
    })
    throw new Error('Invalid permissions, logging out.')
  }

  request.headers.clientId = Constants.TITAN_CLIENT_ID
  request.headers.Authorization = `Bearer ${token}`

  const fromLocalStorage = localStorage.getItem(LocalStorageKeys.MULTI_TENANT_CONFIGURATION)
  const configuration = fromLocalStorage ? JSON.parse(fromLocalStorage) : null
  if (configuration?.selectedTenant) {
    request.headers.tenantCode = configuration.selectedTenant.code
  }

  if (request.method !== 'get' && !request.url.includes('userPreferences')) {
    store.dispatch('global/setLoading', true)
  } else {
    request = checkForDuplicateRequest(request)
  }

  return request
}

const checkForDuplicateRequest = (request) => {
  const accountIdRegex = /^.*accounts\/[0-9]+$/
  if (request.headers.tagsFilter || request.url.includes('tags') || accountIdRegex.test(request.url)) {
    return request
  }

  if (outboundRequests[request.url]) {
    request.cancelToken = new CancelToken((cancel) => cancel('REQ_IN_FLIGHT'))
  } else {
    outboundRequests[request.url] = request
  }

  return request
}
