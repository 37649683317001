<template>
  <v-card>
    <v-card-title class="secondary white--text">
      <span v-html="title"/>
      <v-spacer/>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" xs="12" sm="12" md="6" :lg="isByproductPurchaseContract ? 4 : 3">
            <v-select
            :items="contractTypes"
            :label="$t('contractType')"
            v-model="contractType"
            return-object
            value="name"
            item-text="name"
            color="black">
            </v-select>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" :lg="isByproductPurchaseContract ? 4 : 3">
            <AccountAutocomplete
              :accountId="tract?.contractAccountId"
              :title="$t('account')"
              hideRefresh
              @account-chosen="accountChosen"
              data-testid="contract-tract-account"
              showCertified
            />
          </v-col>
          <v-col v-if="!isByproductPurchaseContract" cols="12" xs="12" sm="12" md="6" lg="3">
            <SettingAutocomplete
              :settingName="tract?.contractSetting ?? ''"
              @setting-chosen="settingChosen"
              hideRefresh
              data-testid="contract-tract-setting"
              />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" :lg="isByproductPurchaseContract ? 4 : 3">
            <v-autocomplete
              v-model="selectedBundle"
              :loading="templatesLoading"
              :items="mappedTemplateBundlesWithWarning"
              :label="$t('templateBundles')"
              :hint="$t('selectBundleToUse')"
              persistent-hint
              return-object
              item-text="name"
              item-value="name"
              @change="templateSelected"
            >
              <template #item="{item}">
                {{ item.name }}
                <Icon
                icon="mdi-alert"
                iconColor="warning"
                tooltipColor="warning"
                v-if="item.missingTractFieldsString"
                :tooltipText="$t('missingNecessaryTractFields', { fields: item.missingTractFieldsString })"/>
              </template>
              <template #selection="{item}">
                {{ item.name }}
                <Icon
                icon="mdi-alert"
                iconColor="warning"
                tooltipColor="warning"
                v-if="item.missingTractFieldsString"
                :tooltipText="$t('missingNecessaryTractFields', { fields: item.missingTractFieldsString })"/>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="selectedBundle">
          <v-col cols="auto">
            <v-checkbox
            :label="$t('onlyShowTractLevelInput')"
            v-model="limitToTractLevelCapture"
            color="secondary"
            ></v-checkbox>
          </v-col>
          <v-col cols="auto">
            <v-checkbox
            v-if="isCruiseRateCheckboxVisible"
            :label="$t('useCruiseRateForStumpage')"
            v-model="useCruiseRateForStumpage"
            color="secondary"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="selectedBundle">
          <v-col cols="12" xs="12" sm="12" md="12" :lg="showGrossProfit ? 10 : 12" :xl="showGrossProfit ? 10 : 12">
            <BundleActivityDefinition
            :tract="tract"
            :tractDestination="tractDestination"
            :propBundle="selectedBundle"
            :products="products"
            :activityTemplates="allActivityTemplates"
            :limitToTractLevelCapture="limitToTractLevelCapture"
            :useCruiseRateForStumpage="isCruiseRateCheckboxVisible ? useCruiseRateForStumpage : false"
            :templateStats="activityTemplateStatsMap"
            :contractAccount="chosenAccount"
            :landowners="landowners"
            @activity-builders-updated="activityBuildersUpdated"
            @duplicate-counterparties="handleDuplicateCounterparties"/>
          </v-col>
          <v-col v-if="showGrossProfit" cols="12" xs="12" sm="12" md="12" lg="2" xl="2">
            <v-card flat>
              <v-card-title>
                <span>{{ $t('grossProfitPerTon') }}</span>
                <v-spacer/>
                <Icon
                v-if="showPayByLoadWarning"
                icon="mdi-information-outline"
                :small="false"
                :tooltipText="$t('profitPerTonCannotBeCalculatedForPayByLoad')"/>
                <Icon
                v-if="showNonInventoryWarning"
                icon="mdi-information-outline"
                :small="false"
                :tooltipText="$t('nonInventoryCostsExcluded')"/>
              </v-card-title>
              <v-card-text>
                <v-data-table
                :items="grossProfitByProduct"
                :headers="grossProfitByProductHeaders"
                data-testid="gross-profit-by-product-table"
                sort-by="amount"
                sort-desc
                hide-default-footer
                dense>
                  <template #item.amount="{item}">
                    {{formatMoney(item.amount)}}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else align="center" justify="center">
          <v-col cols="auto">
            <p class="title">
              {{$t('chooseBundleToAddActivities')}}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-checkbox
      :label="$t('requiresLogger')"
      v-model="requiresLogger"
      v-if="!isStumpageTract"
      />
      <v-checkbox
      :label="$t('markAsDraft')"
      :disabled="lockAsDraft"
      v-model="markAsDraft"
      class="px-6"
      />
      <Icon
      v-if="missingReceivableActivity"
      icon="mdi-alert"
      iconColor="warning"
      :small="false"
      tooltipColor="warning"
      :tooltipText="$t('missingAccountsReceivableActivity')"/>
      <v-btn
      :disabled="hasDuplicateCounterparties || !selectedBundle"
      class="secondary ml-2"
      @click="createContractAndActivities">
        {{$t('createContract')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { TractDestination } from '@/model/TractDestination.js'
import { formatMoney } from '@/utils/NumericMutations.js'
import { mapActions, mapGetters } from 'vuex'
import { localToUTC } from '@/utils/DateFormatter.js'
import Cookies from 'js-cookie'
import { CookieKeys, LocalStorageKeys } from '@/utils/constants.js'
import moment from 'moment'
import {
  ContractType,
  TractTypeCategory,
  AccountingCategory,
  CostType,
  ActivityModifier,
  PayBy,
  FinancialIntegrationType,
  RecoveryMode,
  RuntimeCounterparty
} from '@/utils/Enumerations.js'
import { MAX_ACTIVITY_RATE as maxActivityRate } from '@/utils/rules'
export default {
  name: 'TractContractForm',

  props: {
    tract: Object,
    tractDestination: TractDestination,
    landowners: []
  },

  components: {
    AccountAutocomplete: () => import('@/components/autocomplete/AccountAutocomplete.vue'),
    SettingAutocomplete: () => import('@/components/autocomplete/ContractSettingAutocomplete.vue'),
    BundleActivityDefinition: () => import('@/components/tract/tract-form/BundleActivityDefinition'),
    Icon: () => import('@/components/helper/Icon.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  data: () => ({
    contractType: ContractType.Production,
    selectedBundle: undefined,
    activityBuilders: null,
    limitToTractLevelCapture: false,
    chosenAccount: null,
    chosenSetting: null,
    hasDuplicateCounterparties: false,
    useCruiseRateForStumpage: (Cookies.get(CookieKeys.CONTRACTS_OPERATION_USE_CRUISE_FOR_STUMPAGE) !== undefined)
      ? Cookies.get(CookieKeys.CONTRACTS_OPERATION_USE_CRUISE_FOR_STUMPAGE) === 'true'
      : true,
    markAsDraft: (Cookies.get(CookieKeys.CONTRACTS_OPERATION_ASDRAFT) !== undefined)
      ? Cookies.get(CookieKeys.CONTRACTS_OPERATION_ASDRAFT) === 'true'
      : true,
    requiresLogger: localStorage.getItem(LocalStorageKeys.TRACT_CONTRACT_REQUIRES_LOGGER) === 'true',
    activityTemplateStatsMap: null
  }),

  computed: {
    ...mapGetters('activity-templates', ['templatesLoading', 'allTemplateBundles', 'allActivityTemplates']),
    ...mapGetters('user', ['companyInfo']),
    ...mapGetters('account', ['allAccounts']),
    contractTypes () {
      if (this.tractDestination.products.every(p => p.isByproduct)) return [ContractType.ByproductPurchase]
      if (this.tractDestination.products.every(p => !p.isByproduct)) return [ContractType.Production, ContractType.WoodsSale]
      return [ContractType.Production, ContractType.WoodsSale, ContractType.ByproductPurchase]
    },

    grossProfitByProductHeaders () {
      return [
        { text: this.$t('product'), value: 'productName' },
        { text: this.$t('amount'), value: 'amount', align: 'right' }
      ]
    },

    isStumpageTract () {
      return this.tract.type.category === TractTypeCategory.Stumpage.value
    },

    products () {
      return this.tractDestination.products.filter(p => p.isByproduct === this.isByproductPurchaseContract)
    },

    showGrossProfit () {
      return this.contractType === ContractType.WoodsSale
    },

    showPayByLoadWarning () {
      return this.activityBuilders?.some(a => a.payBy === PayBy.Load.value)
    },

    showNonInventoryWarning () {
      return this.activityBuilders?.some(a => a.costBehavior === CostType.NonInventory.value)
    },

    missingReceivableActivity () {
      return !this.activityBuilders?.some(a => a.category === AccountingCategory.Receivable.value) &&
        this.contractType.value === ContractType.WoodsSale.value
    },

    isByproductPurchaseContract () {
      return this.contractType.value === ContractType.ByproductPurchase.value
    },

    grossProfitByProduct () {
      if (this.selectedBundle === undefined ||
        this.contractType === ContractType.Production ||
        this.activityBuilders === null ||
        this.showPayByLoadWarning) {
        return []
      }

      const updateProductAmount = (product, amount, activity) => {
        const factor = activity.category === AccountingCategory.Receivable.value ? 1 : -1
        return { ...product, amount: product.amount + amount * factor }
      }

      const createProductEntry = (product, amount, activity) => {
        const factor = activity.category === AccountingCategory.Receivable.value ? 1 : -1
        return {
          productName: product.productName,
          productId: product.productId,
          amount: amount * factor
        }
      }

      const addProductToMap = (map, product, amount, activity) => {
        const existingProduct = map.get(product.productId)
        if (existingProduct) {
          map.set(product.productId, updateProductAmount(existingProduct, amount, activity))
        } else {
          map.set(product.productId, createProductEntry(product, amount, activity))
        }
      }

      const getProductsWithAmounts = (activity, products) => {
        if (activity.products.length < products.length) {
          return products.map(p => {
            const definedProduct = activity.products.find(ap => ap.productId === p.productId)
            return definedProduct ? { ...definedProduct } : { ...p, baseRate: activity.baseRate }
          })
        }

        return activity.products.map(p => ({ ...p }))
      }

      const map = this.activityBuilders
        .filter(ab => ab.costBehavior !== CostType.NonInventory.value)
        .reduce((productMap, activity) => {
          const productsWithAmounts = getProductsWithAmounts(activity, this.products)

          productsWithAmounts.forEach(p => {
            const amount = activity.modifier === ActivityModifier.DistanceTraveled.value
              ? p.baseRate * this.tractDestination.distance
              : p.baseRate
            addProductToMap(productMap, p, amount, activity)
          })

          return productMap
        }, new Map())

      return Array.from(map.values())
    },

    title () {
      return `New Contract: ${this.tract.name} &rarr; ${this.tractDestination.destinationAccountName}`
    },

    isCruiseRateCheckboxVisible () {
      if (this.selectedBundle === undefined || this.tract.type.category === 1) {
        return false
      }

      const suitableActivity = this.selectedBundle.activityBuilders.find(a => a.category !== 1 && a.costBehavior === 2)
      return suitableActivity !== undefined
    },

    lockAsDraft () {
      return this.companyInfo.requireApprovalForContractModifications
    },

    mappedTemplateBundlesWithWarning () {
      const nullOrUndefined = (val) => { return val === null || val === undefined }
      return this.allTemplateBundles.map(tb => {
        return {
          ...tb,
          missingTractFieldsString: tb.activityBuilders.reduce((string, builder) => {
            if (!nullOrUndefined(builder.runtimeField) && !this.runtimeCounterpartyAssigned(builder.runtimeField, this.tract, this.tractDestination)) {
              string = string === undefined ? '' : string + ', '
              string += RuntimeCounterparty.fromInt(builder.runtimeField)
            }
            return string
          }, undefined)
        }
      })
    }
  },

  watch: {
    useCruiseRateForStumpage (val) {
      Cookies.set(CookieKeys.CONTRACTS_OPERATION_USE_CRUISE_FOR_STUMPAGE, val)
    },

    limitToTractLevelCapture (val) {
      Cookies.set(CookieKeys.LIMIT_TO_TRACT_LEVEL_CAPTURE, val)
    },

    markAsDraft (mark) {
      if (mark) {
        Cookies.set(CookieKeys.CONTRACTS_OPERATION_ASDRAFT, 'true')
      } else {
        Cookies.set(CookieKeys.CONTRACTS_OPERATION_ASDRAFT, 'false')
      }
    },

    requiresLogger (val) {
      localStorage.setItem(LocalStorageKeys.TRACT_CONTRACT_REQUIRES_LOGGER, val)
    },

    isByproductPurchaseContract (val) {
      if (val) this.chosenSetting = null
    }
  },

  created () {
    this.setInitialValues()
  },

  methods: {
    ...mapActions('activity-templates', ['fetchTemplateBundles', 'fetchActivityTemplates', 'fetchActivityTemplateStatsById']),
    ...mapActions('contract', ['createContract']),
    ...mapActions('activity', ['createActivity']),
    ...mapActions('activity-detail', ['createActivityDetail']),
    formatMoney,

    setInitialValues () {
      this.contractType = this.tractDestination.isExternal
        ? ContractType.WoodsSale
        : this.tractDestination.products.some(p => !p.isByproduct)
          ? ContractType.Production
          : ContractType.ByproductPurchase

      const cookie = Cookies.get(CookieKeys.LIMIT_TO_TRACT_LEVEL_CAPTURE)
      if (cookie) {
        this.limitToTractLevelCapture = cookie === 'true'
      }

      if (this.companyInfo.requireApprovalForContractModifications) this.markAsDraft = true
      this.fetchTemplateBundles()
      this.fetchActivityTemplates()
    },

    accountChosen (account) {
      this.chosenAccount = account
    },

    settingChosen (setting) {
      this.chosenSetting = setting
    },

    activityBuildersUpdated (activityBuilders) {
      this.activityBuilders = [...activityBuilders]
    },

    close () {
      this.$emit('close', false)
    },

    validateForm () {
      if (this.chosenAccount === null) {
        return this.$t('youMustSpecifyAnAccount')
      }

      if (this.chosenSetting === null && !this.isByproductPurchaseContract) {
        return this.$t('youMustSpecifyASetting')
      }

      if (this.activityBuilders === null) {
        return null
      }

      const counterparties = this.activityBuilders.map(b => ({ ...b.counterparty, category: b.category, runtimeField: b.runtimeField }))

      for (const counterparty of counterparties) {
        if ((counterparty?.accountId === null || counterparty?.accountId === undefined) &&
          (counterparty.runtimeField === undefined || counterparty.runtimeField === null)) {
          return this.$t('youMustSpecifyACounterparty')
        }

        if (counterparty?.exportCode === '' &&
          counterparty?.category !== AccountingCategory.Accrual.value &&
          this.companyInfo.financialIntegrationType !== FinancialIntegrationType.None.value &&
          !this.markAsDraft) {
          return this.$t('counterpartyExportCodeErrorActivity', { accountName: counterparty.name })
        }
      }

      for (const builder of this.activityBuilders.filter(b => !b.limitToBaseRate)) {
        const minBaseRate = builder.baseRate
        for (const product of builder.products) {
          if (product.baseRate < minBaseRate) {
            return this.$t('productBaseRatesMustBeGreaterThanOrEqualToActivityBaseRate')
          }
        }
      }

      if (this.activityBuilders.some(ab => ab.baseRate > maxActivityRate) ||
        this.activityBuilders.some(ab => ab.products.some(p => p.baseRate > maxActivityRate))) {
        return this.$t('invalidActivityRate')
      }

      return null
    },

    handleDuplicateCounterparties (duplicates) {
      this.hasDuplicateCounterparties = Object.keys(duplicates).length > 0
    },

    async createContractAndActivities () {
      const validationError = this.validateForm()
      if (validationError !== null) {
        this.setSnackError(validationError)
        return
      }

      const harvestEndDate = (this.tract.harvestEndDate === null || this.tract.harvestEndDate === undefined)
        ? moment().add(3, 'months').format()
        : this.tract.harvestEndDate

      const contractCreateRequest = {
        type: this.contractType.value,
        accountId: this.chosenAccount.accountId,
        settingId: this.chosenSetting?.settingId,
        destinationAccountId: this.tractDestination.destinationAccountId,
        tractId: this.tract.tractId,
        requiresExt1: this.tract.requiresExt1,
        requiresExt2: this.tractDestination.requiresExt2,
        requiresLogger: this.isStumpageTract ? true : this.requiresLogger,
        effectiveDate: localToUTC(this.tract.harvestStartDate),
        expirationDate: localToUTC(harvestEndDate),
        distance: this.tractDestination.distance,
        isDraft: this.markAsDraft
      }

      const contractResponse = await this.createContract(contractCreateRequest)

      for (const activity of this.activityBuilders) {
        const activityCreateRequest = {
          contractId: contractResponse.contractId,
          templateId: activity.activityTemplateId,
          effectiveDate: contractCreateRequest.effectiveDate,
          recoveryMode: activity.recoveryMode.value,
          advanceId: activity.recoveryMode.value !== RecoveryMode.None.value ? activity.advanceId : undefined,
          baseCost: {
            rate: activity.baseRate,
            payOn: activity.payOn,
            payBy: activity.payBy
          },
          runtimeCounterparty: activity.runtimeField,
          contractorId: activity.counterparty?.accountId
        }

        const activityResponse = await this.createActivity(activityCreateRequest)

        if (activity.products !== undefined) {
          for (const product of activity.products) {
            const detailCreateRequest = {
              activityDetail: {
                rate: product.baseRate
              },
              contractId: contractResponse.contractId,
              activityId: activityResponse.activityId,
              productId: product.productId
            }
            await this.createActivityDetail(detailCreateRequest)
          }
        }
      }

      this.$emit('close', true)
    },

    runtimeCounterpartyAssigned (runtimeField, tract, tractDestination) {
      const nullOrUndefined = (val) => { return val === null || val === undefined }
      switch (runtimeField) {
        case RuntimeCounterparty.Driver.value:
          return true
        case RuntimeCounterparty.Landowner.value:
          return tract.landownerCount > 0
        case RuntimeCounterparty.DefaultLogger.value:
          return !nullOrUndefined(tract.loggerAccountId)
        case RuntimeCounterparty.ContractAccount.value:
          return !nullOrUndefined(this.chosenAccount?.accountId)
        case RuntimeCounterparty.Hauler.value:
          return !nullOrUndefined(tract.haulerAccountId)
        case RuntimeCounterparty.Supplier.value:
          return !nullOrUndefined(tract.supplierAccountId)
        case RuntimeCounterparty.DestinationAccount.value:
          return !nullOrUndefined(tractDestination.destinationAccountId)
        case RuntimeCounterparty.ConsultingForester.value:
          return !nullOrUndefined(tract.consultingForesterAccountId)
      }
    },

    async templateSelected () {
      const templateStats = new Map()
      this.selectedBundle.activityBuilders.forEach(async ab => {
        templateStats.set(ab.activityTemplateId, await this.fetchActivityTemplateStatsById(ab.activityTemplateId))
      })
      this.activityTemplateStatsMap = templateStats
    }
  }
}
</script>
