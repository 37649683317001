import messages from '@/lang'

export default {
  payPeriodHeaders (locale) {
    return ([
      {
        sortable: false,
        text: messages[locale].status,
        value: 'status'
      },
      {
        sortable: true,
        text: messages[locale].startDate,
        value: 'startDate'
      },
      {
        sortable: true,
        text: messages[locale].endDate,
        value: 'endDate'
      },
      {
        sortable: true,
        text: messages[locale].payDate,
        value: 'payDate'
      },
      {
        sortable: false,
        text: messages[locale].actions,
        value: 'actions',
        align: 'center'
      }
    ])
  }
}
