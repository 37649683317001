import { serverUrl, timeout } from '../../../env-var.js'
import axios from 'axios'

export const createAxiosObject = () => {
  const ApiServer = axios.create({
    baseURL: serverUrl.includes('VUE') ? process.env.VUE_APP_SERVER_URI : serverUrl
  })

  ApiServer.defaults.timeout = getTimeout(timeout)
  ApiServer.defaults.timeoutErrorMessage = 'Connection to the server timed out.'

  ApiServer.urlFor = (route, options = {}) => {
    options = options ?? {}
    const { params, version } = options

    if (Array.isArray(route)) {
      route = route
        .map(piece => piece.toString())
        .map(piece => (piece.slice(-1) === '/') ? piece.slice(0, -1) : piece)
        .join('/')
    }

    const url = new URL(`${ApiServer.defaults.baseURL}`)
    url.pathname += `v${version ?? 1}/${route}`

    if (params !== undefined) {
      Object.keys(params).forEach(key => {
        if (params[key] !== undefined) {
          url.searchParams.append(key, params[key])
        }
      })
    }

    return url
  }

  return ApiServer
}

const getTimeout = (_timeout) => {
  switch (typeof _timeout) {
    case 'number':
      return _timeout
    case 'string':
      if (!_timeout.includes('VUE')) {
        return Number(_timeout)
      }
      return 10000
    default:
      return 10000
  }
}
