<template>
  <v-card min-height="80vh">
    <v-card-title class="secondary white--text">
      <span class="headline">
        {{title}}
      </span>
      <v-spacer/>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <v-container fluid v-if="!loader.loading">
        <span class="subtitle-1 font-weight-bold mb-5">{{dateRangeAsString}}</span>
        <v-row dense v-if="isStumpage">
          <v-col cols="6" sm="12" md="6" v-for="table in summaryTables" :key="table.label">
            <v-card outlined class="mb-3">
              <v-card-title class="subtitle-1 mt-n1" >{{(table.label)}}</v-card-title>
              <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
                <v-container fluid>
                  <v-simple-table>
                    <tr v-for="item in table.items" :key="`ts-table-${item.label}`">
                      <v-tooltip bottom v-if="item.tooltip" color="secondary">
                        <template #activator="{on}">
                          <td v-on="on">{{(item.label)}}:</td>
                        </template>
                        <span class="subtitle-2 font-weight-regular white--text">{{item.tooltip}}</span>
                      </v-tooltip>
                      <td v-else>{{$t(item.label)}}:</td>
                      <td :style="`text-align: ${item.value?.[0] === '$' ? 'right' : 'unset'}`">{{item.value}}</td>
                    </tr>
                  </v-simple-table>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
          <v-container fluid v-if="!loader.error" >
            <v-row dense justify="end" class="pa-6">
              <v-col cols="auto">
                <ColumnAdjuster
                v-if="viewingProducts"
                :propColumnSet.sync="productColumnSet"
                @new-columns-selected="newProductColumnsSelected"/>
                <ColumnAdjuster
                v-if="!viewingProducts"
                :propColumnSet.sync="ticketColumnSet"
                @new-columns-selected="newTicketColumnsSelected"/>
              </v-col>
              <v-col cols="auto">
                <Icon
                :icon="viewingProducts ? 'mdi-clipboard-text-outline' : 'mdi-pine-tree'"
                iconColor="secondary"
                :tooltipText="viewingProducts ? $t('viewTickets') : $t('viewProducts')"
                :small="false"
                @icon-clicked="viewingProducts = !viewingProducts"
                />
              </v-col>
              <v-col cols="auto">
                <Icon
                icon="mdi-download"
                iconColor="secondary"
                :tooltipText="$t('downloadPDF')"
                :small="false"
                @icon-clicked="printPdfDialog = true"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-expansion-panels v-model="openPanels" multiple>
                  <v-expansion-panel v-for="destination in destinations" :key="destination.accountId">
                    <v-expansion-panel-header active-class="secondary white--text" hide-actions>
                      <span>{{ destination.accountName }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <DataTable
                      v-show="viewingProducts"
                      :headers.sync="detailedSummaryHeaders"
                      :items="destination.productSummaries"
                      :customCells="customCells"
                      :sumHeaders="detailedSummaryHeaders.filter(h => h.total !== undefined).map(c => c.value)"
                      showTotals
                      hideHeader>
                        <template #production-cost-per-ton="{item}">
                          <span>
                            {{formatMoney(item.productionCost / (item.netWeight / 2000))}}
                          </span>
                        </template>
                        <template #stumpage-cost-per-ton="{item}">
                          <span>
                            {{formatMoney(item.stumpageCost / (item.netWeight / 2000))}}
                          </span>
                        </template>
                      </DataTable>
                      <DataTable
                      v-show="!viewingProducts"
                      :headers.sync="detailedTicketHeaders"
                      :items="destination.ticketSummaries"
                      :customCells="customCells"
                      :sumHeaders="detailedTicketHeaders.filter(h => h.total !== undefined).map(c => c.value)"
                      defaultSort="weighedOutAt"
                      sortDesc
                      showTotals
                      >
                        <template #ticket-number="{item}">
                          <Icon
                          :iconColor="getStatus(item).iconColor"
                          :icon="getStatus(item).icon"
                          :tooltipText="getStatus(item).tooltipText"
                          :small="false"
                          />
                          <span>{{ item.ticketNumber }}</span>
                        </template>
                        <template #production-cost-per-ton="{item}">
                          <span>
                            {{formatMoney(item.productionCost / (item.netWeight / 2000))}}
                          </span>
                        </template>
                        <template #stumpage-cost-per-ton="{item}">
                          <span>
                            {{formatMoney(item.stumpageCost / (item.netWeight / 2000))}}
                          </span>
                        </template>
                      </DataTable>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
        <div v-else style="text-align: center; width: 100%;" class="pa-6">
          <span class="title font-italic">{{$t('errorWhileLoadingX', { x: $t('tractcostDetail') })}}</span>
        </div>
      </v-container>
      <Loading v-else/>
    </v-card-text>
    <v-dialog v-model="printPdfDialog" width="400px">
      <ConfirmDialog
      :title="$t('printProductCosts')"
      :body="$t('includeTicketDetailsInPdf')"
      :confirmText="$t('print')"
      color="secondary"
      @confirm="downloadPdf"
      @cancel="printPdfDialog = false">
        <template #custom-body>
          <v-checkbox v-model="includeTickets" color="secondary" :label="$t('includeTickets')"></v-checkbox>
          <v-select
          v-if="includeTickets === true"
          v-model="pdfColumnSort"
          :menu-props="{ bottom: true, offsetY: true }"
          :items="detailedTicketHeaders"
          item-text="text"
          return-object
          :label="$t('sortTicketsBy')"
          ></v-select>
          <v-select
          v-if="includeTickets === true"
          v-model="sortDirection"
          :menu-props="{ bottom: true, offsetY: true }"
          :items="sortDirections"
          item-text="text"
          return-object
          :label="$t('sortDirection')"
          ></v-select>
        </template>
      </ConfirmDialog>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { formatMoney, tonStringFromPounds, numberWithCommas } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import TractHeaders from '@/headers/Tract.js'
import { ResourceLoader } from '../../../utils/ResourceLoader'
import { TicketStatusIcon } from '@/utils/Enumerations.js'
import { TractTypeCategory, EnumValue } from '../../../utils/Enumerations'
import { generatePdf } from '@/utils/pdf-formatters/ProductCostReport.js'
import { LocalStorageKeys } from '@/utils/constants.js'
export default {
  name: 'TractCostDetail',

  props: {
    tractSummary: {
      type: Object,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    entity: {
      type: Object,
      required: true
    },
    tractTypeCategory: {
      type: EnumValue,
      required: true
    }
  },

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    ColumnAdjuster: () => import('@/components/core/ColumnAdjuster.vue'),
    Loading: () => import('@/components/core/Loading.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    openPanels: [0],
    ticketColumnSet: null,
    pdfColumnSort: undefined,
    sortDirection: undefined,
    productColumnSet: null,
    printPdfDialog: false,
    includeTickets: false,
    loading: false,
    loader: ResourceLoader.empty,
    tractCostSummary: undefined,
    viewingProducts: true
  }),

  computed: {
    detailedSummaryHeaders () {
      if (!this.productColumnSet) return []
      return this.productColumnSet.getColumns()
    },

    detailedTicketHeaders () {
      if (!this.ticketColumnSet) return []
      return this.ticketColumnSet.getColumns()
    },

    summary () {
      return this.tractCostSummary?.summary
    },

    sortDirections () {
      return [
        { text: this.$t('ascending'), isAscending: true },
        { text: this.$t('descending'), isAscending: false }
      ]
    },

    destinations () {
      if (this.tractCostSummary === undefined) return []

      return this.tractCostSummary.destinationsInPeriod
    },

    title () {
      return `${this.entity.name} || Products for ${this.tractSummary.name} by Destination`
    },

    dateRangeAsString () {
      return `${utcToLocalDate(this.dateRange.sinceTime)} - ${utcToLocalDate(this.dateRange.untilTime, 'L')}`
    },

    customCells () {
      return [
        {
          slotName: 'ticket-number',
          value: 'ticketNumber'
        },
        {
          slotName: 'production-cost-per-ton',
          value: 'productionCostPerTon'
        },
        {
          slotName: 'stumpage-cost-per-ton',
          value: 'stumpageCostPerTon'
        }
      ]
    },

    isStumpage () {
      return this.tractTypeCategory === TractTypeCategory.Stumpage
    },

    summaryTables () {
      return [
        {
          label: this.$t('tractSummary'),
          items: [
            {
              label: ('tractType'),
              value: this.summary.tractType
            },
            {
              label: this.$t('loadsInPeriod'),
              value: this.summary.loadsInPeriod,
              tooltip: this.$t('loadsBetween', { since: utcToLocalDate(this.dateRange.sinceTime), until: utcToLocalDate(this.dateRange.untilTime, 'L') })
            },
            {
              label: this.$t('loadsThroughEndDate', { endDate: utcToLocalDate(this.dateRange.untilTime, 'L') }),
              value: numberWithCommas(this.summary.loadsThroughUntilTime),
              tooltip: this.$t('loadsBefore', { date: utcToLocalDate(this.dateRange.untilTime, 'L') })
            }
          ]
        },
        {
          label: this.$t('stumpageCosts'),
          items: [
            {
              label: 'purchasePrice',
              value: formatMoney(this.summary.purchasePrice)
            },
            {
              label: this.$t('costsFromStartToEnd', { startDate: utcToLocalDate(this.dateRange.sinceTime), endDate: utcToLocalDate(this.dateRange.untilTime, 'L') }),
              value: formatMoney(this.summary.periodStumpageCosts),
              tooltip: this.$t('stumpageCostsBetween', { since: utcToLocalDate(this.dateRange.sinceTime), until: utcToLocalDate(this.dateRange.untilTime, 'L') })
            },
            {
              label: this.$t('costsThroughEndDate', { endDate: utcToLocalDate(this.dateRange.untilTime, 'L') }),
              value: formatMoney(this.summary.stumpageCostsThroughUntilTime),
              tooltip: this.$t('stumpageCostsBefore', { date: utcToLocalDate(this.dateRange.untilTime, 'L') })
            },
            {
              label: this.$t('lifetimeCosts'),
              value: formatMoney(this.summary.lifetimeStumpageCosts),
              tooltip: this.$t('currentTotalStumpageCosts')
            },
            {
              label: this.$t('balanceThroughEndDate', { endDate: utcToLocalDate(this.dateRange.untilTime, 'L') }),
              value: formatMoney(this.summary.remainingBalance),
              tooltip: `${this.$t('purchasePrice')} - ${this.$t('costsThroughPeriodEnd')}`
            }
          ]
        }
      ]
    }
  },

  watch: {
    includeTickets: {
      handler (val) {
        localStorage.setItem(LocalStorageKeys.PRODUCT_COSTS_INCLUDE_TICKETS, String(val))
      }
    }
  },

  async created () {
    this.productColumnSet = TractHeaders.detailedSummaryHeaders(this.$i18n.locale)
    this.ticketColumnSet = TractHeaders.detailedTicketHeaders(this.$i18n.locale)
    const sortConfig = JSON.parse(localStorage.getItem(LocalStorageKeys.PRODUCT_COST_SORT_CONFIG))
    this.sortDirection = sortConfig?.direction ?? this.sortDirections[1]
    this.pdfColumnSort = sortConfig?.column ?? this.ticketColumnSet.getColumns().find(c => c.value === 'weighedOutAt') ?? this.ticketColumnSet.getColumns()[0]
    this.includeTickets = localStorage.getItem(LocalStorageKeys.PRODUCT_COSTS_INCLUDE_TICKETS) === 'true'
    this.loader = new ResourceLoader(async () => {
      await this.refresh(this.tractSummary.tractId, this.dateRange)
    })
    await this.loader.load()
  },

  beforeDestroy () {
    localStorage.setItem(LocalStorageKeys.PRODUCT_COST_SORT_CONFIG, JSON.stringify({
      column: this.pdfColumnSort,
      direction: this.sortDirection
    }))
  },

  methods: {
    ...mapActions('tract', ['getDetailedTractProductCosts']),
    utcToLocalDate,
    formatMoney,
    tonStringFromPounds,

    toggleProductView () {
      this.viewingProducts = !this.viewingProducts
    },

    getStatus (ticket) {
      return TicketStatusIcon(ticket.status, this.$i18n.locale)
    },

    newTicketColumnsSelected (columnSet) {
      this.ticketColumnSet = columnSet
    },

    newProductColumnsSelected (columnSet) {
      this.productColumnSet = columnSet
    },

    async refresh (tractId, timeframe) {
      const response = await this.getDetailedTractProductCosts({
        tractId: tractId,
        businessEntityId: this.entity.businessEntityId,
        params: {
          ...timeframe
        }
      })

      this.tractCostSummary = response
    },

    async downloadPdf () {
      await generatePdf({
        dateRangeString: this.dateRangeAsString,
        entity: this.entity,
        productHeaders: this.detailedSummaryHeaders,
        ticketHeaders: this.detailedTicketHeaders,
        tractSummary: this.tractCostSummary,
        isStumpage: this.isStumpage,
        includeTickets: this.includeTickets,
        sortConfig: {
          columnSort: this.pdfColumnSort,
          sortDirection: this.sortDirection
        }
      })

      this.printPdfDialog = false
    }
  }
}
</script>
