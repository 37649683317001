import persist from './persist'

export default {
  setLoading (state, status) {
    state.tractsLoading = status
  },
  setTracts (state, tracts) {
    state.tracts = tracts
  },
  addTract (state, tract) {
    state.tracts.push(tract)
  },
  removeTract (state, tractId) {
    state.tracts = state.tracts.filter(t => t.tractId !== tractId)
  },
  updateTract (state, tract) {
    const index = state.tracts.findIndex(t => t.tractId === tract.tractId)
    if (index !== -1) {
      Object.assign(state.tracts[index], tract)
    }
  },
  setChosenTract (state, tract) {
    state.chosenTract = tract
  },
  setTransitionMode (state, mode) {
    state.transitionMode = mode
  },
  setTractTimberVolume (state, timberVolume) {
    state.tractTimberVolume = timberVolume
  },
  setStandingTimberFilter (state, filter) {
    state.standingTimberFilter = filter
    persist.standingTimberFilter.write(filter)
  },
  setCommittedTimberFilter (state, filter) {
    state.committedTimberFilter = filter
    persist.committedTimberFilter.write(filter)
  }
}
