<template>
  <v-autocomplete
    :data-testid="this.dataTestid"
    color="black"
    v-model="chosenTract"
    :label="showLabel ? $t('tract') : ''"
    :clearable="clearable"
    @click:clear="$emit('tract-cleared')"
    :loading="tractsLoading"
    :items="elligibleTracts"
    item-value="tractId"
    return-object
    :filter="filter"
    :solo="soloTheme"
  >
    <template #prepend-inner>
      <v-icon>mdi-crosshairs-gps</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <span>
        {{getTractString(item)}}
      </span>
    </template>
    <template #selection="{item}">
      {{getTractString(item)}}
    </template>
    <template #append-outer v-if="!propFilter">
      <v-icon @click="refreshTracts" color="secondary" tabindex="-1">mdi-refresh</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TractFilter } from '@/model/Tract.js'
export default {
  name: 'TractAutocomplete',

  props: {
    propTractId: { type: Number, default: undefined },
    dataTestid: String,
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true
    },
    propFilter: {
      type: Object,
      required: false,
      default: undefined
    },
    staleSearch: {
      type: Object,
      required: false,
      default: undefined
    },
    soloTheme: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    chosenTract: undefined
  }),

  computed: {
    ...mapGetters('tract', ['allTracts', 'tractsLoading']),
    baseTracts () {
      return this.staleSearch ? [...this.allTracts, this.staleSearch] : this.allTracts
    },
    elligibleTracts () {
      return this.chosenTract && !this.baseTracts.some(bt => bt.tractId === this.chosenTract.tractId)
        ? this.baseTracts.concat(this.chosenTract)
        : this.baseTracts
    }
  },

  watch: {
    chosenTract (val) {
      this.$emit('tract-chosen', val)
    }
  },

  created () {
    this.refreshTracts(true)
  },

  methods: {
    ...mapActions('tract', ['fetchTracts', 'fetchTract']),
    async refreshTracts (setInitial) {
      if (!this.propFilter) {
        await this.fetchTracts(new TractFilter({
          includeInitiated: true,
          includeCruised: true,
          includeExecuted: true,
          includeActive: true,
          includeClosed: true,
          includeArchived: true
        }))
      } else {
        await this.fetchTracts(new TractFilter({
          includeInitiated: this.propFilter.status.includes(0),
          includeCruised: this.propFilter.status.includes(1),
          includeExecuted: this.propFilter.status.includes(2),
          includeActive: this.propFilter.status.includes(3),
          includeClosed: this.propFilter.status.includes(4),
          includeArchived: this.propFilter.status.includes(5),
          selectedForesters: this.propFilter.selectedForesters,
          selectedTractTypes: this.propFilter.selectedTractTypes
        }))
      }

      if (setInitial && this.propTractId !== undefined) {
        const foundTract = this.baseTracts.find(t => t.tractId === this.propTractId)
        this.chosenTract = foundTract ??
          (this.propTractId !== null && this.propTractId !== undefined ? await this.fetchTract(this.propTractId) : undefined)
      }
    },

    filter (item, queryText) {
      return item.name.toLowerCase().includes(queryText.toLowerCase()) ||
        item.code.toLowerCase().includes(queryText.toLowerCase()) ||
        item.type.name.toLowerCase().includes(queryText.toLowerCase())
    },

    getTractString ({ name, code, type }) {
      if (code === '' || !code) {
        return `${name} || ${type.name}`
      }

      return `${name} || ${code} || ${type.name}`
    }
  }
}
</script>
