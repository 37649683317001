export default {
  companyInfo: undefined,
  userInfo: undefined,
  userClaims: [],
  businessEntities: [],
  initializing: false,
  initialized: false,
  loginWithRedirectResult: false,
  entitiesLoading: false,
  invalidAccount: null
}
