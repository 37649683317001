<template>
  <v-col cols="12">
    <v-navigation-drawer
      id="app-drawer"
      v-model="drawer"
      :mini-variant="responsive ? false : mini"
      mini-variant-width="75"
      app
      dark
      floating
      persistent
      touchless
      :permanent="!responsive"
      :temporary="responsive"
      mobile-breakpoint="991"
      width="250"
    >
      <v-layout class="fill-height" tag="v-list" column style="overflow-y: auto;">
        <v-list-item
        id="drawer-header-item"
        to="/dashboard"
        ref="headerImage"
        active-class="drawer-header-item secondary">
          <v-img
          text-align="center"
          min-width="50"
          max-height="100"
          :min-height="responsive ? 0 : mini ? 50 : 35"
          :contain="responsive ? false : mini"
          :src="responsive || !mini ? logoSvg : miniLogoSvg"
          max-width="90%"/>
        </v-list-item>
        <v-divider/>
        <div :class="isFinancialDropdownRoute ? 'gray' : ''">
          <v-list-group
              active-class="white--text"
              ref="financialDropdown"
              v-model="isFinancialDropdown"
              @click="handleFinancialDrawerClick"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-tooltip bottom color="secondary">
                  <template #activator="{ on }">
                    <v-icon color="white" v-on="on">mdi-finance</v-icon>
                  </template>
                  <span class="subtitle-1 white--text" style="white-space: pre-line">
                    {{$t('accounting')}}
                  </span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-title>
                {{$t('accounting')}}
              </v-list-item-title>
            </template>
            <div class="dropdown-content">
              <v-list-item
              to="/advances"
              ref="advances"
              active-class="secondary white--text"
              id="drawer-list-item"
              class="mb-2">
                <v-list-item-icon>
                  <Icon
                  iconColor="white"
                  dataTestId="drawer-advances"
                  :small="false"
                  :tooltipText="$t('advances')"
                  icon="mdi-cash"/>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('advances')}}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
              to="/current-payables"
              ref="payables"
              active-class="secondary white--text"
              id="drawer-list-item"
              class="mb-2">
                <v-list-item-icon>
                  <Icon
                  iconColor="white"
                  dataTestId="drawer-payables"
                  :small="false"
                  :tooltipText="$t('payables')"
                  icon="mdi-bank-transfer-out"/>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('payables')}}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
              to="/accounts-receivable"
              ref="receivable"
              active-class="secondary  white--text"
              id="drawer-list-item">
                <v-list-item-icon>
                  <Icon
                  iconColor="white"
                  dataTestId="drawer-receivables"
                  :small="false"
                  :tooltipText="$t('receivables')"
                  icon="mdi-bank-transfer-in"/>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('receivables')}}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
              to="/accruals"
              ref="accruals"
              active-class="secondary  white--text"
              id="drawer-list-item">
                <v-list-item-icon>
                  <Icon
                  iconColor="white"
                  dataTestId="drawer-accruals"
                  :small="false"
                  :tooltipText="$t('accruals')"
                  icon="mdi-bank-transfer"/>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('accruals')}}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
              to="/settlements"
              ref="settlements"
              active-class="secondary  white--text"
              id="drawer-list-item">
                <v-list-item-icon>
                  <Icon
                  iconColor="white"
                  dataTestId="drawer-settlements"
                  :small="false"
                  :tooltipText="$t('settlements')"
                  icon="mdi-file-check"/>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('settlements')}}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
              v-if="showAdjustingEntries"
              to="/adjusting-entries"
              ref="adjusting-entries"
              active-class="secondary white--text"
              id="drawer-list-item">
                <v-list-item-icon>
                  <Icon
                  iconColor="white"
                  :small="false"
                  :tooltipText="$t('adjustingEntries')"
                  icon="mdi-clock"/>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t('adjustingEntries') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list-group>
        </div>

        <v-list-item
          to="/tickets"
          ref="ticket"
          active-class="secondary white--text"
          id="drawer-list-item">
            <v-list-item-icon>
              <Icon
              iconColor="white"
              dataTestId="drawer-tickets"
              :small="false"
              :tooltipText="$t('tickets')"
              icon="mdi-clipboard-outline"/>
            </v-list-item-icon>
            <v-list-item-title>
              {{$t('tickets')}}
            </v-list-item-title>
        </v-list-item>

        <v-list-item
          to="/contracts"
          ref="contract"
          active-class="secondary white--text"
          id="drawer-list-item">
            <v-list-item-icon>
              <Icon
              iconColor="white"
              dataTestId="drawer-contracts"
              :small="false"
              :tooltipText="$t('contracts')"
              icon="mdi-altimeter"/>
            </v-list-item-icon>
            <v-list-item-title>
              {{$t('contracts')}}
            </v-list-item-title>
        </v-list-item>

        <div :class="isTractDropdownRoute ? 'gray mt-2' : 'pb-2'">
          <v-list-group
              active-class="white--text"
              ref="tractDropdown"
              v-model="isTractDropdown"
              @click="handleTractDrawerClick"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-tooltip bottom color="secondary">
                  <template #activator="{ on }">
                    <v-icon color="white" v-on="on">mdi-crosshairs-gps</v-icon>
                  </template>
                  <span class="secondary subtitle-1 white--text" style="white-space: pre-line">
                    {{$t('tracts')}}
                  </span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-title>
                {{$t('tracts')}}
              </v-list-item-title>
            </template>
            <div class="dropdown-content">
              <v-list-item
              to="/tracts"
              ref="tractSetup"
              active-class="secondary white--text"
              id="drawer-list-item"
              class="mb-2">
                <v-list-item-icon>
                  <Icon
                  iconColor="white"
                  dataTestId="drawer-setup"
                  :small="false"
                  :tooltipText="$t('setup')"
                  icon="mdi-crosshairs"/>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('setup')}}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
              to="/tract-analysis"
              ref="tractAnalysis"
              active-class="secondary white--text"
              id="drawer-list-item">
                <v-list-item-icon>
                  <Icon
                  iconColor="white"
                  dataTestId="drawer-analysis"
                  :small="false"
                  :tooltipText="$t('analysis')"
                  icon="mdi-chart-timeline-variant"/>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('analysis')}}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list-group>
        </div>

        <v-list-item
          to="/accounts"
          ref="accounts"
          active-class="secondary white--text"
          id="drawer-list-item">
            <v-list-item-icon>
              <Icon
              iconColor="white"
              dataTestId="drawer-accounts"
              :small="false"
              :tooltipText="$t('accounts')"
              icon="mdi-axe"/>
            </v-list-item-icon>
            <v-list-item-title>
              {{$t('accounts')}}
            </v-list-item-title>
        </v-list-item>

        <MovingArrow v-if="arrowVisible && currentRoute !== '/Settings'" :move-y="arrowLocation" :isMini="responsive ? false : mini"/>
      </v-layout>

      <template #append>
        <v-list-item>
          <v-list-item-icon id="pendo-activator">
            <v-img :src="angelLogoSvg" width="24"></v-img>
          </v-list-item-icon>
          <v-spacer/>
          <v-list-item-title style="text-align: right;">
            <v-icon v-if="responsive" @click="closeDrawer">
              mdi-chevron-double-left
            </v-icon>
          </v-list-item-title>
      </v-list-item>
      </template>
    </v-navigation-drawer>
  </v-col>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import { CookieKeys } from '@/utils/constants.js'
import { AdjustingEntriesFrequency } from '@/utils/Enumerations.js'

export default {
  name: 'Drawer',

  props: {
    openFirstByDefault: {
      type: Boolean,
      default: false,
      required: false
    },
    propDrawer: {
      type: Boolean
    }
  },

  data: () => ({
    responsive: false,
    linkHeight: 65,
    arrowVisible: true,
    arrowLocation: 0,
    financeDrawerOpen: false,
    logoSvg: './img/Legna-Secondary-WHITE.svg',
    miniLogoSvg: './img/Legna-Icon-WHITE.svg',
    angelLogoSvg: './img/angel-logo.svg',
    isFinancialDropdown: false,
    isTractDropdown: false,
    arrowGeneralOffset: -115,
    arrowDropdownOffset: -110,
    arrowWeirdFinancialDropdownOffset: -285,
    minimumDropdownAnimationTime: 270,
    drawer: null
  }),

  components: {
    MovingArrow: () => import('./MovingArrow.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  watch: {
    currentRoute () {
      this.foldDrawers()
      this.moveArrow()
    },
    isFinancialDropdown () {
      this.moveArrowOnFinancialDropdown()
    },
    isTractDropdown () {
      this.moveArrowOnTractDropdown()
    },
    mini (val) {
      Cookies.set(CookieKeys.DRAWER_MINI, val)
    },
    propDrawer (val) {
      if (val) {
        this.drawer = true
      }
    },
    drawer (val) {
      this.$emit('drawerChanged', val)
    }
  },
  computed: {
    ...mapState('app', ['image', 'color', 'mini']),
    ...mapGetters('user', ['companyInfo']),
    currentRoute () {
      return this.$route.path
    },
    showAdjustingEntries () {
      const companyAdjustingEntriesFrequency = this.adjustingEntriesFrequency(this.companyInfo.adjustingEntriesFrequency)
      return companyAdjustingEntriesFrequency.value !== AdjustingEntriesFrequency.None.value
    },
    arrowStatus () {
      let isNotInDropdown = false
      let isInFinancialDropdown = false
      let isInTractDropdown = false
      if (this.drawerItems.find(({ path }) => path === this.currentRoute)) {
        isNotInDropdown = true
      } else if (this.financialNestedElements.find(({ path }) => path === this.currentRoute)) {
        isInFinancialDropdown = true
      } else if (this.tractNestedElements.find(({ path }) => path === this.currentRoute)) {
        isInTractDropdown = true
      }
      return {
        isNotInDropdown: isNotInDropdown,
        isInFinancialDropdown: isInFinancialDropdown,
        isInTractDropdown: isInTractDropdown
      }
    },
    financialNestedElements () {
      return [
        {
          path: '/advances',
          ref: 'advances'
        },
        {
          path: '/current-payables',
          ref: 'payables'
        },
        {
          path: '/accounts-receivable',
          ref: 'receivable'
        },
        {
          path: '/accruals',
          ref: 'accruals'
        },
        {
          path: '/settlements',
          ref: 'settlements'
        },
        {
          path: '/adjusting-entries',
          ref: 'adjusting-entries'
        }
      ]
    },
    tractNestedElements () {
      return [
        {
          path: '/tracts',
          ref: 'tractSetup'
        },
        {
          path: '/tract-analysis',
          ref: 'tractAnalysis'
        }
      ]
    },
    drawerItems () {
      return [
        {
          path: '/dashboard',
          ref: 'headerImage'
        },
        {
          text: this.$t('accounting'),
          icon: 'mdi-finance',
          nestedElements: this.financialNestedElements
        },
        {
          path: '/tickets',
          ref: 'ticket'
        },
        {
          path: '/contracts',
          ref: 'contract'
        },
        {
          icon: 'mdi-crosshairs-gps',
          text: this.$t('tracts'),
          nestedElements: this.tractNestedElements
        },
        {
          path: '/accounts',
          ref: 'accounts'
        }
      ]
    },

    isTractDropdownRoute () {
      return this.tractNestedElements.map(f => f.path).some(path => this.$route.fullPath === path)
    },

    isFinancialDropdownRoute () {
      return this.financialNestedElements.map(f => f.path).some(path => this.$route.fullPath === path)
    }
  },
  created () {
    if (Cookies.get(CookieKeys.DRAWER_MINI)) {
      this.setMini(Cookies.get(CookieKeys.DRAWER_MINI) === 'true')
    }
    this.foldDrawers()
  },
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    if (this.financialNestedElements.find(({ path }) => path === this.currentRoute)) {
      this.moveArrowOnTractDropdown()
    } else if (this.tractNestedElements.find(({ path }) => path === this.currentRoute)) {
      this.moveArrowOnFinancialDropdown()
    } else {
      this.moveArrow()
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('app', ['setMini']),
    foldDrawers () {
      this.isTractDropdown = this.isTractDropdownRoute
      this.isFinancialDropdown = this.isFinancialDropdownRoute
    },
    handleTractDrawerClick () {
      if (!this.openFirstByDefault) return
      const targetPath = '/tracts'
      if (!this.isTractDropdown && !this.isTractDropdownRoute) this.$router.push(targetPath)
    },
    handleFinancialDrawerClick () {
      if (!this.openFirstByDefault) return
      const targetPath = '/advances'
      if (!this.isFinancialDropdown && !this.isFinancialDropdownRoute) this.$router.push(targetPath)
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
        this.arrowVisible = false
      } else {
        this.responsive = false
        if (window.innerHeight >= 760) {
          this.arrowVisible = true
        } else {
          this.arrowVisible = false
        }
      }
    },
    moveArrow (timeOut) {
      if (!timeOut) { timeOut = 0 }
      setTimeout(() => {
        if (this.currentRoute === '/dashboard') {
          this.arrowLocation = -1 * ((this.$refs.headerImage.$el.getBoundingClientRect().top + this.$refs.headerImage.$el.getBoundingClientRect().bottom) / 2)
        } else if (this.currentRoute !== '/settings') {
          let currentDrawerObj
          if (this.arrowStatus.isNotInDropdown) {
            currentDrawerObj = this.drawerItems.find(({ path }) => path === this.currentRoute)
          } else if (this.arrowStatus.isInFinancialDropdown) {
            currentDrawerObj = this.financialNestedElements.find(({ path }) => path === this.currentRoute)
          } else {
            currentDrawerObj = this.tractNestedElements.find(({ path }) => path === this.currentRoute)
          }
          this.setArrowLocation(currentDrawerObj?.ref, this.arrowGeneralOffset)
        }
      }, timeOut)
    },
    moveArrowOnTractDropdown () {
      if (this.isTractDropdown && this.isFinancialDropdown) { return }
      if (this.arrowStatus.isInFinancialDropdown && !this.isFinancialDropdown) {
        this.setArrowLocation('financialDropdown', this.arrowDropdownOffset)
        return
      }
      if (this.arrowStatus.isInTractDropdown && !this.isTractDropdown) {
        this.setArrowLocation('tractDropdown', this.arrowDropdownOffset, this.minimumDropdownAnimationTime)
      } else {
        this.moveArrow(this.minimumDropdownAnimationTime)
      }
    },
    moveArrowOnFinancialDropdown () {
      if (this.isTractDropdown && this.isFinancialDropdown) { return }
      if (this.arrowStatus.isInTractDropdown && !this.isTractDropdown) {
        if (!this.isFinancialDropdown) {
          this.setArrowLocation('tractDropdown', this.arrowDropdownOffset, this.minimumDropdownAnimationTime)
        } else {
          this.setArrowLocation('tractDropdown', this.arrowDropdownOffset, this.minimumDropdownAnimationTime)
        }
        return
      }
      if (this.arrowStatus.isInFinancialDropdown && !this.isFinancialDropdown) {
        this.setArrowLocation('financialDropdown', this.arrowWeirdFinancialDropdownOffset)
      } else {
        this.moveArrow(this.minimumDropdownAnimationTime)
      }
    },
    setArrowLocation (ref, offset, timeOut) {
      if (!timeOut) { timeOut = 0 }
      setTimeout(() => {
        this.arrowLocation = ((this.$refs[ref].$el.getBoundingClientRect().top + this.$refs[ref].$el.getBoundingClientRect().bottom) / 2) + offset
      }, timeOut)
    },
    adjustingEntriesFrequency: (x) => AdjustingEntriesFrequency.forInt(x),
    closeDrawer () {
      this.drawer = false
    }
  }
}
</script>

<style>
#app-drawer .v-list__item {
  border-radius: 4px;
}

#app-drawer .v-list__item--buy {
  margin-top: auto;
  margin-bottom: 17px;
}

#app-drawer .v-image__image--contain {
  top: 9px;
  height: 60%;
}

#app-drawer .search-input {
  margin-bottom: 30px !important;
  padding-left: 15px;
  padding-right: 15px;
}

#drawer-list-item {
  max-height: 60px;
}

#accounts-payable {
  max-height: 60px;
}

#drawer-header-item {
  max-height: 90px;
}

.drawer-header-item {
  background: #d1602700;
}

.dropdown-content {
  box-shadow: 0px 0px 5px #333 inset;
  background-color: #505050;
  margin-top: 6px;
  padding-top: 2px;
  padding-bottom: 8px;
}

#app-drawer .v-list-item--link:before {
  opacity: 0;
}

#app-drawer .v-list-item__title {
  z-index: 1;
}

#app-drawer .v-list-item--link.secondary{
  background-color: #d16027;
  transition: .5s linear;
}

#app-drawer .gray{
  background-color: #686868;
  transition: background-color .5s linear;
}

#app-drawer .v-list-item {
  margin: 10px 0px 0;
  padding: 0px 30px 0
}

</style>

<style scoped>
.v-navigation-drawer .v-list .v-list-item:not(:nth-child(3)) {
  margin: 10px 15px 0;
}
.v-navigation-drawer .v-list .v-list-item:nth-child(3) {
  margin: 0 15px;
}
</style>
