<template>
  <v-card outlined rounded>
    <v-card-title>
      <Icon
      v-if="contact.isDefault"
      icon="mdi-star"
      iconColor="rgba(0,0,0,.87)"
      tooltipColor="black"
      dataTestId="contact-isdefault"
      :tooltipText="$t('defaultContact')"
      :small="false"
      />
      <Icon
      v-if="contact.receivesDailyTicketReport"
      icon="mdi-email-newsletter"
      iconColor="rgba(0,0,0,.87)"
      tooltipColor="black"
      :small="false"
      :tooltipText="$t('dailyTicketReportRecipient')"/>
      <v-icon class="mr-2" :color="randomColor">mdi-account</v-icon>
      <span data-testid="contact-card-user-name" class="subtitle-1">{{`${contact.person.firstName} ${contact.person.lastName}`}}</span>
      <v-spacer/>
      <template>
        <Icon
        v-if="showCrud && !addContact"
        dataTestId="contact-edit-button"
        icon="mdi-pencil"
        tooltipColor="success"
        iconColor="success"
        :tooltipText="$t('edit')"
        tabindex="0"
        @icon-clicked="$emit('click:edit', contact)"/>
        <Icon
        v-if="showCrud && !isMainContact"
        dataTestId="contact-delete-button"
        icon="mdi-delete-forever"
        iconColor="error"
        tooltipColor="error"
        :tooltipText="$t('delete')"
        tabindex="0"
        @icon-clicked="openDeleteDialog"/>
        <Icon
        v-if="addContact"
        icon="mdi-plus"
        iconColor="success"
        tooltipColor="success"
        :tooltipText="$t('addMainContact')"
        :small="false"
        tabindex="0"
        @icon-clicked="$emit('click:create', contact)"/>
      </template>
    </v-card-title>
    <v-card-text class="mt-n2">
      <v-container fluid grid-list-sm>
        <v-row no-gutters class="ml-n2">
          <v-col data-testid="contact-card-position" cols="12">{{contact.position}}</v-col>
          <v-col data-testid="contact-card-email" cols="12">{{contact.person.email}}</v-col>
          <v-col data-testid="contact-card-phone-number" cols="12">{{formattedPhoneNumber}}</v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog width="400px" v-model="openDialog">
      <ConfirmDialog
      v-if="openDialog"
      :title="$t('deleteContact')"
      :body="$t('deleteContactBody')"
      @confirm="deleteContact"
      @cancel="openDialog = ''"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { hashString32 } from '../../../utils/base'
export default {
  name: 'ContactCard',

  components: {
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    contact: {
      type: Object,
      required: true
    },
    showCrud: {
      type: Boolean,
      default: true
    },
    isMainContact: {
      type: Boolean,
      default: false
    },
    addContact: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    openDialog: ''
  }),

  computed: {
    hashable () {
      const v = this.contact.accountContactId
      let o = v
      for (let i = 0; i < 10; i++) {
        o ^= v << i
      }
      return o.toString()
    },
    randomColor () {
      return `#${(this.hashString32(this.hashable) & 0xffffff).toString(16)}`
    },
    formattedPhoneNumber () {
      return /^\d{3}\d{3}\d{4}/.test(this.contact.person.phoneNumber) ? this.contact.person.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3') : this.contact.person.phoneNumber
    }
  },

  methods: {
    hashString32,
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),

    editContact () {
      this.$emit('edit-contact-clicked', this.contact)
    },

    openDeleteDialog () {
      this.openDialog = true
    },

    deleteContact () {
      this.openDialog = ''
      this.$emit('click:delete', this.contact)
    }
  }
}
</script>

<style scoped>
.v-sheet--outlined {
  border-color: #D15F27 !important;
}
</style>
