<template>
  <v-container fluid>
    <v-row dense no-gutters>
      <v-col cols="12">
        <span class="title font-weight-bold ml-3">{{$t('softwood')}}</span>
        <Icon
        margin="mb-1 ml-1"
        :small="false"
        icon="mdi-plus"
        :tooltipText="$t('newCruiseData')"
        dataTestId="cruise-add-button"
        @icon-clicked="createCruiseData(woodTypeEnum.Softwood)"/>
      </v-col>
      <v-col cols="12" class="mb-2">
        <CruiseDataTable
        v-if="!loading"
        :propCruiseData.sync="splitCruiseData.softwood"
        @edit="c => editCruiseData(c, woodTypeEnum.Softwood)"
        @delete="confirmDeleteCruiseData"/>
      </v-col>
      <v-col cols="12">
        <span class="title font-weight-bold ml-3">{{$t('hardwood')}}</span>
        <Icon
        margin="mb-1 ml-1"
        :small="false"
        icon="mdi-plus"
        :tooltipText="$t('newCruiseData')"
        dataTestId="cruise-add-button"
        @icon-clicked="createCruiseData(woodTypeEnum.Hardwood)"/>
      </v-col>
      <v-col cols="12" class="mb-3">
        <CruiseDataTable
        v-if="!loading"
        :propCruiseData.sync="splitCruiseData.hardwood"
        @edit="c => editCruiseData(c, woodTypeEnum.Hardwood)"
        @delete="confirmDeleteCruiseData"/>
      </v-col>
      <v-col cols="8"/>
      <v-col cols="4">
        <v-row dense no-gutters>
          <v-col cols="12">
            <span class="title font-weight-bold ml-3">{{$t('grandTotals')}}</span>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <tbody>
                <tr v-for="total of totals" :key="total.text">
                  <td><span class="font-weight-bold">{{total.text}}</span></td>
                  <td style="text-align: right" class="font-weight-bold">{{total.value}}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
      <v-dialog v-model="cruiseDataForm" width="80vh">
        <CruiseDataForm
        v-if="creating || editing"
        :tract="tract"
        :editing="editing"
        :propCruiseData="focusedCruiseData"
        :woodType="formWoodType"
        @close="closeForm(false)"
        @cruise-data-changed="closeForm(true)"/>
      </v-dialog>
      <v-dialog
      width="400px"
      v-model="deleting">
        <ConfirmDelete
        :title="$t('cruiseData')"
        v-if="deleting"
        @delete="deleteItem"
        @cancel-delete="closeForm(false)"/>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { scatterToObject } from '../../../utils/base'
import { WoodType } from '../../../utils/Enumerations'
import { cruiseDataTotals } from './CruiseDataTotals'
import { formatMoney } from '@/utils/NumericMutations'
import CruiseDataHeaders from '@/headers/Composition'

export default {
  name: 'CruiseData',

  components: {
    CruiseDataTable: () => import('./CruiseDataTable.vue'),
    CruiseDataForm: () => import('./CruiseDataForm.vue'),
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    tract: Object,
    flat: Boolean,
    showImmediately: { type: Boolean, default: false }
  },

  watch: {
    cruiseDataForm (val) {
      if (!val) {
        this.closeForm(false)
      }
    }
  },

  data: () => ({
    creating: false,
    editing: false,
    cruiseDataForm: false,
    formWoodType: undefined,
    deleting: false,
    loading: true,
    cruiseData: [],
    focusedCruiseData: null
  }),

  computed: {
    splitCruiseData () {
      return scatterToObject(this.cruiseData, {
        softwood: d => d.woodType === WoodType.Softwood.value,
        hardwood: d => d.woodType === WoodType.Hardwood.value
      })
    },

    totals () {
      const cdTotals = cruiseDataTotals(this.cruiseData)
      return Object.keys(cdTotals).map(key => ({
        text: CruiseDataHeaders.compositionHeaders(this.$i18n.locale).find(cdh => cdh.value === key).text,
        value: key === 'cruisedValue' ? formatMoney(cdTotals[key]) : cdTotals[key]
      }))
    },

    woodTypeEnum () { return WoodType }
  },

  async created () {
    await this.refreshTractContent(this.tract.tractId, this.showImmediately)
  },

  methods: {
    ...mapActions('tract-contents', ['fetchTractContents', 'deleteTractContent']),
    async deleteItem () {
      const requestObj = {
        tractId: this.tract.tractId,
        tractContentId: this.focusedCruiseData.tractContentId
      }

      await this.deleteTractContent(requestObj)
      this.closeForm(true)
    },

    createCruiseData (woodType) {
      this.closeForm(false)
      this.formWoodType = woodType

      this.creating = true
      this.cruiseDataForm = true
    },

    editCruiseData (cruiseData, woodType) {
      this.focusedCruiseData = cruiseData
      this.formWoodType = woodType
      this.editing = true
      this.cruiseDataForm = true
    },

    async refreshTractContent (tractId, showImmediately) {
      this.loading = true
      try {
        const response = await this.fetchTractContents(tractId)
        this.cruiseData = response
      } finally {
        this.$emit('tract-contents-updated', this.cruiseData)
        this.loading = false
        if (showImmediately) {
          this.createCruiseData()
        }
      }
    },

    confirmDeleteCruiseData (tractContent) {
      this.focusedCruiseData = tractContent
      this.deleting = true
    },

    async closeForm (refresh) {
      if (refresh) {
        await this.refreshTractContent(this.tract.tractId)
      }

      this.cruiseDataForm = false
      this.deleting = false
      this.creating = false
      this.editing = false
      this.focusedCruiseData = null
    }
  }
}
</script>
