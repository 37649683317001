<template>
  <v-autocomplete
    :data-testid="this.dataTestid"
    color="black"
    item-color="secondary"
    v-model="chosenSettingName"
    :label="$t('setting')"
    @change="parseSettingName"
    :loading="settingsLoading"
    :items="settingNames"
    :filter="(item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
  >
    <template #prepend-inner>
      <v-icon>mdi-map-marker-circle</v-icon>
    </template>
    <template #append-outer>
      <v-icon v-if="!hideRefresh" @click="refreshSettings" color="secondary" tabindex="-1">mdi-refresh</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ContractSettingAutocomplete',

  props: {
    settingName: { String, default: '' },
    dataTestid: String,
    hideRefresh: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    settings: [],
    settingNames: [],
    chosenSettingName: ''
  }),

  watch: {
    chosenSettingName (name) {
      this.parseSettingName(name)
    }
  },

  computed: {
    ...mapGetters('setting', ['allSettings', 'settingsLoading'])
  },

  created () {
    this.refreshSettings()
  },

  methods: {
    ...mapActions('setting', ['fetchSettings']),

    async refreshSettings () {
      await this.fetchSettings()
      this.addSettingNames()
    },

    addSettingNames () {
      for (let i = 0; i < this.allSettings.length; i++) {
        const setting = this.allSettings[i]
        this.settingNames.push(this.addNameWithCode(setting.name, setting.code))

        if (this.settingName === setting.name) {
          this.chosenSettingName = this.addNameWithCode(setting.name, setting.code)
        }
      }

      if (this.allSettings.length === 1) {
        const onlySetting = this.allSettings[0]
        this.chosenSettingName = this.addNameWithCode(onlySetting.name, onlySetting.code)
      }
    },

    parseSettingName () {
      const [name] = this.chosenSettingName.split(' \u2016 ')
      const settingIndex = this.allSettings.findIndex(v => v.name === name)
      if (settingIndex !== -1) {
        const setting = this.allSettings[settingIndex]
        this.$emit('setting-chosen', setting)
      }
    }
  }
}
</script>
