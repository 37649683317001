<template>
  <v-card data-testid="tract-type-form">
    <v-card-title class="secondary white--text">
      <span class="headline">{{ title }}</span>
      <v-spacer/>
      <Icon
      @icon-clicked="$emit('close')"
      :tooltipText="$t('close')"
      dataTestId="tract-type-close-button"
      iconColor="white"
      icon="mdi-close"
      :small="false"
      :large="true"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="tractTypeForm"
        testId="save-tract-type"
        :lazyValidation="true"
        :buttonText="saveBtnText"
        @submit="saveTractType"
      >
        <v-container grid-list-lg>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="tractType.name"
                data-testid="tract-type-name"
                :rules="[rules.required]"
                ref="firstField"
                :label="$t('name')"
                color="black"
                counter="64"
                maxlength="64"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="tractType.code"
                data-testid="tract-type-code"
                :label="$t('code')"
                color="black"
                counter
                maxlength="10"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select
                v-model="tractType.category"
                :rules="[rules.required]"
                item-color="secondary"
                :items="tractCategoryNames"
                data-testid="tract-type-category"
                :label="$t('category')"
                color="black"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="changedToStumpage" dense>
            <v-col xs="12" sm="12" md="12">
              <p>* select business entity to be used for tracts of this type</p>
              <EntitySelector
                :entities="entities"
                :initialEntity.sync="selectedEntity"
                @entity-selected="entitySelected"
              />
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { rules } from '@/utils/FormRules.js'
import { TractTypeCategory } from '@/utils/Enumerations.js'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'TractTypeForm',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    EntitySelector: () => import('@/components/accounting/EntitySelector.vue')
  },

  props: {
    propTractType: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    rules,
    tractType: {
      code: '',
      name: '',
      category: '',
      businessEntityId: undefined
    },
    entities: [],
    selectedEntity: {},
    changedToStumpage: false
  }),

  async created () {
    if (this.propTractType) {
      await this.fetchAllBusinessEntities()
      this.entities = this.businessEntities
      this.selectedEntity = this.entities[0]
      this.tractType = JSON.parse(JSON.stringify(this.propTractType))
      this.tractType.category = TractTypeCategory.fromInt(this.tractType.category)
    } else {
      this.tractType.category = TractTypeCategory.fromInt(1)
    }
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.firstField.focus()
    }, 0)
  },

  computed: {
    ...mapGetters('user', ['businessEntities']),
    title () {
      return this.propTractType ? this.$t('editingTractType') : this.$t('creatingTractType')
    },

    saveBtnText () {
      return this.propTractType ? this.$t('saveChanges') : this.$t('createNewTractType')
    },

    tractCategoryNames () {
      return TractTypeCategory.names
    }
  },

  watch: {
    tractType: {
      handler () {
        this.changedToStumpage = this.propTractType?.category === 1 && this.tractType.category === 'Stumpage'
        if (this.changedToStumpage) {
          this.tractType.businessEntityId = this.businessEntities[0].businessEntityId
        } else {
          this.tractType.businessEntityId = undefined
        }
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('tract-type', ['createTractType', 'updateTractType']),
    ...mapActions('user', ['fetchAllBusinessEntities']),

    async saveTractType () {
      if (!this.$refs.form.$refs.tractTypeForm.validate()) {
        return
      }

      const requestObj = JSON.parse(JSON.stringify(this.tractType))
      requestObj.category = TractTypeCategory.toInt(requestObj.category)

      if (this.propTractType) {
        await this.updateTractType(requestObj)
        this.$emit('tract-type-mutated')
      } else {
        const response = await this.createTractType(requestObj)
        this.$emit('tract-type-mutated', response)
      }
    },
    entitySelected (entity) {
      this.tractType.businessEntityId = entity.businessEntityId
    }
  }
}
</script>
