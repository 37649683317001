<template>
  <v-row dense data-testid="account-table">
    <v-col md="12">
      <material-card
      :title="$t('accounts')"
      :text="$t('accountsSubtitle')"
      :elevation="0"
      :offsetTop="24"
      :offsetBottom="24"
      full-width>
        <DataTable
        :items.sync="allAccounts"
        :loading.sync="accountsLoading"
        :customCells="customCells"
        :actions="tableActions"
        :defaultSort="'name'"
        @create="createAccount"
        @refresh="$emit('refresh-accounts')"
        @download="downloadCSV"
        :headers="headers"
        >
          <template #filters>
            <div class="mt-n12">
              <AccountTagFilter
              @tags-chosen="applyFilter"/>
            </div>
          </template>
          <template #name="{ item }">
            <AccountName :accountId="item.accountId" :certificationStatus="item.certificationStatus">
              <span id="table-shortcut" @click="viewAccountDetails(item)">
                {{item.name}}
              </span>
            </AccountName>
          </template>
          <template #code="{ item }">
            <span id="table-shortcut" @click="viewAccountDetails(item)">{{item.code}}</span>
          </template>
          <template #exportCode="{ item }">
            <span id="table-shortcut" @click="viewAccountDetails(item)">{{item.exportCode}}</span>
          </template>
          <template #account-tags="{item}">
            <v-chip class="mr-1" small v-for="tag in item.accountTags.slice(0, 3)" :key="`${item.accountId}-${tag}`">
              <span>
                {{tag}}
              </span>
            </v-chip>
            <span v-if="item.accountTags.length === 0">
              {{$t('notAvailable')}}
            </span>
            <Icon
            v-if="item.accountTags.length > 3"
            icon="mdi-dots-horizontal"
            iconColor="black"
            tooltipColor="black"
            :tooltipText="item.accountTags.slice(3, item.accountTags.length).join(', ')"/>
          </template>
          <template #related="{ item }">
            <Icon
            dataTestId="account-detail-button"
            icon="mdi-information-outline"
            iconColor="secondary"
            :small="false"
            :tooltipText="$t('details')"
            @icon-clicked="viewAccountDetails(item)"/>
            <Icon
            dataTestId="account-isexternal"
            :icon="item.isExternal ? 'mdi-domain' : 'mdi-home'"
            :iconColor="item.isExternal ? 'grey' : 'black'"
            :small="false"
            :tooltipColor="item.isExternal ? 'grey' : 'black'"
            :tooltipText="item.isExternal ? $t('external') : $t('internal')"
            />
          </template>
          <template v-slot:actions="{ item }">
            <Icon v-for="(aa, index) in accountActions" :key="`${aa.name}-${index}`"
            :icon="aa.icon"
            :iconColor="aa.color"
            :dataTestId="aa.testId"
            :tooltipText="$t(aa.name)"
            @icon-clicked="aa.action(item)"
            :disabled="aa.disabled"/>
          </template>
        </DataTable>
      </material-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { saveAs } from 'file-saver'
import AccountHeaders from '@/headers/Account'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { generateCsvString } from '@/utils/CSVUtility'
import { UserClaims } from '../../utils/Enumerations'
import { userAssignedClaim } from '@/utils/ClaimUtility'
export default {
  name: 'AccountTable',

  components: {
    DataTable: () => import('../core/table/DataTable.vue'),
    Icon: () => import('../helper/Icon.vue'),
    AccountTagFilter: () => import('@/components/account/AccountTagFilter.vue'),
    AccountName: () => import('@/components/account/AccountName.vue')
  },

  computed: {
    ...mapGetters('account', ['allAccounts', 'accountsLoading']),
    headers () { return AccountHeaders.accountHeaders(this.$i18n.locale) },
    tableActions () {
      return [
        {
          actionName: 'download',
          icon: 'mdi-file-delimited-outline',
          text: this.$t('downloadCSV')
        },
        {
          actionName: 'refresh',
          icon: 'mdi-refresh',
          text: this.$t('refresh')
        },
        {
          actionName: 'create',
          icon: 'mdi-plus',
          text: this.$t('new'),
          disabled: !userAssignedClaim(UserClaims.AccountManager)
        }
      ]
    },
    customCells () {
      return [
        {
          slotName: 'name',
          value: 'name'
        },
        {
          slotName: 'code',
          value: 'code'
        },
        {
          slotName: 'exportCode',
          value: 'exportCode'
        },
        {
          slotName: 'account-tags',
          value: 'accountTags'
        },
        {
          slotName: 'insurance-expiry-date',
          value: 'insuranceExpiryDate',
          dateFormat: 'MM/DD/YYYY'
        },
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'related',
          value: 'related'
        }
      ]
    },
    accountActions () {
      return [
        {
          name: 'edit',
          icon: 'mdi-pencil',
          color: 'success',
          testId: 'account-edit-button',
          action: this.editAccount,
          disabled: !userAssignedClaim(UserClaims.AccountManager)
        },
        {
          name: 'delete',
          icon: 'mdi-delete-forever',
          color: 'error',
          testId: 'account-delete-button',
          action: this.deleteAccount,
          disabled: !userAssignedClaim(UserClaims.AccountManager)
        }
      ]
    }
  },

  methods: {
    ...mapActions('account', ['fetchAccounts']),
    ...mapMutations('payable', ['setFocusedAccount']),
    generateCsvString,

    editAccount (account) {
      this.$emit('account-action', {
        actionType: 'edit-account',
        account: account
      })
    },

    advanceBtnColor (account) {
      return account.advances.length === 0 ? 'gray' : 'black'
    },

    createAccount () {
      this.$emit('account-action', {
        actionType: 'create-account'
      })
    },

    viewAccountDetails (account) {
      this.$emit('account-action', {
        actionType: 'view-account-details',
        account: account
      })
    },

    deleteAccount (account) {
      this.$emit('account-action', {
        actionType: 'delete-account',
        account: account
      })
    },

    viewPayables (account) {
      this.$emit('account-action', {
        actionType: 'view-payables',
        account: account
      })
    },

    applyFilter (tags) {
      this.$emit('tags-chosen', tags)
    },

    downloadCSV () {
      const csvString = this.generateCsvString(this.allAccounts, [
        { label: 'name', value: a => a.name },
        { label: 'insuranceExpiryDate', value: a => a.insuranceExpiryDate ? utcToLocalDate(a.insuranceExpiryDate) : undefined },
        { label: 'externalOrInternal', value: a => a.isExternal ? 'External' : 'Internal' },
        { label: 'tags', value: a => (a.accountTags ?? []).join(', ') },
        { label: 'code', value: a => a.code },
        { label: 'exportCode', value: a => a.exportCode }
      ], { emptyValue: this.$t('notAvailable') })
      const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `Accounts-${moment().format()}.csv`)
    }
  }
}
</script>
