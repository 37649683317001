export default {
  dateConfiguration: (state) => state.dateConfiguration,
  arSalesAnalysisBusinessEntity: (state) => state.arSalesAnalysisBusinessEntity,
  currentModuleKey: (state) => state.currentModuleKey,
  currentSubModuleKey: (state) => state.currentSubModuleKey,
  moduleNames: (state) => state.moduleNames,
  payableModule: (state) => state.accountsPayable,
  receivableModule: (state) => state.accountsReceivable,
  accrualsModule: (state) => state.accruals,
  loading: (state) => state.loading,
  currentSubModule: (state) => state[state.currentModuleKey][state.currentSubModuleKey],
  tractPayments: (state) => state.tractPayments,
  settlementContractStatuses: (state) => state.settlementContractStatuses,
  settlementPayeeStatuses: (state) => state.settlementPayeeStatuses
}
