<template>
  <v-row no-gutters>
    <v-col>
      <v-autocomplete
      color="black"
      item-color="secondary"
      v-model="chosenTractType"
      data-testid="tract-type"
      :label="$t('tractType')"
      :items="allTractTypes"
      return-object
      item-text="name"
      item-value="name"
      :rules="[rules.required]"
      :persistent-hint="persistentHint"
      :hint="hint"
      >
      <template #append-outer>
        <Icon
        :small="false"
        icon="mdi-plus"
        dataTestId="tract-type-add"
        :tooltipText="$t('newTractType')"
        @icon-clicked="tractTypeForm = true"
      />
      </template>
      <template #item="{item}">
        {{`${item.name} (${categoryFromInt(item.category)})`}}
      </template>
      <template #selection="{item}">
        {{`${item.name} (${categoryFromInt(item.category)})`}}
      </template>
    </v-autocomplete>
    </v-col>
    <v-dialog v-model="tractTypeForm" max-width="800">
      <TractTypeForm @tract-type-mutated="typeAdded" v-if="tractTypeForm" @close="tractTypeForm = false"/>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TractTypeCategory } from '@/utils/Enumerations.js'
import { rules } from '@/utils/FormRules'

export default {
  name: 'TractTypeSelector',

  components: {
    TractTypeForm: () => import('@/components/settings-components/tract-type/TractTypeForm.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    tractTypeId: {
      type: Number,
      default: undefined
    },
    hint: {
      type: String,
      default: '',
      required: false
    },
    persistentHint: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    tractTypeForm: false,
    chosenTractType: undefined,
    newTractType: {
      name: '',
      code: '',
      category: ''
    },
    rules
  }),

  created () {
    this.refreshTractTypes(this.tractTypeId)
  },

  watch: {
    chosenTractType (val) {
      this.$emit('tract-type', val)
    }
  },

  computed: {
    ...mapGetters('tract-type', ['allTractTypes'])
  },

  methods: {
    ...mapActions('tract-type', ['fetchTractTypes', 'createTractType']),
    categoryFromInt: (x) => TractTypeCategory.fromInt(x),

    async refreshTractTypes (tractTypeId) {
      await this.fetchTractTypes()
      if (tractTypeId) {
        this.chosenTractType = this.allTractTypes.find(tt => tt.tractTypeId === tractTypeId)
      }
    },

    typeAdded (tractType) {
      this.tractTypeForm = false
      this.refreshTractTypes(tractType.tractTypeId)
    }
  }
}
</script>
