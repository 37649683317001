import PDFMake from 'pdfmake'
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations.js'
import i18n from '@/i18n'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { saveAs } from 'file-saver'
import { fileNameForString } from './GenericSettlementsFunctions.js'
import { getEntities, getFinancialsByEntity, PayablesHelpers, ReceivablesHelpers, AccrualsHelpers, getFinancialTypes } from '../RegisterHelpers.js'

export async function createPaymentRegisterPDF (module, exportBatch, isByproduct = false) {
  const moduleType = getModuleType(module)
  const tables = getFinancialTypes(moduleType.id)

  PDFMake.tableLayouts = {
    divider: {
      hLineColor: (_) => '#ddd'
    }
  }

  const entities = getEntities(module[moduleType.value], moduleType.id)

  const entitiesContent = []

  entities.forEach((entity, entityIdx) => {
    const financialsByEntity = getFinancialsByEntity(module[moduleType.value], entity, moduleType.id)
    let grandTotal
    let totalForAccount
    let totalWeightForAccount
    let totalLoadsForAccount
    switch (moduleType) {
      case moduleTypes.Payables: {
        grandTotal = PayablesHelpers.getGrandTotal(financialsByEntity)
        totalForAccount = PayablesHelpers.totalStringForAccount
        break
      }
      case moduleTypes.Receivables: {
        grandTotal = ReceivablesHelpers.getGrandTotal(financialsByEntity)
        totalForAccount = ReceivablesHelpers.totalStringForAccount
        totalWeightForAccount = ReceivablesHelpers.totalWeightStringForAccount
        totalLoadsForAccount = ReceivablesHelpers.totalLoadsStringForAccount
        break
      }
      case moduleTypes.Accruals: {
        totalForAccount = AccrualsHelpers.totalStringForAccount
        totalWeightForAccount = AccrualsHelpers.totalWeightStringForAccount
        break
      }
    }

    const counterparties = []
    financialsByEntity.forEach((counterparty, counterpartyIdx, counterpartyArray) => {
      const counterpartyBody = [{
        columns: [{
          text: `${counterparty[moduleType.counterpartyNameKey]}${counterparty[moduleType.counterpartyCodeKey] ? ` ${emDash} ${counterparty[moduleType.counterpartyCodeKey]}` : ''}`,
          margin: [0, 12, 16, 0],
          style: 'subSubHeader'
        },
        {
          text: `${totalForAccount(counterparty)}${totalWeightForAccount ? '\n' + totalWeightForAccount(counterparty) : ''}${totalLoadsForAccount ? '\n' + totalLoadsForAccount(counterparty) : ''}`,
          width: 'auto',
          style: 'subSubHeader',
          alignment: 'right',
          margin: [0, 12, 0, 0]
        }
        ]
      }]

      Object.values(tables)
        .filter(table => counterparty[table.value] && counterparty[table.value].length > 0)
        .forEach((table) => {
          const headers = table.headers(isByproduct, moduleType.label)
          counterpartyBody.push(
            {
              text: i18n.t(moduleType === moduleTypes.Payables
                ? table.labelPayables ?? table.label
                : moduleType === moduleTypes.Receivables
                  ? table.labelReceivables ?? table.label
                  : table.label),
              style: 'bold',
              margin: [0, 8, 0, 0]
            },
            {
              table: {
                headerRows: 1,
                keepWithHeaderRows: 1,
                widths: headers.map(h => h.value === 'note' ? '35%' : '*'),
                body: getTableRows(counterparty[table.value], headers)
              },
              layout: {
                fillColor: function (rowIndex) {
                  return ((rowIndex + 1) % 2 === 0) ? '#EEEEEE' : null
                }
              }
            })
        })

      counterparties.push(counterpartyBody)
      if (counterpartyIdx < counterpartyArray.length - 1) {
        counterpartyBody.push(
          {
            table: { widths: [16, '*', 16], body: [[{ text: '', border: noBorder }, { text: '', border: bottomBorder }, { text: '', border: noBorder }]] },
            layout: 'divider',
            margin: [0, 12, 0, 0]
          })
      }
    })
    entitiesContent.push({
      columns: [
        { text: entity, style: 'subHeader' },
        { text: [grandTotal, `${grandTotal ? '\n' : ''}${i18n.t('counterparties')}: ${financialsByEntity.length}`], width: 'auto', style: 'subHeader', alignment: 'right' }
      ],
      pageBreak: entityIdx > 0 ? 'before' : ''
    },
    ...counterparties)
  })

  const content = [
    { text: `${i18n.t(isByproduct ? 'byproducts' : 'logs')} ${emDash} ${i18n.t(moduleType.label)}`, style: 'header' },
    ...entitiesContent
  ]

  const docDefinition = {
    pageOrientation: 'landscape',
    content: content,
    styles: styles,
    footer: (currentPage) => {
      return ({
        columns: [
          { text: currentPage, style: 'footer', width: '*' },
          {
            text: i18n.t('exportBatchName', {
              name: exportBatch.label
            }),
            width: 'auto',
            style: 'footer'
          },
          {
            text: i18n.t('printedOnDate',
              { date: utcToLocalDate(Date.now(), 'L - LT') }),
            style: 'footer',
            width: '*',
            alignment: 'right'
          }
        ]
      })
    }
  }

  const filename = `${i18n.t(isByproduct ? 'byproducts' : 'logs')}${i18n.t(moduleType.label)}_${fileNameForString(exportBatch.label)}`
  const pdf = await PDFMake.createPdf(docDefinition)
  const blob = await new Promise(resolve => {
    pdf.getBlob(resolve)
  })
  saveAs(blob, filename)
  return 'pdf-complete'
}

const moduleTypes = {
  Payables: {
    label: 'payables',
    value: 'payments',
    counterpartyNameKey: 'payeeName',
    counterpartyCodeKey: 'payeeExportCode',
    id: 0
  },
  Receivables: {
    label: 'receivables',
    get value () { return this.label },
    counterpartyNameKey: 'accountName',
    counterpartyCodeKey: 'accountExportCode',
    id: 1
  },
  Accruals: {
    label: 'accruals',
    value: 'journalEntries',
    counterpartyNameKey: 'accountName',
    counterpartyCodeKey: 'accountCode',
    id: 2
  }
}

function getModuleType (module) {
  return Object.values(moduleTypes).find(mt => !!module[mt.value])
}

function getTableRows (payables, headers) {
  const tableRows = [headers.map(h => ({
    text: h.text,
    style: 'columnHeader',
    border: bottomBorder,
    alignment: h.align
  }))]
  payables.forEach(activity => {
    const row = headers.map(h => ({
      text: ['weight'].includes(h.value)
        ? tonStringFromPounds(activity[h.value])
        : ['payout', 'amount', 'grossAmount', 'balance', 'recovered'].includes(h.value)
          ? formatMoney(activity[h.value])
          : activity[h.value],
      border: noBorder,
      style: 'cell',
      alignment: h.align
    })
    )
    tableRows.push([...row])
  })
  return tableRows
}

const styles = {
  header: {
    fontSize: 18,
    bold: true
  },
  subHeader: {
    fontSize: 16,
    bold: true
  },
  subSubHeader: {
    fontSize: 14,
    bold: true
  },
  bold: {
    bold: true
  },
  cell: {
    fontSize: 8
  },
  columnHeader: {
    fontSize: 8,
    bold: true
  },
  footer: {
    fontSize: 8,
    margin: [40, 0, 40, 0]
  }
}

const noBorder = [false, false, false, false]
const bottomBorder = [false, false, false, true]

const emDash = '\u2014'
