<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    left
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom color="secondary">
        <template #activator="{ on: tooltip }">
          <v-icon color="secondary" v-bind="attrs" class="mr-1" v-on="{...tooltip, ...menu}">mdi-application-edit</v-icon>
        </template>
        <span class="subtitle-1 white--text" style="white-space: pre-line">
          {{$t('adjustColumns')}}
        </span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="secondary white--text">
        {{$t('adjustColumns')}}
      </v-card-title>
      <v-card-text class="mt-3">
        <v-container :style="containerStyle()">
          <v-row v-for="(c, ii) in chunk(columnSet.columns.filter(c => c.hidable && !c.invisible), 2)" :key="-ii" dense no-gutters>
            <v-col v-for="(column, jj) in c" :key="jj" cols="6">
              <v-checkbox
              v-model="column.visible"
              color="secondary"
              dense
              @click="checkboxClicked(column)"
              :label="column.text"></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="secondary" @click="save">{{$t('apply')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { ColumnSet } from '@/model/DataTableColumn.js'
import { chunk } from 'lodash'
import { getMenuContainerStyle } from '../../utils/componentHelpers'

export default {
  name: 'ColumnAdjuster',

  props: {
    propColumnSet: ColumnSet,
    dynamicSize: {
      type: Boolean,
      required: false,
      default: false
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    columnSet: null,
    menuOpen: false
  }),

  watch: {
    menuOpen (val) {
      if (val === true) {
        this.columnSet = this.propColumnSet.clone()
      } else {
        this.$emit('closed')
      }
    },

    isOpen (val) {
      this.menuOpen = val
    }
  },

  created () {
    this.columnSet = this.propColumnSet.clone()
  },

  mounted () {
    if (this.dynamicSize) {
      this.menuOpen = true
      this.menuOpen = false
    }
  },

  beforeDestroy () {
  },

  methods: {
    chunk,
    save () {
      this.columnSet.saveColumnInfo()
      this.menuOpen = false
      this.$emit('new-columns-selected', this.columnSet)
    },

    checkboxClicked (column) {
      if (column.visible) {
        this.columnSet.showColumn(column.id)
      } else {
        this.columnSet.hideColumn(column.id)
      }
    },

    containerStyle () {
      return getMenuContainerStyle(window.innerWidth, window.innerHeight)
    }
  }
}
</script>
