<template>
  <DataTable
  :loading="receivableLoadingStatus"
  :headers="currentHeaders"
  :items.sync="items"
  :customCells="customCells"
  @refresh="$emit('refresh')"
  >
    <template #rate="{item}">
      <span>{{formatMoney(item.computedAtRate)}}</span>
    </template>
    <template #amount="{item}">
      <span>{{formatMoney(item.amount)}}</span>
    </template>
    <template #net-weight="{item}">
      <span>{{tonStringFromPounds(item.netWeight)}}</span>
    </template>
    <template #defect-weight="{item}">
      <span>{{tonStringFromPounds(item.defectWeight)}}</span>
    </template>
    <template #product="{item}">
      <span>{{item.product === '' ? 'N/A' : item.product}}</span>
    </template>
    <template #pay-on="{item}">
      <span>{{payOn(item.computedAtPayOn)}}</span>
    </template>
    <template #pay-by="{item}">
      <span>{{payBy(item.computedAtPayBy)}}</span>
    </template>
    <template #actions="{item}">
        <Icon
          iconColor="black"
          icon="mdi-information-outline"
          dataTestId="recievable-detail-button"
          :tooltipText="$t('details')"
          @icon-clicked="$emit('view-detail', item)"
        />
    </template>
  </DataTable>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { allReceivableHeaders } from '@/headers/Receivable.js'
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations.js'
import { PayOn, PayBy } from '@/utils/Enumerations.js'
export default {
  name: 'ReceivablesTable',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    receivables: {
      type: Array
    }
  },

  computed: {
    ...mapGetters('receivable', ['allReceivables', 'receivableLoadingStatus']),
    currentHeaders () {
      const headers = allReceivableHeaders(this.$i18n.locale)
      headers.pop()
      return headers
    },

    items () {
      if (this.receivables) {
        return this.receivables
      } else {
        return this.allReceivables
      }
    },

    customCells () {
      return [
        {
          slotName: 'rate',
          value: 'computedAtRate'
        },
        {
          slotName: 'amount',
          value: 'amount'
        },
        {
          slotName: 'pay-on',
          value: 'computedAtPayOn'
        },
        {
          slotName: 'pay-by',
          value: 'computedAtPayBy'
        },
        {
          slotName: 'product',
          value: 'product'
        },
        {
          slotName: 'net-weight',
          value: 'netWeight'
        },
        {
          slotName: 'defect-weight',
          value: 'defectWeight'
        },
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    }
  },

  methods: {
    ...mapActions('receivable', ['fetchReceivables']),
    formatMoney,
    tonStringFromPounds,
    payOn: (x) => PayOn.fromInt(x),
    payBy: (x) => PayBy.fromInt(x)
  }
}
</script>
