import { tonStringFromPounds, formatMoney } from '@/utils/NumericMutations.js'
import i18n from '@/i18n'
import { paymentRegisterHeaders, registerHeaders, receivableRegisterHeaders, prRecoveryHeaders, prAdvanceHeaders, prCorrectionHeaders } from '@/headers/Cycles.js'
import TractHeaders from '@/headers/Tract.js'
import { accountPaymentRegisterHeaders } from '@/headers/Account.js'

const financialTypesByCategory = {
  Payables: {
    ContractPayments: {
      label: 'contractPayments',
      value: 'activities',
      headers: (isBp) => { return paymentRegisterHeaders(isBp) }
    },
    Recoveries: {
      label: 'recoveries',
      get value () { return this.label },
      headers: () => { return prRecoveryHeaders({ isExportBatch: true }) }
    },
    TractPayments: {
      label: 'tractPayments',
      get value () { return this.label },
      headers: () => { return TractHeaders.tractPaymentRegisterHeaders() }
    },
    AccountPayments: {
      label: 'accountPayments',
      get value () { return this.label },
      headers: () => { return accountPaymentRegisterHeaders() }
    },
    Advances: {
      label: 'advances',
      get value () { return this.label },
      headers: () => { return prAdvanceHeaders() }
    },
    Corrections: {
      label: 'corrections',
      value: 'correctionFinancials',
      headers: (isBp) => { return prCorrectionHeaders({ isByproduct: isBp, includeGross: true }) }
    }
  },

  Receivables: {
    Receivables: {
      label: 'receivables',
      value: 'activities',
      headers: (isBp) => { return receivableRegisterHeaders(isBp) }
    },
    Corrections: {
      label: 'corrections',
      value: 'correctionFinancials',
      headers: (isBp) => { return prCorrectionHeaders({ isByproduct: isBp }) }
    }
  },

  Accruals: {
    Activities: {
      label: 'activities',
      get value () { return this.label },
      headers: (isBp) => { return registerHeaders(isBp) }
    },
    Corrections: {
      label: 'corrections',
      value: 'correctionFinancials',
      headers: (isBp) => { return prCorrectionHeaders({ isByproduct: isBp }) }
    }
  }
}

function accountingCategoryStringFromInt (int) {
  switch (int) {
    case 1: return 'Receivables'
    case 2: return 'Accruals'
    default: return 'Payables'
  }
}

export function getFinancialTypes (accountingCategoryInt) {
  return financialTypesByCategory[accountingCategoryStringFromInt(accountingCategoryInt)]
}

export function getEntities (financials, accountingCategory) {
  const entities = {}

  financials.forEach((f) => {
    Object.values(financialTypesByCategory[accountingCategoryStringFromInt(accountingCategory)]).forEach(ft => {
      if (f[ft.value]) {
        f[ft.value].forEach(subFinancial => {
          entities[subFinancial.businessEntityName] = subFinancial.businessEntityId
        })
      }
    })
  })

  return Object.keys(entities).sort()
}

export function getFinancialsByEntity (financials, entity, accountingCategory) {
  const financialTypes = financialTypesByCategory[accountingCategoryStringFromInt(accountingCategory)]
  const filteredFinancials = JSON.parse(JSON.stringify(financials))
  filteredFinancials.forEach(f => {
    Object.values(financialTypes).forEach(ft => {
      if (f[ft.value]) f[ft.value] = f[ft.value].filter(i => i.businessEntityName === entity)
    })
  })

  return filteredFinancials.filter(f => Object.values(financialTypes)
    .map(ft => ft.value)
    .some(ftValue => f[ftValue].length > 0))
}

export const PayablesHelpers = {
  totalStringForAccount ({ activities, tractPayments, advances, correctionFinancials, accountPayments }) {
    const totalPayable = activities.reduce((total, curr) => total + curr.payout, 0) +
    tractPayments.reduce((total, curr) => total + curr.amount, 0) +
    correctionFinancials.reduce((total, curr) => total + curr.amount, 0) +
    accountPayments.reduce((total, curr) => total + curr.amount, 0)

    const totalAdvanced = advances.reduce((total, curr) => total + curr.amount, 0)

    const advanceString = totalAdvanced === 0 ? '' : `(+${formatMoney(totalAdvanced)})`
    return `${formatMoney(totalPayable)} ${advanceString}`
  },

  getGrandTotal (payments) {
    let totalPayable = 0
    let totalAdvanced = 0
    payments.forEach(payment => {
      totalPayable += payment.activities.reduce((total, curr) => total + curr.payout, 0) +
      payment.tractPayments.reduce((total, curr) => total + curr.amount, 0) +
      payment.correctionFinancials.reduce((total, curr) => total + curr.amount, 0) +
      payment.accountPayments.reduce((total, curr) => total + curr.amount, 0)
      totalAdvanced += payment.advances.reduce((total, curr) => total + curr.amount, 0)
    })
    return `${i18n.t('grandTotal')}: ${formatMoney(totalPayable + totalAdvanced)}`
  }
}

export const ReceivablesHelpers = {
  totalStringForAccount ({ activities, correctionFinancials }) {
    const totalReceivable = activities.reduce((a, b) => a + b.amount, 0)
    const totalCorrected = correctionFinancials.reduce((a, b) => a + b.amount, 0)
    return `${formatMoney(totalReceivable + totalCorrected)}`
  },

  totalLoadsStringForAccount ({ activities }) {
    let totalLoads = 0

    activities.forEach((activity) => {
      totalLoads += activity.loadCount
    })

    const loadText = totalLoads > 1 ? 'loads' : 'load'
    return `${totalLoads} ${loadText}`
  },

  totalWeightStringForAccount ({ activities }) {
    let totalWeight = 0

    activities.forEach((activity) => {
      totalWeight += activity.weight
    })

    return `${tonStringFromPounds(totalWeight)} tons`
  },

  getGrandTotal (receivables) {
    let totalReceivable = 0
    receivables.forEach(receivable => {
      totalReceivable += receivable.activities.reduce((total, curr) => total + curr.amount, 0) +
      receivable.correctionFinancials.reduce((total, curr) => total + curr.amount, 0)
    })
    return `${i18n.t('grandTotal')}: ${formatMoney(totalReceivable)}`
  }
}

export const AccrualsHelpers = {
  totalStringForAccount ({ activities, correctionFinancials }) {
    const totalPayable = activities.reduce((a, b) => a + b.amount, 0)
    const totalCorrected = correctionFinancials.reduce((a, b) => a + b.amount, 0)
    return `${formatMoney(totalPayable + totalCorrected)}`
  },

  totalWeightStringForAccount ({ activities }) {
    let totalWeight = 0

    activities.forEach((activity) => {
      totalWeight += activity.weight
    })

    return `${tonStringFromPounds(totalWeight)} tons`
  }
}
