<template>
<v-col cols="12">
  <v-row>
    <v-col cols="12" lg="6" v-if="changedContract">
      <v-card min-height="205" class="fill-height" outlined hover>
        <v-card-title class="subtitle-1 mt-n1">{{ $t('changedContract') }}</v-card-title>
        <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
          <v-container fluid grid-list-sm>
            <v-row no-gutters>
              <v-col cols="6">
                <v-row class="mt-1 mb-1 subtitle-1">
                  {{ $t('oldContract') }}
                </v-row>
                <v-row v-for="cf in contractFields(changedContract.oldContract)" :key="`cf-old-${cf.icon}`">
                  <v-icon>{{ cf.icon }}</v-icon>
                  <span class="text">{{ cf.value }}</span>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row class="mt-1 mb-1 subtitle-1">
                  {{ $t('newContract') }}
                </v-row>
                <v-row v-for="cf in contractFields(changedContract.newContract)" :key="`cf-old-${cf.icon}`">
                  <v-icon>{{ cf.icon }}</v-icon>
                  <span class="text">{{ cf.value }}</span>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" v-if="changedWeight">
      <v-card min-height="205" class="fill-height" outlined hover>
        <v-card-title class="subtitle-1 mt-n1">{{ $t('weightInformation') }}</v-card-title>
        <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
          <v-container fluid grid-list-sm>
            <v-row no-gutters>
              <v-col cols="6">
                {{ $t('inWeight') }}
              </v-col>
              <v-col>
                <span v-html="changedWeight.inWeight"/>
              </v-col>
              <v-col cols="6">
                {{ $t('outWeight') }}
              </v-col>
              <v-col>
                <span v-html="changedWeight.outWeight"/>
              </v-col>
              <v-col cols="6" v-if="isSimpleDefect">
                {{$t('defectWeight')}}:
              </v-col>
              <v-col v-if="isSimpleDefect">
                <span v-html="changedWeight.defectWeight"/>
              </v-col>
              <v-col cols="6" v-if="!isSimpleDefect">
                {{$t('natureDefectWeight')}}:
              </v-col>
              <v-col v-if="!isSimpleDefect">
                <span v-html="changedWeight.natureDefectWeight"/>
              </v-col>
              <v-col cols="6" v-if="!isSimpleDefect">
                {{$t('mismanufacturedDefectWeight')}}:
              </v-col>
              <v-col v-if="!isSimpleDefect">
                <span v-html="changedWeight.mismanufactureDefectWeight"/>
              </v-col>
              <v-col cols="6">
                {{$t('newNetWeight')}}:
              </v-col>
              <v-col>
                <span v-html="changedWeight.newNetWeight"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" v-if="changedProduct">
      <v-card class="fill-height" outlined hover>
        <v-card-title class="subtitle-1 mt-n1">{{ $t('changedProduct') }}</v-card-title>
        <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
          <v-container fluid grid-list-sm>
            <v-row no-gutters>
              <v-col cols="6">
                {{ $t('oldProduct') }}
              </v-col>
              <v-col>
                {{changedProduct.oldProduct}}
              </v-col>
              <v-col cols="6">
                {{ $t('newProduct') }}
              </v-col>
              <v-col>
                {{changedProduct.newProduct}}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" v-if="changedDriver">
      <v-card class="fill-height" outlined hover>
        <v-card-title class="subtitle-1 mt-n1">{{ $t('changedDriver') }}</v-card-title>
        <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
          <v-container fluid grid-list-sm>
            <v-row no-gutters>
              <v-col cols="6">
                {{ $t('oldDriver') }}
              </v-col>
              <v-col>
                {{ticketDriverAccount ?? $t('notAvailable')}}
              </v-col>
              <v-col cols="6">
                {{ $t('newDriver') }}
              </v-col>
              <v-col>
                {{correctionDriverAccount ?? $t('notAvailable')}}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-col>
</template>

<script>
import { tonStringFromPounds } from '@/utils/NumericMutations.js'
import { ContractModes, DefectCategory } from '../../../utils/Enumerations'
import { mapActions } from 'vuex'
export default {
  name: 'NewTicketValues',

  props: {
    correction: {
      type: Object,
      required: true
    },
    contractMode: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    correctionDriverAccount: undefined,
    ticketDriverAccount: undefined
  }),

  computed: {
    changedProduct () {
      const { product, ticket } = this.correction.correction
      if (!ticket || !product) { return false }
      if (ticket.productID === product.productId) { return false }

      return {
        oldProduct: ticket.productID === 0 ? 'Unspecified' : ticket.product,
        newProduct: product.name
      }
    },

    changedDriver () {
      return this.correction.correction.driverAccountId !== this.correction.correction.ticket.driverAccountId
    },

    changedContract () {
      const { contract, ticket } = this.correction.correction
      if (!ticket || !contract) { return false }
      if (ticket.contractId === contract.contractId) { return false }

      return {
        oldContract: {
          account: ticket.account,
          tract: ticket.tract,
          fromAccount: ticket.fromAccount,
          destination: ticket.destination,
          setting: ticket.setting
        },
        newContract: {
          account: contract.account,
          tract: contract.tract,
          fromAccount: contract.fromAccount,
          destination: contract.destination,
          setting: contract.setting
        }
      }
    },

    changedWeight () {
      const correction = this.correction.correction
      const ticket = this.correction.correction.ticket

      if (!ticket) { return false }

      const oldNetWeight = ticket.inWeight - ticket.outWeight - ticket.defectWeight
      const newNetWeight = correction.inWeight - correction.outWeight - correction.defectWeight

      if (oldNetWeight === newNetWeight) return false

      const { natureDefectWeight, mismanufactureDefectWeight } = ticket.defects.reduce((weights, d) => {
        weights.natureDefectWeight += d.category === DefectCategory.Nature.value ? d.totalLBS : 0
        weights.mismanufactureDefectWeight += d.category === DefectCategory.Mismanufactured.value ? d.totalLBS : 0
        return weights
      }, { natureDefectWeight: 0, mismanufactureDefectWeight: 0 })

      const weightsToString = (oldWeight, newWeight) => {
        return oldWeight === newWeight
          ? this.$t('unchangedString', { str: tonStringFromPounds(oldWeight) })
          : `${tonStringFromPounds(oldWeight)} &rarr; ${tonStringFromPounds(newWeight)}`
      }

      return {
        inWeight: weightsToString(ticket.inWeight, correction.inWeight),
        outWeight: weightsToString(ticket.outWeight, correction.outWeight),
        defectWeight: weightsToString(ticket.defectWeight, correction.defectWeight),
        natureDefectWeight: weightsToString(natureDefectWeight, correction.natureDefectWeight),
        mismanufactureDefectWeight: weightsToString(mismanufactureDefectWeight, correction.mismanufactureDefectWeight),
        newNetWeight: weightsToString(oldNetWeight, newNetWeight)
      }
    },

    isSimpleDefect () { return this.correction.correction.ticket.defects.length === 0 }
  },

  async created () {
    if (!this.changedDriver) return

    if (this.correction.correction.driverAccountId) {
      const correctionAccount = await this.fetchAccountById(this.correction.correction.driverAccountId)
      this.correctionDriverAccount = correctionAccount.name
    }

    if (this.correction.correction.ticket.driverAccountId) {
      const ticketAccount = await this.fetchAccountById(this.correction.correction.ticket.driverAccountId)
      this.ticketDriverAccount = ticketAccount.name
    }
  },

  methods: {
    ...mapActions('account', ['fetchAccountById']),
    contractFields (contract) {
      return (this.contractMode.value === ContractModes[0].value)
        ? [
          { icon: 'mdi-account', value: contract.account },
          { icon: 'mdi-crosshairs-gps', value: contract.tract },
          { icon: 'mdi-domain', value: contract.destination },
          { icon: 'mdi-map-marker-circle', value: contract.setting }
        ] : [
          { icon: 'mdi-account', value: contract.account },
          { icon: 'mdi-account-arrow-right', value: contract.fromAccount },
          { icon: 'mdi-domain', value: contract.destination }
        ]
    }
  }
}
</script>
