import ApiServer from '@/utils/api/ApiServer.js'
import { saveAs } from 'file-saver'

export default {
  async fetchTicketsForConsumption (context, deckId) {
    const path = `v2/decks/${deckId}/ticketsForConsumption`
    const { data: ticketsForConsumption } = await ApiServer.get(path)
    context.commit('setTicketsForConsumption', ticketsForConsumption)
    return ticketsForConsumption
  },

  async fetchConsumptionBatches (context) {
    const { data: consumptionBatches } = await ApiServer.get('consumptionBatches')
    context.commit('setConsumptionBatches', consumptionBatches)
    return consumptionBatches
  },

  async downloadConsumptionBatchExport (_, consumptionBatch) {
    const path = `consumptionBatches/${consumptionBatch.consumptionBatchId}/csvExport`
    const response = await ApiServer.get(path)
    const blob = new Blob([response.data], { type: 'text/csv' })
    saveAs(blob, `${consumptionBatch.deck}-${consumptionBatch.label}.csv`)
  },

  async postConsumptionBatch (_, requestObject) {
    const path = `decks/${requestObject.deckId}/consumptionBatches`
    const response = await ApiServer.post(path, requestObject)
    return response.data
  },

  async revertTickets (_, { ticketIds, consumptionBatchId }) {
    if (ticketIds) {
      const results = { successes: 0, failures: 0 }
      for (const ticketId of ticketIds) {
        try {
          await ApiServer.delete(`consumptionBatches/${consumptionBatchId}/Tickets/${ticketId}`)
          results.successes++
        } catch (__) {
          results.failures++
        }
      }

      return results
    }
    const response = await ApiServer.delete(`consumptionBatches/${consumptionBatchId}`)
    return response.data
  },

  async postTransferBatches (_, { ticketIds, destinationDeckId, fromDeckId, weight }) {
    const response = await ApiServer.post(`decks/${fromDeckId}/transferBatches`, { ticketIds: ticketIds, destinationDeckId: destinationDeckId, weight: weight })
    return response.data
  }
}
