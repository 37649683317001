<template>
  <v-autocomplete
    :data-testid="this.dataTestid"
    color="black"
    item-color="secondary"
    v-model="chosenUserName"
    :label="label"
    @change="parseUserName"
    :loading="usersLoading"
    :items="userNames"
    clearable
    :filter="(item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
  >
    <template #append-outer>
      <v-icon @click="refreshUsers" color="secondary" tabindex="-1">mdi-refresh</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'UserAutocomplete',

  props: {
    propUserId: { type: Number },
    dataTestid: { type: String },
    label: { type: String, default: 'User' }
  },

  data: () => ({
    userNames: [],
    chosenUserName: '',
    users: [],
    usersLoading: false
  }),

  watch: {
    chosenUserName (name) {
      this.parseUserName(name)
    }
  },
  created () {
    this.refreshUsers()
  },

  methods: {
    ...mapActions('user', ['getAllUsers']),

    async refreshUsers () {
      this.usersLoading = true
      try {
        const response = await this.getAllUsers()
        this.users = response
        this.addUserNames()
      } finally {
        this.usersLoading = false
      }
    },

    addUserNames () {
      this.userNames = []
      for (let i = 0; i < this.users.length; i++) {
        const user = this.users[i]
        this.userNames.push(user.name)

        if (this.propUserId === user.applicationUserId) {
          this.chosenUserName = user.name
        }
      }
    },

    parseUserName () {
      if (!this.chosenUserName) {
        this.$emit('user-chosen')
        return
      }
      const userIndex = this.users.findIndex(v => v.name === this.chosenUserName)
      if (userIndex !== -1) {
        const user = this.users[userIndex]
        this.$emit('user-chosen', user)
      }
    }
  }
}
</script>
