<template>
  <v-layout wrap>
    <v-chip-group>
        <v-chip
        v-for="filter in filterArray.filter(f => f.active)"
        :key="filter.name"
        data-testid="chip-filter"
        @click:close="removeFilter(filter)"
        text-color="white"
        color="black"
        class="mx-2"
        close>
          <v-avatar left>
            <v-icon>{{filter.icon}}</v-icon>
          </v-avatar>
          <span>
            {{$t(filter.name)}}
          </span>
        </v-chip>
    </v-chip-group>
  </v-layout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'TicketFilterChips',

  computed: {
    ...mapGetters('ticket', ['filters']),

    filterArray () {
      if (this.filters.advancedSearch.active) {
        return [
          {
            name: 'advancedSearch',
            icon: 'mdi-file-search',
            active: true
          }
        ]
      }
      return Object.values(this.filters).filter(f => f.name !== 'phrase')
    }
  },

  methods: {
    ...mapMutations('ticket', ['removeFilter'])
  }
}
</script>
