<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    right
  >
    <template #activator="{ on: menu, attrs }">
      <v-tooltip bottom :color="tooltipColor || iconColor">
        <template #activator="{ on: tooltip }">
          <v-icon
            v-on="{...tooltip, ...menu}"
            v-bind="attrs"
            :x-large="xLarge"
            :color="iconColor"
            data-testid="tract-filter-btn"
            class="mr-2"
          >
            mdi-filter
          </v-icon>
        </template>
        <span class="subtitle-1 white--text">
          {{$t('filter')}}
        </span>
      </v-tooltip>
    </template>
    <v-card width="600px">
      <v-card-title class="secondary white--text">
        {{$t('tractFilter')}}
      </v-card-title>
      <v-card-text>
        <v-container fluid :style="this.containerStyle">
          <v-row dense>
            <v-col v-for="ts in tractStatuses" :key="`status-${ts.name}`" cols="6" xs="6" sm="4" class="ma-0 pa-0">
              <v-checkbox :label="ts.name" v-model="filterBuilder.status" :value="ts.value"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10">
              <v-select
              :items="this.allForesters"
              :label="$t('forester')"
              v-model="filterBuilder.selectedForesters"
              multiple
              chips
              return-object
              :item-text="f => f.name"/>
            </v-col>
            <v-col cols="10">
              <v-select
              :items="this.allTractTypes"
              :label="$t('tractType')"
              v-model="filterBuilder.selectedTractTypes"
              multiple
              chips
              return-object
              :item-text="f => f.name"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" outlined @click="resetFilter">
          {{$t('reset')}}
        </v-btn>
        <v-spacer/>
        <v-btn
        color="secondary"
        class="text--white"
        data-testid="tract-filter-apply-btn"
        :disabled="!changed"
        @click="emitFilter">
          {{$t('apply')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'
import { TractStatus } from '@/utils/Enumerations.js'
import { LocalStorageKeys } from '../../utils/constants'
import { getMenuContainerStyle } from '../../utils/componentHelpers'

export default {
  name: 'TractFilter',

  props: {
    filter: {
      type: Object,
      required: true
    },
    xLarge: {
      type: Boolean,
      required: false,
      default: false
    },
    iconColor: {
      type: String,
      required: false,
      default: 'secondary'
    },
    tooltipColor: {
      type: String,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    menuOpen: false,
    allForesters: [],
    allTractTypes: [],
    filterBuilder: {
      status: [3], // Active status has value 3.
      selectedForesters: [],
      selectedTractTypes: []
    },
    containerStyle: undefined,
    changed: false
  }),

  watch: {
    filter: {
      handler (newFilter) {
        this.filterBuilder = JSON.parse(JSON.stringify(newFilter))
      },
      deep: true
    },
    menuOpen (isOpen) {
      if (isOpen) {
        this.changed = false
      } else {
        this.filterBuilder = JSON.parse(JSON.stringify(this.filter))
      }
    },
    filterBuilder: {
      handler () {
        this.changed = true
      },
      deep: true
    }
  },

  computed: {
    tractStatuses () {
      return TractStatus
    }
  },

  async created () {
    const foresterResponse = await this.getAllForesterUsers()
    this.allForesters = foresterResponse

    const tractTypeResponse = await this.fetchTractTypes()
    this.allTractTypes = tractTypeResponse

    this.filterBuilder = JSON.parse(JSON.stringify(this.filter))

    window.addEventListener('resize', this.setContainerStyle)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.setContainerStyle)
  },

  methods: {
    ...mapActions('user', ['getAllForesterUsers']),
    ...mapActions('tract-type', ['fetchTractTypes']),

    resetFilter () {
      this.filterBuilder = {
        status: [3],
        selectedForesters: [],
        selectedTractTypes: []
      }
      this.emitFilter()
    },

    emitFilter () {
      localStorage.setItem(LocalStorageKeys.TRACT_STATUS_FILTER, this.filterBuilder.status)
      this.$emit('filter-applied', this.filterBuilder)
      this.menuOpen = false
    },

    setContainerStyle () {
      return getMenuContainerStyle(window.innerWidth, window.innerHeight)
    }
  }
}
</script>
