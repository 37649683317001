<template>
  <v-data-table
      :items="cruiseData"
      :headers="headers"
      :hide-default-footer="cruiseData.length < 10"
      :footer-props="{ 'items-per-page-options': [10,25,50,100,-1] }"
      :items-per-page="10"
      dense
      :no-data-text="$t('noCruiseData')"
    >
      <template #item.pricePerTon="{ item }">
        <span>{{ formatMoney(item.pricePerTon) }}</span>
      </template>
      <template #item.cruisedValue="{ item }">
        <span>{{ formatMoney(item.cruisedValue) }}</span>
      </template>
      <template v-if="!hideCrud" #item.actions="{ item }">
        <Icon
        v-if="!hideCrud && !item?.hideCrud"
        icon="mdi-pencil"
        iconColor="success"
        dataTestId="cruise-edit-button"
        :tooltipText="$t('edit')"
        @icon-clicked="editCruiseData(item)"
        />
        <Icon
        v-if="!hideCrud && !item?.hideCrud"
        icon="mdi-delete-forever"
        iconColor="error"
        dataTestId="cruise-delete-button"
        :tooltipText="$t('delete')"
        @icon-clicked="deleteCruiseData(item)"
        />
      </template>
      <template #body.append v-if="cruiseData.length > 1">
        <tr class="font-weight-bold" style="border: solid thin;">
          <td class="text-left">{{$t('totals')}}</td>
          <td class="text-right">{{totals.harvestedTons}}</td>
          <td class="text-right">{{totals.adjustmentTons}}</td>
          <td class="text-right">{{totals.remainingTons}}</td>
          <td class="text-right">{{totals.expectedTons}}</td>
          <td class="text-right">---</td>
          <td class="text-right">{{formatMoney(totals.cruisedValue)}}</td>
          <td class="text-right"></td>
        </tr>
      </template>
    </v-data-table>
</template>

<script>
import CruiseDataHeaders from '@/headers/Composition'
import { mapGetters } from 'vuex'
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations'
import { cruiseDataTotals } from './CruiseDataTotals'

export default {
  name: 'CruiseDataTable',

  props: {
    hideCrud: {
      type: Boolean,
      required: false,
      default: false
    },
    propCruiseData: {
      type: Array,
      default: undefined
    }
  },

  components: {
    Icon: () => import('../../helper/Icon.vue')
  },

  computed: {
    ...mapGetters('tract-contents', ['allTractContents']),

    cruiseData () {
      return this.propCruiseData ?? this.allTractContents
    },

    totals () {
      return cruiseDataTotals(this.cruiseData)
    },

    headers () {
      const headers = CruiseDataHeaders.compositionHeaders(this.$i18n.locale)
      if (this.hideCrud) { return headers.slice(0, -1) }
      return headers
    }
  },

  methods: {
    formatMoney,
    tonStringFromPounds,

    deleteCruiseData (cruiseData) {
      this.$emit('delete', cruiseData)
    },

    editCruiseData (cruiseData) {
      this.$emit('edit', cruiseData)
    }
  }
}
</script>
