<template>
  <v-card flat>
    <v-container fluid>
      <!-- Activity registers -->
      <v-row v-if="register.activities.length > 0">
        <v-col cols="12">
          <span class="title">
            {{$t('activities')}}
          </span>
        </v-col>
        <v-col cols="12" class="mt-n6">
          <DataTable
          :items="register.activities"
          :headers="headers"
          :propDense="true"
          :hideHeader="true"
          :customCells="customRegisterCells"
          :actions="[]"
          >
            <template #weight="slotProps">
              {{ tonStringFromPounds(slotProps.item.weight) }}
            </template>
            <template #amount="{item}">
              <span>
                {{ formatMoney(item.amount) }}
              </span>
            </template>
            <template #footer>
              <tr></tr>
            </template>
          </DataTable>
        </v-col>
      </v-row>

      <!-- Corrections -->
      <v-row v-if="register.correctionFinancials.length > 0">
        <v-col cols="12">
          <span class="title">
            {{$t('corrections')}}
          </span>
        </v-col>
        <v-col cols="12" class="mt-n6">
          <DataTable
            :items.sync="register.correctionFinancials"
            :headers="correctionHeaders"
            :propDense="true"
            :hideHeader="true"
            :customCells="customCorrectionCells"
            :actions="[]"
          >
            <template #activity="{item}">
              <Icon
              margin="mt-n1 mr-1"
              icon="mdi-auto-fix"
              dataTestId="register-correction-icon"
              :small="true"
              :iconColor="item.correctionSide === 0 ? 'red' : 'green'"
              :tooltipColor="item.correctionSide === 0 ? 'red' : 'green'"
              :tooltipText="getCorrectionTooltipText(item)"
              />
              <span>{{item.activity}}</span>
            </template>
            <template #body.append>
              <tr class="font-weight-bold">
                <td v-for="(_, i) in Array.from({length: correctionHeaders.length - 1})" :key="`${register.payeeName}-${i}`"/>
                <td class="text-right">{{totalCorrected}}</td>
              </tr>
            </template>
          </DataTable>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { tonStringFromPounds, formatMoney } from '@/utils/NumericMutations'
import { registerHeaders, prCorrectionHeaders } from '@/headers/Cycles.js'
import { CorrectionTypes, CorrectionSide } from '@/utils/Enumerations.js'
import { customRegisterCells, customCorrectionCells } from './CustomRegisterCells.js'
export default {
  name: 'RegisterCard',

  props: {
    register: {
      type: Object,
      required: true
    },
    isByproduct: {
      type: Boolean,
      required: true
    }
  },

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    customRegisterCells,
    customCorrectionCells
  }),

  computed: {
    headers () {
      return registerHeaders(this.isByproduct)
    },

    correctionHeaders () {
      return prCorrectionHeaders({ isByproduct: this.isByproduct })
    },

    totalCorrected () {
      return formatMoney(this.register.correctionFinancials.reduce((total, curr) => total + curr.amount, 0))
    }
  },

  methods: {
    tonStringFromPounds,
    formatMoney,

    getCorrectionTooltipText ({ correctionType, correctionSide }) {
      return `${CorrectionTypes.find(ct => ct.value === correctionType).name}
              ======
              ${CorrectionSide.find(cs => cs.value === correctionSide).name}`
    }
  }
}
</script>
