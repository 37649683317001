<template>
  <div>
    <v-autocomplete
      v-model="selectedLoggers"
      :label="$t('loggers')"
      :items="accounts"
      data-testid="logger-complete"
      item-text="name"
      value="name"
      dense
      chips
      color="black"
      item-color="secondary"
      return-object
      multiple
    >
      <template #append>
        <v-chip v-for="tag in selectedTags" small :key="tag.tagId" close @click:close="tagClicked(tag)">
          {{tag.value}}
        </v-chip>
      </template>
      <template #append-outer>
        <v-icon @click="refreshAccounts" color="secondary">mdi-refresh</v-icon>
        <v-menu v-model="showMenu"
        offset-y
        :close-on-content-click="false"
        style="max-width: 600px">
          <template #activator="{on}">
            <v-icon color="secondary" v-on="on">mdi-filter</v-icon>
          </template>
          <v-list>
            <v-list-item @click="tagClicked(tag)" v-for="tag in tags" :key="tag.tagId">
              <v-list-item-title>
                {{tag.value}}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { SETTING_TYPES, SETTING_KEYS } from '@/utils/UserSettings.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'LoggerAutocomplete',

  props: {
    userSetting: { type: String, default: undefined },
    loggers: {
      type: Array
    }
  },

  data: () => ({
    selectedLoggers: [],
    accounts: [],
    showMenu: false,
    selectedTags: [],
    tags: []
  }),

  watch: {
    selectedLoggers (val) {
      this.$emit('loggers-mutated', val)
    },

    selectedTags (_) {
      this.setUserSettings()
      this.refreshAccounts()
    },

    accountsLoading (val) {
      if (!val) {
        this.accounts = this.allAccounts
        this.addPreselectedLoggers()
      }
    }
  },

  computed: {
    ...mapGetters('user-settings', ['userSettings']),
    ...mapGetters('account', ['allAccounts', 'accountsLoading'])
  },

  created () {
    this.getTags()
  },

  methods: {
    ...mapActions('account', ['fetchAccounts', 'getAccountsWithTags']),
    ...mapActions('tags', ['fetchTags']),
    ...mapMutations('user-settings', ['mutateUserSetting']),
    async refreshAccounts () {
      if (this.selectedTags.length > 0) {
        const response = await this.getAccountsWithTags(this.selectedTags)
        this.accounts = response.map(acc => {
          return {
            accountId: acc.accountId,
            name: acc.name,
            code: acc.code
          }
        })
        this.addPreselectedLoggers()
      } else {
        if (this.accountsLoading) {
          return
        }
        try {
          const accounts = await this.fetchAccounts()
          this.accounts = this.mapAccounts(accounts)
        } catch (e) {
          if (e.message === 'REQ_IN_FLIGHT') {
            this.accounts = this.mapAccounts(this.allAccounts)
          }
        }
      }
    },

    mapAccounts (accounts) {
      return accounts.map(a => ({
        accountId: a.accountId,
        name: a.name,
        code: a.code
      }))
    },

    async getTags () {
      const response = await this.fetchTags(0)
      this.tags = response
      this.checkUserSettings()
    },

    tagClicked (tag) {
      const tagIndex = this.selectedTags.findIndex(t => t.tagId === tag.tagId)
      if (tagIndex === -1) {
        this.selectedTags.push(tag)
      } else {
        this.selectedTags.splice(tagIndex, 1)
      }
    },

    checkUserSettings () {
      if (this.userSetting === 'accountLoggerAutocomplete') {
        this.selectedTags = this.tagsFromIds(this.userSettings[SETTING_TYPES.TRACT_FORM][SETTING_KEYS.ACCOUNT_AUTOCOMPLETE])
        if (this.loggers) {
          this.selectedLoggers = this.loggers
        } else {
          this.refreshAccounts()
        }
      } else {
        this.refreshAccounts()
      }
    },

    addPreselectedLoggers () {
      if (this.loggers) {
        this.loggers.forEach(logger => {
          if (!this.accounts.find(l => l.accountId === logger.accountId)) {
            this.accounts.unshift(logger)
          }
        })
      }
    },

    setUserSettings () {
      this.mutateUserSetting({
        type: SETTING_TYPES.TRACT_FORM,
        key: SETTING_KEYS.ACCOUNT_AUTOCOMPLETE,
        value: this.selectedTags.map(t => t.tagId)
      })
    },

    tagsFromIds (ids) {
      return (ids ?? []).map(id => this.tags.find(t => t.tagId === id)).filter(t => t !== undefined)
    }
  }
}
</script>
