<template>
<ShortcutWrapper :nShortcutRequiredClaim="UserClaims.GlobalAdmin" @n-shortcut="mutateProduct(null, 'create')">
  <v-container fluid data-testid="product-table">
    <v-dialog width="400px" v-model="deleting">
      <ConfirmDelete
        @close="close"
        v-if="deleting"
        :title="$t('product')"
        @delete="deleteItem"
        @cancel-delete="close"/>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="900">
      <ProductForm
        @close="close"
        @product-mutated="productMutated"
        v-if="editing || creating"
        :isEditing="editing"
        :propProduct="focusedProduct"
      />
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <material-card
        color="secondary"
        :title="$t('products')"
        :text="$t('productDescription')"
        full-width
        >
          <DataTable
          :items="allProducts"
          :headers="headers"
          :customCells="customTableCells"
          :actions="tableActions"
          :loading.sync="loading"
          @new-product="mutateProduct(null, 'create')"
          @refresh="refreshProducts"
          >
            <template #timber-type="{item}">
              <span>{{timberTypeFromInt(item.timberType)}}</span>
            </template>
            <template #wood-type="{item}">
              <span>{{woodTypeFromInt(item.woodType)}}</span>
            </template>
            <template #collect-pieces="{item}">
              <v-icon v-if="item.collectPieceInfo" color="success"
                >mdi-checkbox-marked-circle</v-icon
              >
            </template>
            <template #actions="{item}">
              <v-icon
                small
                class="mr-2"
                color="success"
                data-testid="product-edit-button"
                @click="mutateProduct(item, 'edit')"
                :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
                >mdi-pencil</v-icon
              >
              <v-icon
                small
                color="error"
                data-testid="product-delete-button"
                @click="mutateProduct(item, 'delete')"
                :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
                >mdi-delete-forever</v-icon
              >
            </template>
          </DataTable>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</ShortcutWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { WoodType, TimberType } from '@/utils/Enumerations.js'
import ProductHeaders from '@/headers/Product'
import { UserClaims } from '../../../utils/Enumerations'
import { userAssignedClaim } from '../../../utils/ClaimUtility'

export default {
  name: 'ProductSettings',

  components: {
    ConfirmDelete: () => import('../../helper/ConfirmDelete.vue'),
    ProductForm: () => import('./ProductForm.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue')
  },

  created () {
    this.refreshProducts()
  },

  computed: {
    ...mapGetters('product', ['allProducts']),

    headers () {
      return ProductHeaders.productCRUDHeaders(this.$i18n.locale)
    },

    tableActions () {
      return [
        {
          icon: 'mdi-refresh',
          text: this.$t('refresh'),
          actionName: 'refresh'
        },
        {
          icon: 'mdi-plus',
          text: this.$t('newProduct'),
          actionName: 'new-product',
          disabled: !userAssignedClaim(UserClaims.GlobalAdmin)
        }
      ]
    },
    customTableCells () {
      return [
        {
          slotName: 'collect-pieces',
          value: 'collectPieceInfo'
        },
        {
          slotName: 'wood-type',
          value: 'woodType'
        },
        {
          slotName: 'timber-type',
          value: 'timberType'
        },
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    }
  },

  data: () => ({
    editing: false,
    dialog: false,
    deleting: false,
    creating: false,
    loading: false,
    focusedProduct: {},
    UserClaims
  }),

  watch: {
    deleting (val) {
      if (!val) {
        this.refreshProducts()
      }
    },

    dialog (val) {
      if (!val) {
        this.close()
      }
    }
  },

  methods: {
    ...mapActions('product', ['fetchProducts', 'deleteProduct']),
    woodTypeFromInt: (x) => WoodType.fromInt(x),
    timberTypeFromInt: (x) => TimberType.fromInt(x),
    userAssignedClaim,
    refreshProducts () {
      this.loading = true
      const requestObj = {
        contractId: undefined,
        includeProduction: true
      }
      this.fetchProducts(requestObj)
        .finally(() => {
          this.loading = false
        })
    },

    mutateProduct (product, mutationType) {
      this.focusedProduct = Object.assign({}, product)

      switch (mutationType) {
        case 'delete':
          this.deleting = true
          break
        case 'edit':
          this.editing = true
          this.dialog = true
          break
        case 'create':
          this.focusedProduct = {}
          this.creating = true
          this.dialog = true
          break
        default:
          this.close()
          break
      }
    },

    async deleteItem () {
      await this.deleteProduct(this.focusedProduct.productId)
      this.productMutated()
    },

    productMutated () {
      this.refreshProducts()
      this.close()
    },

    close () {
      this.deleting = false
      this.editing = false
      this.creating = false
      this.dialog = false
      this.focusedProduct = {}
    }
  }
}
</script>
