<template>
<ShortcutWrapper :nShortcutRequiredClaim="UserClaims.GlobalAdmin" @n-shortcut="createTag">
  <v-row dense data-testid="account-table">
    <v-col md="12">
      <material-card
      :title="title"
      full-width
      :text="$t('tagsSubtitle')">
        <DataTable
        :loading.sync="loading"
        :items="tags"
        :actions="tableActions"
        :showDense="false"
        @create="createTag"
        @refresh="refreshTags"
        :customCells="customCells"
        :headers="headers">
          <template #custom-controls>
            <v-select
            style="max-width: 200px;"
            :items="areas"
            class="ml-2"
            item-color="secondary"
            v-model="selectedArea"
            item-text="name"
            return-object
            data-testid="tag-area-name"
            :label="$t('tagType')"
            />
          </template>
          <template #actions="{item}">
            <Icon
            icon="mdi-pencil"
            iconColor="success"
            dataTestId="tag-setting-edit-button"
            :tooltipText="$t('edit')"
            @icon-clicked="editTag(item)"
            :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"/>
          </template>
        </DataTable>
        <v-dialog v-model="formDialog" width="600px">
          <TagForm
            v-if="formDialog"
            :propTag="focusedTag"
            :propTagArea="selectedArea"
            @tag-mutated="refreshTags"
            @close="formDialog = false"/>
        </v-dialog>
        <v-dialog width="400px" v-model="deleteDialog">
          <ConfirmDelete
            @close="deleteDialog = false"
            v-if="deleteDialog"
            :title="$t('tag')"
            @delete="confirmDeleteTag"
            @cancel-delete="deleteDialog = false"/>
        </v-dialog>
      </material-card>
    </v-col>
  </v-row>
</ShortcutWrapper>
</template>

<script>
import { tagHeaders } from '@/headers/Tags.js'
import { mapActions } from 'vuex'
import { TagArea, UserClaims } from '../../../utils/Enumerations.js'
import { userAssignedClaim } from '../../../utils/ClaimUtility'
export default {
  name: 'TagSettings',

  components: {
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    TagForm: () => import('@/components/account/account-tags/TagForm.vue')
  },

  data: () => ({
    formDialog: false,
    deleteDialog: false,
    tags: [],
    focusedTag: undefined,
    loading: false,
    selectedArea: TagArea.Accounts,
    UserClaims
  }),

  watch: {
    async area () {
      await this.refreshTags()
    }
  },

  computed: {
    headers () {
      return tagHeaders(this.$i18n.locale)
    },
    customCells () {
      return [
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    },
    areas () {
      return TagArea.enums
    },
    area () {
      return this.selectedArea.value
    },
    title () {
      return this.$t('tagsTitle')
    },
    tableActions () {
      return [
        {
          actionName: 'refresh',
          icon: 'mdi-refresh',
          text: this.$t('refresh')
        },
        {
          actionName: 'create',
          icon: 'mdi-plus',
          text: this.$t('new'),
          disabled: !userAssignedClaim(UserClaims.GlobalAdmin)
        }
      ]
    }
  },

  created () {
    this.refreshTags()
  },

  methods: {
    ...mapActions('tags', ['fetchTags', 'deleteTag']),
    userAssignedClaim,
    async refreshTags () {
      this.formDialog = false
      this.loading = true
      try {
        const tags = await this.fetchTags(this.area)
        this.tags = tags
      } finally {
        this.loading = false
      }
    },

    showDeleteTag (tag) {
      this.focusedTag = tag
      this.deleteDialog = true
    },

    async confirmDeleteTag () {
      await this.deleteTag(this.focusedTag.tagId)
      this.refreshTags()
      this.focusedTag = undefined
      this.deleteDialog = false
    },

    editTag (tag) {
      this.focusedTag = tag
      this.formDialog = true
    },

    createTag () {
      this.focusedTag = undefined
      this.formDialog = true
    }
  }
}
</script>
