import { formatMoney, tonStringFromPounds } from '../utils/NumericMutations'
import { utcToLocalDate } from '../utils/DateFormatter'
import i18n from '../i18n'

export default class AuditEntry {
  constructor (auditEntry, formatTree) {
    if (auditEntry.Version !== '1') throw new Error(`unknown version ${auditEntry.Version} for audit entry`)

    const formatter = getComponentSegmentFormat(auditEntry.Component.split('/').map(decodeURIComponent), formatTree)
    this.rowKey = auditEntry.RowKey
    this.from = auditEntry.From !== undefined ? formatter.formatter(auditEntry.From) : auditEntry.From
    this.to = auditEntry.To !== undefined ? formatter.formatter(auditEntry.To) : auditEntry.To
    this.opType = opTypeLabel(auditEntry.OpType)
    this.modifiedAt = new Date(Date.parse(auditEntry.ModifiedAt))
    this.modifiedBy = auditEntry.ModifiedBy
    this.modifiedReason = auditEntry.ModifiedReason
    this.component = decodeURIComponent(auditEntry.Component)
    this.componentSegments = formatter.segments
  }

  static create (auditEntry, entityType) {
    return new AuditEntry(auditEntry, formatTreesV1[entityType])
  }
}

const opTypeLabel = type => {
  switch (type) {
    case 'create':
    case 'add':
      return i18n.t('add')
    case 'update':
      return i18n.t('change')
    case 'delete':
    case 'remove':
      return i18n.t('remove')
    default:
      return type
  }
}

const formatBuilders = {
  named: (label, children) => ({ label: i18n.t(label), repr: true, children }),
  composite: (label, children) => ({ label: i18n.t(label), children }),
  field: (label, format = undefined) => ({ label: i18n.t(label), format })
}

const formatters = {
  currency: formatMoney,
  miles: v => `${v} miles`,
  dateonly: d => utcToLocalDate(d),
  datetime: d => utcToLocalDate(d, 'MM/DD/YYYY hh:mm A'),
  yesno: b => b ? i18n.t('yes') : i18n.t('no'),
  rawPercentage: b => `${b}%`,
  feet: b => `${b}"`,
  lbsToTons: lbs => tonStringFromPounds(lbs, 3)
}

const buildFormatTree = builderFn => builderFn(formatBuilders.named, formatBuilders.composite, formatBuilders.field, formatters)

const formatTreesV1 = buildFormatTree((named, composite, field, fmt) => ({
  contract: {
    activity: named('activity', {
      activityDetail: named('detail', {
        cost: composite('cost', {
          rate: field('rate', fmt.currency)
        }),
        product: field('product')
      }),
      baseCost: composite('baseCost', {
        rate: field('rate', fmt.currency),
        payOn: field('payOn'),
        payBy: field('payBy')
      }),
      counterparty: field('counterparty'),
      activityTemplate: field('activityTemplate'),
      effectiveDate: field('effectiveDate', fmt.dateonly)
    }),
    distance: field('distance', fmt.miles),
    expiration: field('expirationDate', fmt.dateonly),
    effectiveDate: field('effectiveDate', fmt.dateonly),
    paused: field('paused', fmt.yesno),
    draft: field('draft', fmt.yesno),
    status: field('status'),
    approvalStatus: field('approvalStatus')
  },
  activityTemplate: {
    baseCost: composite('baseCost', {
      rate: field('rate', fmt.currency),
      payBy: field('payBy'),
      payOn: field('payOn')
    }),
    details: named('detail', {
      product: field('product'),
      rate: field('rate', fmt.currency)
    }),
    rateModifier: field('modifier'),
    costType: field('costType')
  },
  ticket: {
    inWeight: field('inWeight', fmt.lbsToTons),
    outWeight: field('outWeight', fmt.lbsToTons),
    defectWeight: field('defectWeight', fmt.lbsToTons),
    weighedInAt: field('weighedInAt', fmt.datetime),
    weighedOutAt: field('weighedOutAt', fmt.datetime),
    extTicketNumber1: field('extTicketNumber1'),
    extTicketNumber2: field('extTicketNumber2'),
    product: field('product'),
    contract: field('contract'),
    deck: field('deck'),
    transitStatus: field('transitStatus'),
    defects: named('defects', {
      quantity: field('quantity'),
      percentage: field('percentage', fmt.rawPercentage),
      diameter: field('diameter', fmt.feet),
      defectDefinition: field('defectDefinition', fmt.feet)
    })
  },
  tract: {
    tractCode: field('tractCode'),
    tractName: field('tractName'),
    cost: field('cost', fmt.currency),
    contractAccount: field('contractAccount'),
    hauler: field('hauler'),
    tractCertifications: field('tractCertifications'),
    defaultLogger: field('defaultLogger'),
    supplier: field('supplier'),
    consultingForester: field('consultingForester'),
    forester: field('forester'),
    tractType: field('tractType'),
    landowner: field('landOwner'),
    setting: field('setting'),
    purchaser: field('purchaser'),
    purchaseDate: field('purchaseDate', fmt.dateonly),
    harvestStartDate: field('harvestStart', fmt.dateonly),
    harvestEndDate: field('harvestEnd', fmt.dateonly),
    tractLogger: field('loggers'),
    countrySubdivision: field('state'),
    countrySecondarySubdivision: field('county'),
    mapAndParcelNumber: field('mapAndParcelNumber'),
    acres: field('acres'),
    latitude: field('latitude'),
    longitude: field('longitude'),
    tractContents: named('cruise', {
      product: field('product'),
      pricePerTon: field('pricePerTon', fmt.currency),
      adjustment: field('adjustment', fmt.lbsToTons),
      expected: field('expected', fmt.lbsToTons)
    })
  },
  defectDefinition: {
    name: field('name'),
    defectLbs: field('defectTons', fmt.lbsToTons),
    defaultPercentage: field('defaultPercentage'),
    category: field('category')
  },
  diameterWeights: {
    diameterWeights: field('diameterWeights')
  }
}))

const getComponentSegmentFormat = (segments, formatTree) => {
  const fmt = formatComponentSegments(segments, formatTree)
  if (fmt === undefined) {
    console.warn(`Could not get formatting information for the following component string (check the audit data format tree):\n${segments.map(s => `'${s}'`).join(' > ')}`)
    return { formatter: s => s, segments }
  }
  return fmt
}

const formatComponentSegments = (segments, formatTree) => {
  if (segments.length === 0) {
    return {
      segments: [],
      formatter: undefined
    }
  }

  const fmt = formatTree?.[segments[0]]
  if (fmt === undefined) return undefined

  const seg = (fmt.repr)
    ? [fmt.label, segments[1]]
    : [fmt.label]

  const remainingFormat = formatComponentSegments(segments.slice(fmt.repr ? 2 : 1), fmt.children)
  if (remainingFormat === undefined) return remainingFormat
  return {
    formatter: remainingFormat.formatter ?? fmt.format ?? (s => s),
    segments: [...seg, ...remainingFormat.segments]
  }
}
