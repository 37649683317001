<template>
  <v-col cols="auto">
    <v-row dense>
        <v-col cols="auto">
          <v-select
          v-model="selectedFiscalYear"
          :items="fiscalYears"
          :label="$t('fiscalYear')"
          :menu-props="{ bottom: true, offsetY: true }"
          item-value="fiscalYearId"
          color="secondary"
          class="select-field-width"
          return-object
          outlined
          @change="fiscalYearSelected"
          >
            <template #selection="{item}">
              <Icon
                dataTestId="fiscal-year-status"
                :icon="getFiscalYearStatus(item).icon"
                :tooltipText="getFiscalYearStatus(item).tooltipText"
                :small="false"
                :iconColor="getFiscalYearStatus(item).color"
                :tooltipColor="getFiscalYearStatus(item).color"
              />
              <span>{{getPeriodString(item)}}</span>
            </template>
            <template #item="{item}">
              <Icon
                dataTestId="fiscal-year-status"
                :icon="getFiscalYearStatus(item).icon"
                :tooltipText="getFiscalYearStatus(item).tooltipText"
                :small="false"
                :iconColor="getFiscalYearStatus(item).color"
                :tooltipColor="getFiscalYearStatus(item).color"
              />
              <span>{{getPeriodString(item)}}</span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="auto">
          <v-select
          v-model="selectedPayPeriod"
          :items="payPeriods"
          :label="$t('payPeriod')"
          :menu-props="{ bottom: true, offsetY: true }"
          color="secondary"
          class="select-field-width"
          item-value="payPeriodId"
          return-object
          outlined
          @change="payPeriodSelected"
          >
            <template #selection="{item}">
              <Icon
                dataTestId="fiscal-year-status"
                :icon="getPeriodStatus(item).icon"
                :tooltipText="getPeriodStatus(item).tooltipText"
                :small="false"
                :iconColor="getPeriodStatus(item).color"
                :tooltipColor="getPeriodStatus(item).color"
              />
              <span>{{getPeriodString(item)}}</span>
            </template>
            <template #item="{item}">
              <Icon
                dataTestId="fiscal-year-status"
                :icon="getPeriodStatus(item).icon"
                :tooltipText="getPeriodStatus(item).tooltipText"
                :small="false"
                :iconColor="getPeriodStatus(item).color"
                :tooltipColor="getPeriodStatus(item).color"
              />
              <span>{{getPeriodString(item)}}</span>
            </template>
          </v-select>
      </v-col>
        <v-col cols="auto">
          <v-autocomplete
          v-model="selectedExportBatch"
          :label="$t('exportBatch')"
          :items="exportBatches"
          :menu-props="{ bottom: true, offsetY: true }"
          class="select-field-width"
          item-text="label"
          item-value="exportBatchId"
          color="secondary"
          outlined
          return-object
          @change="exportBatchSelected"
          >
          </v-autocomplete>
      </v-col>
      <v-col cols="auto">
        <v-select
        v-model="currentCategory"
        :label="$t('accountingCategory')"
        :items="categories"
        :menu-props="{ bottom: true, offsetY: true }"
        class="select-category"
        color="secondary"
        item-text="name"
        return-object
        outlined
        @change="$emit('category-changed', currentCategory)"
        >
        </v-select>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { PayPeriodStatus, FiscalYearStatus, AccountingCategory } from '@/utils/Enumerations.js'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'PayPeriodSelector',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    fiscalYears: [],
    payPeriods: [],
    exportBatches: [],
    selectedFiscalYear: undefined,
    categories: AccountingCategory.enums,
    currentCategory: AccountingCategory.enums[0],
    selectedPayPeriod: undefined,
    selectedExportBatch: undefined
  }),

  computed: {
    ...mapGetters('pay-period', ['allPayPeriods']),
    ...mapGetters('fiscal-year', ['allFiscalYears']),
    ...mapGetters('export-batch', ['allExportBatches'])
  },

  watch: {
    allPayPeriods: {
      handler () {
        this.payPeriods = []

        const filteredPayPeriods = this.allPayPeriods
          .filter(pp => pp.status !== PayPeriodStatus.Inactive.value)
          .sort((a, b) => Date.parse(b.startDate) - Date.parse(a.startDate))

        this.payPeriods = filteredPayPeriods

        const payPeriod = filteredPayPeriods.find(pp => pp.payPeriodId === this.selectedPayPeriod?.payPeriodId) ?? filteredPayPeriods[0]
        this.payPeriodSelected(payPeriod)
      },
      deep: true
    },
    allFiscalYears: {
      handler () {
        const filteredFiscalYears = this.allFiscalYears
          .filter(fy => fy.status !== FiscalYearStatus.Inactive.value)
          .sort((a, b) => Date.parse(b.startDate) - Date.parse(a.startDate))

        this.fiscalYears = filteredFiscalYears
        this.fiscalYearSelected(filteredFiscalYears[0])
      },
      deep: true
    },
    allExportBatches: {
      handler () {
        this.exportBatches = []
        const sortedBatches = JSON.parse(JSON.stringify(this.allExportBatches)).sort((a, b) => Date.parse(b.exportDate) - Date.parse(a.exportDate))

        if (this.selectedPayPeriod.status !== PayPeriodStatus.Exported.value) {
          sortedBatches.unshift({
            exportBatchId: undefined,
            label: this.$t('unexported'),
            exportDate: undefined
          })
        } else if (sortedBatches.length === 0) {
          sortedBatches.unshift({
            exportBatchId: undefined,
            label: this.$t('noExportBatches'),
            exportDate: undefined
          })
        }

        this.exportBatches = sortedBatches

        if (!this.selectedExportBatch) {
          this.exportBatchSelected(sortedBatches[0])
        }
      },
      deep: true
    }
  },

  created () {
    this.getFiscalYears()
  },

  methods: {
    ...mapActions('fiscal-year', ['fetchFiscalYears']),
    ...mapActions('pay-period', ['fetchPayPeriods']),
    ...mapActions('export-batch', ['fetchExportBatches']),
    ...mapActions('settlements', ['setCurrentDateConfiguration', 'refreshModuleData']),
    utcToLocalDate,

    async getFiscalYears () {
      this.fetchFiscalYears()
    },

    getPayPeriodsForFiscalYear (fiscalYearId) {
      this.fetchPayPeriods(fiscalYearId)
    },

    getExportBatchesForPayPeriod (payPeriodId) {
      this.fetchExportBatches(payPeriodId)
    },

    fiscalYearSelected (fiscalYear) {
      this.selectedFiscalYear = fiscalYear
      this.getPayPeriodsForFiscalYear(fiscalYear.fiscalYearId)
    },

    payPeriodSelected (payPeriod) {
      this.selectedPayPeriod = payPeriod
      this.selectedExportBatch = null
      this.getExportBatchesForPayPeriod(payPeriod.payPeriodId)
    },

    exportBatchSelected (exportBatch) {
      if (!exportBatch) return
      this.selectedExportBatch = exportBatch
      this.setCurrentDateConfiguration({
        fiscalYear: this.selectedFiscalYear,
        payPeriod: this.selectedPayPeriod,
        exportBatch: this.selectedExportBatch
      })
    },

    getPeriodString (period) {
      const { startDate, endDate } = period
      const convertedEndDate = new Date(endDate)
      convertedEndDate.setDate(convertedEndDate.getDate() - 1)
      return `${utcToLocalDate(startDate)} - ${utcToLocalDate(convertedEndDate)}`
    },

    getExportBatchString ({ label = '', exportDate }, limit = 0) {
      if (limit > 0 && label.length > limit) return label === '' ? utcToLocalDate(exportDate) : `${label.slice(0, limit)}...`

      return label === '' ? utcToLocalDate(exportDate, 'L - LT') : label
    },

    getPeriodStatus (payPeriod) {
      switch (payPeriod.status) {
        case PayPeriodStatus.Closed.value:
          return {
            color: 'black',
            tooltipText: this.$t('closed'),
            icon: 'mdi-cancel'
          }
        case PayPeriodStatus.Open.value:
          return {
            color: 'green',
            tooltipText: this.$t('open'),
            icon: 'mdi-checkbox-marked-circle'
          }
        case PayPeriodStatus.Inactive.value:
          return {
            color: 'black',
            tooltipText: this.$t('inactive'),
            icon: 'mdi-circle-outline'
          }
        case PayPeriodStatus.Exported.value:
          return {
            color: 'black',
            tooltipText: this.$t('exported'),
            icon: 'mdi-export'
          }
      }
    },

    getFiscalYearStatus (fiscalYear) {
      switch (fiscalYear.status) {
        case FiscalYearStatus.Closed.value:
          return {
            color: 'black',
            tooltipText: this.$t('closed'),
            icon: 'mdi-cancel'
          }
        case FiscalYearStatus.Open.value:
          return {
            color: 'green',
            tooltipText: this.$t('open'),
            icon: 'mdi-checkbox-marked-circle'
          }
        case FiscalYearStatus.Inactive.value:
          return {
            color: 'black',
            tooltipText: this.$t('inactive'),
            icon: 'mdi-circle-outline'
          }
      }
    }
  }
}
</script>

<style scoped>
  .select-field-width {
    width: 280px;
  }

  .select-category {
    width: 180px;
  }
</style>
