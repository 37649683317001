import i18n from '@/i18n'
import { formatMoney, tonStringFromPounds } from '../utils/NumericMutations'
import { DataTableColumn, ColumnSet } from '@/model/DataTableColumn.js'
import { sortSettlementInlineRates } from '../utils/SortHelpers'

const ifn = (condition, value) => condition ? value : undefined

export function prAdvanceHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('title'),
      value: 'note'
    },
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    }
  ]
}

export function prRecoveryHeaders (isExportBatch) {
  return [
    {
      sortable: true,
      text: i18n.t('title'),
      value: 'note',
      align: 'left',
      width: '50%'
    },
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset'
    },
    {
      sortable: true,
      text: i18n.t('balance'),
      value: 'balance',
      align: 'right'
    },
    {
      sortable: true,
      text: isExportBatch ? i18n.t('recoveredInBatch') : i18n.t('recoveries'),
      value: 'recovered',
      align: 'right'
    }
  ]
}

export function paymentRegisterHeaders (isByproduct = false) {
  return [
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activityName',
      align: 'left',
      width: '20%'
    },

    ifn(!isByproduct, {
      sortable: true,
      text: i18n.t('tract'),
      value: 'tractName'
    }),

    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset'
    },
    {
      sortable: true,
      text: i18n.t('netTons'),
      value: 'weight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('amountGross'),
      value: 'payout',
      align: 'right'
    }
  ].filter(f => f !== undefined)
}

export function bpPaymentRegisterHeaders (locale) {
  return [
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activityName',
      align: 'left',
      width: '20%'
    },
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset'
    },
    {
      sortable: true,
      text: i18n.t('netTons'),
      value: 'weight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('amountGross'),
      value: 'payout',
      align: 'right'
    }
  ].filter(h => h !== undefined)
}

export function bpReceivablesByPayerHeaders (locale) {
  return [
    {
      sortable: true,
      text: i18n.t('payer'),
      value: 'counterpartyName',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('loads'),
      value: 'loads',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('netTons'),
      value: 'netTons',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('grossTons'),
      value: 'grossTons',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('averageRateGross'),
      value: 'averageGrossRate',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('averageRateNet'),
      value: 'averageNetRate',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('grossAmount'),
      value: 'amount',
      align: 'right'
    }
  ]
}

export function receivableRegisterHeaders (isByproduct = false) {
  return [
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activityName',
      align: 'left',
      width: '20%'
    },

    ifn(!isByproduct, {
      sortable: true,
      text: i18n.t('tract'),
      value: 'tractName'
    }),
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode'
    },
    {
      sortable: true,
      text: i18n.t('glCodeOffset'),
      value: 'glOffset'
    },
    {
      sortable: true,
      text: i18n.t('loads'),
      value: 'loadCount',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('netTons'),
      value: 'weight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    }
  ].filter(h => h !== undefined)
}

export function registerHeaders (isByproduct = false) {
  return [
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activityName',
      align: 'left',
      width: '20%'
    },
    ifn(!isByproduct, {
      sortable: true,
      text: i18n.t('tract'),
      value: 'tractName'
    }),
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode'
    },
    {
      sortable: true,
      text: i18n.t('glCodeOffset'),
      value: 'glOffset'
    },
    {
      sortable: true,
      text: i18n.t('netTons'),
      value: 'weight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    }
  ].filter(h => h !== undefined)
}

export function tractProductionHeaders (locale) {
  return [
    {
      sortable: true,
      text: i18n.t('product'),
      value: 'productName',
      width: '20%'
    },
    {
      sortable: true,
      text: i18n.t('rateSlashTon'),
      value: 'rate',
      align: 'right',
      width: '20%'
    },
    {
      sortable: true,
      text: i18n.t('loads'),
      value: 'loads',
      align: 'right',
      width: '20%'
    },
    {
      sortable: true,
      text: i18n.t('netTons'),
      value: 'weight',
      align: 'right',
      width: '20%'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      align: 'right',
      value: 'amount',
      width: '20%'
    }
  ]
}

export function tractSummaryHeaders (locale) {
  return [
    {
      sortable: true,
      text: i18n.t('tract'),
      value: 'tractName'
    },
    {
      sortable: true,
      text: i18n.t('loads'),
      value: 'loads',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('inWeightTons'),
      value: 'inWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('outWeightTons'),
      value: 'outWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('defectTons'),
      value: 'defectWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('netTons'),
      value: 'netWeight',
      align: 'right'
    }
  ]
}

export function contractTicketHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('hashSign'),
      value: 'ticketNumber',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('extTicketNum1'),
      value: 'extTicketNumber1',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('extTicketNum2'),
      value: 'extTicketNumber2',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('product'),
      value: 'product'
    },
    {
      sortable: true,
      text: i18n.t('grossTons'),
      value: 'gross',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('defectTons'),
      value: 'defectWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('netTons'),
      value: 'netWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('recovered'),
      value: 'grossAmount',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    },
    {
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    }
  ]
}

export function ticketRegisterHeaders (locale) {
  return [
    {
      sortable: true,
      text: i18n.t('hashSign'),
      value: 'ticketNumber',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('truck'),
      value: 'truck'
    },
    {
      sortable: true,
      text: i18n.t('product'),
      value: 'productName'
    },
    {
      sortable: true,
      text: i18n.t('weighedInAt'),
      value: 'weighedInAt',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('weighedOutAt'),
      value: 'weighedOutAt',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('inWeightTons'),
      value: 'inWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('outWeightTons'),
      value: 'outWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('defectTons'),
      value: 'defectWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('netTons'),
      value: 'netWeight',
      align: 'right'
    }
  ]
}

export function prCorrectionHeaders ({ isByproduct = false, includeGross = false }) {
  return [
    {
      text: i18n.t('activity'),
      value: 'activity',
      align: 'left'
    },

    ifn(!isByproduct, {
      sortable: true,
      text: i18n.t('tract'),
      value: 'tract'
    }),
    ifn(isByproduct, {
      sortable: true,
      text: i18n.t('fromAccount'),
      value: 'fromAccount',
      align: 'left'
    }),

    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset'
    },
    {
      sortable: true,
      text: i18n.t('netWeightTons'),
      value: 'netWeight',
      align: 'right',
      format: tonStringFromPounds
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right',
      format: formatMoney
    },
    ifn(includeGross, {
      sortable: true,
      text: i18n.t('gross'),
      value: 'grossAmount',
      align: 'right',
      format: formatMoney
    })
  ].filter(h => h !== undefined)
}

export function productSalesAnalysisHeaders (locale) {
  return [
    {
      text: i18n.t('product'),
      value: 'productName'
    },
    {
      sortable: true,
      text: i18n.t('loads'),
      value: 'loads',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('netTons'),
      value: 'netTons',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('totalRevenue'),
      value: 'totalRevenue',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('revenuePerTon'),
      value: 'revenuePerTon',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('productionCost'),
      value: 'productionCost',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('stumpageCost'),
      value: 'stumpageCost',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('totalCost'),
      value: 'totalCost',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('costPerTon'),
      value: 'costPerTon',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('grossProfit'),
      value: 'grossProfit',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('grossProfitPerTon'),
      value: 'grossProfitPerTon',
      align: 'right'
    }
  ]
}

export function settlementsByContractHeaders (locale) {
  return [
    {
      sortable: true,
      text: i18n.t('account'),
      value: 'contractInfo.accountName',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('tract'),
      value: 'contractInfo.tractName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('destination'),
      value: 'contractInfo.destinationName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('setting'),
      value: 'contractInfo.settingName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('loads'),
      value: 'contractInfo.loads',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('netTons'),
      value: 'contractInfo.netTons',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('grossTons'),
      value: 'contractInfo.grossTons',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('rate'),
      value: 'rate',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('grossAmount'),
      value: 'contractInfo.grossAmount',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'contractInfo.amount',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('status'),
      value: 'contractInfo.status',
      align: 'center'
    },
    {
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    }
  ]
}

export function settlementsByPayeeHeaders () {
  const headers = [
    new DataTableColumn({
      id: 1,
      sortable: true,
      text: i18n.t('payee'),
      value: 'payeeName',
      align: 'left'
    }),
    new DataTableColumn({
      id: 2,
      sortable: true,
      text: i18n.t('loads'),
      value: 'loads',
      align: 'right'
    }),
    new DataTableColumn({
      id: 3,
      sortable: true,
      text: i18n.t('netTons'),
      value: 'netTons',
      align: 'right'
    }),
    new DataTableColumn({
      id: 4,
      sortable: true,
      text: i18n.t('grossTons'),
      value: 'grossTons',
      align: 'right'
    }),
    new DataTableColumn({
      id: 5,
      sortable: true,
      text: i18n.t('rate'),
      value: 'rate',
      align: 'right',
      sort: sortSettlementInlineRates
    }),
    new DataTableColumn({
      id: 6,
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    }),
    new DataTableColumn({
      id: 7,
      sortable: true,
      text: i18n.t('grossAmount'),
      value: 'grossAmount',
      align: 'right'
    }),
    new DataTableColumn({
      id: 8,
      sortable: true,
      text: i18n.t('status'),
      value: 'status',
      align: 'center'
    }),
    new DataTableColumn({
      id: 9,
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    })
  ]

  return new ColumnSet({
    columns: headers,
    setName: 'settlementsByPayeeHeader'
  })
}

export function settlementProductHeaders (locale) {
  return [
    {
      sortable: true,
      text: i18n.t('product'),
      value: 'product',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('loadCount'),
      value: 'loadCount',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('grossTons'),
      value: 'grossTons',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('netTons'),
      value: 'netTons',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('payOnTons'),
      value: 'payOnTons',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('rate'),
      value: 'averageRate',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('recovered'),
      value: 'recovered',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'payout',
      align: 'right'
    }
  ]
}

export function tractPaymentDialogHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('title'),
      value: 'paymentNote',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('tract'),
      value: 'tractName',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activityName',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    }
  ]
}

export function accountPaymentDialogHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('title'),
      value: 'paymentNote',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activityName',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    }
  ]
}

export function recoveryDialogHeaders (isExportBatch) {
  return [
    {
      sortable: true,
      text: i18n.t('entity'),
      value: 'businessEntityName',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('title'),
      value: 'note',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('type'),
      value: 'type',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('rate'),
      value: 'rate',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('previouslyRecovered'),
      value: 'previouslyRecovered',
      align: 'right'
    },
    {
      sortable: true,
      text: isExportBatch ? i18n.t('recoveredInBatch') : i18n.t('recoveries'),
      value: 'recovered',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('balance'),
      value: 'balance',
      align: 'right'
    }
  ]
}

export function advanceDialogHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('entity'),
      value: 'businessEntityName',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('title'),
      value: 'note',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('remaining'),
      value: 'remainingBalance',
      align: 'right'
    }
  ]
}

export function rateVerificationGroupingKeys (includePayee = true) {
  return [
    {
      id: 0,
      sortable: true,
      text: i18n.t('activity'),
      value: 'activity',
      align: 'left'
    },
    {
      id: 1,
      sortable: true,
      text: i18n.t('product'),
      value: 'product',
      align: 'left'
    },
    ifn(includePayee, {
      id: 2,
      sortable: true,
      text: i18n.t('counterparty'),
      value: 'counterparty',
      align: 'left'
    }),
    {
      id: 3,
      sortable: true,
      text: i18n.t('rate'),
      value: 'rate',
      align: 'right'
    }
  ].filter(h => h !== undefined)
}
