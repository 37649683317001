<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="auto">
        <Selector
        :label="$t('entities')"
        class="mr-4"
        :items="entities"
        @item-selected="entitySelected"
        minWidth="150px"
        style="max-width:360px"
        itemText="name"/>
      </v-col>
      <v-col class="fill-width">
        <v-row style="max-width: 650px; min-width: 320px;">
          <v-col>
            <DatePicker
            dataTestId="standingTimberDate"
            :dateLabel="$t('asOf')"
            @date-picked="dateChosen"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn @click="refreshTracts" :disabled="applyButtonDisabled" class="secondary">
              {{$t('apply')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer style="max-width: 20px;"/>
      <v-col cols="auto" class="mb-6">
        <ColumnAdjuster
        :propColumnSet.sync="columnSet"
        @new-columns-selected="newColumnsSelected"
        />
        <Icon
        icon="mdi-file-delimited-outline"
        dataTestId="timber-download-button"
        :tooltipText="$t('downloadCSV')"
        @icon-clicked="downloadCSV"
        :small="false"/>
        <Icon
        icon="mdi-refresh"
        dataTestId="refresh-icon-standing-timber"
        :tooltipText="$t('refresh')"
        @icon-clicked="refreshTracts"
        :small="false"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="auto">
        <v-card flat :loading="tractsLoading || entitiesLoading" :style="totalsStyle">
          <v-card-title>
            {{$t('totals')}}
          </v-card-title>
          <v-card-text class="font-weight-bold">
            <v-row dense>
              <v-col>{{$t('expectedTons')}}</v-col>
              <v-col class="text-right">{{numberWithCommas(totals.expected, 3)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('harvestedTons')}}</v-col>
              <v-col class="text-right">{{numberWithCommas(totals.harvested, 3)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('remainingTons')}}</v-col>
              <v-col class="text-right">{{numberWithCommas(totals.remaining, 3)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('overcutTons')}}</v-col>
              <v-col class="text-right">{{numberWithCommas(totals.overcut, 3)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('cruisedValue')}}</v-col>
              <v-col class="text-right">{{formatMoney(totals.cruisedValue)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('cost')}}</v-col>
              <v-col class="text-right">{{formatMoney(totals.tractCost)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('stumpageValue')}}</v-col>
              <v-col class="text-right">{{formatMoney(totals.stumpageValue)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('averageCutoutValue')}}</v-col>
              <v-col class="text-right">{{formatMoney(totals.cutoutValue)}}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="" class="fill-width" style="min-width: 0;">
        <DataTable
        :items="standingTimber"
        :customCells="customCells"
        :headers="headers"
        :loading="tractsLoading || entitiesLoading"
        @filtered-items="setTotals">
          <template #custom-controls>
            <StandingTimberFilter
            @input="applyFilters"
            />
          </template>
          <template #filters>
            <v-chip v-for="fk in activeFilters" :key="fk.key" color="black" class="white--text mr-1 mb-1" close @click:close="removeFilterField(fk.key)">
              <span style="font-weight: bold;">{{$t(fk.label)}}</span>: {{fk.text}}
            </v-chip>
          </template>
          <template #actions="{item}">
            <Icon
            icon="mdi-information-outline"
            :dataTestId="`timber-${item.name}`"
            :small="false"
            :tooltipText="$t('viewDetailedBreakdown')"
            @icon-clicked="openDetailDialog(item)"/>
          </template>
        </DataTable>
      </v-col>
      <v-col cols="12">
        <v-row justify="end">
          <span class="font-italic">{{$t('tractHarvestTicketStatusDenotation')}}</span>
        </v-row>
      </v-col>
    </v-row>
    <Dialog :stateId="dialogId" @dialog-closing="resetDialogs">
      <TractDetail
      v-if="focusedTractId"
      :tractId="focusedTractId"
      @edit-tract="editTract"
      :asOfDate="localToUTC(asOfDate)"
      :tabToOpen="1"/>
    </Dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import RouterJump from '@/model/RouterJump.js'
import standingTimberHeaders from '@/headers/Tract.js'
import { formatMoney, numberWithCommas } from '@/utils/NumericMutations.js'
import { TractStatus } from '../../../utils/Enumerations'
import { generateCsvString } from '../../../utils/CSVUtility'
import { localToUTC } from '@/utils/DateFormatter.js'
import { saveAs } from 'file-saver'
import moment from 'moment'
export default {
  name: 'StandingTimber',

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    TractDetail: () => import('@/components/tract/tract-detail/TractDetail.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    Selector: () => import('@/components/core/Selector.vue'),
    StandingTimberFilter: () => import('./StandingTimberFilter.vue'),
    ColumnAdjuster: () => import('@/components/core/ColumnAdjuster.vue')
  },

  data: () => ({
    dialogId: 'standing-timber',
    applyButtonDisabled: true,
    focusedTractId: null,
    selectedEntity: {
      name: 'All',
      businessEntityId: null
    },
    standingTimber: [],
    filters: [],
    asOfDate: new Date().toISOString(),
    totals: {
      expected: 0,
      harvested: 0,
      overcut: 0,
      cruisedValue: 0,
      tractCost: 0,
      stumpageValue: 0,
      cutoutValue: 0
    },
    columnSet: null
  }),

  computed: {
    ...mapGetters('user', ['businessEntities', 'entitiesLoading']),
    ...mapGetters('tract', ['tractsLoading', 'standingTimberFilter']),
    ...mapGetters('tract-type', ['allTractTypes']),
    headers () {
      return this.columnSet?.getColumns() ?? []
    },

    customCells () {
      return [{ slotName: 'actions', value: 'actions' }]
    },

    entities () {
      if (!this.businessEntities) return []
      const businessEntities = JSON.parse(JSON.stringify(this.businessEntities))
      businessEntities.unshift({
        name: 'All',
        businessEntityId: null
      })
      return businessEntities
    },

    activeFilters () {
      const keys = Object.keys(this.standingTimberFilter)
      return keys.map(key => ({
        key,
        label: key === 'tractForester' ? 'forester' : key,
        filter: this.standingTimberFilter[key],
        text: this.getFilterChipText(key, this.standingTimberFilter[key])
      })).filter(kf => kf.filter.length > 0)
    },

    totalsStyle () {
      return {
        minWidth: '240px',
        maxWidth: '320px',
        width: this.$vuetify.breakpoint.mdAndDown ? '100vw' : '15vw'
      }
    }
  },

  watch: {
    asOfDate () {
      this.applyButtonDisabled = false
    },

    standingTimberFilter: {
      handler () {
        this.refreshTracts()
      },
      deep: true
    }
  },

  async created () {
    this.initializeHeaders()
    await this.refreshTracts()
    await this.fetchAllBusinessEntities()
    this.setTotals()
  },

  methods: {
    ...mapActions('tract', ['fetchStandingTimber', 'setStandingTimberFilter']),
    ...mapActions('user', ['fetchAllBusinessEntities']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapMutations('global', ['setRouterJump']),
    formatMoney,
    numberWithCommas,
    generateCsvString,
    localToUTC,
    async entitySelected (entity) {
      if (JSON.stringify(entity) === JSON.stringify(this.selectedEntity)) return
      this.selectedEntity = entity
      await this.refreshTracts()
    },

    initializeHeaders () {
      this.columnSet = standingTimberHeaders.standingTimberHeaders(this.$i18n.locale)
    },

    dateChosen (date) {
      this.asOfDate = date
    },

    newColumnsSelected (columnSet) {
      this.columnSet = columnSet
    },

    async refreshTracts () {
      this.standingTimber = await this.fetchStandingTimber({
        asOfDate: localToUTC(this.asOfDate),
        businessEntityId: this.selectedEntity.businessEntityId,
        statuses: this.standingTimberFilter.status,
        tractTypes: this.standingTimberFilter.tractType,
        tractForesters: this.standingTimberFilter.tractForester
      })
      this.applyButtonDisabled = true
    },

    ignoreFilter (filter) {
      return (filter.tractType.length === 0 && filter.status.length === 0)
    },

    applyFilters (filters) {
      this.filters = filters
    },

    getFilterChipText (key, filter) {
      switch (key) {
        case 'status':
          return filter.map(f => TractStatus[f].name).join(', ')
        case 'tractType':
          return filter.map(tt => tt.name).join(', ')
        case 'tractForester':
          return filter.map(tf => tf.name).join(', ')
        default:
          return ''
      }
    },

    removeFilterField (fieldKey) {
      const f = {
        ...this.standingTimberFilter,
        [fieldKey]: []
      }
      this.setStandingTimberFilter(f)
    },

    downloadCSV () {
      const csvString = this.generateCsvString(this.standingTimber, [
        { label: 'tract', value: st => st.tractName },
        { label: 'type', value: st => st.tractType },
        { label: 'forester', value: st => st.tractForester },
        { label: 'cruisedValue', value: st => st.cruisedValue },
        { label: 'stumpageValue', value: st => st.stumpageValue },
        { label: 'cost', value: st => st.tractCost },
        { label: 'overcutTons', value: st => st.overcutTons },
        { label: 'averageCutoutValue', value: st => st.cutoutValue.toFixed(2) },
        { label: 'acres', value: st => st.tractAcres },
        { label: 'harvestedTons', value: st => st.harvestedTons },
        { label: 'expectedTons', value: st => st.expectedTons },
        { label: 'remainingTons', value: st => st.remainingTons }
      ])
      const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `Standing-Timber-${moment().format()}.csv`)
    },

    openDetailDialog (tract) {
      this.focusedTractId = tract.tractId
      this.openOrUpdateDialog({ id: this.dialogId, width: '90vw' })
    },

    editTract () {
      const routerJump = new RouterJump('Tickets', 'Tracts', {
        tractId: this.focusedTractId
      })
      this.setRouterJump(routerJump)
      this.$router.push('tracts')
    },

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.focusedTractId = null
    },

    setTotals (filteredTimber) {
      const timber = filteredTimber || this.standingTimber
      const totals = timber.reduce((totals, tract) => {
        totals.expected += tract.expectedTons
        totals.harvested += tract.harvestedTons
        totals.overcut += tract.overcutTons
        totals.cruisedValue += tract.cruisedValue
        totals.tractCost += tract.tractCost
        totals.stumpageValue += tract.stumpageValue
        totals.cutoutValue += tract.cutoutValue
        return totals
      }, {
        expected: 0,
        harvested: 0,
        overcut: 0,
        cruisedValue: 0,
        tractCost: 0,
        stumpageValue: 0,
        cutoutValue: 0
      })
      const remainingTons = totals.expected - totals.harvested
      totals.remaining = remainingTons < 0 ? 0 : remainingTons

      this.totals = totals
    }
  }

}
</script>
