import { LocalStorageKeys } from '../../../utils/constants'
import { LocalStorageActor } from '../../../utils/LocalStorageActor'

export default {
  standingTimberFilter: new LocalStorageActor({
    key: LocalStorageKeys.STANDING_TIMBER_FILTER,

    init: () => ({ status: [], tractType: [], tractForester: [] }),

    read: ({ status, tractType, tractForester } = {}, { context }) => ({
      status,
      tractType: tractType.map(id => context.rootGetters['tract-type/allTractTypes'].find(tt => tt.tractTypeId === id)),
      tractForester
    }),

    write: ({ status, tractType, tractForester }) => ({
      status,
      tractForester,
      tractType: tractType.map(tt => tt.tractTypeId)
    })
  }),

  committedTimberFilter: new LocalStorageActor({
    key: LocalStorageKeys.COMMITTED_TIMBER_FILTER,

    init: () => ({ status: [], tractType: [], tractForester: [] }),

    read: ({ status, tractType, tractForester } = {}, { context }) => ({
      status,
      tractType: tractType.map(id => context.rootGetters['tract-type/allTractTypes'].find(tt => tt.tractTypeId === id)),
      tractForester
    }),

    write: ({ status, tractType, tractForester }) => ({
      status,
      tractForester,
      tractType: tractType.map(tt => tt.tractTypeId)
    })
  })
}
