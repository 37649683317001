<template>
  <v-container fill-height fluid v-if="contractsLoading">
    <v-row align="center" justify="center">
      <v-progress-circular
      indeterminate
      color="secondary"
      />
    </v-row>
  </v-container>
  <v-card v-else :loading="templatesLoading">
    <v-card-title :class="contractColor">
      <span class="headline mr-4">{{title}}</span>
      <ActivityFormContractHeader :contract="contract" v-if="contract"/>
      <v-spacer />
      <BaseDialogActions hideRefresh />
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid">
        <v-row class="mt-4">
          <v-col cols="12" xs="12">
            <v-data-table
              id="add-with-bundle-table"
              item-key="activityBuilderId"
              v-if="activityBuilders"
              :items="activityBuilders"
              :loading.sync="templatesLoading"
              :headers="bundleHeaders"
              :expanded="expanded"
              :hide-default-footer="activityBuilders.length < 10"
            >
            <template #top>
              <v-container fluid>
                <v-row justify="end">
                  <Icon
                  :small="false"
                  icon="mdi-plus"
                  :tooltipText="$t('addByproductDetail')"
                  @icon-clicked="addProductClicked"
                  />
                </v-row>
              </v-container>
            </template>
              <template #item.activityStatus="{ item }">
                <Icon
                dataTestId="activity-status-icon"
                :icon="item.activityStatus === 1 ? 'mdi-cancel' : 'mdi-checkbox-marked-circle'"
                :iconColor="item.activityStatus === 1 ? 'black' : 'success'"
                :tooltipColor="item.activityStatus === 1 ? 'black' : 'success'"
                :tooltipText="item.activityStatus === 1 ? $t('inactive') : $t('active')"
                :small="false"
                />
              </template>
              <template #item.counterpartyName="{ item }">
                <span v-if="item.counterpartyName" :key="item.activityBuilderId">
                  {{ item.counterpartyName }}
                </span>
                <v-autocomplete
                  v-else
                  v-model="item.counterparty"
                  item-text="name"
                  item-value="name"
                  return-object
                  :placeholder="$t('account')"
                  :rules="[rules.required]"
                  :items="allAccounts"
                ></v-autocomplete>
              </template>
              <template #item.category="{ item }">
                {{ accountingCategoryFromInt(item.category) }}
              </template>
              <template #item.costBehavior="{ item }">
                {{ typeFromInt(item.costBehavior) }}
              </template>
              <template #item.glCode="{ item }">
                {{ item.glCode }}
              </template>
              <template #item.glOffset="{ item }">
                {{ !item.glOffset ? "N/A" : item.glOffset }}
              </template>
              <template #item.baseRate="{ item }">
                <MoneyTextField
                :key="item.activityBuilderId"
                :initialValue="item.baseRate"
                :itemId="item.activityBuilderId"
                :label="$t('rate')"
                dense
                @val-entered="baseRateEnteredForActivity"/>
              </template>
              <template #item.payOn="{ item }">
                {{ payOnFromInt(item.payOn)}}
              </template>
              <template #item.payBy="{ item }">
                {{ payByFromInt(item.payBy) }}
              </template>
              <template #item.modifier="{ item }">
                {{ modifierFromInt(item.modifier) }}
              </template>
              <template v-slot:expanded-item="{ headers, item: builder }">
                <td :colspan="headers.length + 2" :key="builder.activityBuilderId">
                  <v-container fluid>
                    <v-row align="center">
                      <div v-if="builder.products?.length === 0" style="width: 30px; height: 150px"/>
                      <v-col
                      v-for="product in builder.products"
                      :key="product.productId"
                      cols="12" xs="12" sm="12" md="4" lg="3" xl="2"
                      >
                        <v-card>
                          <v-card-title class="py-0 px-2">
                            <span style="font-size: 14px;" class="pa-1 ma-0">{{product.name}}</span>
                            <v-spacer></v-spacer>
                            <Icon
                              icon="mdi-close"
                              iconColor="grey"
                              tooltipColor="grey"
                              @icon-clicked="removeProductDetail(builder, product)"
                              :tooltipText="$t('removeByproductDetail')"
                            />
                          </v-card-title>
                          <v-card-text>
                            <MoneyTextField
                            :initialValue="product.baseRate"
                            :itemId="`${builder.activityBuilderId}-${product.productId}`"
                            :minimumValueConfig="{ min: builder.baseRate, error: $t('baseRateLowerThanActivity') }"
                            :label="$t('rate')"
                            :expectedValue="expectedValue(builder.activityTemplateId, product.productId)"
                            dense
                            @blur-entered="baseRateEnteredForProduct"/>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="auto" v-if="products.length > 0">
                        <v-menu>
                          <template #activator="{ on: menu }">
                            <v-tooltip bottom color="grey">
                              <template #activator="{ on: tooltip }">
                                <v-icon
                                v-on="{ ...menu, ...tooltip }"
                                v-show="missingProducts(builder, products).length > 0"
                                height="24"
                                v-if="products.length > 0"
                                >
                                  mdi-plus
                                </v-icon>
                              </template>
                              <span class="subtitle-1 white--text">{{$t('addByproductDetail')}}</span>
                            </v-tooltip>
                          </template>
                          <v-list>
                            <v-list-item
                            v-for="product in missingProducts(builder, products)"
                            :key="product.productId"
                            link
                            @click="addProductDetail(builder, product)">
                              {{product.name}}
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn data-testid="cancel-bundle" text color="black" @click="$emit('close')">{{$t('cancel')}}</v-btn>
      <v-spacer/>
      <v-btn data-testid="submit-bundle" :class="contractColor" :disabled="!valid" @click="saveBtnClicked">{{ $t('submit')}}</v-btn>
    </v-card-actions>
    <Dialog :stateId="dialogId" maxWidth="400">
      <v-card>
        <v-card-title :class="contractColor">
          <v-row>
            <v-col>
              <span class="headline mr-4">{{$t('newByproductDetail')}}</span>
            </v-col>
            <v-col cols="auto">
              <BaseDialogActions hideRefresh hideFullscreen />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <ProductSelector
            :includeByproducts="true"
            :includeLogsProducts="false"
            @products-selected="productsSelected"
            :excludeProductIds="products.map(p => p.productId)"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-0">
          <v-row no-gutters justify="end">
            <v-btn :color="contractColor" @click="addProductSubmitted">{{$t('add')}}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </Dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { PayOn, PayBy, AccountingCategory, ActivityModifier, CostType, TemplateAccountingCategory } from '@/utils/Enumerations'
import ActivityTemplateHeaders from '@/headers/Activity'
import fieldRules from '@/utils/rules.js'
import { colorClassForContractMode } from '@/utils/componentHelpers.js'
import { formatMoney } from '@/utils/NumericMutations'
import { uniqueDialogId } from '@/utils/componentHelpers'

export default {
  name: 'AddWithBundleDialog',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    ActivityFormContractHeader: () => import('@/components/activity/ActivityFormContractHeader.vue'),
    MoneyTextField: () => import('@/components/helper/MoneyTextField'),
    Icon: () => import('@/components/helper/Icon.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    ProductSelector: () => import('@/components/tract/tract-form/ProductSelector.vue')
  },

  props: {
    contractId: { type: Number, required: true },
    bundle: { type: Object, required: true },
    contractMode: { type: Object, default: () => ({ value: 0 }) }
  },

  data: () => ({
    rules: fieldRules.rules,
    contract: undefined,
    activityBuilders: undefined,
    headers: [
      {
        sortable: true,
        text: 'Name',
        value: 'name',
        align: 'left'
      }
    ],
    valid: false,
    dialogId: uniqueDialogId('add-product-dialog'),
    productSelectorState: undefined,
    products: []
  }),

  computed: {
    ...mapGetters('activity-templates', ['templatesLoading', 'allTemplateBundles']),
    ...mapGetters('contract', ['contractsLoading']),
    ...mapGetters('account', ['allAccounts']),
    bundleHeaders () {
      const bundleHeaders = [...ActivityTemplateHeaders.templateBundleHeaders(this.$i18n.locale)]
      bundleHeaders.pop()
      return bundleHeaders
    },
    title () {
      switch (this.contractMode.value) {
        case 0: return this.$t('editingContract')
        case 1: return this.$t('editingByproductContract')
      }
      return this.isByProducts ? this.$t('editingByproductContract') : this.$t('editingContract')
    },

    contractColor () {
      return colorClassForContractMode(this.contractMode.value)
    },

    expanded () {
      return this.products.length > 0 ? this.activityBuilders.filter(ab => !ab.limitToBaseRate) : []
    }
  },

  async created () {
    this.activityBuilders = [...this.bundle.activityBuilders.map(ab => ({ ...ab, products: [] }))]
    this.loading = true
    try {
      this.contract = await this.getContract(this.contractId)
    } finally {
      this.loading = false
    }
  },

  methods: {
    ...mapActions('contract', ['updateContract', 'getContract']),
    ...mapActions('activity', ['createActivity']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('activity-detail', ['createActivityDetail']),

    categoryToInt: (x) => TemplateAccountingCategory.toInt(x),
    modifierFromInt: (x) => ActivityModifier.fromInt(x),
    typeFromInt: (x) => CostType.fromInt(x),
    payByFromInt: (val) => PayBy.fromInt(val),
    payOnFromInt: (val) => PayOn.fromInt(val),
    accountingCategoryFromInt: (val) => AccountingCategory.fromInt(val),
    formatMoney,

    async saveBtnClicked () {
      if (this.areBuildersValid()) {
        for (const builder in this.activityBuilders) {
          const b = this.activityBuilders[builder]
          if (b.counterparty) b.counterpartyId = b.counterparty.accountId
          b.contractId = this.contractId
          b.baseCost = { payBy: undefined, payOn: undefined, rate: undefined }
          b.baseCost.payBy = b.payBy
          b.baseCost.payOn = b.payOn
          b.baseCost.rate = b.baseRate
          b.templateId = b.activityTemplateId
          b.contractorId = b.counterpartyId
          b.effectiveDate = this.contract.effectiveDate

          const result = await this.createActivity(b)

          if (result?.activityId && b.products?.length > 0) {
            for (const product of b.products) {
              const detailCreateRequest = {
                activityDetail: {
                  rate: product.baseRate
                },
                contractId: this.contractId,
                activityId: result.activityId,
                productId: product.productId
              }
              await this.createActivityDetail(detailCreateRequest)
            }
          }
        }
        this.$emit('added-activities-close')
      }
    },

    baseRateEnteredForActivity ({ amount, itemId }) {
      const ab = this.activityBuilders.find(a => a.activityBuilderId === itemId)

      if (ab) {
        ab.baseRate = amount
      }
    },

    areBuildersValid () {
      let isValid = true
      let accountsForSameActivity = []
      let accountDupsHash = {}
      let dupAccountId
      let dupActivityName
      /*
        Checks to see if an activity has duplicate counterparties. Gets a set of all builder names.
        Filter list of builders by name and maps to account id. Then hashes the account ids to find dups.
        If invalid sets gives an error message with account name and activity name.
      */
      const builderNames = new Set(this.activityBuilders.map(builder => builder.activityTemplateName))
      builderNames.forEach(name => {
        accountsForSameActivity = this.activityBuilders.filter(nestedBuilder => nestedBuilder.activityTemplateName === name)
        accountsForSameActivity = accountsForSameActivity.map(mapBuilder => mapBuilder.counterparty ? mapBuilder.counterparty.accountId : mapBuilder.counterpartyId)
        accountsForSameActivity.forEach(accountId => {
          if (accountDupsHash[accountId]) {
            dupActivityName = name
            dupAccountId = accountId
            isValid = false
          } else accountDupsHash[accountId] = true
        })
        accountsForSameActivity = []
        accountDupsHash = {}
      })
      if (!isValid) {
        const duplicateAccount = this.allAccounts.find(account => account.accountId === dupAccountId)
        this.setSnackError(`${dupActivityName} already has an activity using ${duplicateAccount.name} counterparty.`)
      }
      return isValid
    },

    addProductClicked () {
      this.openOrUpdateDialog(this.dialogId)
    },

    productsSelected (e) {
      this.productSelectorState = e
    },

    addProductSubmitted () {
      this.products = [...new Set([...this.products, ...this.productSelectorState])]
      this.activityBuilders.forEach(ab => {
        this.productSelectorState.forEach(p => {
          if (!ab.limitToBaseRate) this.addProductDetail(ab, p)
        })
      })
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    expectedValue (activityTemplateId, productId) {
      const activityStats = this.templateStats?.get(activityTemplateId)
      if (!productId) return activityStats?.baseRecentAverage
      return activityStats?.detailStats.find(p => p.productId === productId)?.recentAverage
    },

    missingProducts (builder, products) {
      return products.filter(p => !(builder?.products ?? []).some(bp => bp.productId === p.productId))
    },

    addProductDetail (builder, product) {
      const newDetail = {
        productId: product.productId,
        name: product.name,
        baseRate: builder.baseRate
      }

      builder.products.push(newDetail)
    },

    removeProductDetail (builder, detail) {
      const ab = this.activityBuilders.find(ab => ab.activityBuilderId === builder.activityBuilderId)
      if (!ab) return
      const productIndex = ab.products.findIndex(pd => pd.productId === detail.productId)
      if (productIndex === -1) return
      ab.products.splice(productIndex, 1)
    },

    baseRateEnteredForProduct ({ amount, itemId }) {
      let [activityBuilderId, productId] = itemId.split('-')
      activityBuilderId = parseInt(activityBuilderId)
      productId = parseInt(productId)

      const activityIndex = this.activityBuilders.findIndex(a => a.activityBuilderId === activityBuilderId)

      if (activityIndex !== -1) {
        const productIndex = this.activityBuilders[activityIndex].products.findIndex(d => d.productId === productId)

        if (productIndex !== -1) {
          this.activityBuilders[activityIndex].products[productIndex].baseRate = amount
        }
      }
    }
  }
}
</script>

<style>
#add-with-bundle-table>.v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  background-color:#eee;
  box-shadow: inset 0 0 0 0 #00000000;
  transition: .05s;
}

#add-with-bundle-table>.v-data-table__wrapper tbody tr.v-data-table__expanded__content:hover {
  background-color:#e0e0e0;
  box-shadow: inset 0 0 0 0 #00000000;
  transition: .05s;
}
</style>
