import ApiServer from '@/utils/api/ApiServer.js'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { SettlementKeys, SettlementSubKeys } from '@/components/settlements/SettlementKeys.js'
import store from '@/store'

export default {
  async refreshModuleData (context, setLoading = true) {
    try {
      if (setLoading) context.commit('setLoading', true)

      const { currentModuleKey, dateConfiguration, currentSubModuleKey } = context.getters

      if (!dateConfiguration.fiscalYear || !dateConfiguration.payPeriod) { return }

      let data

      switch (currentModuleKey) {
        case SettlementKeys.AccountsPayable:
          data = await refreshAccountsPayableModule(dateConfiguration, currentSubModuleKey)
          break
        case SettlementKeys.AccountsReceivable:
          const arRes = await refreshAccountsReceivableModule(dateConfiguration, currentSubModuleKey)
          data = arRes.data
          break
        case SettlementKeys.Accruals:
          const acRes = await refreshAccrualsModule(dateConfiguration, currentSubModuleKey)
          data = acRes.data
          break
      }

      if (currentSubModuleKey === SettlementSubKeys.AP.SettlementsByContract) {
        context.commit('initializeContractStatuses', {
          settlements: data.settlements,
          payPeriods: store.getters['pay-period/allPayPeriods']
        })
      }

      if (currentSubModuleKey === SettlementSubKeys.AP.SettlementsByPayee || currentSubModuleKey === SettlementSubKeys.AP.ByproductSettlementsByPayee) {
        context.commit('initializePayeeStatuses', {
          payees: data.payees,
          payPeriods: store.getters['pay-period/allPayPeriods']
        })
      }

      context.commit('setModuleData', {
        moduleKey: currentModuleKey,
        subModuleKey: currentSubModuleKey,
        data: data
      })
    } finally {
      context.commit('setLoading', false)
    }
  },

  async fetchSettlementsByPayee (context) {
    const { fiscalYear, payPeriod, exportBatch } = context.getters.dateConfiguration
    const isExportBatch = exportBatch.exportBatchId !== undefined
    const path = isExportBatch
      ? `payPeriods/${payPeriod.payPeriodId}/exportBatches/${exportBatch.exportBatchId}/settlementsByPayee`
      : `fiscalYears/${fiscalYear.fiscalYearId}/payPeriods/${payPeriod.payPeriodId}/settlementsByPayee`
    const url = ApiServer.urlFor(path, { version: isExportBatch ? 2 : 1 })
    const { data: settlementsByPayee } = await ApiServer.get(url)
    return settlementsByPayee
  },

  async fetchByproductSettlementsByPayee (context) {
    const { fiscalYear, payPeriod, exportBatch } = context.getters.dateConfiguration
    const isExportBatch = exportBatch.exportBatchId !== undefined
    const path = isExportBatch
      ? `payPeriods/${payPeriod.payPeriodId}/exportBatches/${exportBatch.exportBatchId}/ByproductSettlementsByPayee`
      : `fiscalYears/${fiscalYear.fiscalYearId}/payPeriods/${payPeriod.payPeriodId}/ByproductSettlementsByPayee`

    const options = { version: isExportBatch ? 2 : 1 }
    const url = ApiServer.urlFor(path, options)
    const response = await ApiServer.get(url)
    return response.data
  },

  async getTicketsForPeriod (context, tractIds) {
    const headers = {
      tractIds: tractIds
    }
    const { fiscalYear, payPeriod } = context.getters.dateConfiguration
    const path = `v2/fiscalYears/${fiscalYear.fiscalYearId}/payPeriods/${payPeriod.payPeriodId}/tickets`
    const response = await ApiServer.get(path, {
      headers: headers
    })
    return response.data
  },

  async getTractsForPeriod (context) {
    const { fiscalYear, payPeriod } = context.getters.dateConfiguration
    const path = `v2/fiscalYears/${fiscalYear.fiscalYearId}/payPeriods/${payPeriod.payPeriodId}/tracts`
    const response = await ApiServer.get(path)
    return response.data
  },

  setCurrentDateConfiguration (context, dateConfiguration) {
    context.commit('setDateConfiguration', dateConfiguration)
  },

  setCurrentModule (context, moduleConfiguration) {
    context.commit('setCurrentModule', moduleConfiguration)
  },

  resetModules (context) {
    const defaultModuleConfiguration = {
      moduleKey: SettlementKeys.AccountsPayable,
      subModuleKey: SettlementSubKeys.AP.ProductionSummary
    }
    context.commit('setCurrentModule', defaultModuleConfiguration)
    context.commit('resetModules')
  },

  async fetchSettlementCSV (_, { payPeriod, fiscalYear, exportBatch, type, isByproduct }) {
    const isExportBatch = exportBatch.exportBatchId !== undefined
    const path = isExportBatch ? `payPeriods/${payPeriod.payPeriodId}/exportBatches/${exportBatch.exportBatchId}/${type}` : `fiscalYears/${fiscalYear.fiscalYearId}/payPeriods/${payPeriod.payPeriodId}/${type}`
    const options = {
      version: isExportBatch ? 2 : 1,
      params: { byproduct: isByproduct }
    }
    const url = ApiServer.urlFor(path, options)
    const response = await ApiServer.get(url, { headers: { 'Content-Type': 'text/csv' } })
    generateCSV(response.data, type)
    return response.data
  },

  toggleContractStatus (context, contractId) {
    context.commit('toggleContractStatus', contractId)
  },

  togglePayeeStatus (context, payeeId, isByproduct) {
    context.commit('togglePayeeStatus', payeeId, isByproduct)
  },

  clearStaleSubModuleData (context, { isByproduct, allData }) {
    context.commit('clearStaleSubModuleData', { isByproduct: isByproduct, allData: allData })
  }
}

const refreshAccountsPayableModule = async (dateConfiguration, currentSubModuleKey) => {
  const { fiscalYear, payPeriod, exportBatch } = dateConfiguration
  const isExportBatch = exportBatch.exportBatchId !== undefined
  let path = isExportBatch
    ? `payPeriods/${payPeriod.payPeriodId}/exportBatches/${exportBatch.exportBatchId}/`
    : `fiscalYears/${fiscalYear.fiscalYearId}/payPeriods/${payPeriod.payPeriodId}/`

  const options = {
    version: isExportBatch ? 2 : 1,
    params: {
      byproduct: (
        currentSubModuleKey === SettlementSubKeys.AP.BPPaymentRegister ||
        currentSubModuleKey === SettlementSubKeys.AP.ByproductCorrections) ? true : undefined
    }
  }

  switch (currentSubModuleKey) {
    case SettlementSubKeys.AP.SettlementsByContract:
      path += 'settlementsByContract'
      break
    case SettlementSubKeys.AP.PaymentRegister:
      path += 'paymentRegister'
      break
    case SettlementSubKeys.AP.BPPaymentRegister:
      path += 'paymentRegister'
      break
    case SettlementSubKeys.AP.ProductionSummary:
      path += isExportBatch ? 'productionSummary' : 'producerProduction'
      break
    case SettlementSubKeys.AP.TicketRegister:
      path += 'ticketRegister'
      break
    case SettlementSubKeys.AP.SettlementsByPayee:
      path += 'settlementsByPayee'
      break
    case SettlementSubKeys.AP.TractPayments:
      path += 'tractPayments'
      break
    case SettlementSubKeys.AP.Advances:
      path += 'advances'
      options.version = 2
      break
    case SettlementSubKeys.AP.Corrections:
    case SettlementSubKeys.AP.ByproductCorrections:
      path += 'corrections'
      options.version = 2
      break
    case SettlementSubKeys.AP.ByproductSettlementsByPayee:
      path += 'byproductSettlementsByPayee'
      break
    case SettlementSubKeys.AP.AccountPayments:
      path += 'accountPayments'
      break
    case SettlementSubKeys.AP.MiscPayments:
      const [tractPayments, advances, accountPayments] = await Promise.all([
        fetchData(path + 'tractPayments', options),
        fetchData(path + 'advances', { ...options, version: 2 }),
        fetchData(path + 'accountPayments', options)
      ])
      return { tractPayments, advances, accountPayments }
  }

  return await fetchData(path, options)
}

const fetchData = async (path, options) => {
  const url = ApiServer.urlFor(path, options)
  return (await ApiServer.get(url)).data
}

const refreshAccountsReceivableModule = async (dateConfiguration, currentSubModuleKey) => {
  const { fiscalYear, payPeriod, exportBatch } = dateConfiguration
  const isExportBatch = exportBatch.exportBatchId !== undefined
  let path = isExportBatch
    ? `payPeriods/${payPeriod.payPeriodId}/exportBatches/${exportBatch.exportBatchId}/`
    : `fiscalYears/${fiscalYear.fiscalYearId}/payPeriods/${payPeriod.payPeriodId}/`

  const params = { byproduct: SettlementSubKeys.AR.BPReceivableRegister === currentSubModuleKey }
  switch (currentSubModuleKey) {
    case SettlementSubKeys.AR.ReceivableRegister:
      path += 'receivableRegister'
      break
    case SettlementSubKeys.AR.BPReceivableRegister:
      path += 'receivableRegister'
      break
    case SettlementSubKeys.AR.BPReceivablesByPayer:
      path += 'byproductARByCounterparty'
      break
    case SettlementSubKeys.AR.ProductionSummary:
      path += isExportBatch ? 'receivableSummary' : 'arProductionSummary'
      break
    case SettlementSubKeys.AR.SalesAnalysis:
      path += isExportBatch ? 'salesAnalysis' : 'arSalesAnalysis'
      const businessEntityId = store.getters['settlements/arSalesAnalysisBusinessEntity'].businessEntityId
      params.businessEntityId = businessEntityId
      break
  }

  const options = { version: isExportBatch ? 2 : 1, params: params }
  const url = ApiServer.urlFor(path, options)

  return ApiServer.get(url)
}

const refreshAccrualsModule = async (dateConfiguration, currentSubModuleKey) => {
  const { fiscalYear, payPeriod, exportBatch } = dateConfiguration
  const options = {
    version: exportBatch.exportBatchId ? 2 : 1,
    params: { byproduct: currentSubModuleKey === SettlementSubKeys.AC.BPRegister }
  }

  const path = exportBatch.exportBatchId
    ? `payPeriods/${payPeriod.payPeriodId}/exportBatches/${exportBatch.exportBatchId}/`
    : `fiscalYears/${fiscalYear.fiscalYearId}/payPeriods/${payPeriod.payPeriodId}/`

  const url = ApiServer.urlFor(`${path}journalEntryRegister`, options)
  return ApiServer.get(url)
}

const generateCSV = (csvData, name) => {
  const csvFile = new Blob([csvData], { type: 'text/csv' })
  const parsedName = getCSVName(name)
  const date = moment().format('MM-DD-YYYY')
  saveAs(csvFile, `${parsedName}-${date}.csv`)
}

const getCSVName = (name) => {
  switch (name) {
    case 'apcsvexport':
      return 'AP'
    case 'jecsvexport':
      return 'AC'
    case 'arcsvexport':
      return 'AR'
  }
}
