import messages from '@/lang'

export default {
  tractTypeHeaders (locale) {
    return [
      {
        sortable: true,
        text: messages[locale].name,
        value: 'name'
      },
      {
        sortable: true,
        text: messages[locale].category,
        value: 'category',
        align: 'center'
      },
      {
        sortable: false,
        text: messages[locale].actions,
        value: 'actions',
        align: 'center'
      }
    ]
  }
}
