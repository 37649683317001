import { formatMoney, tonStringFromPounds, formatPercentage } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { PayOn, PayBy, ActivityModifier, CorrectionTypes, CorrectionSide, RecoveryType } from '@/utils/Enumerations.js'
import { scatterToObject, padArrayTo } from '../base'
import i18n from '@/i18n'
import store from '@/store/index.js'
import { PdfLogoDimensions } from '../CompanyLogo.js'
import { DefectCategory } from '../Enumerations'
import moment from 'moment/moment'

// Cell border styles.
const noBorder = [false, false, false, false]
const topBorder = [false, true, false, false]
const bottomBorder = [false, false, false, true]

export function getHeader ({ logoAsDataUrl, logoTopMargin }) {
  const companyInfo = store.getters['user/companyInfo']
  const companyName = companyInfo.name ? companyInfo.name : ''
  const companyAddress = formatAddress(companyInfo)
  const companyPhoneNumber = companyInfo.mainContact && companyInfo.mainContact.phoneNumber ? companyInfo.mainContact.phoneNumber : ''
  const companyInfoSection = (logoAsDataUrl === undefined || logoTopMargin === undefined)
    ? [
      {
        table: {
          body: [
            [''],
            [{ text: companyName, alignment: 'left' }],
            [{ text: companyAddress, alignment: 'left', fontSize: 8 }],
            [{ text: companyPhoneNumber, alignment: 'left', fontSize: 8 }]
          ]
        },
        layout: 'noBorders'
      }
    ]
    : [
      {
        image: logoAsDataUrl,
        maxWidth: PdfLogoDimensions.LOGO_MAX_WIDTH,
        maxHeight: PdfLogoDimensions.LOGO_MAX_HEIGHT,
        marginTop: logoTopMargin,
        alignment: 'center'
      },
      {
        table: {
          body: [
            [''],
            [{ text: companyName, alignment: 'left' }],
            [{ text: companyAddress, alignment: 'left', fontSize: 8 }],
            [{ text: companyPhoneNumber, alignment: 'left', fontSize: 8 }]
          ]
        },
        layout: 'noBorders'
      }
    ]
  return {
    fontSize: 15,
    bold: true,
    marginLeft: 40,
    marginRight: 40,
    table: {
      widths: '*',
      body: [
        [
          {
            table: {
              body: [
                companyInfoSection
              ]
            },
            layout: 'noBorders'
          },
          {
            alignment: 'center',
            table: {
              widths: '*',
              body: [
                [''],
                [''],
                [{ text: 'Settlement Statement', fontSize: 15, alignment: 'center' }]
              ]
            },
            layout: 'noBorders'
          },
          { text: '' }
        ]
      ]
    },
    layout: 'noBorders'
  }
}

export function formatAddress (companyInfo) {
  if (companyInfo.address && companyInfo.address.addressLine1 && companyInfo.address.city && companyInfo.address.state && companyInfo.address.postalCode) {
    return companyInfo.address.addressLine1 + '\n' + companyInfo.address.city + ', ' + companyInfo.address.state + ' ' + companyInfo.address.postalCode
  } else {
    return ''
  }
}

export function getContractTable (contractInfo) {
  const tractCountyState = (subdivision, secondarySubdivision) => (secondarySubdivision || secondarySubdivision ? ` (${secondarySubdivision}, ${subdivision})` : '')

  const info = contractInfo

  const body = (contractInfo.type === 3 || contractInfo.type === 4 || contractInfo.type === 5)
    ? [
      (contractInfo.type === 4)
        ? [{ text: 'Type: ' }, { text: 'Transfer' }]
        : undefined,
      [{ text: 'Account: ' }, { text: info.accountName }],
      [{ text: 'From Account: ' }, { text: info.fromAccountName }],
      (info.distance > 0)
        ? [{ text: 'Destination: ' }, { text: `${info.destinationName} (${info.distance}mi.)` }]
        : [{ text: 'Destination: ' }, { text: `${info.destinationName}` }]
    ].filter(l => l !== undefined)
    : [
      [{ text: 'Account: ' }, { text: `${info.accountName}` }],
      [{ text: 'Tract: ' }, { text: `${info.tractName} ${tractCountyState(info.countrySubdivision, info.countrySecondarySubdivision)}` }],
      (info.distance > 0)
        ? [{ text: 'Destination: ' }, { text: `${info.destinationName} (${info.distance}mi.)` }]
        : [{ text: 'Destination: ' }, { text: `${info.destinationName}` }],
      [{ text: 'Setting: ' }, { text: `${info.settingName}` }],
      [{ text: 'Logger: ' }, { text: info.loggerName || 'N/A' }]
    ]

  return {
    table: {
      body
    },
    layout: 'noBorders'
  }
}

export function getPaymentRollupTable (paymentRollup, distance, contractType) {
  const paddedRow = padArrayTo.bind(this, 8, { text: '', border: noBorder })
  const rowPaddedWith = padArrayTo.bind(this, 8)
  const notUndefined = d => d !== undefined
  const withDefaults = defaults => r => ({ ...defaults, ...r })
  const includeRecoveries = contractType !== 4
  const keys = rowPaddedWith({ text: '' }, {
    start: [
      { text: 'Activity' },
      { text: 'Product' },
      { text: 'Loads', alignment: 'right' },
      { text: 'Unit', alignment: 'right' }
    ],
    end: [
      { text: 'Rate', alignment: 'right' },
      { text: 'Gross Payout', alignment: 'right' },
      includeRecoveries ? { text: 'Recovered', alignment: 'right' } : undefined,
      { text: 'Net Payout', alignment: 'right' }
    ].filter(notUndefined)
  }).map(withDefaults({ bold: true, border: bottomBorder }))
  const values = []
  const totalPayouts = paymentRollup.reduce((total, item) => {
    total.net += item.payout
    total.gross += item.grossPayout
    return total
  }, { net: 0, gross: 0 })
  paymentRollup.forEach(pr => {
    const rateText = pr.modifier === 0
      ? `${formatMoney(pr.averageRate)}`
      : `${formatMoney(pr.averageRate)} (${distance}mi.)`
    pr.productSummaries.forEach(ps => {
      values.push(paddedRow({
        start: [
          { text: pr.activityName },
          { text: ps.product },
          { text: ps.loadCount, alignment: 'right' },
          { text: getUnitTextForActivity({ payOn: pr.payOn, payBy: pr.payBy, ...ps }, distance, false), alignment: 'right' }
        ],
        end: [
          { text: formatMoney(ps.rate), alignment: 'right' },
          { text: formatMoney(ps.grossPayout), alignment: 'right' },
          includeRecoveries
            ? { text: formatMoney(Math.abs(ps.grossPayout - ps.payout)), alignment: 'right' }
            : undefined,
          { text: formatMoney(ps.payout), alignment: 'right' }
        ].filter(notUndefined)
      }).map(r => ({
        border: noBorder,
        ...r
      })))
    })

    const unitString = getUnitTextForActivity(pr, distance)

    values.push(paddedRow({
      start: [
        { text: pr.activityName },
        { text: 'Total' },
        { text: pr.loadCount, alignment: 'right' },
        { text: unitString, alignment: 'right' }
      ],
      end: [
        { text: rateText, alignment: 'right' },
        { text: formatMoney(pr.grossPayout), alignment: 'right' },
        (includeRecoveries)
          ? { text: formatMoney(Math.abs(pr.grossPayout - pr.payout)), alignment: 'right' }
          : undefined,
        { text: formatMoney(pr.payout), alignment: 'right' }
      ].filter(notUndefined)
    }).map(r => ({
      bold: true,
      border: bottomBorder,
      ...r
    })))
  })
  const footerRowStyling = { alignment: 'right', bold: true, fontSize: 9, border: topBorder }
  values.push(rowPaddedWith('', {
    start: [
      'Total'
    ],
    end: [
      formatMoney(totalPayouts.gross),
      (includeRecoveries)
        ? formatMoney(totalPayouts.gross - totalPayouts.net)
        : undefined,
      formatMoney(totalPayouts.net)
    ].filter(notUndefined)
  }).map((r, i) => ({
    text: r,
    ...footerRowStyling,
    alignment: i === 0 ? 'left' : 'right'
  })))
  return [
    {
      table: {
        widths: ['*', '*', 'auto', '*', '*', 'auto', 'auto', 'auto'],
        body: [
          [
            { text: 'Payment Detail', border: noBorder, fontSize: 15, bold: true },
            { text: '', border: noBorder },
            { text: '', border: noBorder },
            { text: '', border: noBorder },
            { text: '', border: noBorder },
            { text: '', border: noBorder },
            { text: '', border: noBorder },
            { text: '', border: noBorder }
          ],
          keys,
          ...values
        ]
      },
      style: 'nestedTable'
    }
  ]
}

export function getLoadSummaryTable (loadSummaries) {
  const body = [[
    { text: '#', bold: true, border: bottomBorder },
    { text: 'Delivered', bold: true, border: bottomBorder },
    { text: 'Activity', bold: true, border: bottomBorder },
    { text: 'Product', bold: true, border: bottomBorder },
    { text: 'Gross Tons', alignment: 'right', bold: true, border: bottomBorder },
    { text: 'Defect Tons', alignment: 'right', bold: true, border: bottomBorder },
    { text: 'Net Tons', alignment: 'right', bold: true, border: bottomBorder },
    { text: 'Pay On Tons', bold: true, alignment: 'right', border: bottomBorder },
    { text: 'Rate', bold: true, alignment: 'right', border: bottomBorder },
    { text: 'Recovered', bold: true, aligment: 'right', border: bottomBorder },
    { text: 'Amount', bold: true, alignment: 'right', border: bottomBorder }
  ]]

  const processedLoadSummaries = processLoadSummaries(loadSummaries)
  processedLoadSummaries.forEach(({ ticket, rowContent }, index) => {
    body.push(rowContent)

    const isLastRowForTicket = processedLoadSummaries[index + 1]?.ticket?.ticketId !== ticket?.ticketId
    if (ticket?.defects?.length > 0 && isLastRowForTicket) {
      body.push([
        { text: '', border: noBorder },
        { text: '', border: noBorder },
        { text: '', border: noBorder },
        { text: '', border: noBorder },
        { text: '', border: noBorder },
        loadDefectsTable(ticket),
        { text: '', border: noBorder },
        { text: '', border: noBorder },
        { text: '', border: noBorder },
        { text: '', border: noBorder },
        { text: '', border: noBorder }
      ])
    }
  })
  return [{
    table: {
      widths: '*',
      body: [
        [{ text: 'Ticket Summary', border: noBorder, bold: true, fontSize: 15 }],
        [{
          table: {
            headerRows: 1,
            widths: ['auto', 'auto', '*', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: body
          },
          layout: {
            fillColor: function (rowIndex) {
              return ((rowIndex + 1) % 2 === 0) ? '#EEEEEE' : null
            }
          },
          border: noBorder
        }]
      ]
    },
    style: 'nestedTable'
  }]
}

function processLoadSummaries (loadSummaries) {
  const processedLoadSummaries = []
  const totals = {
    grossTons: 0,
    defectTons: 0,
    netTons: 0,
    amount: 0,
    grossAmount: 0
  }
  loadSummaries.forEach(loadSummary => {
    totals.grossTons += loadSummary.inWeight - loadSummary.outWeight
    totals.defectTons += loadSummary.defectWeight
    totals.netTons += loadSummary.inWeight - loadSummary.outWeight - loadSummary.defectWeight

    loadSummary.mismanufacturedDefectWeight = (loadSummary.defects ?? [])
      .filter(d => d.category === DefectCategory.Mismanufactured.value)
      .reduce((acc, d) => acc + d.totalLBS, 0)
    loadSummary.natureDefectWeight = (loadSummary.defects ?? [])
      .filter(d => d.category === DefectCategory.Nature.value)
      .reduce((acc, d) => acc + d.totalLBS, 0)

    let ticketNumber = loadSummary.ticketNumber.toString()
    if (loadSummary.extTicketNumber1 !== '') {
      ticketNumber = `${ticketNumber} (${loadSummary.extTicketNumber1})`
    }
    if (loadSummary.extTicketNumber2 !== '') {
      ticketNumber = `${ticketNumber} (${loadSummary.extTicketNumber2})`
    }

    const hasMultipleActivities = loadSummary.paymentDetails.length > 1
    if (hasMultipleActivities) {
      processedLoadSummaries.push({
        ticket: loadSummary,
        rowContent: [
          { text: ticketNumber, border: noBorder, bold: true },
          { text: utcToLocalDate(loadSummary.weighedOutAt), border: noBorder, bold: true },
          { text: '', border: noBorder, bold: true },
          { text: loadSummary.product ? loadSummary.product : 'Other', border: noBorder, bold: true },
          { text: tonStringFromPounds(loadSummary.inWeight - loadSummary.outWeight), alignment: 'right', border: noBorder, bold: true },
          { text: tonStringFromPounds(loadSummary.defectWeight), alignment: 'right', border: noBorder, bold: true },
          { text: tonStringFromPounds(loadSummary.inWeight - loadSummary.outWeight - loadSummary.defectWeight), alignment: 'right', border: noBorder, bold: true },
          { text: '--', border: noBorder, alignment: 'right', bold: true },
          { text: '--', alignment: 'right', border: noBorder, bold: true },
          { text: formatMoney(loadSummary.grossAmount - loadSummary.amount), alignment: 'right', border: noBorder, bold: true },
          { text: formatMoney(loadSummary.amount), alignment: 'right', border: noBorder, bold: true }
        ]
      })
    }

    for (const pd of loadSummary.paymentDetails) {
      const shouldShowActivity = (pd.computedAtPayOn === PayOn.Defect.value && loadSummary.defectWeight > 0) ||
        (pd.computedAtPayOn === PayOn.NatureDefect.value && loadSummary.defects?.some(d => d.category === DefectCategory.Nature.value)) ||
        (pd.computedAtPayOn === PayOn.MismanufacturedDefect.value && loadSummary.defects?.some(d => d.category === DefectCategory.Mismanufactured.value)) ||
        (pd.computedAtPayOn === PayOn.Net.value || pd.computedAtPayOn === PayOn.Gross.value || pd.computedAtPayBy === PayBy.Load.value)

      // Only skip if there are multiple activities and the activity should not be shown.
      if (!shouldShowActivity && hasMultipleActivities) {
        continue
      }

      totals.amount += pd.amount
      totals.grossAmount += pd.grossAmount

      const payOnTons = getUnitTextForActivity({
        payOn: pd.computedAtPayOn,
        payBy: pd.computedAtPayBy,
        modifier: pd.computedAtModifier,
        grossTons: tonStringFromPounds(loadSummary.inWeight - loadSummary.outWeight),
        defectTons: tonStringFromPounds(loadSummary.defectWeight),
        netTons: tonStringFromPounds(loadSummary.inWeight - loadSummary.outWeight - loadSummary.defectWeight),
        mismanufactureDefectTons: tonStringFromPounds(loadSummary.mismanufacturedDefectWeight),
        natureDefectTons: tonStringFromPounds(loadSummary.natureDefectWeight)
      })

      let rate = ''
      let activity = pd.activity
      if (pd.computedAtModifier > 0) {
        rate = `${formatMoney(pd.computedAtRate)} x ${pd.computedAtModifier}mi.`
      } else {
        rate = formatMoney(pd.computedAtRate)
      }

      if (pd.computedAtSplit > 0 && pd.computedAtSplit < 100) {
        const formattedSplit = formatPercentage(pd.computedAtSplit)
        rate = `${rate} x ${formattedSplit}`
        activity = `${activity} (${formattedSplit})`
      }

      processedLoadSummaries.push({
        ticket: loadSummary,
        rowContent: [
          { text: hasMultipleActivities ? '' : ticketNumber, border: noBorder },
          { text: hasMultipleActivities ? '' : utcToLocalDate(loadSummary.weighedOutAt), border: noBorder },
          { text: activity, border: noBorder },
          { text: hasMultipleActivities ? '' : loadSummary.product, border: noBorder },
          { text: hasMultipleActivities ? '' : tonStringFromPounds(loadSummary.inWeight - loadSummary.outWeight), alignment: 'right', border: noBorder, bold: true },
          { text: hasMultipleActivities ? '' : tonStringFromPounds(loadSummary.defectWeight), alignment: 'right', border: noBorder, bold: true },
          { text: hasMultipleActivities ? '' : tonStringFromPounds(loadSummary.inWeight - loadSummary.outWeight - loadSummary.defectWeight), alignment: 'right', border: noBorder, bold: true },
          { text: payOnTons, alignment: 'right', border: noBorder },
          { text: rate, alignment: 'right', border: noBorder },
          { text: formatMoney(pd.grossAmount - pd.amount), alignment: 'right', border: noBorder },
          { text: formatMoney(pd.amount), alignment: 'right', border: noBorder }
        ]
      })
    }
  })

  totals.recovered = { text: formatMoney(totals.grossAmount - totals.amount), alignment: 'right', bold: true, fontSize: 9 }
  totals.amount = { text: formatMoney(totals.amount), alignment: 'right', bold: true, fontSize: 9 }
  totals.grossTons = tonStringFromPounds(totals.grossTons)
  totals.defectTons = tonStringFromPounds(totals.defectTons)
  totals.netTons = tonStringFromPounds(totals.netTons)

  // Total Row
  processedLoadSummaries.push({
    ticket: null,
    rowContent: [
      { text: 'Totals', bold: true, fontSize: 9, border: noBorder },
      { text: '', bold: true, fontSize: 9, border: noBorder },
      { text: '', bold: true, fontSize: 9, border: noBorder },
      { text: '', bold: true, fontSize: 9, border: noBorder },
      { text: totals.grossTons, alignment: 'right', bold: true, fontSize: 9, border: noBorder },
      { text: totals.defectTons, alignment: 'right', bold: true, fontSize: 9, border: noBorder },
      { text: totals.netTons, alignment: 'right', bold: true, fontSize: 9, border: noBorder },
      { text: '', border: noBorder },
      { text: '', bold: true, fontSize: 9, border: noBorder },
      { text: totals.recovered, bold: true, fontSize: 9, border: noBorder },
      { text: totals.amount, bold: true, fontSize: 9, border: noBorder }
    ]
  })

  return processedLoadSummaries
}

function loadDefectsTable (loadSummary) {
  const values = []

  let defects = loadSummary.defects

  if (loadSummary.defects.length > 0) {
    const firstDefect = defects[0]
    // Check if defect is segmented.
    if (firstDefect.quantity === 0 || firstDefect.quantity === null) {
      defects = Object.values(
        defects.reduce((segmentedDefects, defect) => {
          if (segmentedDefects[defect.name] === undefined) {
            segmentedDefects[defect.name] = {
              ...defect
            }
          } else {
            segmentedDefects[defect.name].totalLBS += defect.totalLBS
          }
          return segmentedDefects
        }, {})
      )
    }
  }

  defects = defects.sort((a, b) => a.category === b.category ? a.name.localeCompare(b.name) : a.category - b.category)

  const defectNameWithCategory = (defect) => {
    switch (defect.category) {
      case DefectCategory.Nature.value:
        return `N - ${defect.name}`
      case DefectCategory.Mismanufactured.value:
        return `M - ${defect.name}`
      default:
        return defect.name
    }
  }

  defects.forEach(defect => {
    values.push(
      [
        { text: defectNameWithCategory(defect), border: noBorder, fontSize: 8, alignment: 'left' },
        { text: tonStringFromPounds(defect.totalLBS), alignment: 'right', border: noBorder, fontSize: 8 }
      ]
    )
  })

  return {
    table: {
      widths: ['*', 'auto'],
      body: values
    },
    alignment: 'right',
    border: noBorder,
    layout: {
      paddingRight: () => 0
    }
  }
}

export function getFooter (page, pages) {
  return {
    marginRight: 50,
    marginLeft: 50,
    columns: [
      {
        width: '*',
        text: `${page}/${pages}`,
        fontSize: 8,
        bold: true
      },
      {
        width: '*',
        text: `As of ${utcToLocalDate(new Date().toISOString())}`,
        alignment: 'right',
        fontSize: 8,
        bold: true
      }
    ]
  }
}

export const getHeaderForSettlement = (dateInfo, payee, contract = undefined) => {
  const { address } = payee
  const payeeInfoTable = {
    table: {
      body: [
        [{ text: 'Payee: ' }, { text: `${payee.payeeName}` }]
      ]
    },
    layout: 'noBorders'
  }

  if (address !== null && address !== undefined) {
    const { addressLine1, addressLine2, city, state, postalCode } = address

    const addressLine = (addressLine2 && addressLine2 !== '')
      ? [[{ text: 'Address: ' }, { text: addressLine1 }], [{ text: '' }, { text: addressLine2 }]]
      : [[{ text: 'Address: ' }, { text: addressLine1 }]]

    payeeInfoTable.table.body.push(
      ...addressLine,
      [{ text: '' }, { text: `${city}, ${state} ${postalCode}` }]
    )
  } else {
    payeeInfoTable.table.body.push(
      [{ text: 'Address: ' }, { text: 'N/A' }]
    )
  }

  const dateInfoTable = getPeriodDateTable(dateInfo)

  return [{
    alignment: 'left',
    table: {
      widths: '*',
      body: [
        [
          payeeInfoTable,
          contract ? getContractTable(contract) : [''],
          dateInfoTable
        ]
      ]
    },
    layout: {
      vLineColor: function (i) {
        if (i === 0 || i === 3) { return 'white' }
        return 'black'
      },
      hLineColor: function () {
        return 'white'
      }
    }
  }]
}

export const getDateInfo = ({ periodStartDate, periodEndDate, payDate }, exportBatch) => {
  const subtractDay = (date) => {
    const formattedDate = new Date(date)
    return formattedDate.setDate(formattedDate.getDate() - 1)
  }
  const endDate = subtractDay(periodEndDate)
  const formattedPayDate = subtractDay(payDate)
  return {
    startDate: utcToLocalDate(periodStartDate),
    endDate: utcToLocalDate(endDate),
    payDate: utcToLocalDate(formattedPayDate),
    exportBatchLabel: exportBatch?.label,
    exportDate: exportBatch?.exportDate ? utcToLocalDate(exportBatch.exportDate) : undefined
  }
}

export const nestedTableStyling = {
  layout: {
    hLineWidth: function (i, node) {
      return (i === 0 || i === node.table.body.length) ? 2 : 1
    },
    vLineWidth: function (i, node) {
      return (i === 0 || i === node.table.widths.length) ? 2 : 1
    },
    hLineColor: function (i, node) {
      return (i % 2 !== 0 || i === 0 || i === node.table.body.length) ? 'black' : 'white'
    },
    vLineColor: function () {
      return 'black'
    }
  },
  style: 'nestedTable'
}

export const defaultDocumentConfig = {
  header: getHeader,
  footer: getFooter,
  pageMargins: [40, 80, 40, 30],
  pageOrientation: 'landscape',
  headerRows: 1,
  styles: {
    header: {
      fontSize: 100
    },
    rightAlign: {
      alignment: 'right'
    },
    nestedTable: {
      fontSize: 8
    }
  }
}

export function getAdditionalFinancialTables (tractPayables, advances, corrections, recoveryInfo, accountPayables, params = {}) {
  const { isByproducts = false, unexported = false } = params
  tractPayables ??= []
  advances ??= []
  corrections ??= []
  recoveryInfo ??= []
  accountPayables ??= []
  const additionalTables = []
  if (tractPayables.length > 0) {
    additionalTables.push({
      table: getTractPaymentTable(tractPayables),
      type: 'tp'
    })
  }

  if (advances.length > 0 || recoveryInfo.length > 0) {
    additionalTables.push({
      table: getAdvanceInfoTables(advances, recoveryInfo, unexported),
      type: 'ad'
    })
  }

  if (corrections.length > 0) {
    additionalTables.push({
      table: getCorrectionsTable(corrections, { isByproducts }),
      type: 'co'
    })
  }

  if (accountPayables.length > 0) {
    additionalTables.push({
      table: getAccountPaymentTable(accountPayables),
      type: 'ap'
    })
  }

  return additionalTables
}

// TODO: BEN - refactor the rest of the financial table methods to look like this
const getCorrectionsTable = (ticketCorrections, params = {}) => {
  const { isByproducts } = params

  const columns = [
    {
      text: '#',
      value: correction => {
        let ticketNumber = correction.ticketNumber
        if (correction.extTicketNumber1 !== '') ticketNumber = `${ticketNumber} (${correction.extTicketNumber1})`
        if (correction.extTicketNumber2 !== '') ticketNumber = `${ticketNumber} (${correction.extTicketNumber2})`
        return ticketNumber
      },
      width: 'auto'
    },
    {
      text: 'Type',
      value: correction => CorrectionTypes.find(c => c.value === correction.correctionType).name,
      width: '*'
    },
    {
      text: 'Action',
      value: correction => CorrectionSide.find(c => c.value === correction.correctionSide).name,
      width: '*'
    },
    {
      text: 'Activity',
      value: correction => correction.activity,
      width: 'auto'
    },
    {
      text: 'Product',
      value: correction => correction.product,
      width: 'auto'
    },

    !isByproducts ? {
      text: 'Tract',
      value: correction => correction.tract,
      width: 'auto'
    } : undefined,

    {
      text: 'Destination',
      value: correction => correction.destination,
      width: 'auto'
    },

    !isByproducts ? {
      text: 'Setting',
      value: correction => correction.setting,
      width: 'auto'
    } : undefined,

    {
      text: 'Net Tons',
      value: correction => correction.netWeight,
      format: tonStringFromPounds,
      alignment: 'right',
      width: 'auto'
    },
    {
      text: 'Gross Amount',
      value: correction => correction.grossAmount,
      format: formatMoney,
      alignment: 'right',
      width: 'auto'
    },
    {
      text: 'Amount',
      value: correction => correction.amount,
      format: formatMoney,
      alignment: 'right',
      width: 'auto'
    }
  ].filter(c => c !== undefined)

  const body = [
    columns.map(col => ({
      text: col.text,
      bold: true,
      border: bottomBorder,
      alignment: col.alignment
    })),

    ...ticketCorrections.map(correction => columns.map(col => ({
      text: col.format ? col.format(col.value(correction)) : col.value(correction),
      alignment: col.alignment,
      border: noBorder
    })))
  ]

  return [
    [[{ text: 'Corrections', border: noBorder, fontSize: 12, bold: true }]],
    [{
      marginBottom: 20,
      table: {
        widths: columns.map(col => col.width),
        body
      },
      layout: {
        fillColor: function (rowIndex) {
          return ((rowIndex + 1) % 2 === 0) ? '#EEEEEE' : null
        }
      }
    }]
  ]
}

const getTractPaymentTable = (tractPayables) => {
  const body = [[
    { text: 'Title', bold: true, border: bottomBorder },
    { text: 'Tract', bold: true, border: bottomBorder },
    { text: 'Activity', bold: true, border: bottomBorder },
    { text: 'Amount', bold: true, border: bottomBorder, alignment: 'right' }
  ]]
  tractPayables.forEach(tractPayment => {
    body.push([
      { text: tractPayment.paymentNote, border: noBorder },
      { text: tractPayment.tractName, border: noBorder },
      { text: tractPayment.activityName, border: noBorder },
      { text: formatMoney(tractPayment.amount), border: noBorder, alignment: 'right' }
    ])
  })

  const totalTractPayment = formatMoney(tractPayables.reduce((total, payable) => {
    total += payable.amount
    return total
  }, 0))

  body.push([
    { text: 'Total', bold: true, border: noBorder },
    { text: '', bold: true, border: noBorder },
    { text: '', bold: true, border: noBorder },
    { text: totalTractPayment, bold: true, border: noBorder, alignment: 'right' }
  ])

  return [
    [[{ text: 'Tract Payments', border: [false, false, false, false], fontSize: 15, bold: true }]],
    [{
      marginBottom: 20,
      table: {
        widths: '*',
        body: body
      },
      layout: {
        fillColor: function (rowIndex) {
          return ((rowIndex + 1) % 2 === 0) ? '#EEEEEE' : null
        }
      }
    }]
  ]
}

const getAccountPaymentTable = (accountPayables) => {
  const body = [[
    { text: 'Title', bold: true, border: bottomBorder },
    { text: 'Activity', bold: true, border: bottomBorder },
    { text: 'Amount', bold: true, border: bottomBorder, alignment: 'right' }
  ]]
  accountPayables.forEach(accountPayment => {
    body.push([
      { text: accountPayment.paymentNote, border: noBorder },
      { text: accountPayment.activityName, border: noBorder },
      { text: formatMoney(accountPayment.amount), border: noBorder, alignment: 'right' }
    ])
  })

  const totalAccountPayment = formatMoney(accountPayables.reduce((total, payable) => {
    total += payable.amount
    return total
  }, 0))

  body.push([
    { text: 'Total', bold: true, border: noBorder },
    { text: '', bold: true, border: noBorder },
    { text: totalAccountPayment, bold: true, border: noBorder, alignment: 'right' }
  ])

  return [
    [[{ text: 'Account Payments', border: [false, false, false, false], fontSize: 15, bold: true }]],
    [{
      marginBottom: 20,
      table: {
        widths: '*',
        body: body
      },
      layout: {
        fillColor: function (rowIndex) {
          return ((rowIndex + 1) % 2 === 0) ? '#EEEEEE' : null
        }
      }
    }]
  ]
}

const getAdvanceInfoTables = (advances, recoveryInfo, unexported) => {
  const totalBody = []
  if (advances.length > 0) {
    totalBody.push(...getAdvanceTable(advances))
  }

  const info = scatterToObject(recoveryInfo, {
    contract: ri => ri.contractRecovered !== 0,
    manual: ri => ri.manualRecovered > 0
  }, { multi: true })

  if (info.contract.length > 0) totalBody.push(...getContractRecoveryTable({ recoveryInfo: info.contract, unexported: unexported }))
  if (info.manual.length > 0) totalBody.push(...getManualRecoveryTable({ recoveryInfo: info.manual, unexported: unexported }))

  return totalBody
}

const getAdvanceTable = (advances) => {
  const advanceBody = [[
    { text: 'Entity', bold: true, border: bottomBorder },
    { text: 'Title', bold: true, border: bottomBorder },
    { text: 'Advanced', bold: true, border: bottomBorder, alignment: 'right' },
    { text: 'Remaining', bold: true, border: bottomBorder, alignment: 'right' }
  ]]
  advances.forEach(advance => {
    advanceBody.push([
      { text: advance.businessEntityName, border: noBorder },
      { text: advance.note, border: noBorder },
      { text: formatMoney(advance.amount), border: noBorder, alignment: 'right' },
      { text: formatMoney(advance.remainingBalance), border: noBorder, alignment: 'right' }
    ])
  })

  const totalAdvanced = advances.reduce((total, advance) => {
    total.amount += advance.amount
    total.remainingBalance += advance.amount - advance.recovered
    return total
  }, {
    amount: 0,
    remainingBalance: 0,
    recovered: 0
  })

  advanceBody.push([
    { text: 'Total', bold: true, border: noBorder },
    { text: '', bold: true, border: noBorder },
    { text: formatMoney(totalAdvanced.amount), bold: true, border: noBorder, alignment: 'right' },
    { text: formatMoney(totalAdvanced.remainingBalance), bold: true, border: noBorder, alignment: 'right' }
  ])

  return [[[{ text: 'New Advances', border: [false, false, false, false], fontSize: 15, bold: true }]],
    [{
      marginBottom: 20,
      table: {
        widths: ['auto', '*', 'auto', 'auto'],
        body: advanceBody
      },
      layout: {
        fillColor: function (rowIndex) {
          return ((rowIndex + 1) % 2 === 0) ? '#EEEEEE' : null
        }
      }
    }]
  ]
}

export const getRecoveryTable = (recoveryInfo, getRecovered = r => r.recovered, title = 'Advance Recoveries', unexported = false) => {
  const totalRecovered = recoveryInfo.reduce((total, r) => {
    total.amount += r.amount
    total.balance += r.balance
    total.recovered += getRecovered(r)
    total.previouslyRecoveredAmount += r.previouslyRecoveredAmount ?? 0
    return total
  }, {
    amount: 0,
    balance: 0,
    recovered: 0,
    previouslyRecoveredAmount: 0
  })

  const recoveryBody = [
    [
      { text: 'Entity', bold: true, border: bottomBorder },
      { text: 'Title', bold: true, border: bottomBorder },
      { text: 'Type', bold: true, border: bottomBorder },
      { text: 'Rate', bold: true, border: bottomBorder, alignment: 'right' },
      { text: 'Advanced', bold: true, border: bottomBorder, alignment: 'right' },
      { text: 'Previously Recovered', bold: true, border: bottomBorder, alignment: 'right' },
      { text: unexported ? 'Total Recovered' : 'Recovered in Batch', bold: true, border: bottomBorder, alignment: 'right' },
      { text: 'Balance', bold: true, border: bottomBorder, alignment: 'right' }
    ],

    ...recoveryInfo.map(r => ([
      { text: r.businessEntityName, border: noBorder },
      { text: r.note, border: noBorder },
      { text: RecoveryType.fromInt(r.type), border: noBorder, alignment: 'right' },
      { text: (r.type === 1) ? `${r.rate}%` : formatMoney(r.rate), border: noBorder, alignment: 'right' },
      { text: formatMoney(r.amount), border: noBorder, alignment: 'right' },
      { text: formatMoney(r.previouslyRecoveredAmount ?? 0), border: noBorder, alignment: 'right' },
      { text: formatMoney(getRecovered(r)), border: noBorder, alignment: 'right' },
      { text: formatMoney(r.balance), border: noBorder, alignment: 'right' }
    ])),

    [
      { text: 'Total', bold: true, border: noBorder },
      { text: '', bold: true, border: noBorder },
      { text: '', bold: true, border: noBorder },
      { text: '', bold: true, border: noBorder },
      { text: formatMoney(totalRecovered.amount), bold: true, border: noBorder, alignment: 'right' },
      { text: formatMoney(totalRecovered.previouslyRecoveredAmount), bold: true, border: noBorder, alignment: 'right' },
      { text: formatMoney(totalRecovered.recovered), bold: true, border: noBorder, alignment: 'right' },
      { text: formatMoney(totalRecovered.balance), bold: true, border: noBorder, alignment: 'right' }
    ]
  ]

  return [[[{ text: title, border: [false, false, false, false], fontSize: 15, bold: true }]],
    [{
      marginBottom: 20,
      table: {
        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: recoveryBody
      },
      layout: {
        fillColor: function (rowIndex) {
          return ((rowIndex + 1) % 2 === 0) ? '#EEEEEE' : null
        }
      }
    }]
  ]
}

export const getFileNameForPeriod = ({ startDate, endDate, exportBatchLabel, exportDate }) => {
  if (exportDate === undefined) {
    return `unexported-${startDate}-${endDate}`
  }

  const label = exportBatchLabel === undefined || exportBatchLabel === null || exportBatchLabel === ''
    ? ''
    : `-${exportBatchLabel}`

  return fileNameForString(`${exportDate}${label}`)
}

export const getContractRecoveryTable = ({ recoveryInfo, unexported }) => getRecoveryTable(recoveryInfo, r => r.contractRecovered, 'Contract Recoveries', unexported)
export const getManualRecoveryTable = ({ recoveryInfo, unexported }) => getRecoveryTable(recoveryInfo, r => r.manualRecovered, 'Manual Recoveries', unexported)

export const getUnitTextForActivity = ({
  payOn,
  payBy,
  modifier,
  grossTons,
  defectTons,
  mismanufactureDefectTons,
  natureDefectTons,
  netTons
}, distance, includeLabel = true) => {
  if (payBy === PayBy.Load.value) return '--'
  if (modifier === ActivityModifier.DistanceTraveled.value) { return i18n.t('distanceInMiles', { distance: distance }) }

  let weight = 0
  switch (payOn) {
    case PayOn.Gross.value: weight = grossTons; break
    case PayOn.Net.value: weight = netTons; break
    case PayOn.Defect.value: weight = defectTons; break
    case PayOn.NatureDefect.value: weight = natureDefectTons; break
    case PayOn.MismanufacturedDefect.value: weight = mismanufactureDefectTons; break
    default: weight = netTons
  }

  return includeLabel
    ? `${abbreviationForPayOnType(payOn)} - ${weight}`
    : `${weight}t`
}

export const getPeriodDateTable = (dateInfo) => {
  const body = []

  const payPeriodLength = Math.abs(moment(dateInfo.endDate).diff(moment(dateInfo.startDate), 'days'))
  if (payPeriodLength <= 21) {
    body.push(
      [{ text: 'Pay Date: ' }, { text: dateInfo.payDate }],
      [{ text: 'Pay Period Start: ' }, { text: dateInfo.startDate }],
      [{ text: 'Pay Period End:' }, { text: dateInfo.endDate }]
    )
  }

  if (dateInfo?.exportBatchLabel !== '' && dateInfo?.exportBatchLabel !== undefined) {
    body.push([{ text: 'Export Batch:' }, { text: dateInfo.exportBatchLabel }])
  }
  if (dateInfo?.exportDate !== undefined) {
    body.push([{ text: 'Export Date:' }, { text: dateInfo.exportDate }])
  }

  return {
    alignment: 'left',
    table: { body },
    layout: 'noBorders'
  }
}

const abbreviationForPayOnType = (payOn) => {
  switch (payOn) {
    case PayOn.Gross.value: return 'Gross'
    case PayOn.Net.value: return 'Net'
    case PayOn.Defect.value: return 'D'
    case PayOn.NatureDefect.value: return 'N'
    case PayOn.MismanufacturedDefect.value: return 'M'
  }
}

export const fileNameForString = (str, extension = null) => {
  return extension
    ? `${str.replaceAll(/\s/g, '_').replaceAll(/[<>:"/\\|?*.]+/g, '_')}.${extension}`
    : str.replaceAll(/\s/g, '_').replaceAll(/[<>:"/\\|?*.]+/g, '_')
}
