<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" xs="12" sm="12" lg="4">
        <v-text-field
          v-model="tract.name"
          data-testid="tract-name"
          ref="firstField"
          :label="$t('tractName')"
          :rules="[rules.required]"
          color="black"
          counter
          maxlength="40"
          persistent-hint
          :hint="$t('required')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="6" lg="4">
        <v-text-field
          v-model="tract.code"
          data-testid="tract-code"
          :label="$t('tractCode')"
          color="black"
          counter
          maxlength="10"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4">
        <UserAutocomplete
        :label="$t('forester')"
        :propUserId="tract.foresterUserId"
        data-testid="tract-forester"
        @user-chosen="foresterChosen"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4">
        <TractTypeSelector
          data-testid="tract-type"
          :tractTypeId="tract.type.tractTypeId"
          @tract-type="tractTypeChosen"
          :hint="$t('required')"
          :persistentHint="true"
        />
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <AccountAutocomplete
        id="tract-logger"
        data-testid="tract-logger"
        :accountId="tract.loggerAccountId"
        :title="$t('defaultLogger')"
        clearable
        showCertified
        userSetting="defaultLoggerAutocomplete"
        @account-chosen="defaultLoggerChosen"
        :fetchTagsAndAccounts="false"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="12" lg="4">
        <AccountAutocomplete
        id="hauler"
        data-testid="tract-hauler"
        :accountId="tract.haulerAccountId"
        :title="$t('hauler')"
        clearable
        showCertified
        userSetting="haulerAccountAutocomplete"
        @account-chosen="haulerChosen"
        :fetchTagsAndAccounts="false"
        />
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <AccountAutocomplete
        id="supplier"
        data-testid="tract-supplier"
        :accountId="tract.supplierAccountId"
        :title="$t('supplier')"
        clearable
        showCertified
        userSetting="supplierAccountAutocomplete"
        @account-chosen="supplierChosen"
        :fetchTagsAndAccounts="false"
        />
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <AccountAutocomplete
        id="consulting-forester"
        data-testid="tract-consulting-forester"
        :accountId="tract.consultingForesterAccountId"
        :title="$t('consultingForester')"
        clearable
        showCertified
        userSetting="consultingForesterAccountAutocomplete"
        @account-chosen="consultingForesterChosen"
        :fetchTagsAndAccounts="false"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="12" lg="4">
        <AccountAutocomplete
        id="contract-account"
        data-testid="tract-contract-account"
        :accountId="tract.contractAccountId"
        :title="$t('contractAccount')"
        clearable
        showCertified
        userSetting="tractContractAccountAutocomplete"
        @account-chosen="contractAccountChosen"
        :fetchTagsAndAccounts="false"
        />
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <SettingAutocomplete
        :settingName="tract.contractSetting"
        @setting-chosen="contractSettingChosen"
        hideRefresh
        data-testid="tract-contract-setting"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="isStumpageTract" sm="12" md="6" lg="6">
        <EntitySelector
          :entities="entities"
          :initialEntity.sync="tract.businessEntity"
          @entity-selected="entitySelected"
          :hint="$t('required')"
          :persistentHint="true"
          :label="$t('purchaser')"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="2">
        <MoneyTextField
        data-testid="tract-cost"
        :initialValue="tract.cost"
        :label="$t('cost')"
        @val-changed="tractCostEntered"
        :extraRules="['validTractCost']"/>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <DatePicker
        v-if="isStumpageTract"
        :dateLabel="$t('purchaseDate')"
        :startDate="tract.purchaseDate"
        dataTestId="tract-purchase-date"
        clearable
        @date-picked="purchaseDatePicked"
        @valid-date="(val) => {
          isValidPurchaseDate = val
        }"
        ></DatePicker>
      </v-col>
    </v-row>
    <TractLandowners
    :propLandowners="landowners"
    @ownership-changed="landowners = $event"/>
    <v-divider class="my-6"></v-divider>
    <v-row dense>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <DatePicker
        @date-picked="startDatePicked"
        clearable
        dataTestId="tract-harvest-start-date"
        :startDate="tract.harvestStartDate"
        v-if="renderDates"
        @valid-date="(val) => {
          isValidStartDate = val
        }"
        :dateLabel="$t('harvestStart')"
        ></DatePicker>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <DatePicker
        @date-picked="endDatePicked"
        clearable
        dataTestId="tract-harvest-end-date"
        :startDate="tract.harvestEndDate"
        v-if="renderDates"
        @valid-date="(val) => {
          isValidEndDate = val
        }"
        :dateLabel="$t('harvestEnd')"
        ></DatePicker>
      </v-col>
    </v-row>
    <v-row dense>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-checkbox
          data-testid="contract-requires-ext-ticket-1"
          v-model="tract.requiresExt1"
          color="secondary"
          :label="$t('requiresExt1')"
        />
      </v-col>
      <v-col cols="auto">
        <v-checkbox
          data-testid="contract-requires-ext-ticket-2"
          v-model="tract.requiresExt2"
          color="secondary"
          :label="$t('requiresExt2')"
        />
      </v-col>
      <v-col cols="auto" v-if="propTract.tractId">
        <v-checkbox
        v-if="didHarvestDatesChange"
        v-model="applyDateChangesToContracts"
        color="secondary"
        label="Apply harvest dates to contracts"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <LoggerAutocomplete
        data-testid="tract-loggers"
        :loggers="tract.loggers"
        userSetting="accountLoggerAutocomplete"
        @loggers-mutated="loggersChosen">
        </LoggerAutocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <Certifications
          label
          :category="0"
          data-testid="tract-certifications"
          :propCertifications="tract.certificationIds"
          @certifications="certificationChosen"
          useIdOnly
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6">
        <span class="subtitle-1">{{$t('quality')}}</span>
        <v-row dense>
          <v-col cols="12" sm="4">
            <v-text-field
            :label="$t('rpi')"
            v-model="tract.rpi"
            :rules="[rules.numberOrBlank, rules.twoDecimalPlacesOrFewer, rules.validRPI]"
            maxLength="5"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
            :label="$t('dbh')"
            v-model="tract.dbh"
            :rules="[rules.numberOrBlank, rules.positiveIntegerOrBlank, rules.validDBH]"
            maxLength="3"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
            :label="$t('age')"
            v-model="tract.age"
            :rules="[rules.numberOrBlank, rules.positiveIntegerOrBlank, rules.validAge]"
            maxLength="3"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fieldRules from '@/utils/rules.js'
import { TractTypeCategory } from '@/utils/Enumerations.js'
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'GeneralInformation',

  props: {
    propTract: Object,
    propLandowners: Array
  },

  components: {
    Certifications: () => import('@/components/settings-components/certification/Certifications.vue'),
    TractTypeSelector: () => import('@/components/tract/TractTypeSelector.vue'),
    AccountAutocomplete: () => import('@/components/autocomplete/AccountAutocomplete.vue'),
    SettingAutocomplete: () => import('@/components/autocomplete/ContractSettingAutocomplete.vue'),
    UserAutocomplete: () => import('@/components/autocomplete/UserAutocomplete.vue'),
    LoggerAutocomplete: () => import('@/components/autocomplete/LoggerAutocomplete.vue'),
    EntitySelector: () => import('@/components/accounting/EntitySelector.vue'),
    MoneyTextField: () => import('@/components/helper/MoneyTextField.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    TractLandowners: () => import('@/components/tract/tract-form/TractLandowners.vue')
  },

  data: () => ({
    rules: fieldRules.rules,
    entities: [],
    isValidStartDate: true,
    renderDates: true,
    isValidEndDate: true,
    isValidPurchaseDate: true,
    applyDateChangesToContracts: false,
    originalHarvestStart: null,
    originalHarvestEnd: null,
    tract: {
      name: '',
      code: '',
      type: {
        tractTypeId: null
      },
      cost: 0,
      foresterUserId: null,
      businessEntity: null,
      loggerAccountId: null,
      haulerAccountId: null,
      supplierAccountId: null,
      contractAccountId: null,
      contractSettingId: null,
      contractSetting: null,
      loggers: [],
      loggerAccountIds: [],
      certificationIds: [],
      harvestStartDate: new Date().toISOString(),
      harvestEndDate: new Date().toISOString(),
      purchaseDate: new Date().toISOString(),
      requiresExt1: false,
      requiresExt2: false,
      consultingForesterAccountId: null,
      rpi: null,
      dbh: null,
      age: null
    },
    landowners: [],
    accounts: undefined,
    tags: undefined
  }),

  computed: {
    isStumpageTract () {
      return this.tract.type?.category === TractTypeCategory.Stumpage.value
    },

    didHarvestDatesChange () {
      if (this.tract.harvestStartDate === null || this.tract.harvestEndDate === null) return false
      const isStartDayEqual = moment(this.tract.harvestStartDate).isSame(moment(this.originalHarvestStart), 'day')
      const isEndDayEqual = moment(this.tract.harvestEndDate).isSame(moment(this.originalHarvestEnd), 'day')
      return !(isStartDayEqual && isEndDayEqual)
    },

    type () {
      return this.tract.type
    },

    showAccountSelectors () {
      return !!this.accounts && !!this.tags
    }
  },

  watch: {
    tract: {
      handler (val) {
        this.$emit('tract-changed', val)
      },
      deep: true
    },

    type (val, oldVal) {
      if (this.propTract.tractId || (this.tract.harvestStartDate || this.tract.harvestEndDate)) {
        return
      }

      const selectedDeliveredType = oldVal.tractTypeId === null && val.category === TractTypeCategory.Delivered.value
      const switchedFromStumpage = val.category === TractTypeCategory.Delivered.value && (!this.tract.harvestStartDate && !this.tract.harvestEndDate)
      if (selectedDeliveredType || switchedFromStumpage) {
        this.tract.harvestStartDate = moment().startOf('day').format()
        this.tract.harvestEndDate = moment().add(90, 'day').startOf('day').format()
        this.renderDates = false
        this.$nextTick(_ => {
          this.renderDates = true
        })
      }
    },

    applyDateChangesToContracts (shouldApplyDates) {
      this.$emit('apply-to-contracts-toggled', shouldApplyDates)
    },

    landowners: {
      handler (val) {
        this.$emit('ownership-changed', val)
      },
      deep: true
    },

    propLandowners: {
      handler () {
        this.landowners = this.propLandowners
      },
      deep: true
    }
  },

  created () {
    this.setInitialValues(this.propTract, this.propLandowners)
    this.getBusinessEntities()
    this.getAccounts()
    this.getAccountTags()
  },

  methods: {
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('tags', ['fetchAccountTags']),
    ...mapActions('user', ['fetchAllBusinessEntities']),
    setInitialValues (propTract, propLandowners) {
      this.tract.name = propTract.name
      this.tract.code = propTract.code
      this.tract.type = propTract.type
      this.tract.foresterUserId = propTract.foresterUser?.applicationUserId
      this.tract.harvestStartDate = propTract.harvestStartDate
      this.tract.harvestEndDate = propTract.harvestEndDate
      this.tract.purchaseDate = propTract.purchaseDate
      this.tract.cost = propTract.cost
      this.tract.businessEntity = {
        name: propTract.businessEntity,
        businessEntityId: propTract.businessEntityId
      }
      this.tract.loggerAccountId = propTract.loggerAccountId
      this.tract.haulerAccountId = propTract.haulerAccountId
      this.tract.supplierAccountId = propTract.supplierAccountId
      this.tract.contractAccountId = propTract.contractAccountId
      this.tract.contractSettingId = propTract.contractSettingId
      this.tract.contractSetting = propTract.contractSetting
      this.tract.requiresExt1 = propTract.requiresExt1 ?? false
      this.tract.requiresExt2 = propTract.requiresExt2 ?? false
      this.tract.certificationIds = propTract.tractCertifications?.map(c => c.certificationId)
      this.tract.loggers = propTract.loggers ?? []
      this.originalHarvestStart = this.propTract.harvestStartDate
      this.originalHarvestEnd = this.propTract.harvestEndDate
      this.tract.consultingForesterAccountId = propTract.consultingForesterAccountId
      this.tract.rpi = propTract.rpi || undefined
      this.tract.dbh = propTract.dbh || undefined
      this.tract.age = propTract.age || undefined
      this.landowners = propLandowners
    },

    async getBusinessEntities () {
      this.entities = await this.fetchAllBusinessEntities()
    },

    async getAccounts () {
      await this.fetchAccounts()
    },

    async getAccountTags () {
      await this.fetchAccountTags()
    },

    tractCostEntered (cost) {
      this.tract.cost = cost
    },

    tractTypeChosen (tractType) {
      if (tractType.category === TractTypeCategory.Delivered.value) {
        this.tract.businessEntity = null
      }
      this.tract.type = tractType
    },

    certificationChosen (certifications) {
      this.tract.certificationIds = certifications
    },

    entitySelected (entity) {
      this.tract.businessEntity = entity
    },

    loggersChosen (loggers) {
      this.tract.loggerAccountIds = loggers.map(l => l.accountId)
    },

    defaultLoggerChosen (defaultLogger) {
      this.tract.loggerAccountId = defaultLogger?.accountId
    },

    haulerChosen (hauler) {
      this.tract.haulerAccountId = hauler?.accountId
    },

    supplierChosen (supplier) {
      this.tract.supplierAccountId = supplier?.accountId
    },

    consultingForesterChosen (consultingForester) {
      this.tract.consultingForesterAccountId = consultingForester?.accountId
    },

    contractAccountChosen (contractAccount) {
      this.tract.contractAccountId = contractAccount?.accountId
    },

    contractSettingChosen (setting) {
      this.tract.contractSettingId = setting?.settingId
    },

    foresterChosen (forester) {
      this.tract.foresterUserId = forester?.applicationUserId ?? null
    },

    startDatePicked (startDate) {
      this.tract.harvestStartDate = startDate
    },

    endDatePicked (endDate) {
      this.tract.harvestEndDate = endDate
    },

    purchaseDatePicked (purchaseDate) {
      this.tract.purchaseDate = purchaseDate
    }

  }
}
</script>
