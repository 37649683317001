import { numberWithCommas, tonStringFromPounds } from '../../../utils/NumericMutations'
export const cruiseDataTotals = (cruiseData) => {
  cruiseData = Array.isArray(cruiseData) ? cruiseData : [cruiseData]

  const totals = cruiseData.reduce((total, cd) => {
    total.harvestedTons += cd.harvestedPounds
    total.expectedTons += cd.expectedPounds
    total.adjustmentTons += cd.adjustmentPounds
    total.cruisedValue += cd.cruisedValue
    total.remainingTons += cd.remainingTons
    return total
  }, {
    harvestedTons: 0,
    adjustmentTons: 0,
    remainingTons: 0,
    expectedTons: 0,
    cruisedValue: 0
  })

  totals.remainingTons = Math.max(totals.remainingTons, 0)

  totals.remainingTons = numberWithCommas(totals.remainingTons, 3)
  totals.harvestedTons = tonStringFromPounds(totals.harvestedTons)
  totals.adjustmentTons = tonStringFromPounds(totals.adjustmentTons)
  totals.expectedTons = tonStringFromPounds(totals.expectedTons)

  return totals
}
