<template>
  <div style="display: inline-block">
    <v-menu
    v-show="dateConfiguration.selectedPayPeriod !== undefined"
    bottom
    left>
      <template #activator="{ on, attrs}">
        <v-icon
        color="secondary"
        v-bind="attrs"
        v-on="on">mdi-dots-vertical</v-icon>
      </template>
      <v-list>
        <v-list-item
        v-for="(action, index) in actions"
        :key="`accounting-action-${index}`"
        :disabled="action.disabled"
        @click="action.onClick(action.clickArgument)">
          <v-avatar left >
            <v-icon
            :color="action.isEnabled ? 'grey' : 'black'">
              {{action.icon}}
            </v-icon>
          </v-avatar>
          <v-list-item-title class="subtitle-1">
            {{action.text}}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="csvDialog" width="400">
      <CSVExport
      v-if="csvDialog"
      :isByProducts="isByProducts"
      @close-csv-export="csvDialog = false"/>
    </v-dialog>
  </div>
</template>

<script>
import { generateSinglePDFSettlementReport } from '@/utils/pdf-formatters/SinglePDFSettlementsReport.js'
import { generateZippedSettlementReport } from '@/utils/pdf-formatters/ZippedSettlementsReport.js'
import { createProductionSummaryPDF } from '@/utils/pdf-formatters/ProductionSummaryReport.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { PayPeriodStatus, FinancialIntegrationType, ErrorSource } from '@/utils/Enumerations.js'
import saveAs from 'file-saver'
import { createPaymentRegisterPDF } from '@/utils/pdf-formatters/RegisterPDF'
export default {
  name: 'SettlementActionMenu',

  components: {
    CSVExport: () => import('@/components/settlements/CSVExport.vue')
  },

  props: {
    isByProducts: {
      type: Boolean,
      required: true
    },
    isExporter: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    csvDialog: false
  }),

  computed: {
    ...mapGetters('settlements', ['currentModuleKey', 'currentSubModuleKey', 'dateConfiguration', 'currentSubModule']),
    ...mapGetters('user', ['companyInfo']),

    showIntegrationsOption () {
      return this.dateConfiguration?.exportBatch?.exportBatchId !== undefined &&
        (this.companyInfo.financialIntegrationType === FinancialIntegrationType.BusinessCentralWithBinaryStream.value ||
          this.companyInfo.financialIntegrationType === FinancialIntegrationType.CsvDrop.value || !this.isNullOrUndefined(this.dateConfiguration?.exportBatch?.secondaryBiPumpStatus))
    },

    showCsvOption () {
      return this.dateConfiguration?.exportBatch?.exportBatchId !== undefined &&
        (this.companyInfo.financialIntegrationType === FinancialIntegrationType.CsvDownloads.value ||
        this.companyInfo.financialIntegrationType === FinancialIntegrationType.CsvDrop.value)
    },

    pdfDownloadDisabled () {
      if (this.currentSubModule === undefined) return true

      switch (this.currentSubModuleKey) {
        case 'settlementsByContract': return this.currentSubModule.settlements.length === 0
        case 'settlementsByPayee':
        case 'byproductSettlementsByPayee': return this.currentSubModule.payees.length === 0
        case 'productionSummary': return this.currentModuleKey !== 'accountsPayable'
        case 'register':
        case 'bpRegister':
        case 'receivableRegister':
        case 'bpReceivableRegister':
        case 'bpPaymentRegister':
        case 'paymentRegister': return !this.dateConfiguration.exportBatch.exportBatchId
        default: return true
      }
    },

    singlePdfFileText () {
      switch (this.currentModuleKey) {
        case 'accountsPayable':
          switch (this.currentSubModuleKey) {
            case 'bpPaymentRegister':
            case 'paymentRegister': return this.$t('paymentRegisterPDF')
            case 'productionSummary': return this.$t('productionSummaryPDF')
            case 'byproductSettlementsByPayee':
            case 'settlementsByContract':
            case 'settlementsByPayee': return this.$t('settlementStatementPDF')
          }
        // eslint-disable-next-line no-fallthrough
        case 'accountsReceivable':
          switch (this.currentSubModuleKey) {
            case 'receivableRegister':
            case 'bpReceivableRegister': return this.$t('receivableRegisterPDF')
            case 'productionSummary': return this.$t('receivableSummaryPDF')
          }
        // eslint-disable-next-line no-fallthrough
        case 'accruals':
          switch (this.currentSubModuleKey) {
            case 'register':
            case 'bpRegister': return this.$t('registerPDF')
          }
        // eslint-disable-next-line no-fallthrough
        default: return this.$t('singlePDFFile')
      }
    },

    rateVerificationDisabled () {
      if (this.currentSubModule === undefined || this.currentModuleKey === undefined) return true
      if (this.currentSubModuleKey === 'settlementsByContract' && this.currentModuleKey === 'accountsPayable') return this.currentSubModule.settlements.length === 0
      if ((this.currentSubModuleKey === 'settlementsByPayee' || this.currentSubModuleKey === 'byproductSettlementsByPayee') && this.currentModuleKey === 'accountsPayable') return !this.currentSubModule.payees.some(p => p.contracts.length > 0)

      return true
    },

    actions () {
      let isExportDisabled = true
      const { payPeriod, exportBatch } = this.dateConfiguration
      if (payPeriod !== undefined && exportBatch !== undefined) {
        isExportDisabled = (payPeriod.status !== PayPeriodStatus.Closed.value) || !this.isExporter
      }

      const menuOptions = [
        {
          text: this.$t('zippedPDFFolder'),
          disabled: this.pdfDownloadDisabled || (this.currentSubModuleKey !== 'settlementsByContract' && this.currentSubModuleKey !== 'settlementsByPayee' && this.currentSubModuleKey !== 'byproductSettlementsByPayee'),
          icon: 'mdi-folder-zip-outline',
          onClick: this.downloadPDFReport,
          clickArgument: true
        },
        {
          text: this.singlePdfFileText,
          disabled: this.pdfDownloadDisabled,
          icon: 'mdi-file-pdf-box',
          onClick: this.downloadPDFReport,
          clickArgument: false
        },
        {
          text: this.$t('rateVerification'),
          icon: 'mdi-check-decagram',
          onClick: this.viewRateVerification,
          disabled: this.rateVerificationDisabled
        }
      ]

      if (this.showCsvOption) {
        menuOptions.push(
          {
            text: this.$t('CSV'),
            icon: 'mdi-file-table-box',
            onClick: this.openCSVExport
          }
        )
      }

      if (this.showIntegrationsOption) {
        menuOptions.push(
          {
            text: this.$t('integrations'),
            icon: 'mdi-cloud-sync',
            onClick: this.openIntegrationsDialog
          }
        )
      }

      if (!this.isByProducts) {
        menuOptions.push(
          {
            text: this.getExportButtonText(),
            disabled: isExportDisabled,
            icon: 'mdi-export',
            onClick: this.exportPayPeriod
          },
          {
            text: this.$t('ticketsInPeriodPDF'),
            icon: 'mdi-printer',
            onClick: this.openTicketPDFPrint
          }
        )
      }
      return menuOptions
    }
  },
  methods: {
    ...mapActions('settlements', ['fetchByproductSettlementsByPayee', 'fetchSettlementsByPayee']),
    ...mapMutations('global', ['setLoading']),
    isNullOrUndefined (v) {
      return v === null || v === undefined
    },
    async downloadPDFReport (isSplitSettlement) {
      switch (this.currentSubModuleKey) {
        case 'productionSummary':
          createProductionSummaryPDF(this.currentSubModule)
          break
        case 'settlementsByContract':
        case 'settlementsByPayee':
          this.performLoadingAction(async () => {
            const settlementsByPayee = await this.fetchSettlementsByPayee()
            const exportBatch = this.dateConfiguration?.exportBatch
            const { blob, filename } = (isSplitSettlement)
              ? await generateZippedSettlementReport(settlementsByPayee, { exportBatch })
              : await generateSinglePDFSettlementReport(settlementsByPayee, { exportBatch })
            saveAs(blob, filename)
          })
          break
        case 'byproductSettlementsByPayee':
          this.performLoadingAction(async () => {
            const settlementsByPayee = await this.fetchByproductSettlementsByPayee()
            const exportBatch = this.dateConfiguration?.exportBatch
            const { blob, filename } = (isSplitSettlement)
              ? await generateZippedSettlementReport(settlementsByPayee, { exportBatch })
              : await generateSinglePDFSettlementReport(settlementsByPayee, { exportBatch })
            saveAs(blob, filename)
          })
          break
        case 'register':
        case 'bpRegister':
        case 'receivableRegister':
        case 'bpReceivableRegister':
        case 'bpPaymentRegister':
        case 'paymentRegister':
          createPaymentRegisterPDF(this.currentSubModule,
            this.dateConfiguration.exportBatch,
            this.isByProducts)
          break
        default:
          break
      }
    },

    async performLoadingAction (asyncLoadingAction) {
      this.setLoading(true)
      try {
        await asyncLoadingAction()
      } catch (error) {
        console.error(error)
        this.setSnackError({
          message: error.message,
          code: 'NO_DATA',
          source: ErrorSource.WebClient
        })
      } finally {
        this.setLoading(false)
      }
    },

    openCSVExport () {
      if (this.dateConfiguration.payPeriod === undefined) {
        return
      }

      if (this.dateConfiguration.exportBatch !== undefined) {
        this.csvDialog = true
        return
      }

      this.setSnackError(this.$t('unavailableCSV'))
    },

    exportPayPeriod () {
      this.$emit('export-button-pressed')
    },

    openTicketPDFPrint () {
      this.$emit('open-ticket-pdf-print')
    },

    openIntegrationsDialog () {
      this.$emit('open-integrations-dialog')
    },

    getExportButtonText () {
      if (this.dateConfiguration.payPeriod) {
        switch (this.dateConfiguration.payPeriod.status) {
          case PayPeriodStatus.Closed.value:
            return this.$t('exportPayPeriod')
          case PayPeriodStatus.Open.value:
            return this.$t('cannotExport')
          case PayPeriodStatus.Inactive.value:
            return this.$t('notAvailable')
          case PayPeriodStatus.Exported.value:
            return this.$t('exported')
          default:
            return 'EXCEPTION'
        }
      } else {
        return this.$t('cannotExport')
      }
    },

    viewRateVerification () {
      this.$emit('open-rate-verification-dialog')
    }
  }
}
</script>
