<template>
  <v-card min-height="90vh" id="tract-detail-card">

    <v-card-title :class="this.mobileCompatible ? 'secondary pa-0 flex-nowrap' : 'secondary'">

      <DialogWithTabsTitle
        @menu-clicked="navDrawer = !navDrawer"
        :mobileCompatible="mobileCompatible"
        :titleText="titleText"
        hideRefresh
        @close="removeTractFilter()"
        @fullscreen-toggled="handleFullscreenToggled"
        :tabs="tabs"
        @tab-clicked="onTabClicked"
        :enlargeTabs="enlargeNavigationTabs"
        v-if="!loading"
      >
        <template #append-title>
          <TractStatusIcon v-if="tract" :status="tract.status" isHeader />
          <Icon v-if="tract?.paused" icon="mdi-pause-circle-outline" iconColor="tertiary" :small="false"
            :tooltipText="$t('tractPaused')" />
        </template>
        <template #prepend-base-actions>
          <v-icon v-if="includeRefresh" @click="onRefresh" medium class="mr-2" color="tertiary">
            mdi-refresh
          </v-icon>
            <v-menu v-if="!loading" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon medium class="mr-2" v-bind="attrs" color="tertiary" data-testid="tract-detail-actions" v-on="on">
                  mdi-dots-vertical
                </v-icon>
              </template>
              <v-list :style="actionMenuStyle">
                <v-list-item v-for="(item, i) in headerActions" :key="i" @click="item.action" :disabled="item.disabled">
                  <v-avatar left>
                    <v-icon color="black">{{ item.icon }}</v-icon>
                  </v-avatar>
                  <v-list-item-title class="subtitle-1">{{ item.tooltipText }}</v-list-item-title>
                </v-list-item>
                <div v-if="mobileCompatible && includeExtraActions">
                  <v-divider style="margin:5% 10% 5% 10%;"></v-divider>
                  <v-list-item v-for="(item, i) in currentExtraActions" :key="i" @click="item.action">
                    <v-avatar left>
                      <v-icon color="black">{{ item.icon }}</v-icon>
                    </v-avatar>
                    <v-list-item-title class="subtitle-1">{{ item.tooltipText }}</v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
        </template>
      </DialogWithTabsTitle>

    </v-card-title>

    <v-card-text :class="mobileCompatible ? 'px-0' : 'mt-3'">
      <v-container fluid fill-height v-if="loading">
        <v-row align="center" justify="center">
          <v-progress-circular indeterminate color="secondary" />
        </v-row>
      </v-container>

      <v-tabs v-model="currentTab" v-if="!loading && !mobileCompatible">
        <v-tab v-for="(item, index) in tabs" :key="index" :value="index">{{ item }}</v-tab>
      </v-tabs>

      <v-window v-model="currentTab" v-if="!loading" touchless>
        <v-window-item :class="mobileCompatible ? '' : 'mt-6'">
          <v-container fluid>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" :lg="emptyNotes ? 10 : 9" :xl="emptyNotes ? 10 : 9">
                <TractInformation :tract="tract" :landowners="landowners"/>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" :lg="emptyNotes ? 2 : 3" :xl="emptyNotes ? 2 : 3">
                <TractNotes :tractId="tractId" @emptyNotes="setEmptyNotes" />
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>
        <v-window-item class="mt-3">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" :class="mobileCompatible ? 'px-0 mt-n3' : 'mt-3'">
                <TractHarvest
                  :tract="tract"
                  :asOfDate="asOfDate"
                  :shouldRefresh="shouldRefreshHarvest"
                  :menuVisible="!mobileCompatible"
                  :class="mobileCompatible ? 'px-0' : ''"
                  :mobileCompatible="mobileCompatible"
                  :distanceFromTract="distanceFromTract"
                  @extra-menu-options="appendExtraMenuOptions"
                  />
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>
        <v-window-item>
          <TractMetrics
            :tract="tract"
            :mobileCompatible="mobileCompatible"
            @extra-menu-options="appendExtraMenuOptions"
          />
        </v-window-item>
        <v-window-item :class="mobileCompatible ? 'ma-1' : 'mt-2 pr-1'">
          <v-row>
            <v-col cols="12" lg="4" xl="3" class="mt-3 pt-0" order-lg="last">
              <TractLocation :tract="tract"/>
            </v-col>
            <v-col cols="12" lg="8" xl="9">
              <InteractiveMap
              v-if="reRender"
              :tracts="[tract]"
              :height="mapHeight"
              :containerId="'tract-detail-card'"
              isLocationMap/>
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item>
          <TractPayments :propTract="tract" @trigger-refresh="triggerRefresh" @bond-changed="onBondChanged" />
        </v-window-item>
        <v-window-item>
          <TractContracts :tractId="tractId" />
        </v-window-item>
        <v-window-item>
          <TractTickets :tractId="tractId" />
        </v-window-item>
        <v-window-item>
          <TractFiles :tract="tract" />
        </v-window-item>
      </v-window>

      <v-dialog v-model="showingTractPtFormDownload" v-if="showingTractPtFormDownload" width="500">
        <TractPtFormDownload v-if="isGeorgia" :tract="tract" @close-form="toggleTractPtFormDownload()" />
      </v-dialog>

      <v-dialog v-model="customDateRangeDialog" width="400px">
        <CustomTicketDateRangeForm :sinceTime="sinceTime" :untilTime="untilTime" @dates-chosen="customTimePeriodChosen"
          @close="customDateRangeDialog = false" />
      </v-dialog>

    </v-card-text>

    <Dialog :stateId="dialogId" @dialog-closing="resetDialogs">
      <ModificationHistory entityType="tract" :entityId="tractId" :entityName="$t('tract')" :headerStyle="headerStyle" />
    </Dialog>

  </v-card>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { formatMoney, numberWithCommas } from '@/utils/NumericMutations.js'
import { TractTypeCategory, UserClaims } from '@/utils/Enumerations.js'
import { getTicketPDF } from '@/utils/pdf-formatters/Ticket.js'
import { uniqueDialogId } from '../../../utils/componentHelpers'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { mapResizeTimeout } from '@/utils/MapHelpers'

export default {
  name: 'TractDetail',
  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    InteractiveMap: () => import('@/components/maps/InteractiveMap.vue'),
    TractInformation: () => import('@/components/tract/tract-detail/TractInformation.vue'),
    CustomTicketDateRangeForm: () => import('@/components/tract/tract-detail/CustomTicketDateRangeForm.vue'),
    TractHarvest: () => import('./TractHarvest.vue'),
    TractMetrics: () => import('./TractMetrics.vue'),
    TractPtFormDownload: () => import('./TractPtFormDownload.vue'),
    TractPayments: () => import('@/components/tract/tract-payables/TractPayables.vue'),
    TractNotes: () => import('@/components/tract/tract-detail/TractNotes.vue'),
    TractContracts: () => import('@/components/tract/tract-detail/TractContracts.vue'),
    TractTickets: () => import('@/components/tract/tract-detail/TractTickets.vue'),
    ModificationHistory: () => import('@/components/admin/ModificationHistory.vue'),
    TractStatusIcon: () => import('@/components/tract/TractStatusIcon.vue'),
    TractFiles: () => import('@/components/tract/tract-form/TractFiles.vue'),
    DialogWithTabsTitle: () => import('@/components/core/DialogWithTabsTitle.vue'),
    TractLocation: () => import('@/components/tract/tract-detail/TractLocation.vue')
  },

  props: {
    tractId: {
      type: Number,
      required: true
    },
    tabToOpen: {
      type: Number,
      default: 0
    },
    asOfDate: {
      type: String,
      default: null
    },
    distanceFromTract: {
      type: Number,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    units: ['imperial', 'metric'],
    customDateRangeDialog: false,
    reRender: false,
    currentTab: 0,
    tract: undefined,
    loading: true,
    showingTractPtFormDownload: false,
    sinceTime: '',
    untilTime: '',
    emptyNotes: true,
    shouldRefreshHarvest: false,
    dialogId: uniqueDialogId('tract-audit-entries'),
    mobileCompatible: false,
    navDrawer: false,
    enlargeNavigationTabs: false,
    windowHeight: undefined,
    extraActions: [],
    mapHeight: '69vh',
    resizeObserver: undefined,
    landowners: []
  }),

  computed: {
    ...mapGetters('user', ['companyInfo']),
    ...mapGetters('tract-contents', ['allTractContents']),
    ...mapGetters('ticket', ['filters']),

    isGeorgia () {
      if (!this.tract) return false
      const tractState = this.tract.spot.countrySubdivision || ''
      return tractState === 'GA'
    },

    titleText () {
      if (!this.tract) return ''
      return this.tract.name
    },

    subdivision () {
      if (!this.tract) { return '' }
      if (this.tract.spot.countrySecondarySubdivision !== '' && this.tract.spot.countrySubdivision !== '') {
        return `${this.tract.spot.countrySecondarySubdivision}, ${this.tract.spot.countrySubdivision}`
      }
      return ''
    },

    headerActions () {
      const actions = [
        {
          icon: 'mdi-printer',
          tooltipText: this.$t('printTickets'),
          action: this.openCustomDateRange
        },
        {
          icon: 'mdi-pencil',
          tooltipText: this.$t('editTractDetail'),
          action: this.editTract,
          disabled: !userAssignedClaim(UserClaims.ContractManager)
        },
        {
          icon: 'mdi-history',
          tooltipText: this.$t('viewModifications'),
          action: this.viewModificationHistory
        }
      ]

      if (this.isGeorgia) {
        actions.unshift({
          icon: 'mdi-file-pdf-box',
          tooltipText: this.$t('downloadPTForm'),
          action: this.toggleTractPtFormDownload,
          large: true
        })
      }
      return actions
    },

    loggerHeaders () {
      return [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Code',
          value: 'code'
        }
      ]
    },

    headerStyle () {
      return 'background-color: #d15f27; color: white;'
    },

    tabs () {
      return [
        this.$t('properties'),
        this.$t('tractCostAnalysis'),
        this.$t('metrics'),
        this.$t('location'),
        this.$t('payments'),
        this.$t('contracts'),
        this.$t('tickets'),
        this.$t('files')
      ]
    },

    actionMenuStyle () {
      const h = this.windowHeight
      const maxHeight = h - 25
      if (h > 800) {
        return {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          maxHeight: `${maxHeight}px`
        }
      } else {
        return {}
      }
    },

    includeExtraActions () {
      return !!this.extraActions.find(a => a.tab === this.tabs[this.currentTab])
    },

    currentExtraActions () {
      if (!this.includeExtraActions) return undefined
      return this.extraActions.find(a => a.tab === this.tabs[this.currentTab])?.actions.filter(a => !!a.icon)
    },

    includeRefresh () {
      return this.mobileCompatible && !!this.extraActions.find(a => a.tab === this.tabs[this.currentTab])?.actions.find(a => a.id === 'refresh')
    }
  },

  async mounted () {
    this.onMobileInverted()
    window.addEventListener('resize', this.onMobileInverted)
    this.resizeObserver = new ResizeObserver(this.setMapHeight).observe(document.getElementById('tract-detail-card'))
    this.loading = true
    try {
      await this.fetchTractData()
      this.currentTab = this.tabToOpen
      this.reRender = !this.reRender
    } finally {
      this.loading = false
    }
  },

  beforeDestroy () {
    document.removeEventListener('resize', this.onMobileInverted)
    if (this.resizeObserver) this.resizeObserver.unobserve()
  },

  methods: {
    ...mapMutations('global', ['setRouterJump']),
    ...mapMutations('ticket', ['applyFilter']),
    ...mapActions('tract', ['fetchTract', 'fetchTractTickets', 'getWeatherForTract', 'fetchTractLandowners']),
    ...mapMutations('ticket', ['removeFilter']),
    ...mapActions('dialog', ['openOrUpdateDialog']),

    formatMoney,
    numberWithCommas,

    categoryFromInt: (x) => TractTypeCategory.fromInt(x),

    toggleTractPtFormDownload () {
      this.showingTractPtFormDownload = !this.showingTractPtFormDownload
    },

    editTract () {
      this.$emit('edit-tract', this.tract)
    },

    openCustomDateRange () {
      this.customDateRangeDialog = true
    },

    async printTractTickets () {
      const requestObj = {
        tractId: this.tract.tractId,
        sinceTime: this.sinceTime,
        untilTime: this.untilTime
      }
      const tractTickets = await this.fetchTractTickets(requestObj)
      if (tractTickets.length > 0) {
        getTicketPDF(tractTickets, this.companyInfo, [this.tract])
      } else {
        this.setSnackError('No available tickets for selection.')
      }
    },

    customTimePeriodChosen (dateRange) {
      this.customDateRangeDialog = false
      this.sinceTime = dateRange.sinceTime
      this.untilTime = dateRange.untilTime
      this.printTractTickets()
    },

    setEmptyNotes (val) {
      this.emptyNotes = val
    },

    removeTractFilter () {
      if (this.filters?.filter) {
        var filter = this.filters?.filter(f => f.name === 'tract')[0]
        this.removeFilter(filter)
      }
    },

    viewModificationHistory () {
      this.openOrUpdateDialog({ id: this.dialogId, width: '75vw' })
      this.auditChanges = true
    },

    resetDialogs () {
      this.auditChanges = false
    },

    triggerRefresh () {
      this.shouldRefreshHarvest = true
      this.$nextTick(() => {
        this.shouldRefreshHarvest = false
      })
    },

    onMobileInverted () {
      this.setMapHeight()
      this.windowHeight = window.innerHeight
      if (window.innerWidth < 991) {
        this.mobileCompatible = true
        this.enlargeNavigationTabs = window.innerHeight > 600
      } else {
        this.mobileCompatible = false
      }
    },

    navItemClicked (e) {
      this.currentTab = e
    },

    appendExtraMenuOptions (extraMenuOptions) {
      if (!this.extraActions.find(ea => ea.tab === extraMenuOptions.tab)) {
        this.extraActions.push(extraMenuOptions)
      } else {
        this.extraActions.find(ea => ea.tab === extraMenuOptions.tab).actions = extraMenuOptions.actions
      }
    },

    handleFullscreenToggled () {
      setTimeout(() => this.setMapHeight(), mapResizeTimeout)
    },

    setMapHeight () {
      const height = document.getElementById('tract-detail-card')?.offsetHeight - 180
      this.mapHeight = height ? height > 250 ? `${height}px` : '250px' : '69vh'
    },

    onTabClicked (t) {
      this.currentTab = t
    },

    onBondChanged () {
      this.fetchTractData()
      this.$emit('changed')
    },

    async fetchTractData () {
      const tractResponse = await this.fetchTract(this.tractId)
      this.tract = tractResponse
      this.tract.bondBalance = tractResponse.tractPayables.filter(p => p.performanceBond).reduce((acc, pb) => acc + pb.amount, 0)
      this.landowners = await this.fetchTractLandowners(this.tractId)
    },

    onRefresh () {
      if (this.includeRefresh) {
        const action = this.extraActions.find(a => a.tab === this.tabs[this.currentTab])?.actions?.find(a => a.id === 'refresh')?.action
        action()
      }
    }
  }
}

</script>
