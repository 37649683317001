<template>
  <v-card :max-width="width" :max-height="height" :data-testid="dataTestid" :width="width">
    <v-card-title :class="`white--text headline ${color}`" style="word-break: break-word">
      {{title}}
    </v-card-title>
    <v-card-text class="mt-6">
      <p class="subtitle-1 black--text font-weight-medium" style="white-space: pre-line">{{ body }}</p>
      <slot name="custom-body"></slot>
      <slot name="progress-bar"></slot>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="canCancel" class="ml-n4" text color="black" @click="$emit('cancel')" data-testid="cancel" ref="cancelButton">{{cancelButtonText()}}</v-btn>
      <v-spacer/>
      <v-btn :class="color" class="mr-n4" @click="$emit('confirm')" data-testid="confirm" ref="submitButton">{{confirmButtonText()}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ConfirmDialog',

  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    confirmText: {
      type: String,
      required: false
    },
    cancelText: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false,
      default: 'error'
    },
    dataTestid: {
      type: String,
      required: false,
      default: 'confirm-dialog'
    },
    canCancel: {
      type: Boolean,
      required: false,
      default: true
    },
    width: {
      type: String,
      required: false,
      default: '400px'
    },
    height: {
      type: String,
      default: undefined,
      required: false
    },
    initialFocus: {
      type: String,
      required: false,
      default: undefined
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (!this.initialFocus) return

      if (this.initialFocus === 'submit') this.$refs.submitButton.$el.focus()
      else if (this.initialFocus === 'cancel') this.$refs.cancelButton.$el.focus()
      else console.error(`Invalid initial focus argument '${this.initialFocus}'. Must be one of 'submit' | 'cancel'`)
    })
  },

  methods: {
    confirmButtonText () {
      if (this.confirmText === undefined) {
        return this.$t('delete')
      } else {
        return this.confirmText
      }
    },
    cancelButtonText () {
      if (this.cancelText === undefined) {
        return this.$t('cancel')
      } else {
        return this.cancelText
      }
    }
  }
}
</script>
