<template>
<ShortcutWrapper :nShortcutRequiredClaim="UserClaims.ContractManager" @n-shortcut="mutateTractType(null, 'create')">
  <v-container fill-height fluid grid-list-xl data-testid="tract-type-table">
    <v-row dense>
      <v-col cols="12" xs="12">
        <v-dialog v-model="tractTypeForm" max-width="900">
          <TractTypeForm
            v-if="tractTypeForm"
            :propTractType="focusedTractType"
            @close="close"
            @tract-type-mutated="tractTypeMutated"
          />
        </v-dialog>
        <material-card
          color="secondary"
          full-width
          :title="$t('tractTypes')"
          :text="$t('tractTypeDescription')"
        >
          <v-card flat>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                single-line
                clearable
                data-testid="tract-type-search"
                color="secondary"
                :label="$t('searchTractTypes')"
              />
              <v-tooltip bottom color="secondary">
                <template v-slot:activator="{ on }">
                  <v-switch
                    color="secondary"
                    small
                    class="ml-5"
                    v-model="dense"
                    v-on="on"
                  ></v-switch>
                </template>
                <span class="white--text subtitle-1">{{
                  $t("denseView")
                }}</span>
              </v-tooltip>
              <v-btn icon color="secondary" @click="refreshTractTypes">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                rounded
                icon
                color="secondary"
                data-testid="tract-type-create-button"
                @click="mutateTractType(null, 'create')"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :items="allTractTypes"
                :headers="headers"
                :search="search"
                :dense="dense"
                :loading="loading"
              >
                <template #item.collectPieceInfo="{ item }">
                  <v-icon v-if="item.collectPieceInfo" color="success"
                    >mdi-checkbox-marked-circle</v-icon
                  >
                </template>
                <template #item.category="{ item }">
                  {{ categoryFromInt(item.category) }}
                </template>
                <template #item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    color="success"
                    data-testid="tract-type-edit-button"
                    @click="mutateTractType(item, 'edit')"
                    :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon
                    small
                    color="error"
                    data-testid="tract-type-delete-button"
                    @click="mutateTractType(item, 'delete')"
                    :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                    >mdi-delete-forever</v-icon
                  >
                </template>
              </v-data-table>
              <v-dialog width="400px" v-model="confirmDeleteDialog">
                <ConfirmDelete
                  v-if="confirmDeleteDialog"
                  :title="$t('tractType')"
                  @delete="deleteItem"
                  @cancel-delete="close"/>
              </v-dialog>
            </v-card-text>
          </v-card>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</ShortcutWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TractTypeCategory, UserClaims } from '@/utils/Enumerations.js'
import TractTypeHeaders from '@/headers/TractType'
import { userAssignedClaim } from '../../../utils/ClaimUtility'

export default {
  name: 'TractTypeSettings',

  components: {
    ConfirmDelete: () => import('../../helper/ConfirmDelete.vue'),
    TractTypeForm: () => import('./TractTypeForm.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue')
  },

  created () {
    this.refreshTractTypes()
  },

  computed: {
    ...mapGetters('tract-type', ['allTractTypes']),

    headers () {
      return TractTypeHeaders.tractTypeHeaders(this.$i18n.locale)
    }
  },

  data: () => ({
    tractTypeForm: false,
    confirmDeleteDialog: false,
    dense: true,
    loading: false,
    search: '',
    focusedTractType: undefined,
    UserClaims
  }),

  watch: {
    tractTypeForm (val) {
      if (!val) {
        this.focusedTractType = undefined
      }
    },

    confirmDeleteDialog (val) {
      if (!val) {
        this.focusedTractType = undefined
      }
    }
  },

  methods: {
    ...mapActions('tract-type', ['fetchTractTypes', 'deleteTractType']),
    userAssignedClaim,

    categoryFromInt: (x) => TractTypeCategory.fromInt(x),

    refreshTractTypes () {
      this.loading = true
      this.fetchTractTypes()
        .finally(() => {
          this.loading = false
        })
    },

    mutateTractType (tractType, mutationType) {
      this.focusedTractType = tractType

      switch (mutationType) {
        case 'delete':
          this.confirmDeleteDialog = true
          break
        case 'edit':
          this.tractTypeForm = true
          break
        case 'create':
          this.tractTypeForm = true
          break
      }
    },

    async deleteItem () {
      await this.deleteTractType(this.focusedTractType.tractTypeId)
      this.tractTypeMutated()
    },

    tractTypeMutated () {
      this.refreshTractTypes()
      this.close()
    },

    close () {
      this.confirmDeleteDialog = false
      this.tractTypeForm = false
    }
  }
}
</script>
