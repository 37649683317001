<template>
  <v-card>
    <v-card-title :class="`white--text headline ${color}`" style="word-break: break-word">
      <v-col class="pa-0">
        {{ dialogTitle }}
      </v-col>
      <v-col cols="auto" class="pa-0">
        <BaseDialogActions hideRefresh @close="handleClose"/>
      </v-col>
    </v-card-title>
    <v-card-text class="mt-6">
      <v-row align="start" dense>
        <v-col>
          <v-chip-group column>
            <v-col cols="auto" class="py-2 px-0">
              <Icon
              v-if="showResetButton"
              icon="mdi-arrow-u-left-top-bold"
              :small="false"
              iconColor="black"
              tooltipColor="black"
              tooltipText="Reset Filters"
              @icon-clicked="resetPressed"
              />
            </v-col>
            <v-chip
            v-for="chip in chips"
            :key="`${chip.key}: ${chip.value}`"
            text-color="white"
            :color="chip.color ?? 'black'"
            class="mx-2"
            :active="chipActive(chip)"
            :close="chip.close"
            @click:close="chipDeactivated(chip)"
            >
              <v-avatar left>
                <v-col>
                  <Icon
                  :icon="chip.icon"
                  :small="false"
                  iconColor="white"
                  tooltipColor="black"
                  :tooltipText="chip.key"
                  />
                </v-col>
              </v-avatar>
              <span>
                {{chip.value}}
              </span>
            </v-chip>
          </v-chip-group>
        </v-col>
        <v-col cols="auto" class="mt-2">
          <Icon
          :icon="filterEditMode ? 'mdi-filter-check' : 'mdi-filter-plus'"
          :small="false"
          iconColor="black"
          tooltipColor="black"
          :tooltipText="filterEditMode ? $t('doneAddingFilters') : $t('addFilters')"
          @icon-clicked="filterEditMode = !filterEditMode"
          />
        </v-col>
        <v-col cols="auto" class="mt-2">
          <TabbedColumnAdjuster
          v-if="columnSet"
          :propColumnSet.sync="columnSet"
          :propTabs="columnAdjusterTabs"
          @new-columns-selected="newColumnsSelected"
          showReset
          @reset="resetColumns"
          dynamicSize
          removeFromTabIndex
          />
        </v-col>
        <v-col cols="12" v-if="filterEditMode">
          <v-row align="center" dense>
            <v-col>
              <v-row align="center" dense justify="end">
                <v-col cols="12" md="6" xl="auto">
                  <v-select
                    clearable
                    :items="getFilterTypes"
                    return-object
                    :value="filterType"
                    :label="$t('filterBy')"
                    outlined
                    rounded
                    dense
                    class="mb-n6 mr-2"
                    style="min-width:260px"
                    color="black"
                    @input="setFilterType"
                  >
                    <template #item="{ item, on }">
                      <v-list-item v-on="on">
                        <v-icon class="mr-2">{{getIconForObjectType(item.key)}}</v-icon>
                        <span>{{item.name}}</span>
                      </v-list-item>
                    </template>
                    <template #selection="{ item } ">
                      <template>
                        <v-icon class="mr-2">{{getIconForObjectType(item.key)}}</v-icon>
                        <span>{{item.name}}</span>
                      </template>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" xl="auto">
                  <v-select
                    clearable
                    :items="filterOptions"
                    return-object
                    :value="selectedFilter"
                    :label="filterType?.name"
                    outlined
                    rounded
                    dense
                    class="mb-n6 mr-2"
                    style="min-width:260px"
                    color="black"
                    :disabled="!filterType"
                    @input="setFilter"
                  >
                    <template #item="{ item, on }">
                      <v-list-item v-on="on">
                        <span>{{item}}</span>
                      </v-list-item>
                    </template>
                    <template #selection="{ item } ">
                      <template>
                        <span>{{item}}</span>
                      </template>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-data-table
          :items-per-page="10"
          :items.sync="landownerSortableTickets"
          :headers.sync="headers"
          sort-desc
          sort-by="ticketNumber"
          :loading="loading"
          :hideDefaultFooter="tickets?.length < 11"
          >
            <template v-slot:item.ticketNumber="{ item }">
              <span id="table-shortcut" @click="openTicketDetails(item.ticketId)">{{item.ticketNumber}}</span>
            </template>
            <template v-slot:item.loadCreatedAt="{ item }">
              {{ utcToLocalDate(item.loadCreatedAt, 'L - LT') }}
            </template>
            <template v-slot:item.grossWeight="{ item }">
              {{ tonStringFromPounds(item.grossWeight) }}
            </template>
            <template v-slot:item.netWeight="{ item }">
              {{ tonStringFromPounds(item.netWeight) }}
            </template>
            <template v-slot:item.departureWeight="{ item }">
              {{ tonStringFromPounds(item.departureWeight) }}
            </template>
            <template v-slot:item.defectWeight="{ item }">
              {{ tonStringFromPounds(item.defectWeight) }}
            </template>
            <template v-slot:item.landownerBundle="{ item }">
              <span v-if="item.landownerCount < 2">{{ item.tractLandOwnerName ?? $t('notAvailable') }}</span>
              <Icon
              v-else
              iconColor="black"
              icon="mdi-dots-horizontal"
              tooltipColor="black"
              :tooltipText="$t('multipleLandowners')"
              :small="false" />
            </template>
            <template v-slot:item.hauler="{ item }">
              {{ item.hauler ?? $t('notAvailable') }}
            </template>
            <template v-slot:item.supplier="{ item }">
              {{ item.supplier ?? $t('notAvailable') }}
            </template>
            <template v-slot:item.logger="{ item }">
              {{ item.logger ?? $t('notAvailable') }}
            </template>
            <template v-slot:item.driver="{ item }">
              <AccountAutocomplete
                v-if="widget === DashboardOperationalWidgets.IncompleteTickets && item.status === TicketStatus.WeighedOut.value && !loading"
                hideRefresh
                :fetchTagsAndAccounts="false"
                :title="$t('driver')"
                :accountId="driverSelectionByTicketId.get(item.ticketId)"
                :propSelectedTags="selectedTags"
                @account-chosen="accountChosen(item.ticketId, $event)"
                :key="item.ticketId"
                singleLine
                class="ma-n1 mb-n2"
                style="min-width: 250px;"
                isDashboard
              >
              <template v-slot:append>
                <v-chip v-for="tag in selectedTags" x-small :key="tag.tagId" close @click:close="tagClicked(tag)">
                  {{tag.value}}
                </v-chip>
              </template>
                <template v-slot:append-outer>
                  <v-menu
                  offset-y
                  style="max-width: 600px"
                  :close-on-content-click="false"
                  >
                    <template #activator="{on}">
                      <v-btn
                      icon
                      v-on="on"
                      class="my-n2 ml-n4 mr-n2"
                      color="secondary"
                      tabindex="-1"
                      :disabled="tags.length < 1"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="tagClicked(tag)" v-for="tag in tags" :key="tag.tagId">
                        <v-list-item-title>
                          {{tag.value}}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    icon
                    class="my-n2 mr-n2"
                    color="secondary"
                    tabindex="0"
                    @click="saveTicket(item.ticketId)"
                    :disabled="!driverSelectionByTicketId.get(item.ticketId)"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </template>
              </AccountAutocomplete>
              <v-progress-linear
                v-else-if="widget === DashboardOperationalWidgets.IncompleteTickets && item.status === TicketStatus.WeighedOut.value"
                rounded
                indeterminate
                style="min-width: 250px;"
              />
              <span v-else>
                {{ item.driver ?? $t('notAvailable') }}
              </span>
            </template>
            <template v-slot:item.consultingForester="{ item }">
              {{ item.consultingForester ?? $t('notAvailable') }}
            </template>
            <template v-slot:item.deckName="{ item }">
              {{ item.deckName ?? $t('notAvailable') }}
            </template>
            <template v-slot:item.weighedOutAt="{ item }">
              {{ utcToLocalDate(item.weighedOutAt, 'L - LT') ?? $t('notAvailable') }}
            </template>
            <template v-slot:item.actions="{ item }">
              <Icon
              icon="mdi-information-outline"
              :small="false"
              :tooltipText="$t('viewTicket')"
              @icon-clicked="openTicketDetails(item.ticketId)"/>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <Dialog :stateId="dialogId" @dialog-closing="resetDialogs">
      <TicketDetail :ticketId="ticketId" :contractMode="localContractMode"/>
    </Dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TicketStatus, ContractModes } from '@/utils/Enumerations.js'
import TicketQuery from '@/model/TicketParams'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { ticketFiltersForDashboard } from '@/utils/TicketFiltering'
import getIconForObjectType from '@/utils/IconHelpers'
import { tonStringFromPounds } from '@/utils/NumericMutations'
import TicketHeaders from '@/headers/Ticket'
import { hasTouchScreen } from '@/utils/DeviceUtility.js'
import { DashboardLogsWidgets, DashboardOperationalWidgets, AccountLoggerToggle } from '@/components/dashboard/WidgetMappings'
import { SETTING_TYPES, SETTING_KEYS } from '@/utils/UserSettings.js'

export default {
  name: 'WidgetTickets',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    TicketDetail: () => import('@/components/ticket/ticket-details/TicketDetails.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    TabbedColumnAdjuster: () => import('@/components/helper/TabbedColumnAdjuster.vue'),
    AccountAutocomplete: () => import('@/components/autocomplete/AccountAutocomplete.vue')
  },

  props: {
    widget: {
      type: Object,
      required: true
    },
    requestObject: {
      type: Object,
      required: false,
      default: undefined
    },
    contractMode: {
      type: Object,
      required: true
    },
    primaryFilterValue: {
      type: String,
      required: false,
      default: undefined
    },
    secondaryFilterValue: {
      type: String,
      required: false,
      default: undefined
    },
    primaryFilterKey: {
      type: String,
      required: false,
      default: undefined
    },
    secondaryFilterKey: {
      type: String,
      required: false,
      default: undefined
    },
    data: {
      type: Object,
      required: true
    },
    details: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    hasTouchScreen,
    TicketHeaders,
    ContractModes,
    TicketStatus,
    DashboardOperationalWidgets,
    chips: [],
    ticketFilter: undefined,
    tickets: undefined,
    ticketDetailsOpen: false,
    ticketId: undefined,
    localFilterData: {},
    filterEditMode: false,
    filterType: undefined,
    selectedFilter: undefined,
    filterOptions: undefined,
    dialogId: 'dashboard-widget-ticket-details',
    showResetButton: false,
    loading: true,
    columnSet: null,
    emptyTicketQuery: new TicketQuery({
      includeOffice: false,
      includeReceiver: false,
      includeLoader: false,
      includeYardOperator: false,
      includeTransporter: false,
      includeKiosk: false,
      weighedIn: false,
      readyForPayment: false,
      inTransit: false,
      posted: false,
      exported: false
    }),
    driverSelectionByTicketId: new Map(),
    tags: [],
    selectedTags: [],
    showTagMenu: false,
    filteredAccounts: undefined
  }),

  watch: {
    selectedTags () {
      const createMutatedSetting = (type, key, value) => ({ type, key, value })

      this.mutateUserSetting(
        createMutatedSetting(
          SETTING_TYPES.TRACT_FORM,
          SETTING_KEYS.DRIVER_ACCOUNT_AUTOCOMPLETE,
          this.selectedTags.map(tag => tag.tagId))
      )
    }
  },

  async mounted () {
    this.initializeHeaders()
    this.resetLocalFilterData()
    this.initializeChips()
    await this.getTickets()
    if (this.widget === DashboardOperationalWidgets.IncompleteTickets) {
      await this.fetchAccounts()
      const response = await this.fetchTags(0)
      this.tags = response
      this.checkUserSettings()
    }
    this.loading = false
  },

  computed: {
    ...mapGetters('ticket', ['allTickets', 'filter']),
    ...mapGetters('account', ['allAccounts']),
    ...mapGetters('user-settings', ['mutatedUserSettings']),

    headers () {
      if (!this.columnSet) return []
      return this.columnSet.getColumns()
    },

    landownerSortableTickets () {
      return this.tickets?.map(t => Object.assign(t, { landownerBundle: JSON.stringify({ loAccount: t.tractLandOwnerName, loCount: t.landownerCount }) }))
    },

    dialogTitle () {
      switch (this.widget) {
        case DashboardLogsWidgets.DefectsByAccount: return `${this.details?.mismanufactureOnly ? this.$t('mismanufacture').concat(' ') : ''}${this.$t(this.primaryFilterKey === 'logger' ? 'defectsByLogger' : this.widget.label)}`
        case DashboardLogsWidgets.ItemizedDefectSummary: return `${this.details?.mismanufactureOnly ? this.$t('mismanufacture').concat(' ') : ''}${this.$t(this.widget.label)}`
        case DashboardOperationalWidgets.LoadsReadyForPickup: return this.$t(this.data.paused ? 'pausedLoads' : this.widget.label)
        default: return this.$t(this.widget.label)
      }
    },

    getFilterTypes () {
      const additionalFilterTypes = []
      if (this.primaryFilterKey && (this.primaryFilterKey !== 'destination' || this.allowFilterByDestination)) additionalFilterTypes.push(this.primaryFilterKey)
      if (this.secondaryFilterKey && (this.secondaryFilterKey !== 'destination' || this.allowFilterByDestination)) additionalFilterTypes.push(this.secondaryFilterKey)
      return ticketFiltersForDashboard(
        this.localContractMode.value === this.logsMode,
        this.allowFilterByDestination,
        additionalFilterTypes
      )
    },

    actionMenuStyle () {
      const h = this.windowHeight
      const maxHeight = h - 25
      if (h > 800) {
        return {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          maxHeight: `${maxHeight}px`
        }
      } else {
        return {}
      }
    },

    columnAdjusterTabs () {
      return ['load', 'misc', 'contract', 'weight']
    },

    logsMode () {
      return ContractModes.find(m => m.text === this.$t('logs')).value
    },

    byproductsMode () {
      return ContractModes.find(m => m.text === this.$t('byproducts')).value
    },

    allowFilterByDestination () {
      return !this.requestObject || this.requestObject.location.locationId < 1
    },

    getDataIndexForDefectsByAccount () {
      return AccountLoggerToggle.forLabel(this.primaryFilterKey).value
    },

    pausedStateStringForData () {
      return this.data.paused ? 'pausedLoads' : 'readyLoads'
    },

    pausedStateStringForTicketIds () {
      return this.data.paused ? 'paused' : 'ready'
    },

    includeTimePeriodChip () {
      return this.primaryFilterKey !== 'date' && this.widget !== DashboardLogsWidgets.TrucksInYard
    },

    allowClosePrimaryKey () {
      return this.primaryFilterKey !== 'date'
    },

    localContractMode () {
      return this.widget === DashboardLogsWidgets.TransferLoads ? ContractModes.find(cm => cm.text === this.$t('transfers')) : this.contractMode
    },

    color () {
      switch (this.localContractMode.text) {
        case this.$t('byproducts'): return 'byproduct'
        case this.$t('transfers'): return 'grey'
        default: return 'secondary'
      }
    },

    dataSetIndex () {
      if (this.data.miniBarType) {
        return this.data.dataSets.labels.findIndex(l => l === this.primaryFilterValue)
      } else if (this.data.data) {
        const data = this.widget === DashboardLogsWidgets.DefectsByAccount ? this.data.data[this.getDataIndexForDefectsByAccount] : this.data.data
        return data.findIndex(d => d[this.data.key] === this.primaryFilterValue)
      } else {
        return undefined
      }
    }
  },

  methods: {
    tonStringFromPounds,
    getIconForObjectType,
    utcToLocalDate,
    ...mapActions('ticket', ['fetchTickets']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('ticket', ['updateTicket']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('tags', ['fetchTags']),
    ...mapActions('dashboard', ['fetchIncompleteTickets']),

    ...mapMutations('user-settings', ['mutateUserSetting']),

    async getTickets () {
      this.setTicketRequestFilter()
      let ticketIds = []

      switch (this.widget) {
        case DashboardLogsWidgets.DefectsByAccount: {
          const itemizedDefects = this.data.data[this.getDataIndexForDefectsByAccount].find(d => d.accountName === this.primaryFilterValue).itemizedDefects
          ticketIds = this.secondaryFilterValue
            ? itemizedDefects.find(d => d.defectName === this.secondaryFilterValue).ticketIds
            : itemizedDefects.reduce((a, d) => {
              return [...new Set(a.concat(d.ticketIds))]
            }, [])
          break
        }
        case DashboardOperationalWidgets.LoadsReadyForPickup: {
          ticketIds = this.data.dataSets.sets.reduce((a, d) => {
            if (!this.secondaryFilterValue || this.secondaryFilterValue === d.label) {
              return [...new Set(a.concat(d.ticketIds[this.dataSetIndex][this.pausedStateStringForTicketIds]))]
            }
            return a
          }, [])
          break
        }
        default: {
          ticketIds = this.data.ticketIds
            ? this.data.ticketIds
            : this.data.miniBarType
              ? this.secondaryFilterValue
                ? this.data.dataSets.sets.find(ds => ds.label === this.secondaryFilterValue).ticketIds[this.dataSetIndex]
                : this.data.dataSets.sets.reduce((a, d) => {
                  return [...new Set(a.concat(d.ticketIds[this.dataSetIndex]))]
                }, [])
              : this.data.data.find(d => d[this.data.key] === this.primaryFilterValue || (d[this.data.key] === null && this.primaryFilterValue === 'N/A')).ticketIds
        }
      }
      if (ticketIds.length > 0) this.tickets = await this.fetchTickets({ ...this.ticketFilter, ticketIds: ticketIds })
    },

    resetLocalFilterData () {
      this.localFilterData.filterBy = []
      if (this.primaryFilterValue && (this.allowFilterByDestination || this.primaryFilterKey !== 'destination')) {
        this.localFilterData.filterBy.push({
          key: this.primaryFilterKey ?? this.data.type,
          values: [this.primaryFilterValue]
        })
      }
      if (this.secondaryFilterValue && (this.allowFilterByDestination || this.secondaryFilterKey !== 'destination')) {
        this.localFilterData.filterBy.push({
          key: this.secondaryFilterKey,
          values: [this.secondaryFilterValue]
        })
      }
    },

    setTicketRequestFilter () {
      this.ticketFilter = new TicketQuery({
        ...this.filter,
        posted: true,
        exported: true
      })
    },

    async filterFetchedTickets (makeServerCall = true) {
      if (makeServerCall) {
        const primaryFilters = this.localFilterData.filterBy.find(fc => fc.key === this.primaryFilterKey)?.values
        const secondaryFilters = this.localFilterData.filterBy.find(fc => fc.key === this.secondaryFilterKey)?.values
        let ticketIds = []

        switch (this.widget) {
          case DashboardLogsWidgets.DefectsByAccount: {
            const relevantData = this.data.data[this.getDataIndexForDefectsByAccount]
            ticketIds = relevantData.filter(d => !primaryFilters || primaryFilters.includes(d[this.data.key])).reduce((a, dataByAccount) => {
              const tn = dataByAccount.itemizedDefects.filter(d => !secondaryFilters || secondaryFilters.includes(d.defectName)).map(d => d.ticketIds)
              return [...new Set(Array.prototype.concat.apply(a, tn))]
            }, [])
            break
          }

          case DashboardOperationalWidgets.LoadsReadyForPickup: {
            const dataIndexes = primaryFilters?.map(v => this.data.dataSets.labels.findIndex(l => l === v))
            ticketIds = this.data.dataSets.sets.reduce((a, d) => {
              if (!secondaryFilters || !this.secondaryFilterValue || secondaryFilters.includes(d.label)) {
                const tn = dataIndexes ? dataIndexes.map(idx => d.ticketIds[idx][this.pausedStateStringForTicketIds]) : d.ticketIds.map(d => d[this.pausedStateStringForTicketIds])
                return [...new Set(Array.prototype.concat.apply(a, tn))]
              }
              return a
            }, [])
            break
          }

          default: {
            const dataForIndex = this.data.data ?? this.data
            const getDataIndexByValue = (v) => dataForIndex.findIndex(d => d[this.data.key] === v || (d[this.data.key] === null && v === 'N/A'))
            if (this.data.miniBarType) {
              ticketIds = this.data.dataSets.sets.reduce((a, dataSet) => {
                if (!secondaryFilters || secondaryFilters.includes(dataSet.label)) {
                  var tn = []
                  if (primaryFilters) {
                    const dataIndexes = primaryFilters.map(v => getDataIndexByValue(v))
                    dataIndexes.forEach(idx => { tn.push(...dataSet.ticketIds[idx] ?? '') })
                  } else {
                    dataSet.ticketIds.forEach(ticketIds => {
                      tn.push(...ticketIds)
                    })
                  }
                  return [...new Set(a.concat(tn))]
                } else {
                  return a
                }
              }, [])
            } else {
              ticketIds =
              this.data.ticketIds ??
              this.data.data
                .filter(d => !primaryFilters || primaryFilters.includes(d[this.data.key]) || (d[this.data.key] === null && primaryFilters.includes('N/A')))
                .reduce((a, d) => [...new Set(a.concat(d.ticketIds))], [])
            }
          }
        }

        if (ticketIds.length > 0) {
          await this.fetchTickets({ ...this.ticketFilter, ticketIds: ticketIds })
        } else {
          await this.fetchTickets(this.emptyTicketQuery)
        }
      }
      const otherFilters = this.localFilterData.filterBy
        .filter(fc => (!this.secondaryFilterValue || fc.key !== this.secondaryFilterKey) && (!this.primaryFilterValue || fc.key !== this.primaryFilterKey) && fc.key !== 'defect')
      if (otherFilters && otherFilters.length > 0) {
        this.tickets = this.allTickets.filter(t => {
          var result = true
          otherFilters.forEach(i => {
            const val = t[i.key] ?? 'N/A'
            if (!i.values.includes(val)) {
              result = false
            }
          })
          return result
        })
      } else {
        this.tickets = this.allTickets
      }
    },

    initializeChips () {
      this.chips = []
      if (this.requestObject) {
        this.chips.push({
          key: `${this.$t('destination')}${this.requestObject.includeExternal || this.requestObject.includeInternal ? 's' : ''}`,
          value: this.requestObject.location.name,
          icon: this.getIconForLocation(),
          close: false,
          active: !this.requestObject.includeExternal // Do not display if viewing all destinations
        })
        this.chips.push({
          key: `${utcToLocalDate(this.requestObject.timePeriod.sinceTime)} - ${utcToLocalDate(this.requestObject.timePeriod.untilTime, 'L - LT').slice(0, 10)}`,
          value: this.$t(this.requestObject.timePeriod.timeLabel),
          icon: 'mdi-clock-outline',
          close: false,
          active: this.includeTimePeriodChip
        })
      }
      this.localFilterData.filterBy.forEach(ft => {
        ft.values.forEach(val => {
          this.chips = this.chips.concat({
            key: this.$t(ft.key),
            value: val,
            icon: this.getIconForObjectType(ft.key),
            close: this.allowClosePrimaryKey,
            active: true
          })
        })
      })
    },

    openTicketDetails (ticketId) {
      this.ticketId = ticketId
      this.openDetailDialog()
    },

    getIconForLocation () {
      if (!this.requestObject || this.requestObject.includeExternal === this.requestObject.includeInternal) {
        return 'mdi-domain'
      } else {
        return 'mdi-home'
      }
    },

    chipDeactivated (chip) {
      chip.active = false
      const valuesForKey = this.localFilterData.filterBy.find(i => this.$t(i.key) === chip.key).values
      const idx = valuesForKey.indexOf(chip.value)
      valuesForKey.splice(idx, 1)
      if (valuesForKey.length < 1) {
        this.localFilterData.filterBy = this.localFilterData.filterBy.filter(i => this.$t(i.key) !== chip.key)
      }
      this.filterFetchedTickets(chip.key === this.$t(this.primaryFilterKey) || chip.key === this.$t(this.secondaryFilterKey))
      this.setFilterOptions()
      this.setResetButton()
      this.setFilter(undefined)
    },

    resetPressed () {
      this.resetLocalFilterData()
      this.initializeChips()
      this.filterFetchedTickets()
      this.setFilterOptions()
      this.setFilter(undefined)
      this.showResetButton = false
    },

    async setFilterOptions () {
      switch (this.filterType?.key) {
        case undefined: {
          this.filterOptions = []
          break
        }

        case this.primaryFilterKey: {
          const chartData = this.widget === DashboardLogsWidgets.DefectsByAccount ? this.data.data[this.getDataIndexForDefectsByAccount] : this.data.data
          this.filterOptions = chartData.reduce((a, d) => {
            const val = d[this.data.key] ?? 'N/A'
            if (!this.localFilterData.filterBy.some(filterCategory => filterCategory.key === this.filterType?.key && filterCategory.values.includes(val))) {
              a.push(val)
            }
            return a
          }, []).sort()
          break
        }

        case this.secondaryFilterKey: {
          switch (this.widget) {
            case DashboardLogsWidgets.DefectsByAccount: {
              this.filterOptions = this.data.data[this.getDataIndexForDefectsByAccount].reduce((a, d) => {
                const defectNames = d.itemizedDefects.map(d => d.defectName).filter(d => !this.localFilterData.filterBy.find(fb => fb.key === 'defect')?.values.includes(d))
                a = [...new Set(Array.prototype.concat.apply(a, defectNames))]
                return a
              }, []).sort()
              break
            }
            default: {
              this.filterOptions = this.data.dataSets.sets.reduce((a, d) => {
                const val = d.label ?? 'N/A'
                if (!this.localFilterData.filterBy.some(filterCategory => filterCategory.key === this.filterType?.key && filterCategory.values.includes(val))) {
                  a.push(val)
                }
                return a
              }, []).sort()
            }
          }
          break
        }

        default: {
          this.filterOptions = this.allTickets.reduce((acc, currentTicket) => {
            const val = currentTicket[this.filterType?.key] ?? 'N/A'
            if (!acc.includes(val) && !this.localFilterData.filterBy.find(filterCategory => filterCategory.key === this.filterType?.key && filterCategory.values.includes(val))) {
              acc.push(val)
            }
            return acc
          }, []).sort()
        }
      }
    },

    setFilterType (f) {
      this.filterType = f
      this.setFilterOptions()
    },

    setFilter (f) {
      this.selectedFilter = f
      if (f) this.addFilter()
    },

    addFilter () {
      const filter = {
        key: this.filterType.key,
        values: [this.selectedFilter]
      }
      const existingFilter = this.localFilterData.filterBy.find(i => i.key === filter.key)
      if (existingFilter) {
        if (existingFilter.values.includes(this.selectedFilter)) {
          this.setSnackError(this.$t('filterAlreadyApplied')) // This is a failsafe and should not appear unless there's a bug
        } else {
          existingFilter.values.push(this.selectedFilter)
        }
      } else {
        this.localFilterData.filterBy.push(filter)
      }

      const existingChip = this.chips.find(c => c.key === this.$t(filter.key) && c.value === this.selectedFilter)
      if (existingChip) {
        existingChip.active = true
      } else {
        this.chips.push({
          key: this.$t(filter.key),
          value: this.selectedFilter,
          icon: this.getIconForObjectType(filter.key),
          close: true,
          active: true
        })
      }
      this.orderChips()
      this.selectedFilter = ''
      this.filterFetchedTickets(this.filterType.key === this.primaryFilterKey || this.filterType.key === this.secondaryFilterKey)
      this.setFilterOptions()
      this.setResetButton()
    },

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.ticketId = null
    },

    openDetailDialog () {
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    orderChips () {
      this.chips = this.chips.sort((a, b) => {
        return !a.close
          ? 1
          : a.key.localeCompare(b.key) !== 0
            ? a.key.localeCompare(b.key)
            : a.value.localeCompare(b.value)
      })
    },

    setResetButton () {
      const pfk = this.primaryFilterValue ? this.primaryFilterKey : undefined
      const sfk = this.secondaryFilterValue ? this.secondaryFilterKey : undefined
      this.showResetButton = !this.localFilterData.filterBy.every(fb =>
        (fb.key === pfk && fb.values.length === 1 && fb.values[0] === this.primaryFilterValue) ||
        (fb.key === sfk && fb.values.length === 1 && fb.values[0] === this.secondaryFilterValue)) ||
        (pfk && (this.allowFilterByDestination || this.primaryFilterKey !== 'destination') && this.localFilterData.filterBy.every(fb => fb.key !== this.primaryFilterKey)) ||
        (sfk && (this.allowFilterByDestination || this.secondaryFilterKey !== 'destination') && this.localFilterData.filterBy.every(fb => fb.key !== this.secondaryFilterKey))
    },

    initializeHeaders () {
      this.columnSet = TicketHeaders.ticketHeadersForInteractiveWidget(this.widget)
    },

    newColumnsSelected (columnSet) {
      this.columnSet = columnSet
    },

    resetColumns () {
      this.columnSet.columns = TicketHeaders.ticketHeadersForInteractiveWidget(this.widget).columns
    },

    chipActive (chip) {
      return chip.key === 'Destinations' ? !this.localFilterData.filterBy.some(f => f.key === 'destination') && chip.active : chip.active
    },

    async saveTicket (ticketId) {
      try {
        await this.updateTicket({
          ...this.allTickets.find(t => t.ticketId === ticketId),
          driverAccountId: this.driverSelectionByTicketId.get(ticketId)
        })
      } finally {
        await this.filterFetchedTickets()
      }
    },

    handleClose () {
      if (this.widget === DashboardOperationalWidgets.IncompleteTickets) {
        this.fetchIncompleteTickets()
      }
    },

    accountChosen (ticketId, account) {
      if (account) {
        this.driverSelectionByTicketId.set(ticketId, account.accountId)
      } else {
        this.driverSelectionByTicketId.delete(ticketId)
      }
    },

    tagClicked (tag) {
      const tagIndex = this.selectedTags.findIndex(t => t.tagId === tag.tagId)
      if (tagIndex === -1) {
        this.selectedTags.push(tag)
      } else {
        this.selectedTags.splice(tagIndex, 1)
      }
      this.filterAccounts()
    },

    checkUserSettings () {
      this.selectedTags =
      this.mutatedUserSettings[SETTING_TYPES.TRACT_FORM][SETTING_KEYS.DRIVER_ACCOUNT_AUTOCOMPLETE]
        .map(tagId => this.tags.find(t => t.tagId === tagId))
        .filter(t => t)
      this.filterAccounts()
    },

    filterAccounts () {
      this.filteredAccounts = this.allAccounts.filter(a => {
        if (this.selectedTags.length > 0) return a.accountTags.some(at => this.selectedTags.map(t => t.value).includes(at))
        return true
      })
      this.driverSelectionByTicketId.entries().forEach(e => {
        if (!this.filteredAccounts.map(a => a.accountId).includes(e[1])) {
          this.driverSelectionByTicketId.delete(e[0])
        }
      })
    }
  }
}

</script>
