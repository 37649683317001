<template>
  <v-menu
  bottom
  :close-on-content-click="false"
  v-model="open"
  left>
    <template #activator="{on}">
      <v-icon v-on="on" class="mr-2" color="secondary">{{!filterIsFalsity ? 'mdi-filter' : 'mdi-filter-outline'}}</v-icon>
    </template>

    <v-card width="600px">
      <v-card-title class="secondary white--text">
        <span>{{title ?? $t('filterItem', { item: $t('tracts') })}}</span>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-container fluid>
          <v-row dense class="mb-3">
            <span style="font-weight: bold;">
              {{$t('tractStatus')}}
            </span>
            <span v-if="unsavedFilterIsFalsity" class="font-italic ml-2">
              {{$t('selectOneOrMoreTractStatuses')}}
            </span>
          </v-row>
          <v-row dense no-gutters>
            <v-col v-for="ts in tractStatuses" :key="`status-${ts.name}`" cols="12" xs="12" sm="4">
              <v-checkbox
              :label="ts.name"
              v-model="filter.status"
              :value="ts.value"
              :class="'my-0'"/>
            </v-col>
          </v-row>
          <v-row dense>
            <span style="font-weight: bold;">
              {{$t('tractTypes')}}
            </span>
          </v-row>
          <v-row dense no-gutters>
            <v-col cols="9">
              <v-select
              v-if="selectableTractTypes.length > 0"
              :items="selectableTractTypes"
              v-model="filter.tractType"
              multiple
              chips
              return-object
              :item-text="tt => tt.name"/>
            </v-col>
          </v-row>
          <v-row dense>
            <span style="font-weight: bold;">
              {{ $t('forester') }}
            </span>
          </v-row>
          <v-row dense no-gutters>
            <v-col cols="9">
              <v-select
              v-if="allForesters"
              :items="allForesters"
              v-model="filter.tractForester"
              multiple
              chips
              return-object
              :item-text="tf => tf.name"/>
            </v-col>
          </v-row>
          <v-row>
            <v-btn color="secondary" outlined @click="resetFilter">
              {{$t('reset')}}
            </v-btn>
            <v-spacer/>
            <v-btn color="secondary" class="text--white" :disabled="!(changed?.size > 0) || unsavedFilterIsFalsity" @click="applyFilter">{{$t('apply')}}</v-btn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { TractStatus, TractTypeCategory } from '../../../utils/Enumerations'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'StandingTimberFilter',

  props: {
    title: {
      type: String,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    allForesters: [],
    filter: {
      status: [],
      tractType: [],
      tractForester: []
    },
    changed: new Set(),
    open: false
  }),

  watch: {
    'filter.status' (ss) {
      this.trackChangesFor('status', ss)
    },
    'filter.tractType' (tt) {
      this.trackChangesFor('tractType', tt)
    },
    'filter.tractForester' (tf) {
      this.trackChangesFor('tractForester', tf)
    },

    open (isOpen) {
      if (isOpen) {
        this.filter = JSON.parse(JSON.stringify(this.standingTimberFilter))
        if (this.unsavedFilterIsFalsity) this.hint = true
      }
    }
  },

  computed: {
    ...mapGetters('tract', ['standingTimberFilter']),
    ...mapGetters('tract-type', ['allTractTypes']),
    tractStatuses () {
      return [
        TractStatus[2],
        TractStatus[3],
        TractStatus[4]
      ]
    },

    selectableTractTypes () {
      return this.allTractTypes.filter(tt => tt.category === TractTypeCategory.Stumpage.value)
    },

    stumpageCategory () {
      return TractTypeCategory.Stumpage.value
    },

    filterIsFalsity () {
      return this.standingTimberFilter.status.length === 0
    },

    unsavedFilterIsFalsity () {
      return this.filter.status.length === 0
    }
  },

  async created () {
    this.allForesters = await this.getAllForesterUsers()
    this.filter.tractForester = this.allForesters
    await this.fetchTractTypes()
    this.readFilter()
    if (this.filterIsFalsity) {
      setTimeout(() => { this.open = true }, 250)
    }
  },

  methods: {
    ...mapActions('tract', ['setStandingTimberFilter', 'readStandingTimberFilter']),
    ...mapActions('tract-type', ['fetchTractTypes']),
    ...mapActions('user', ['getAllForesterUsers']),

    trackChangesFor (key, items) {
      const currentSet = new Set(this.standingTimberFilter[key])
      if (currentSet.size !== items.length || items.some(i => !currentSet.has(i))) {
        this.changed.add(key)
      } else {
        this.changed.delete(key)
      }
      this.filter[key] = items
    },

    resetFilter () {
      this.filter.status = []
      this.filter.tractType = []
      this.filter.tractForester = []
      this.updateFilter()
      this.$emit('input', JSON.parse(JSON.stringify(this.filter)))
    },

    updateFilter () {
      this.setStandingTimberFilter(JSON.parse(JSON.stringify(this.filter)))
      this.changed = new Set()
    },

    applyFilter () {
      this.updateFilter()
      this.$emit('input', JSON.parse(JSON.stringify(this.filter)))
      this.open = false
    },

    readFilter () {
      this.readStandingTimberFilter()
    }
  }
}
</script>
