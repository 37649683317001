<template>
    <v-card>
      <v-card-title class="secondary">
        <v-row>
          <v-col cols="auto">
            <span class="headline white--text">{{titleText}}</span>
          </v-col>
          <v-col v-if="editing" cols="auto">
            <Icon
              icon="mdi-account"
              :small="false"
              iconColor="white"
              :tooltipText="$t('account')"
              tooltipColor="secondary"
            />
            <span class="white--text">{{account.name}}</span>
          </v-col>
        </v-row>
        <v-spacer/>
        <BaseDialogActions hideRefresh/>
      </v-card-title>
      <v-card-text class="mt-4">
        <FormWrapper
          :buttonText="editing ? $t('saveChanges') : $t('createAdvance')"
          :disabled="isButtonDisabled"
          @submit="submit"
        >
          <v-container grid-list-lg>
            <AccountAutocomplete
              v-if="noAccount"
              data-testid="advance-account"
              @account-chosen="accountChosen"
            />
            <TemplateAutocomplete
              data-testid="advance-template"
              :templateId="advance.activityTemplateId ? advance.activityTemplateId : undefined"
              :propAccountingCategories="[0]"
              @template-chosen="templateChosen"
            />
            <v-row dense>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                <MoneyTextField
                  data-testid="advance-amount"
                  :initialValue="advance.amount"
                  :shouldHighlight="false"
                  :label="$t('amount')"
                  :readonly="allowModification || (advance.modifications && advance.modifications.length > 0)"
                  @val-changed="advanceAmountEntered"
                  :extraRules="['validAdvanceAmount']"
                >
                  <template v-slot:append>
                    <Icon
                      v-if="editing"
                      icon="mdi-pencil"
                      dataTestId="advance-mod-edit-button"
                      :small="true"
                      :iconColor="allowModification ? 'secondary' : 'red'"
                      :tooltipText="allowModification ? $t('addModification') : $t('modificationRequiresRecoveries')"
                      :tooltipColor="allowModification ? 'secondary' : 'red'"
                      @icon-clicked="openModForm"
                    />
                  </template>
                </MoneyTextField>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                <DatePicker
                  dataTestId="advance-effective-date"
                  :dateLabel="$t('effectiveDate')"
                  :startDate="advance.effectiveDate"
                  @valid-date="(val) => {isValidEffectiveDate = val}"
                  @date-picked="effectiveDateChosen"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <TractAutocomplete
                :propTractId="advance.tractId"
                clearable
                @tract-chosen="tractChosen"
                @tract-cleared="tractCleared"
                :propFilter="tractFilter"/>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col xs="12" sm="12" md="12" lg="12">
                <span class="title font-weight-bold">{{this.$t('recoveryInformation')}}</span>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="4">
                <v-select
                  v-model="advance.recoveryType"
                  item-color="secondary"
                  data-testid="advance-recovery-type"
                  :label="$t('recoveryType')"
                  :items="recoveryTypes"
                  :rules="[rules.required]"
                  color="black"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="4"
                v-show="advance.recoveryType !== recoveryTypes[1]"
              >
                <MoneyTextField
                  data-testid="advance-recovery-rate"
                  :initialValue="recoveryRateDollars"
                  :label="$t('recoveryRate')"
                  @val-changed="recoveryRateDollarsEntered"
                  :extraRules="['validAdvanceRecoveryRate']"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="4"
                v-show="advance.recoveryType === recoveryTypes[1]"
              >
                <v-text-field
                  v-model="recoveryRatePercentage"
                  ref="fieldTwo"
                  data-testid="advance-recovery-percent"
                  :rules="[rules.validPercentage(true)]"
                  :label="$t('recoveryRate')"
                  color="black"
                />
              </v-col>
              <v-col cols="12"  xs="12" sm="6" md="6" lg="4">
                <DatePicker
                  dataTestId="advance-recovery-start-date"
                  :startDate="advance.recoveryStartDate"
                  :dateLabel="$t('recoveryEffectiveDate')"
                  @valid-date="(val) => {isValidRecoveryDate = val}"
                  @date-picked="recoveryDateChosen"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <span class="title font-weight-bold">{{this.$t('title')}}</span>
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model="advance.note"
                  :rules="[rules.required, rules.minFiveCharacters]"
                  data-testid="advance-note"
                  auto-grow
                  counter
                  maxlength="64"
                  clearable
                  color="black"
                  name="input-7-4"
                >
              </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <v-layout wrap my-n6>
                  <v-spacer/>
                  <v-checkbox
                    v-model="advance.preIssued"
                    color="secondary"
                    data-testid="advance-preissued-check"
                    :label="$t('preIssued')"
                  />
                </v-layout>
              </v-col>
            </v-row>
          </v-container>
        </FormWrapper>
      </v-card-text>
      <Dialog :stateId="dialogId" maxWidth="600px" @close="closeModForm()">
        <AdvanceModForm
          v-if="editing"
          :initialAmount="advance.amount"
          :advanceId="propAdvance.advanceId"
          @advance-modified="advanceModified"
        />
      </Dialog>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import * as rules from '@/utils/rules.js'
import { utcToLocalDate, localToUTC } from '@/utils/DateFormatter.js'
import moment from 'moment'
import { RecoveryType } from '@/utils/Enumerations.js'

export default {
  name: 'AdvanceForm',

  props: {
    account: {
      type: Object,
      default: undefined
    },
    propAdvance: {
      type: Object,
      default: undefined
    },
    editing: {
      type: Boolean,
      default: false
    },
    noAccount: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    DatePicker: () => import('../../helper/DatePicker.vue'),
    MoneyTextField: () => import('../../helper/MoneyTextField.vue'),
    TemplateAutocomplete: () => import('../../autocomplete/TemplateAutocomplete.vue'),
    AdvanceModForm: () => import('./AdvanceModForm.vue'),
    AccountAutocomplete: () => import('../../autocomplete/AccountAutocomplete.vue'),
    TractAutocomplete: () => import('../../autocomplete/TractAutocomplete.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  data: () => ({
    maxAdvanceAmount: rules.MAX_ADVANCE_AMOUNT,
    maxAdvanceRecoveryRate: rules.MAX_ADVANCE_RECOVERY_RATE,
    dialogId: 'advance-form',
    rules: rules.default.rules,
    recoveryRatePercentage: 0,
    recoveryRateDollars: 0,
    isValidEffectiveDate: true,
    isValidRecoveryDate: true,
    modFormDialog: false,
    advance: {
      payee: {},
      activityTemplate: {},
      amount: 0,
      preIssued: true,
      recoveryStartDate: moment().format(),
      effectiveDate: moment().format(),
      activityTemplateId: null,
      recoveryRate: '',
      note: '',
      recoveryType: 'Flat Rate',
      tractId: undefined,
      tract: undefined
    },
    tractFilter: {
      status: [0, 1, 2, 3],
      selectedForesters: [],
      selectedTractTypes: []
    }
  }),

  computed: {
    titleText () { return this.editing ? this.$t('editAdvance') : this.$t('createAdvance') },
    recoveryTypes () {
      if (!RecoveryType) {
        return []
      }
      return RecoveryType.names
    },
    isButtonDisabled () {
      return (!(this.isValidEffectiveDate && this.isValidRecoveryDate))
    },
    allowModification () {
      return this.editing && this.propAdvance.balance !== this.propAdvance.amount
    }
  },

  created () {
    if (this.editing) {
      this.advance = JSON.parse(JSON.stringify(this.propAdvance))
      this.advance.recoveryStartDate = utcToLocalDate(this.propAdvance.recoveryStartDate)
      this.advance.effectiveDate = utcToLocalDate(this.propAdvance.effectiveDate)

      this.advance.recoveryType = RecoveryType.fromInt(this.propAdvance.recoveryType)
      if (this.advance.recoveryType === this.recoveryTypes[1]) {
        this.recoveryRatePercentage = this.advance.recoveryRate
      } else {
        this.recoveryRateDollars = this.advance.recoveryRate
      }
    }
  },

  methods: {
    ...mapActions('advances', ['createAdvance', 'updateAdvance']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    accountChosen (account) { this.advance.payeeId = account.accountId },
    effectiveDateChosen (date) { this.advance.effectiveDate = localToUTC(date) },
    recoveryDateChosen (date) { this.advance.recoveryStartDate = localToUTC(date) },
    recoveryRateDollarsEntered (amount) { this.recoveryRateDollars = amount },
    advanceAmountEntered (amount) { this.advance.amount = amount },
    templateChosen (template) { this.advance.activityTemplateId = template.activityTemplateId },
    advanceModified () { this.$emit('advance-changed') },

    async submit () {
      if (!this.validate()) {
        return
      }
      const requestObject = this.getFormattedRequestObject(this.advance)
      if (this.editing) {
        await this.updateAdvance(requestObject)
        this.$emit('advance-changed')
      } else {
        await this.createAdvance(requestObject)
        this.$emit('advance-changed')
      }
    },

    validate () {
      if (this.advance.amount > this.maxAdvanceAmount) {
        this.setSnackError(this.$t('invalidAdvanceAmount'))
        return false
      } else if (this.advance.amount <= 0) {
        this.setSnackError(this.$t('advanceAmountError'))
        return false
      } else if (this.recoveryRateDollars >= this.maxAdvanceRecoveryRate) {
        this.setSnackError(this.$t('invalidAdvanceRecoveryRate'))
        return false
      } else if (!this.account && !this.advance.payeeId) {
        this.setSnackError(this.$t('noAccountChosen'))
        return false
      } else {
        if (this.advance.recoveryType === this.recoveryTypes[1] && (this.recoveryRatePercentage <= 0 || this.recoveryRatePercentage > 100)) {
          this.setSnackError(this.$t('advanceInvalidPercentage'))
          return false
        } else if (this.advance.recoveryType !== this.recoveryTypes[1] && this.recoveryRateDollars <= 0) {
          this.setSnackError(this.$t('advanceNonzeroRate'))
          return false
        }
      }

      if (!this.advance.note || this.advance.note?.length < 5) {
        this.setSnackError(this.$t('advanceNeedsTitle'))
        return false
      }

      if (!this.advance?.activityTemplateId) {
        this.setSnackError(this.$t('advanceRequiresActivityTemplate'))
        return false
      }

      return true
    },

    tractChosen (val) {
      this.advance.tractId = val?.tractId
      this.advance.tract = val?.name
    },

    tractCleared () {
      this.advance.tractId = undefined
      this.advance.tract = undefined
    },

    getFormattedRequestObject (advance) {
      const recoveryType = RecoveryType.toInt(advance.recoveryType)
      const recoveryRate = recoveryType === 1 ? this.recoveryRatePercentage : this.recoveryRateDollars
      const formattedAdvance = {
        amount: advance.amount,
        note: advance.note,
        preIssued: advance.preIssued,
        recoveryStartDate: advance.recoveryStartDate,
        effectiveDate: advance.effectiveDate,
        recoveryRate: parseFloat(recoveryRate),
        recoveryType: recoveryType,
        payeeId: !this.noAccount ? this.account.accountId : this.advance.payeeId,
        activityTemplateId: this.advance.activityTemplateId,
        tractId: advance.tractId
      }

      if (this.editing) {
        formattedAdvance.advanceId = this.propAdvance.advanceId
      }

      return formattedAdvance
    },

    openModForm () {
      if (this.allowModification) {
        this.openOrUpdateDialog({ id: this.dialogId, width: '600px' })
      }
    },

    closeModForm () {
      this.closeDialogsAtOrAbove(this.dialogId)
    }
  }
}
</script>
