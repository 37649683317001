import store from '@/store/index.js'
import { getRelevantUrlComponent } from './ApiHelpers.js'
import { outboundRequests } from '@/utils/api/Interceptor.js'

export const responseHandler = (response) => {
  const { config } = response
  delete outboundRequests[config.url]

  if (shouldSnack(config)) {
    store.dispatch('snackbar/setSnack', getSnackMessageForResponse(config))
  }

  store.dispatch('global/setLoading', false)
  return response
}

const shouldSnack = ({ url, method }) => {
  return method !== 'get' && method !== 'patch' &&
    !url.toLowerCase().includes('userpreferences') &&
    !(url.toLowerCase().includes('postbatches') && url.toLowerCase().includes('tickets'))
}

const getSnackMessageForResponse = ({ url, method }) => {
  const resourceName = getRelevantUrlComponent(url)

  if (resourceName === 'user preferences') return

  if (customResponses[resourceName]) return customResponses[resourceName][method]

  switch (method) {
    case 'put': return `Updated ${resourceName} successfully!`
    case 'delete': return `Deleted ${resourceName} successfully!`
    case 'post': return `Created ${resourceName} successfully!`
  }
}

const customResponses = {
  'consumption group': {
    post: 'Inventory consumption successful!',
    delete: 'Consumption reverted successfully!'
  },
  'posting group': {
    post: 'Selected tickets posted successfully!',
    delete: 'Select tickets have been unposted successfully!'
  },
  transferbatche: {
    post: 'Inventory transfer successful!'
  },
  deckadjustment: {
    post: 'Deck adjustment created successfully!',
    delete: 'Deck adjustment deleted successfully!'
  },
  adjustingentrie: {
    post: 'Adjusting Entries creation process is underway, please refresh shortly!'
  },
  userclaim: {
    post: 'Modified role assignment successfully!'
  },
  contractapproval: {
    post: 'Created contract approval successfully!',
    put: 'Updated contract approval successfully!'
  },
  tractlandowner: {
    post: 'Allocated landownership successfully!',
    delete: 'Removed landownership successfully!'
  },
  templatebundle: {
    post: 'Created template bundle successfully!',
    put: 'Updated template bundle successfully!',
    delete: 'Deleted template bundle successfully'
  }
}
