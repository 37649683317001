<template>
  <v-card>
    <v-card-title
    :class="`${!isByproduct ? 'secondary' : ''} white--text`"
    :style="`background-color: ${isByproduct ? '#363636' : 'unset'}`">
      <Icon
      :icon="headerIcon.icon"
      :tooltipText="$t(headerIcon.tooltip)"
      iconColor="white"
      :small="false"
      margin="mr-2"
      />
      <span>
        {{$t('activitiesForPayeeTitle', { payeeName: counterpartyName })}}
      </span>
      <v-spacer></v-spacer>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" xs="12" sm="12" md="6">
            <div class="font-weight-bold">
              {{$t('contractInformation')}}
            </div>
            <table style="border-collapse: separate; border-spacing: 10px">
              <tr v-for="(chunk, i) in chunk(contractIcons, 2)" :key="i">
                <td v-for="ci in chunk" :key="ci.icon">
                  <Icon
                    dataTestId="contract-tract"
                    :icon="ci.icon"
                    :tooltipText="$t(ci.tooltip)"
                    iconColor="secondary"
                    tooltipColor="secondary"
                  />
                  <span class="ml-2">{{ci.value(contractInfo)}}</span>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6">
            <div class="ml-2 font-weight-bold">
              {{$t('totalsForEntity', { entity: counterpartyName })}}
            </div>
            <table style="border-collapse: separate; border-spacing: 10px">
              <tr v-for="(ttc, ii) in chunk(totalsTable, 2)" :key="`ttc-${ii}`">
                <template v-for="tt in ttc">
                  <td :key="`tti-${tt.label}`">{{$t(tt.label)}}:</td>
                  <td :key="`tti-v${tt.label}`">{{tt.value ?? $t('notAvailable')}}</td>
                </template>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row dense>
          <v-switch
            v-model="ticketSummaries"
            color="secondary"
            data-testid="ticket-summary-switch"
            :label="$t('showTicketSummaries')"
          ></v-switch>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-expansion-panels v-model="openPanel">
              <v-expansion-panel v-for="(activity, index) in paymentRollup" :key="activity.activityId">
                <v-expansion-panel-header class="pa-12">
                  <v-row dense>
                    <v-col :cols="isFullscreen ? 9 : 8" class="mt-8">{{activity.activityName}}</v-col>
                    <v-col :cols="isFullscreen ? 3 : 4">
                      <table style="font-size: 16px; border-collapse: separate; border-spacing: 8px; table-layout: fixed; width: 100%;">
                        <tr>
                          <td style="width: 100px;">{{$t('payout')}}: </td>
                          <td class="text-right">
                            {{formatMoney(activity.payout ?? activity.amount)}}
                            {{getRecoveredText(activity)}}
                          </td>
                        </tr>
                        <tr>
                          <td>{{$t('avgRate')}}: </td>
                          <td class="text-right">
                            <Icon
                            v-if="splitByActivity.get(activity.activityName)?.length > 0"
                            :iconColor="index === openPanel ? 'white' : 'secondary'"
                            icon="mdi-percent-circle-outline"
                            :small="false"
                            :tooltipText="$t('reflectsOwnershipPercentage', { x: ownershipPercentageValue(splitByActivity.get(activity.activityName)) })"/>
                            {{formatMoney(activity.averageRate)}}{{activity.modifier === activityModifier.DistanceTraveled.value ? '/m' : '/t'}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('unit')}}: </td>
                          <td class="text-right">
                            {{getUnitText(activity)}}
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                  :items="activity.productSummaries"
                  :headers="productHeaders"
                  dense
                  :hide-default-footer="activity.productSummaries.length < 10"
                  class="mt-6"
                  >
                    <template #item="{item}">
                      <tr>
                        <td class="text-left">{{item.product}}</td>
                        <td class="text-right">{{item.loadCount}}</td>
                        <td class="text-right">{{item.grossTons}}t</td>
                        <td class="text-right">{{item.netTons}}t</td>
                        <td class="text-right">{{getPayOnTonsForProductSummary(item, activity)}}</td>
                        <td class="text-right">
                          {{formatMoney(item.rate)}}{{activity.modifier === activityModifier.DistanceTraveled.value ? '/m' : '/t'}}
                          <span v-if="splitByActivity.get(activity.activityName)?.length > 0">
                           x {{ ownershipPercentageValue(splitByActivity.get(activity.activityName)) }}
                          </span>
                        </td>
                        <td class="text-right" v-if="!isTransfer && !isReceivable">{{formatMoney(item.grossPayout - item.payout)}}</td>
                        <td class="text-right">{{formatMoney(item.payout)}}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row dense v-if="ticketSummaries">
          <v-col cols="12" class="mt-6">
            <span class="h5 font-weight-bold black--text">{{$t('ticketSummaries')}}</span>
          </v-col>
          <v-col cols="12">
            <v-data-table
            :items="loadSummariesForTable"
            :headers="loadSummaryHeaders"
            :hide-default-footer="loadSummariesForTable.length < 10"
            dense>
            <template #item.actions="{item}">
              <Icon
              icon="mdi-briefcase"
              :tooltipText="$t('unpostTicket')"
              iconColor="error"
              tooltipColor="error"
              @icon-clicked="showUnpostDialog(item)"/>
            </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog v-model="showUnpostTicketsDialog" max-width="400px">
      <ConfirmDialog
      :title="$t('unpostTicket')"
      :body="$t('unpostTicketBodySBP')"
      :confirmText="$t('unpostTicket')"
      @cancel="closeUnpostDialog"
      @confirm="unpostFocusedTicket"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { settlementProductHeaders, contractTicketHeaders } from '@/headers/Cycles.js'
import { PayOn, PayBy, ActivityModifier, ContractType } from '@/utils/Enumerations.js'
import { contractIconsFor } from '../ContractIcons'
import { chunk } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { formatMoney, numberWithCommas, tonStringFromPounds, formatPercentage } from '@/utils/NumericMutations'
export default {
  name: 'SettlementActivityBreakdown',

  props: {
    propPayee: {
      type: Object,
      required: true
    },
    propContractInfo: {
      type: Object,
      required: true
    },
    isReceivable: {
      type: Boolean,
      required: false
    }
  },

  components: {
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    ticketSummaries: false,
    activityModifier: ActivityModifier,
    showUnpostTicketsDialog: false,
    focusedTicketForUnpost: undefined,
    loadSummariesForTable: undefined,
    splitByActivity: new Map(),
    openPanel: undefined
  }),

  watch: {
    propContractInfo: {
      handler (_) {
        this.calculateLoadSummaries()
      },
      deep: true
    }
  },

  created () {
    this.calculateLoadSummaries()
    this.initSplitByActivityMap()
  },

  computed: {
    ...mapGetters('dialog', ['topDialogState']),
    ...mapGetters('settlements', ['currentSubModuleKey', 'dateConfiguration']),
    isTransfer () { return this.propContractInfo.type === ContractType.Transfer.value },
    isByproduct () { return this.propContractInfo.type === ContractType.ByproductSale.value || this.propContractInfo.type === ContractType.ByproductPurchase.value },

    productHeaders () {
      const headers = settlementProductHeaders(this.$i18n.locale)
      return (this.isTransfer || this.isReceivable)
        ? headers.filter(h => h.value !== 'recovered')
        : headers
    },

    loadSummaryHeaders () {
      const headers = contractTicketHeaders()
      return headers
        .filter(h => (!this.isTransfer && !this.isReceivable) || h.value !== 'grossAmount')
    },

    isFullscreen () {
      return this.topDialogState?.fullscreen ?? false
    },

    payeeHasPayout () {
      return this.propPayee.payout !== undefined && this.propPayee.payout !== null
    },

    loadSummaries () {
      return (this.payeeHasPayout) ? this.propPayee.loadSummaries : this.propContractInfo.loadSummaries
    },

    totals () {
      return (this.isReceivable)
        ? {
          payout: this.propContractInfo.amount,
          loads: this.propContractInfo.loads,
          grossTons: this.propContractInfo.grossTons,
          netTons: this.propContractInfo.netTons,
          recovered: 0
        } : {
          payout: this.payee.payout,
          loads: this.contractInfo.loads,
          grossTons: this.contractInfo.grossTons,
          netTons: this.contractInfo.netTons,
          recovered: (typeof this.payee.grossPayout === 'number') ? this.payee.grossPayout - this.payee.payout : 0
        }
    },

    grossTons () {
      return (this.isReceivable) ? this.propPayee.grossTons : this.contractInfo.grossTons
    },

    paymentRollup () {
      return (this.payeeHasPayout) ? this.propPayee.paymentRollup : this.propContractInfo.paymentRollup
    },

    contractInfo () {
      if (this.propContractInfo.loads) {
        return this.propContractInfo
      } else {
        const contractInfo = { ...this.propContractInfo }
        contractInfo.loads = this.propContractInfo.loadSummaries.length
        return contractInfo
      }
    },

    payee () {
      if ((this.propPayee.payout !== undefined && this.propPayee.payout !== null) || this.isReceivable) {
        return this.propPayee
      } else {
        const payee = { ...this.propContractInfo }
        payee.payeeName = this.propPayee.payeeName
        payee.payout = this.propContractInfo.amount
        payee.grossPayout = this.propContractInfo.grossAmount
        return payee
      }
    },

    counterpartyName () {
      return this.propPayee?.counterpartyName ?? this.propPayee?.payeeName
    },

    contractIcons () {
      return this.contractIconsFor(this.contractInfo.type, 'contract', (this.isReceivable) ? {
        destination: {
          value: c => c.destinationAccountName
        },
        fromAccount: {
          value: c => c.fromAccountName
        }
      } : {})
    },

    headerIcon () {
      const headerIcons = [
        {
          contractTypes: [ContractType.Production.value, ContractType.WoodsSale.value],
          icon: 'mdi-clipboard-outline',
          tooltip: 'logContract'
        },
        {
          contractTypes: [ContractType.ByproductSale.value, ContractType.ByproductPurchase.value],
          icon: 'mdi-circular-saw',
          tooltip: 'byproductContract'
        },
        {
          contractTypes: [ContractType.Transfer.value],
          icon: 'mdi-transfer',
          tooltip: 'transferContract'
        }
      ]
      return headerIcons.find(hi => hi.contractTypes.some(ct => ct === this.contractInfo.type)) ?? headerIcons[0]
    },

    totalsTable () {
      return [
        {
          label: 'payout',
          value: (this.totals.recovered > 0)
            ? `${formatMoney(this.totals.payout)} (${this.$t('xRecovered', { x: formatMoney(this.totals.recovered) })})`
            : `${formatMoney(this.totals.payout)}`
        },
        {
          label: 'loads',
          value: this.totals.loads
        },
        {
          label: 'grossTons',
          value: this.totals.grossTons
        },
        {
          label: 'netTons',
          value: this.totals.netTons
        }
      ]
    },

    isExportBatch () {
      return this.dateConfiguration?.exportBatch?.exportBatchId !== undefined
    }
  },

  methods: {
    ...mapActions('posting', ['unpostTicket']),
    formatMoney,
    numberWithCommas,
    tonStringFromPounds,
    chunk,
    contractIconsFor,

    getUnitText ({
      payOn,
      payBy,
      modifier,
      grossTons,
      defectTons,
      mismanufactureDefectTons,
      natureDefectTons,
      netTons
    }) {
      if (payBy === PayBy.Load.value) return this.$t('payByLoad')
      if (modifier === ActivityModifier.DistanceTraveled.value) { return this.$t('distanceInMiles', { distance: this.contractInfo.distance }) }

      let weight = 0
      switch (payOn) {
        case PayOn.Gross.value: weight = grossTons; break
        case PayOn.Net.value: weight = netTons; break
        case PayOn.Defect.value: weight = defectTons; break
        case PayOn.NatureDefect.value: weight = natureDefectTons; break
        case PayOn.MismanufacturedDefect.value: weight = mismanufactureDefectTons; break
        default: weight = netTons
      }

      return `${PayOn.fromInt(payOn)}: ${weight.toFixed(3)}t`
    },

    getPayOnTonsForProductSummary (productSummary, activity) {
      if (activity.payBy === PayBy.Load.value) return this.$t('payByLoad')

      let payOnTons = productSummary.netTons

      switch (activity.payOn) {
        case PayOn.Gross.value: payOnTons = productSummary.grossTons; break
        case PayOn.Net.value: payOnTons = productSummary.netTons; break
        case PayOn.Defect.value: payOnTons = productSummary.defectTons; break
        case PayOn.NatureDefect.value: payOnTons = productSummary.natureDefectTons; break
        case PayOn.MismanufacturedDefect.value: payOnTons = productSummary.mismanufactureDefectTons; break
        default: payOnTons = productSummary.netTons
      }

      return `${payOnTons}t`
    },

    getNetTons ({ inWeight, outWeight, defectWeight }) {
      return tonStringFromPounds(inWeight - outWeight - defectWeight)
    },

    getGrossTons ({ inWeight, outWeight }) {
      return tonStringFromPounds(inWeight - outWeight)
    },

    getRecoveredText ({ payout, grossPayout }) {
      if (payout === grossPayout) {
        return ''
      } else {
        return `(${formatMoney(grossPayout - payout)} Recovered)`
      }
    },

    showUnpostDialog (ticket) {
      if (this.isExportBatch) {
        this.setSnackError(this.$t('ticketsCannotBeUnpostedInExportBatch'))
        return
      }

      this.focusedTicketForUnpost = ticket
      this.showUnpostTicketsDialog = true
    },

    closeUnpostDialog () {
      this.focusedTicketForUnpost = undefined
      this.showUnpostTicketsDialog = false
    },

    async unpostFocusedTicket () {
      await this.unpostTicket(this.focusedTicketForUnpost)
      this.$emit('refresh-settlement')
      this.closeUnpostDialog()
    },

    calculateLoadSummaries () {
      this.loadSummariesForTable = JSON.parse(JSON.stringify(this.loadSummaries))
      this.loadSummariesForTable.forEach(item => {
        item.gross = this.getGrossTons(item)
        item.netWeight = this.getNetTons(item)
        item.grossAmount = this.formatMoney(item.grossAmount - item.amount)
        item.amount = this.formatMoney(item.amount)
        item.defectWeight = this.tonStringFromPounds(item.defectWeight)
      })
    },

    initSplitByActivityMap () {
      const loadSummaryRoot = this.payeeHasPayout ? this.propPayee.loadSummaries : this.propPayee.contracts.flatMap(c => c.loadSummaries)
      loadSummaryRoot.forEach(ls => ls.paymentDetails.forEach(pd => {
        const key = pd.activity
        const splits = this.splitByActivity.get(key) ?? []
        if (pd.computedAtSplit !== null && pd.computedAtSplit !== 100) splits.push(pd.computedAtSplit)
        this.splitByActivity.set(key, splits)
      }))
    },

    ownershipPercentageValue (val) {
      return val.length === 1 ? formatPercentage(val[0]) : this.$t('multipleSplits')
    }
  }
}
</script>
