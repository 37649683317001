<template>
  <v-container fluid class="mx-n3">
    <v-tabs
    v-model="currentSubModuleTab"
    slider-color="secondary"
    color="#888888"
    slider-size="4">
      <v-tab v-for="subModuleName in subModuleNames" :key="subModuleName">
        <span class="black--text" style="font-size: 16px;">
          {{subModuleName}}
        </span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentSubModuleTab" v-if="!loading && currentSubModule !== undefined && !showNoDataText" class="mt-2" touchless>
      <v-tab-item>
        <ReceivableRegister
        isByproduct
        :receivableRegisterObject="currentSubModule"/>
      </v-tab-item>
      <v-tab-item>
        <ReceivablesByPayer
        isByproduct
        :receivablesByContractObject="currentSubModule"
        @refresh-settlement="refreshSettlement"/>
      </v-tab-item>
    </v-tabs-items>
    <v-row class="my-12 pa-12" v-if="loading" justify="center" align="center">
      <v-progress-circular indeterminate color="secondary"/>
    </v-row>
    <v-row class="my-12 pa-12" v-if="showNoDataText" justify="center" align="center">
      <span class="headline black--text">
        {{ $t('noDataForSelection') }}
      </span>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SettlementKeys, SettlementSubKeys } from '@/components/settlements/SettlementKeys.js'

export default {
  name: 'BPAccountsReceivableModule',

  components: {
    ReceivableRegister: () => import('@/components/settlements/accounts-receivable-module/receivable-register/ReceivableRegister.vue'),
    ReceivablesByPayer: () => import('@/components/settlements/accounts-receivable-module/ReceivablesByPayer.vue')
  },

  data: () => ({
    currentSubModuleTab: 0
  }),

  watch: {
    currentSubModuleTab (tab) {
      let subkey = ''
      switch (tab) {
        case 0:
          subkey = SettlementSubKeys.AR.BPReceivableRegister
          break
        case 1:
          subkey = SettlementSubKeys.AR.BPReceivablesByPayer
          break
      }
      this.setCurrentModule({
        moduleKey: SettlementKeys.AccountsReceivable,
        subModuleKey: subkey
      })
    }
  },

  computed: {
    ...mapGetters('settlements', ['receivableModule', 'currentSubModuleKey', 'loading']),

    subModuleNames () {
      return [
        this.$t('receivableRegister'),
        this.$t('receivableByPayer')
      ]
    },

    currentSubModule () {
      return this.receivableModule[this.currentSubModuleKey]
    },

    showNoDataText () {
      if (this.currentSubModule === undefined || this.loading) {
        return false
      }

      switch (this.currentSubModuleKey) {
        case SettlementSubKeys.AR.BPReceivableRegister:
          return this.currentSubModule.receivables.length === 0
        case SettlementSubKeys.AR.BPReceivablesByPayer:
          return this.currentSubModule.counterpartyDetails.length === 0
        default:
          return true
      }
    }
  },

  methods: {
    ...mapActions('settlements', ['setCurrentModule', 'refreshModuleData', 'clearStaleSubModuleData']),

    async refreshSettlement () {
      await this.refreshModuleData(false)
      this.clearStaleSubModuleData({ isByproduct: true })
    }
  }
}
</script>
